import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Container,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, ProductBrands } from '../../../oas/codegen3';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from '@reach/router';
import { IconTrash } from '@tabler/icons-react';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { openConfirmModal } from '@mantine/modals';

export const BrandFormPage = ({
  brandID,
}: {
  path?: string;
  brandID?: string;
}) => {
  const navigate = useNavigate();
  const getProductBrand = useStatefulAPIRequestMaker(
    DefaultOperations.getProductBrand
  );

  const deleteBrand = useStatefulAPIRequestMaker(
    DefaultOperations.deleteProductBrand
  );

  useDocumentTitle(
    brandID
      ? 'Brand: ' + getProductBrand.response?.productBrand.brandName ??
          'Loading...'
      : 'New Brand'
  );

  const reloadBrand = () => {
    if (brandID) {
      getProductBrand.execute({
        productBrandId: brandID,
      });
    }
  };

  useEffect(() => {
    reloadBrand();
  }, [brandID]);

  const showDeleteModal = () => {
    if (!getProductBrand.response?.productBrand) {
      return;
    }

    openConfirmModal({
      title: (
        <Title order={3}>
          Delete {getProductBrand.response?.productBrand.brandName}
        </Title>
      ),
      children: (
        <Text>
          Are you sure you want to delete this product brand? All products will
          this brand will not have a brand associated with it.
        </Text>
      ),
      labels: {
        confirm: 'Yes - Delete Brand',
        cancel: 'Go Back',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        await deleteBrand.execute({ productBrandId: brandID! });

        showNotification({
          color: 'green',
          title: 'Brand Deleted',
          message: `The brand ${getProductBrand.response?.productBrand.brandName} has been deleted.`,
        });

        navigate('/products/brands');
      },
    });
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <LoadingContainer
          loading={getProductBrand.loading || deleteBrand.loading}
        >
          <Stack>
            <Stack spacing={0}>
              {brandID ? (
                <Stack spacing={0}>
                  <Text size="sm" fw={700}>
                    Brand Name
                  </Text>
                  <Title>
                    {getProductBrand.response?.productBrand.brandName ??
                      'Loading...'}
                  </Title>
                </Stack>
              ) : (
                <Title>New Product Brand</Title>
              )}
            </Stack>

            <Group>
              <Button
                compact
                variant="light"
                color="red"
                leftIcon={<IconTrash size={14} />}
                onClick={showDeleteModal}
              >
                Delete
              </Button>
            </Group>

            <ProductBrandForm
              currentProductBrand={getProductBrand.response?.productBrand}
              onBrandUpdated={reloadBrand}
            />
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};

const ProductBrandForm = ({
  currentProductBrand,
  onBrandUpdated,
}: {
  currentProductBrand?: ProductBrands;
  onBrandUpdated: () => void;
}) => {
  const navigation = useNavigate();
  const createBrand = useStatefulAPIRequestMaker(
    DefaultOperations.createProductBrand
  );

  const updateBrand = useStatefulAPIRequestMaker(
    DefaultOperations.updateProductBrand
  );

  type FormFields = {
    brandName: string;
  };

  const form = useForm<FormFields>();

  useEffect(() => {
    if (currentProductBrand) {
      form.setValues({
        brandName: currentProductBrand.brandName,
      });
    }
  }, [currentProductBrand]);

  const onSubmit = form.onSubmit(async (v) => {
    if (currentProductBrand) {
      await updateBrand.execute({
        productBrandId: currentProductBrand.id,
        requestBody: {
          brandName: v.brandName,
        },
      });

      showNotification({
        color: 'green',
        title: 'Product Brand Updated',
        message: `Brand ${v.brandName} has been created.`,
      });

      onBrandUpdated();
    } else {
      const response = await createBrand.execute({
        requestBody: {
          brandName: v.brandName,
        },
      });

      showNotification({
        color: 'green',
        title: 'Product Brand Created',
        message: `Brand ${v.brandName} has been created.`,
      });

      navigation(`products/brands/${response.productBrand.id}`);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <TextInput
          placeholder="Product Brand"
          label="Product Brand"
          required
          {...form.getInputProps('brandName')}
        />

        <Group>
          <Button
            type="submit"
            color="green"
            loading={createBrand.loading || updateBrand.loading}
          >
            {currentProductBrand ? 'Update' : 'Create'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
