import React from 'react';
import { StockLocation } from '@pcc/api/models/StockLocation';
import { GenericSelector } from '@components/V2/selectors/GenericSelector';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../oas/codegen3';

export const StockLocationSelector = ({
  onStockLocationSelected,
  stockLocation,
  disabled,
  label,
  readOnly,
  storeID,
  error,
  stockLocationID,
}: {
  onStockLocationSelected: (sl: StockLocation | null) => void;
  stockLocation?: StockLocation | null;
  stockLocationID?: string | null;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  storeID?: string;
  error?: string | null;
}) => {
  const searchStockLocations = useStatefulAPIRequestMaker(
    DefaultOperations.searchStockLocations
  );
  const getStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.getStockLocation
  );

  const searchItems = (target: string) => {
    searchStockLocations.execute({ target, storeId: storeID });
  };

  const searchItemsResponse = (
    searchStockLocations.response?.stockLocations || []
  ).map((sl) => {
    return {
      value: sl.stockLocation.id,
      label: sl.stockLocation.name,
      item: sl.stockLocation,
    };
  });

  const getItemByID = async (stockLocationID: string) => {
    const response = await getStockLocation.execute({
      stockLocationId: stockLocationID,
    });
    return response.stockLocation;
  };

  const item = stockLocation
    ? {
        value: stockLocation.id,
        label: stockLocation.name,
        item: stockLocation,
      }
    : null;

  return (
    <GenericSelector
      onItemSelected={onStockLocationSelected}
      searchItems={searchItems}
      searchItemsResponse={searchItemsResponse}
      getItemByID={getItemByID}
      itemID={stockLocationID}
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      item={item}
      loading={searchStockLocations.loading || getStockLocation.loading}
      error={error}
    />
  );
};
