import { Location, Router } from '@reach/router';
import { ListSalePayments } from './ListSalePayments';
import { SalePaymentViewer } from './SalePaymentViewer';

export const SalePaymentRoutes = ({}: { path?: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListSalePayments path="/" />
          <SalePaymentViewer path="/:salePaymentID" />
        </Router>
      )}
    </Location>
  );
};
