import { Button, Container, Group, Stack, Table, Title } from '@mantine/core';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import { useEffect } from 'react';
import { navigate } from '@reach/router';
import { EnabledBadge } from '@components/V2/EnabledBadge';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';

export const PaymentMethodsList = ({}: { path?: string }) => {
  const listPaymentMethods = useStatefulAPIRequestMaker(
    DefaultOperations.getPaymentMethods
  );

  useEffect(() => {
    listPaymentMethods.execute({});
  }, []);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Payment Methods</Title>
        <Group>
          <Button color="green" onClick={() => navigate('/paymentMethods/new')}>
            New Payment Method
          </Button>
        </Group>
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Target Payment Type</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {(listPaymentMethods.response?.paymentMethods || []).map((l) => {
              return (
                <tr
                  onClick={() =>
                    navigate(`/paymentMethods/${l.paymentMethod.id}`)
                  }
                >
                  <td>{l.paymentMethod.name}</td>
                  <td>{l.paymentMethod.targetPaymentType}</td>
                  <td>
                    <EnabledBadge fullWidth enabled={l.paymentMethod.enabled} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Stack>
    </Container>
  );
};
