import { Location, Router } from '@reach/router';
import React from 'react';
import { ListCouponGroups } from './ListCouponGroups';
import { CouponGroupForm } from './CouponGroupForm';

export const CouponsRouter = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListCouponGroups path="/" />
          <CouponGroupForm path="/new" />
          <CouponGroupForm path="/:couponGroupID" />
        </Router>
      )}
    </Location>
  );
};
