import { Location, Router, useNavigate } from '@reach/router';
import React, { useState } from 'react';
import { HomePage } from './HomePage';
import { ProductRouter } from './product_pages';
import { TaxRouter } from './taxpages';
import { StoresPages } from './storespages';
import { VendorPages } from './vendorpages';
import { Box } from '../../components/box/Box';
import { FlexBox } from '../../components/flex/FlexBox';
import { UserPages } from './userpages';
import {
  IconLayoutSidebarLeftExpand,
  IconLayoutSidebarLeftCollapse,
} from '@tabler/icons-react';
import { AnalyticsRouter } from './analytics/AnalyticsRouter';
import { Navbar2 } from '../../components/navbar2/Navbar2';
import { PurchaseOrderRouter } from './purchaseorderpages/PurchaseOrderRouter';
import {
  ActionIcon,
  Center,
  Collapse,
  Loader,
  Stack,
  Tooltip,
  Transition,
} from '@mantine/core';
import { PX8 } from '../../components/Px';
import { MemberPageRouter } from './memberpage/MemberPageRouter';
import { SystemSettingsRouter } from './systemsettingspages/SystemSettingsRouter';
import { SalesRouter } from './salepages/SalesRouter';
import { UserStoreRouter } from './userstorepage/UserStoreRouter';
import { StockLocationsRouter } from './stockLocationPages/StockLocationRouter';
import { StockMovePagesRouter } from './stockmovepages/StockMovePagesRouter';
import { PaymentMethodRoutes } from './payment_method_pages/PaymentMethodRoutes';
import { PosAppVersionPages } from './pos_app_version_pages';

import { TeamPages } from './team_pages/TeamPages';
import { StockAdjustmentGroupRouter } from './stockAdjustmentGroupPages/StockAdjustmentGroupRouter';
import { CouponsRouter } from './coupon_pages/CouponsRouter';
import { usePccStoreSelector } from '../../pccstores/PccStore';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const AdminPanelRouter = ({}: { default?: any }) => {
  const [navbarOpened, setNavbarOpened] = useState(true);
  const navigate = useNavigate();
  const authenticated = usePccStoreSelector(
    (state) => state.userStates.authentication.authenticated
  );

  if (authenticated !== true) {
    return <CenterPageLoader />;
  }

  return (
    <FlexBox height="100vh">
      <Collapse in={navbarOpened}>
        <Navbar2
          navigate={navigate}
          hideSidebarIcon={
            <Tooltip label="Hide Sidebar">
              <ActionIcon
                size="xl"
                color="orange"
                m={PX8.Number}
                onClick={() => setNavbarOpened(false)}
              >
                <IconLayoutSidebarLeftCollapse />
              </ActionIcon>
            </Tooltip>
          }
        />
      </Collapse>

      <Transition
        mounted={!navbarOpened}
        transition="slide-right"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <div
            style={{
              position: 'relative',
              zIndex: 100,
              ...styles,
            }}
          >
            <Tooltip label="Show sidebar">
              <ActionIcon
                size="xl"
                m={PX8.Number}
                onClick={() => setNavbarOpened(true)}
                style={{ position: 'absolute' }}
              >
                <IconLayoutSidebarLeftExpand />
              </ActionIcon>
            </Tooltip>
          </div>
        )}
      </Transition>

      <Box flexGrow={1} overflow="scroll">
        <Location>
          {({ location }) => (
            <Router location={location}>
              <HomePage path="/home" />
              <ProductRouter path="/products/*" />
              <SalesRouter path="/sales/*" />
              <StockLocationsRouter path="/stockLocations/*" />
              <TaxRouter path="/taxes/*" />
              <StoresPages path="/stores/*" />
              <VendorPages path="/vendors/*" />
              <PurchaseOrderRouter path="/purchaseOrders/*" />
              <AnalyticsRouter path="/analytics/*" />
              <MemberPageRouter path="/members/*" />
              <StockMovePagesRouter path="/stockMoves/*" />
              <UserStoreRouter path="/store/:storeID/*" />
              <PaymentMethodRoutes path="/paymentMethods/*" />
              <PosAppVersionPages path="/posAppVersions/*" />
              <StockAdjustmentGroupRouter path="/stockAdjustmentGroups/*" />
              <CouponsRouter path="/couponGroups/*" />

              <UserPages path="/users/*" />
              <TeamPages path="/teams/*" />
              <SystemSettingsRouter path="/settings/system/*" />
            </Router>
          )}
        </Location>
      </Box>
    </FlexBox>
  );
};

const CenterPageLoader = () => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'fixed' }}>
      <Center h="100%">
        <Stack align="center">
          <Loader />
          Loading...
        </Stack>
      </Center>
    </div>
  );
};
