import { showNotification } from '@mantine/notifications';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import { useNavigate } from '@reach/router';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import {
  Button,
  Container,
  Flex,
  Group,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

export const ProductClassCodeFormPage = ({
  productClassCodeID,
}: {
  productClassCodeID?: string;
  path?: string;
}) => {
  const navigate = useNavigate();
  const createProductClassCode = useStatefulAPIRequestMaker(
    DefaultOperations.createProductClassCode
  );
  const updateProductClassCode = useStatefulAPIRequestMaker(
    DefaultOperations.updateProductClassCode
  );
  const getProductClassCode = useStatefulAPIRequestMaker(
    DefaultOperations.getProductClassCode
  );
  const deleteProductClassCode = useStatefulAPIRequestMaker(
    DefaultOperations.deleteProductClassCode
  );

  type FormFields = {
    classCode: string;
  };

  const form = useForm<FormFields>({
    validate: {
      classCode: (value) =>
        value.trim().length === 0 ? 'Product Class Code is required' : null,
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (productClassCodeID) {
      await updateProductClassCode.execute({
        classCodeId: productClassCodeID,
        requestBody: {
          classCode: values.classCode,
        },
      });

      showNotification({
        color: 'green',
        title: 'ProductClassCode Updated',
        message: `ProductClassCode ${values.classCode} has been updated.`,
      });
    } else {
      const response = await createProductClassCode.execute({
        requestBody: {
          classCode: values.classCode,
        },
      });

      showNotification({
        color: 'green',
        title: 'ProductClassCode Created',
        message: `ProductClassCode ${values.classCode} has been created.`,
      });

      navigate(`/products/classCodes/${response.productClassCode.id}`);
    }
  });

  useEffect(() => {
    const fn = async () => {
      if (!productClassCodeID) {
        return;
      }

      const response = await getProductClassCode.execute({
        classCodeId: productClassCodeID,
      });
      form.setValues({
        classCode: response.productClassCode.classCode,
      });
    };

    fn();
  }, [productClassCodeID]);

  const onDelete = async () => {
    if (!productClassCodeID) {
      return;
    }

    openConfirmModal({
      title: <Title order={3}>Delete Class Code</Title>,
      children:
        'Are you sure you want to delete the product class code? If a product is using this class code, the class code will be emptied. No products will be deleted.',
      labels: {
        confirm: 'Yes - Delete Class Code',
        cancel: 'Go Back',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        await deleteProductClassCode.execute({
          classCodeId: productClassCodeID,
        });

        showNotification({
          color: 'green',
          title: 'Class Code Deleted',
          message: `Product class code has been deleted.`,
        });

        navigate(`/products/classCodes`);
      },
    });
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Group position="apart">
          <Title>
            {productClassCodeID
              ? form.values.classCode
              : 'New Product Class Code'}
          </Title>
          {productClassCodeID && (
            <Button color="red" onClick={onDelete}>
              Delete
            </Button>
          )}
        </Group>
        <form onSubmit={onSubmit}>
          <Stack>
            <TextInput
              label="Product Class Code"
              placeholder="Product Class Code"
              {...form.getInputProps('classCode')}
            />

            <Flex>
              <Button
                type="submit"
                color="green"
                disabled={!form.isValid || !form.isDirty}
              >
                {productClassCodeID ? 'Update' : 'Create'}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
};
