import {
  Currency,
  DefaultOperations,
  PurchaseOrderDiscount,
} from '../../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { PX192, PX640, PX8 } from '../../../../components/Px';
import {
  ActionIcon,
  Button,
  Group,
  Menu,
  NumberInput,
  Stack,
  Table,
  Textarea,
  Title,
} from '@mantine/core';
import { RFC3339ToEasyDate } from '../../../../utils/dateFormatting';
import { StatusBadge } from '@components/V2/StatusBadge';
import { formatToCurrency } from '@pcc/api/models/Currency';
import React, { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { IconDots, IconPencil, IconX } from '@tabler/icons-react';
import { IconCertificate } from '@tabler/icons-react';

export const PurchaseOrderDiscountsTab = ({
  purchaseOrderID,
  discounts,
  currency,
  refreshPurchaseOrder,
}: {
  purchaseOrderID: string;
  discounts: PurchaseOrderDiscount[];
  currency: Currency;
  refreshPurchaseOrder: () => void;
}) => {
  const updateDiscountState = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderDiscountState
  );

  const newDiscountClicked = () => {
    openModal({
      modalId: 'po-discount-modal',
      size: PX640.Number,
      title: <Title order={3}>Create Discount</Title>,
      children: (
        <PurchaseOrderDiscountFormModal
          purchaseOrderID={purchaseOrderID}
          currency={currency}
          onCompleted={() => {
            closeModal('po-discount-modal');
            refreshPurchaseOrder();
          }}
        />
      ),
    });
  };

  const DiscountMenu = ({ discount }: { discount: PurchaseOrderDiscount }) => {
    const editDiscountClicked = () => {
      openModal({
        modalId: 'po-discount-modal',
        size: PX640.Number,
        title: <Title order={3}>Edit Discount</Title>,
        children: (
          <PurchaseOrderDiscountFormModal
            discountID={discount.id}
            purchaseOrderID={purchaseOrderID}
            currency={currency}
            onCompleted={() => {
              closeModal('po-discount-modal');
              refreshPurchaseOrder();
            }}
          />
        ),
      });
    };

    const onPostClicked = () => {
      openConfirmModal({
        title: <Title order={3}>Post Discount</Title>,
        children: 'Are you sure you want to post this discount?',
        labels: {
          confirm: 'Yes - Post Discount',
          cancel: 'Cancel',
        },
        onConfirm: async () => {
          await updateDiscountState.execute({
            purchaseOrderDiscountId: discount.id,
            requestBody: {
              state: 'posted',
            },
          });
          refreshPurchaseOrder();
        },
      });
    };

    const onCancelClicked = () => {
      openConfirmModal({
        title: <Title order={3}>Cancel Discount</Title>,
        children: 'Are you sure you want to cancel this discount?',
        labels: {
          confirm: 'Yes - Cancel Discount',
          cancel: 'Cancel',
        },
        confirmProps: { color: 'red' },
        onConfirm: async () => {
          await updateDiscountState.execute({
            purchaseOrderDiscountId: discount.id,
            requestBody: {
              state: 'cancelled',
            },
          });
          refreshPurchaseOrder();
        },
      });
    };

    return (
      <Menu shadow="md" width={PX192.Number}>
        <Menu.Target>
          <ActionIcon loading={updateDiscountState.loading}>
            <IconDots />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IconPencil />}
            onClick={editDiscountClicked}
            disabled={discount.state !== 'draft'}
          >
            Edit
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            icon={<IconCertificate />}
            onClick={onPostClicked}
            disabled={discount.state !== 'draft'}
          >
            Post
          </Menu.Item>
          <Menu.Item
            icon={<IconX />}
            color="red"
            disabled={discount.state !== 'draft'}
            onClick={onCancelClicked}
          >
            Cancel
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <Stack spacing={PX8.Number}>
      <Group>
        <Button onClick={newDiscountClicked}>New Discount</Button>
      </Group>
      <Table>
        <thead>
          <tr>
            <th>Note</th>
            <th>Created At</th>
            <th>State</th>
            <th>Discount Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {discounts.map((d) => {
            return (
              <tr key={d.id}>
                <td>{d.note}</td>
                <td>{RFC3339ToEasyDate(d.createdAt)}</td>
                <td>
                  <StatusBadge state={d.state} />
                </td>
                <td>{formatToCurrency(d.amount, currency)}</td>
                <td>
                  <DiscountMenu discount={d} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

const PurchaseOrderDiscountFormModal = ({
  currency,
  discountID,
  purchaseOrderID,
  onCompleted,
}: {
  purchaseOrderID: string;
  onCompleted: () => void;
  discountID?: string;
  currency: Currency;
}) => {
  const createPODiscount = useStatefulAPIRequestMaker(
    DefaultOperations.createPurchaseOrderDiscount
  );

  const updatePODiscount = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderDiscount
  );

  const getPODiscount = useStatefulAPIRequestMaker(
    DefaultOperations.getPurchaseOrderDiscount
  );

  type FormFields = {
    amount: number | '';
    note: string;
  };

  const form = useForm<FormFields>();

  useEffect(() => {
    const fn = async () => {
      console.log('discountID', discountID);
      if (discountID) {
        const response = await getPODiscount.execute({
          purchaseOrderDiscountId: discountID,
        });
        form.setValues({
          note: response.purchaseOrderDiscount.note,
          amount: Number(response.purchaseOrderDiscount.amount),
        });
      } else {
        getPODiscount.clearResponse();
      }
    };

    fn();
  }, [discountID]);

  const onSubmit = form.onSubmit(async (v: FormFields) => {
    if (v.amount === '' || v.note === '') {
      return;
    }

    if (discountID) {
      await updatePODiscount.execute({
        purchaseOrderDiscountId: discountID,
        requestBody: {
          note: v.note,
          amount: `${v.amount}`,
        },
      });
    } else {
      await createPODiscount.execute({
        requestBody: {
          purchaseOrderID,
          note: v.note,
          amount: `${v.amount}`,
        },
      });
    }

    onCompleted();
  });

  return (
    <LoadingContainer
      loading={
        createPODiscount.loading ||
        updatePODiscount.loading ||
        getPODiscount.loading
      }
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={PX8.Number}>
          <NumberInput
            precision={currency.decimalPlaces}
            label="Amount"
            placeholder="Amount..."
            {...form.getInputProps('amount')}
            min={0}
            withAsterisk
          />
          <Textarea
            label="Note"
            placeholder="Note..."
            {...form.getInputProps('note')}
            withAsterisk
          />
          <Button type="submit">{discountID ? 'Update' : 'Create'}</Button>
        </Stack>
      </form>
    </LoadingContainer>
  );
};
