import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Alert,
  Button,
  Container,
  Group,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { IconRefreshAlert } from '@tabler/icons-react';

import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, SaleReturnCode } from '../../../oas/codegen3';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const SaleReturnCodeViewer = ({
  storeID,
}: {
  path?: string;
  storeID: string;
}) => {
  const getSaleReturnCode = useStatefulAPIRequestMaker(
    DefaultOperations.getSaleReturnCode
  );
  const generateSaleReturnCode = useStatefulAPIRequestMaker(
    DefaultOperations.generateSaleReturnCode
  );
  const [showNoCodeExists, setShowNoCodeExists] = useState(false);
  const [saleReturnCode, setSaleReturnCode] =
    useState<SaleReturnCode | null>(null);

  useEffect(() => {
    const fn = async () => {
      try {
        const response = await getSaleReturnCode.execute({ storeId: storeID });
        setSaleReturnCode(response.saleReturnCode);
      } catch (e: any) {
        if (e.response.status === 404) {
          setShowNoCodeExists(true);
        }
      }
    };

    fn();
  }, []);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer loading={getSaleReturnCode.loading}>
        <Stack>
          <Title>Sale Return Password</Title>
          <TextInput readOnly value={saleReturnCode?.code || ''} />
          <Group>
            <Button
              leftIcon={<IconRefreshAlert />}
              loading={generateSaleReturnCode.loading}
              onClick={async () => {
                const response = await generateSaleReturnCode.execute({
                  storeId: storeID,
                });
                setSaleReturnCode(response.saleReturnCode);
              }}
            >
              Generate New Code
            </Button>
          </Group>
          <Alert
            hidden={!showNoCodeExists}
            color="blue"
            variant="outline"
            title="No Sale Return Code Exists"
          >
            You don't have a sale return code. Click "Generate New Code" to
            create a new sale return code.
          </Alert>
        </Stack>
      </LoadingContainer>
    </Container>
  );
};
