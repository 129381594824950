import { Location, Router } from '@reach/router';
import React from 'react';
import { PosAppVersionsList } from './PosAppVersionsList';
import { PosAppVersionViewer } from './PosAppVersionViewer';

export const PosAppVersionPages = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <PosAppVersionsList path="/" />
          <PosAppVersionViewer path="/new" />
          <PosAppVersionViewer path="/:id" />
        </Router>
      )}
    </Location>
  );
};
