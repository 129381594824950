import { DefaultOperations, Store } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const StoreSelectorModalBody = ({
  onStoreSelected,
}: {
  onStoreSelected: (v: Store) => void;
}) => {
  const searchStores = useStatefulAPIRequestMaker(
    DefaultOperations.searchStores
  );

  const { debounceValue, onChangeDeps, target, setTargetFromInput } =
    useListSearcherHelper({
      limitPerPage: 30,
      debounceDelay: 200,
    });

  useEffect(() => {
    searchStores.execute({ target: debounceValue });
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for Store"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={searchStores.loading}>
        <Table highlightOnHover withBorder>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {(searchStores.response?.stores || []).map((s) => {
              const onClick = async () => {
                onStoreSelected(s);
              };

              return (
                <ClickableTableRow onClick={onClick} key={s.id}>
                  <td>{s.name}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
