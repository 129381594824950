import { Alert, Box, Button, Center, Indicator, Stack } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import React, { useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';

export const DateRangePickerModal = ({
  onDateRangeSelected,
}: {
  onDateRangeSelected: (v: [Date, Date]) => void;
}) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const submitDateRange = () => {
    if (dateRange[0] === null || dateRange[1] === null) {
      return;
    }

    onDateRangeSelected(dateRange as [Date, Date]);
  };

  return (
    <Stack>
      <Alert icon={<IconInfoCircle />} color="blue">
        Select the same date twice to select a single day.
      </Alert>
      <Center>
        <DatePicker
          size="md"
          firstDayOfWeek={0}
          weekendDays={[5, 6]}
          renderDay={(date) => {
            const day = date.getDate();
            const month = date.getMonth();

            const currentDate = new Date();
            const currentDay = currentDate.getDate();
            const currentMonth = currentDate.getMonth();
            return (
              <Indicator
                size={8}
                offset={-5}
                disabled={day !== currentDay || month !== currentMonth}
              >
                <Box style={{ border: '#a40000' }}>{day}</Box>
              </Indicator>
            );
          }}
          type="range"
          allowSingleDateInRange
          value={dateRange}
          onChange={setDateRange}
        />
      </Center>
      <Button
        onClick={submitDateRange}
        disabled={dateRange[0] === null || dateRange[1] === null}
      >
        Submit
      </Button>
    </Stack>
  );
};
