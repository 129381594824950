import React, { useEffect, useState } from 'react';
import { useStatefulAPIRequestMaker } from 'src/hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3/services/DefaultOperations';
import { PX32, PX384, PX64, PX768, PX96 } from '../../../components/Px';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import {
  ActionIcon,
  Button,
  Container,
  Group,
  Modal,
  NumberInput,
  Stack,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { StatusBadge } from '@components/V2/StatusBadge';
import { IconExternalLink, IconHistory, IconPencil } from '@tabler/icons-react';
import { RingProgressCard } from '@components/V2/RingProgressCard';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { toBig, toBigOrNull } from '../../../utils/toBigOrNull';
import { PaymentMethodMetadataManager } from '@components/V2/PaymentMethodMetadataManager';
import {
  CorePayment,
  Currency,
  PaymentMethod,
  SalePayment,
} from '../../../oas/codegen3';
import { closeModal, openModal } from '@mantine/modals';
import { PaymentMethodModalSelector } from '@components/V2/modal_selector/PaymentMethodModalSelector';
import { useDisclosure } from '@mantine/hooks';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { showNotification } from '@mantine/notifications';
import { ChangelogsTimelines } from '@components/V2/ChangelogsTimelines';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

export const SalePaymentViewer = ({
  salePaymentID,
}: {
  path?: string;
  salePaymentID?: string;
}) => {
  const getSalePayment = useStatefulAPIRequestMaker(
    DefaultOperations.getSalePayment
  );

  console.log('salePaymentID', salePaymentID);

  useEffect(() => {
    if (salePaymentID) {
      getSalePayment.execute({ salePaymentId: salePaymentID });
    }
  }, [salePaymentID]);

  const openSaleInNewPage = () => {
    window.open(`/sales/${getSalePayment.response?.sale.id}`);
  };

  const canChangePaymentMethod = useFindPrivileges({
    resource: 'sale_payment',
    action: 'write',
  });

  const canChangePaymentAmount = useFindPrivileges({
    resource: 'sale_payment',
    action: 'write',
  });

  const showPaymentMethodChangerModal = () => {
    if (!getSalePayment.response || !salePaymentID) {
      return;
    }

    openModal({
      modalId: 'payment-method-changer',
      title: <Title order={3}>Select Payment Method</Title>,
      children: (
        <PaymentMethodChangerModal
          salePayment={getSalePayment.response.salePayment}
          paymentMethod={getSalePayment.response.paymentMethod ?? null}
          metadata={getSalePayment.response.corePayment.metadata ?? {}}
          onComplete={() => {
            closeModal('payment-method-changer');
            getSalePayment.execute({ salePaymentId: salePaymentID });
          }}
        />
      ),
    });
  };

  const showPaymentAmountChangerModal = () => {
    if (!getSalePayment.response || !salePaymentID) {
      return;
    }

    openModal({
      modalId: 'payment-method-changer',
      title: <Title order={3}>Update Payment Amount</Title>,
      children: (
        <PaymentAmountChangerModal
          salePayment={getSalePayment.response.salePayment}
          currency={getSalePayment.response.currency}
          corePayment={getSalePayment.response.corePayment}
          onAmountUpdated={() => {
            closeModal('payment-method-changer');
            getSalePayment.execute({ salePaymentId: salePaymentID });
          }}
        />
      ),
    });
  };

  const showChangelogsTimeline = () => {
    if (!getSalePayment.response || !salePaymentID) {
      return;
    }

    openModal({
      modalId: 'changelogs-timeline',
      title: <Title order={3}>Changelog</Title>,
      children: (
        <TimelineModal salePayment={getSalePayment.response.salePayment} />
      ),
    });
  };

  return (
    <Container maw={PX768.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer
        loading={getSalePayment.loading}
        minHeight={PX384.Number}
      >
        <Stack>
          <Group>
            <Button
              compact
              variant="light"
              color="blue"
              leftIcon={<IconHistory size={14} />}
              onClick={showChangelogsTimeline}
            >
              History
            </Button>
          </Group>
          <Title order={1}>
            {getSalePayment.response?.salePayment.reference}
          </Title>
          <Group>
            <StatusBadge state={getSalePayment.response?.corePayment.state} />
          </Group>

          {getSalePayment.response && (
            <RingProgressCard
              label="Amount"
              amount={formatToCurrency(
                toBig(getSalePayment.response?.corePayment.amount, 0),
                getSalePayment.response?.currency
              )}
            />
          )}
          {getSalePayment.response && (
            <Group align="flex-end">
              <TextInput
                style={{ flex: 1 }}
                readOnly
                label="Amount"
                value={formatToCurrency(
                  toBig(getSalePayment.response?.corePayment.amount, 0),
                  getSalePayment.response?.currency
                )}
              />
              {canChangePaymentAmount.hasPrivilege && (
                <Button
                  variant="light"
                  leftIcon={<IconPencil />}
                  onClick={showPaymentAmountChangerModal}
                >
                  Change
                </Button>
              )}
            </Group>
          )}

          <TextInput
            label="Sale"
            readOnly
            value={getSalePayment.response?.sale.number}
            style={{ cursor: 'pointer' }}
            rightSection={
              <Tooltip label="Open sale in new page.">
                <ActionIcon onClick={openSaleInNewPage} color="blue">
                  <IconExternalLink />
                </ActionIcon>
              </Tooltip>
            }
          />

          <Group align="flex-end">
            <TextInput
              style={{ flex: 1 }}
              readOnly
              label="Payment Method"
              value={getSalePayment.response?.paymentMethod?.name ?? 'Cash'}
            />
            {canChangePaymentMethod.hasPrivilege && (
              <Button
                variant="light"
                leftIcon={<IconPencil />}
                onClick={showPaymentMethodChangerModal}
              >
                Change
              </Button>
            )}
          </Group>

          {getSalePayment.response && (
            <PaymentMethodMetadataManager
              readonly={true}
              paymentMethod={getSalePayment.response.paymentMethod ?? null}
              onUpdated={() => null}
              metadata={getSalePayment.response.corePayment.metadata}
            />
          )}
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const TimelineModal = ({ salePayment }: { salePayment: SalePayment }) => {
  const listSalePaymentChangelogs = useStatefulAPIRequestMaker(
    DefaultOperations.listSalePaymentChangelogs
  );

  useEffect(() => {
    listSalePaymentChangelogs.clearResponse();
    listSalePaymentChangelogs.execute({
      salePaymentId: salePayment.id,
    });
  }, [salePayment]);

  return (
    <LoadingContainer
      loading={listSalePaymentChangelogs.loading}
      minHeight={PX384.Number}
    >
      <ChangelogsTimelines
        changelogs={listSalePaymentChangelogs.response?.changelogs ?? []}
      />
    </LoadingContainer>
  );
};

const PaymentAmountChangerModal = ({
  salePayment,
  corePayment,
  currency,
  onAmountUpdated,
}: {
  salePayment: SalePayment;
  corePayment: CorePayment;
  currency: Currency;
  onAmountUpdated: () => void;
}) => {
  const [amount, setAmount] = useState<number | ''>('');
  useEffect(() => {
    setAmount(toBig(corePayment.amount, 0).toNumber());
  }, [corePayment]);

  const updateSalePaymentAmount = useStatefulAPIRequestMaker(
    DefaultOperations.updateSalePaymentAmount
  );

  const invalidAmount = amount === '' || amount < 0;

  const updatePaymentAmount = async () => {
    const amountBig = toBigOrNull(amount);
    if (!amountBig) {
      return;
    }

    await updateSalePaymentAmount.execute({
      salePaymentId: salePayment.id,
      requestBody: {
        amount: amountBig.toString(),
      },
    });

    onAmountUpdated();
  };

  return (
    <LoadingContainer loading={updateSalePaymentAmount.loading}>
      <Stack>
        <NumberInput
          label="Amount"
          placeholder="Amount"
          value={amount}
          precision={currency.decimalPlaces}
          onChange={setAmount}
        />
        <Button disabled={invalidAmount} onClick={updatePaymentAmount}>
          Update
        </Button>
      </Stack>
    </LoadingContainer>
  );
};

const PaymentMethodChangerModal = ({
  metadata,
  paymentMethod,
  salePayment,
  onComplete,
}: {
  salePayment: SalePayment;
  paymentMethod: PaymentMethod | null;
  metadata: { [key: string]: string };
  onComplete: () => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [newMetadata, setNewMetadata] =
    useState<{ [key: string]: string }>(metadata);
  const [metadataValid, setMetadataValid] = useState<boolean>(true);
  const [newPaymentMethod, setNewPaymentMethod] =
    useState<PaymentMethod | null>(paymentMethod);

  const updateSalePaymentPaymentMethod = useStatefulAPIRequestMaker(
    DefaultOperations.updateSalePaymentPaymentMethod
  );

  const updatePaymentMethod = async () => {
    try {
      await updateSalePaymentPaymentMethod.execute({
        salePaymentId: salePayment.id,
        requestBody: {
          paymentMethodID: newPaymentMethod?.id ?? undefined,
          metadata: newMetadata,
        },
      });

      showNotification({
        color: 'green',
        title: 'Payment Method Updated',
        message: "The sale's payment method has been updated.",
      });

      onComplete();
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  return (
    <LoadingContainer loading={updateSalePaymentPaymentMethod.loading}>
      <Stack>
        <Modal
          zIndex={10000}
          opened={opened}
          onClose={close}
          title={<Title order={3}>Select Payment Method</Title>}
        >
          <PaymentMethodModalSelector
            targetPaymentType="sale_payment"
            onPaymentMethodSelected={(pm) => {
              setNewPaymentMethod(pm);
              close();
            }}
          />
        </Modal>
        <TextInput
          onClick={open}
          readOnly
          label="Payment Method"
          value={newPaymentMethod?.name ?? 'Cash'}
        />
        <PaymentMethodMetadataManager
          paymentMethod={newPaymentMethod}
          onUpdated={(m) => {
            setNewMetadata(m.metadata);
            setMetadataValid(m.valid);
          }}
          metadata={newMetadata}
        />
        <Button disabled={!metadataValid} onClick={updatePaymentMethod}>
          Update
        </Button>
      </Stack>
    </LoadingContainer>
  );
};
