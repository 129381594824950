import { useNavigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { Container, Input, Stack, Table, Title } from '@mantine/core';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, Member, MemberUser } from '../../../oas/codegen3';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const MemberHomePage = ({}: { path?: string }) => {
  const [target, setTarget] = useState('');
  const listMembers = useStatefulAPIRequestMaker(DefaultOperations.listMembers);

  useEffect(() => {
    const t = setTimeout(() => {
      listMembers.execute({ target });
    }, 200);

    return () => {
      clearTimeout(t);
    };
  }, [target]);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Members</Title>
        <Input
          placeholder="Search"
          onChange={(e: any) => setTarget(e.target.value)}
          value={target}
        />
        <MembersTable members={listMembers.response?.members || []} />
      </Stack>
    </Container>
  );
};

const MembersTable = ({
  members,
}: {
  members: {
    member: Member;
    user: MemberUser;
  }[];
}) => {
  const navigate = useNavigate();

  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {members.map((m) => {
          const onClick = () => {
            navigate(`/members/${m.member.id}`);
          };

          return (
            <tr onClick={onClick} key={m.member.id}>
              <td>{m.user.firstName + ' ' + m.user.lastName}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
