import { createContext, ReactNode, useContext, useState } from 'react';
import { useStatefulAPIRequestMaker } from '../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../oas/codegen3';

export type PointsConversionContextState = {
  currencyPointsFactors?: { [currency: string]: string };
  earnRate?: string;
};

const PointsConversionContext = createContext<
  [
    PointsConversionContextState,
    (newState: PointsConversionContextState) => void
  ]
>([
  {},
  () => {
    return;
  },
]);

export const PointsConversionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [pointsContextState, setPointsContextState] =
    useState<PointsConversionContextState>({});

  return (
    <PointsConversionContext.Provider
      value={[pointsContextState, setPointsContextState]}
    >
      {children}
    </PointsConversionContext.Provider>
  );
};

export const usePointsConversionContext = () =>
  useContext(PointsConversionContext);

export const useHydratePointsConversionContext = () => {
  const [, setPointsContext] = usePointsConversionContext();
  const getPointsCurrencyFactors = useStatefulAPIRequestMaker(
    DefaultOperations.getPointsCurrencyFactor
  );

  const hydratePointsConversionContext = async () => {
    const pointsFactors = await getPointsCurrencyFactors.execute();
    setPointsContext(pointsFactors);
  };

  const clearPointsConversionContext = async () => {
    setPointsContext({});
  };

  return {
    hydratePointsConversionContext,
    clearPointsConversionContext,
  };
};
