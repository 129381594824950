import { DefaultOperations } from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Container,
  Group,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { IconGitPullRequest, IconSearch } from '@tabler/icons-react';
import { useNavigate } from '@reach/router';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

export const ListBrandsPage = ({}: { path: string }) => {
  useDocumentTitle('Product Brands');

  const navigate = useNavigate();
  const listBrands = useStatefulAPIRequestMaker(
    DefaultOperations.listProductBrands
  );

  const {
    pagination,
    paginationElement,
    debounceValue,
    target,
    setTargetFromInput,
  } = useListSearcherHelper({
    debounceDelay: 200,
    limitPerPage: 30,
  });

  useEffect(() => {
    listBrands.execute({
      target: debounceValue,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [debounceValue, pagination.limit, pagination.offset]);

  const canChangeProductMetadata = useFindPrivileges({
    resource: 'product.metadata',
    action: 'write',
  });

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Group position="apart">
          <Title>Product Brands</Title>
          <Button
            color="green"
            hidden={!canChangeProductMetadata.hasPrivilege}
            onClick={() => navigate('/products/brands/new')}
          >
            New Brand
          </Button>
        </Group>
        <Group>
          <Button
            compact
            variant="light"
            hidden={!canChangeProductMetadata.hasPrivilege}
            leftIcon={<IconGitPullRequest size={14} />}
            onClick={() => navigate('/products/brands/merge')}
          >
            Merge Brands
          </Button>
        </Group>

        <TextInput
          icon={<IconSearch />}
          label="Search"
          placeholder="Search Product Brands"
          onChange={setTargetFromInput}
          value={target}
        />

        {paginationElement(listBrands.response?.count)}

        <LoadingContainer loading={listBrands.loading} minHeight={PX384.Number}>
          <Table highlightOnHover withBorder striped>
            <thead>
              <tr>
                <th>Brands</th>
                <th style={{ textAlign: 'end' }}>Products</th>
              </tr>
            </thead>

            <tbody>
              {(listBrands.response?.productBrands || []).map((pb) => {
                const onClick = () => {
                  window.open(`/products/brands/${pb.productBrand.id}`);
                };

                return (
                  <ClickableTableRow onClick={onClick} key={pb.productBrand.id}>
                    <td>{pb.productBrand.brandName}</td>
                    <td style={{ textAlign: 'end' }}>
                      {pb.productBrand.productCount}
                    </td>
                  </ClickableTableRow>
                );
              })}
            </tbody>
          </Table>
        </LoadingContainer>

        {paginationElement(listBrands.response?.count)}
      </Stack>
    </Container>
  );
};
