import { Badge, Text } from '@mantine/core';
import React from 'react';
import { capitalCase } from 'change-case';

export const StatusBadge = ({
  state,
  fullWidth,
}: {
  state?: string;
  fullWidth?: boolean;
}) => {
  if (!state) {
    return null;
  }

  let color: string;

  const stateLower = state.toLowerCase();

  if (stateLower === 'draft' || stateLower === 'in_progress') {
    color = 'blue';
  } else if (stateLower === 'completed') {
    color = 'indigo';
  } else if (stateLower === 'waiting_approval') {
    color = 'grape';
  } else if (stateLower === 'ready') {
    color = 'violet';
  } else if (stateLower === 'processing') {
    color = 'red';
  } else if (
    stateLower === 'posted' ||
    stateLower === 'coupons_created' ||
    stateLower === 'reviewed'
  ) {
    color = 'green';
  } else if (stateLower === 'canceled' || stateLower == 'cancelled') {
    color = 'gray';
  } else if (stateLower === 'waiting_review') {
    color = 'orange';
  } else {
    color = 'dark';
  }

  return (
    <Badge color={color} variant="light" fullWidth={fullWidth} size="md">
      <Text fw={700}>{capitalCase(state)}</Text>
    </Badge>
  );
};
