import { Location, Router } from '@reach/router';
import React from 'react';

export const AnalyticsRouter = ({}: { path?: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>{/*<HomePage path="/home" />*/}</Router>
      )}
    </Location>
  );
};
