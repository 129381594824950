import { Location, Router } from '@reach/router';
import React from 'react';
import { StoresViewer } from './StoresViewer';
import { StoresForm } from './StoresForm';
import { ListCashierSessions } from './ListCashierSessions';
import { ViewCashierSessionPage } from './ViewCashierSessionPage';

export const StoresPages = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <StoresViewer path="/" />
          <StoresForm path="/:storeID" />
          <StoresForm path="/new" />

          <ViewCashierSessionPage path="/cashierSessions/:cashierSessionID" />
          <ListCashierSessions path="/cashierSessions" />
        </Router>
      )}
    </Location>
  );
};
