import styled, { css } from 'styled-components';
import { HTMLAttributes } from 'react';
import { ParagraphCSS } from '../paragraph/Paragraph';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  width?: string | number;
  styleType?: 'primary' | 'basic';
  disabled?: boolean;
  color?: 'orange' | 'blue' | 'red' | 'green';
}

function getTextColor(props: ButtonProps): string {
  if (props.disabled === true) {
    return '#D3D2D2';
  } else if (props.styleType === 'basic') {
    return getButtonColor(props);
  } else {
    return '#FFFFFF';
  }
}

function getTextHoverColor(props: ButtonProps): string {
  if (props.disabled === true) {
    return '#D3D2D2';
  } else if (props.styleType === 'basic') {
    return getButtonColor(props);
  } else {
    return '#FFFFFF';
  }
}

function getTextClickColor(props: ButtonProps): string {
  if (props.disabled === true) {
    return '#D3D2D2';
  } else if (props.styleType === 'basic') {
    return getButtonColor(props);
  } else {
    return '#FFFFFF';
  }
}

function getButtonColor(props: ButtonProps): string {
  if (props.disabled === true && props.styleType === 'basic') {
    return '#D3D2D2';
  } else if (props.disabled === true) {
    return '#FAFAFA';
  } else {
    switch (props.color) {
      case 'orange':
        return '#FF6800';
      case 'blue':
        return '#47AFF5';
      case 'red':
        return '#EF476F';
      case 'green':
        return '#0CCA4A';
      default:
        return '#FF6800';
    }
  }
}

function getButtonHoverColor(props: ButtonProps): string {
  if (props.disabled === true && props.styleType === 'basic') {
    return '#D3D2D2';
  } else if (props.disabled === true) {
    return '#FAFAFA';
  } else {
    switch (props.color) {
      case 'orange':
        return '#e65e00';
      case 'blue':
        return '#29a2f3';
      case 'red':
        return '#ed2a59';
      case 'green':
        return '#0bb643';
      default:
        return '#e65e00';
    }
  }
}

function getButtonClickColor(props: ButtonProps): string {
  if (props.disabled === true && props.styleType === 'basic') {
    return '#D3D2D2';
  } else if (props.disabled === true) {
    return '#FAFAFA';
  } else {
    switch (props.color) {
      case 'orange':
        return '#cc5300';
      case 'blue':
        return '#0d95f0';
      case 'red':
        return '#e41445';
      case 'green':
        return '#0aa23b';
      default:
        return '#cc5300';
    }
  }
}

const CoreButtonCSS = css<ButtonProps>`
  width: ${(props) => props.width};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 10px;
  border-radius: 2px;
  box-sizing: border-box;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:focus-visible {
    outline: 3px solid #47aff5;
  }
`;

const FilledButtonCSS = css<ButtonProps>`
  color: ${(props) => getTextColor(props)};
  background: ${(props) => getButtonColor(props)};
  border: 0;

  &:hover {
    color: ${(props) => getTextHoverColor(props)};
    background: ${(props) => getButtonHoverColor(props)};
  }

  &:active {
    color: ${(props) => getTextClickColor(props)};
    background: ${(props) => getButtonClickColor(props)};
  }
`;

const BasicButtonCSS = css<ButtonProps>`
  color: ${(props) => getTextColor(props)};
  background: #ffffff;
  border: 1px solid ${(props) => getButtonColor(props)};

  &:hover {
    background: #f9f9f9;
    color: ${(props) => getTextHoverColor(props)};
    border: 1px solid ${(props) => getButtonColor(props)};
  }

  &:active {
    background: #f2f2f2;
    color: ${(props) => getTextClickColor(props)};
    border: 1px solid ${(props) => getButtonColor(props)};
  }
`;

export const Button = styled.button<ButtonProps>`
  ${ParagraphCSS};
  ${CoreButtonCSS};
  ${(props) =>
    props.styleType === 'basic' ? BasicButtonCSS : FilledButtonCSS};
`;
