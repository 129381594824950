import { NavigateFn } from '@reach/router';
import { useEffect } from 'react';
import {
  usePccStoreDispatch,
  usePccStoreSelector,
} from '../pccstores/PccStore';
import { useStatefulAPIRequestMaker } from './useStatefulAPIRequestMaker';
import { DefaultOperations } from '../oas/codegen3';
import { actionResourceToString } from '../utils/actionResourceToString';
import {
  logoutUser,
  setAuthenticated,
  setUser,
} from '../pccstores/UserReducer';
import { useHydratePointsConversionContext } from '../providers/PointsConversionProvider';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const useAuthStateNavigator = (navigate: NavigateFn) => {
  const authenticated = usePccStoreSelector(
    (state) => state.userStates.authentication.authenticated
  );

  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentPathIsLogin = currentPath.startsWith('/login');
    const search = new URLSearchParams(location.search);
    let redirectTo = search.get('redirectTo');
    if (redirectTo?.startsWith('/login')) {
      redirectTo = null;
    }

    if (authenticated === true && currentPathIsLogin) {
      navigate(redirectTo ?? '/home');
    }

    if (authenticated === false && !currentPathIsLogin) {
      navigate('/login?redirectTo=' + currentPath);
    }
  }, [authenticated]);
};

export const useAutoRefreshUserOnAuthChange = () => {
  const dispatch = usePccStoreDispatch();
  const getMe = useStatefulAPIRequestMaker(DefaultOperations.getMe);
  const logout = useStatefulAPIRequestMaker(DefaultOperations.logout);
  const authenticated = usePccStoreSelector(
    (state) => state.userStates.authentication.authenticated
  );
  const user = usePccStoreSelector((state) => state.userStates.user);
  const hydrateUserContext = async () => {
    try {
      const me = await getMe.execute();

      const storePrivilegesMap: {
        [storeID: string]: { [actionResource: string]: boolean };
      } = {};
      const systemPrivilegesMap: { [actionResource: string]: boolean } = {};

      me.privileges.forEach((p) => {
        const actionResourceStr = actionResourceToString(p.action, p.resource);
        if (p.storeID) {
          const currentStorePrivileges = storePrivilegesMap[p.storeID] || {};
          currentStorePrivileges[actionResourceStr] = true;
          storePrivilegesMap[p.storeID] = currentStorePrivileges;
        } else {
          systemPrivilegesMap[actionResourceStr] = true;
        }
      });

      dispatch(
        setUser({
          privileges: me.privileges,
          id: me.userID,
          type: me.userType,
          userStores: me.userStores,
          systemPrivilegesMap,
          storePrivilegesMap,
          firstName: me.userFirstName,
          lastName: me.userLastName,
        })
      );
      dispatch(setAuthenticated(true));
    } catch (e: any) {
      dispatch(logoutUser());
      await logout.execute();
    }
  };
  const { hydratePointsConversionContext, clearPointsConversionContext } =
    useHydratePointsConversionContext();

  useEffect(() => {
    if ((authenticated && user === null) || authenticated === null) {
      hydrateUserContext();
      hydratePointsConversionContext();
    } else if (authenticated === false) {
      dispatch(setUser(null));
      clearPointsConversionContext();
    }
  }, [authenticated]);
};
