import React, { useEffect, useState } from 'react';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import {
  Code,
  Container,
  Flex,
  Group,
  Pagination,
  Stack,
  Switch,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import Big from 'big.js';
import { formatBig } from '../../../models/Currency';
import { LoadingContainer } from '@components/V2/LoadingContainer';

import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  Member,
  MemberUser,
  Store,
  User,
} from '../../../oas/codegen3';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { StatusBadge } from '@components/V2/StatusBadge';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { closeModal, openModal } from '@mantine/modals';
import { StoreSelectorModalBody } from '@components/V2/modal_selector/StoreSelectorModalBody';
import { UserModalSelectorBody } from '@components/V2/modal_selector/UserModalSelectorBody';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { MemberModalSelectorBody } from '@components/V2/modal_selector/MemberModalSelectorBody';

export const ListSales = ({}: { path?: string }) => {
  const listSales = useStatefulAPIRequestMaker(DefaultOperations.listSales);
  const [store, setStore] = useState<Store | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [member, setMember] =
    useState<{ member: Member; memberUser: MemberUser } | null>(null);
  const [hasReturn, setHasReturn] = useState(false);
  const {
    debounceValue,
    onChangeDeps,
    pagination,
    target,
    setTargetFromInput,
  } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
    additionalDeps: [store, user, hasReturn, member],
  });

  useEffect(() => {
    const p = listSales.execute({
      limit: pagination.limit,
      offset: pagination.offset,
      target: debounceValue,
      storeId: store?.id,
      userId: user?.id,
      memberId: member?.member.id ?? undefined,
      hasReturn,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  const onStoreBadgeClicked = () => {
    openModal({
      modalId: 'store-selector-modal',
      title: 'Select Store',
      children: (
        <StoreSelectorModalBody
          onStoreSelected={(s) => {
            setStore(s);
            closeModal('store-selector-modal');
          }}
        />
      ),
    });
  };

  const onUserBadgeClicked = () => {
    openModal({
      modalId: 'user-selector-modal',
      title: 'Select Store',
      children: (
        <UserModalSelectorBody
          onUserSelected={(u) => {
            setUser(u);
            closeModal('user-selector-modal');
          }}
        />
      ),
    });
  };

  const showMemberSelectorModal = () => {
    openModal({
      modalId: 'member-selector-modal',
      title: 'Select Store',
      children: (
        <MemberModalSelectorBody
          onSelected={(v) => {
            setMember(v);
            closeModal('member-selector-modal');
          }}
        />
      ),
    });
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Sales</Title>
        <TextInput
          placeholder="Search"
          label="Search"
          value={target}
          onChange={setTargetFromInput}
        />
        <Group>
          <Switch
            label="Has Return"
            checked={hasReturn}
            onChange={() => setHasReturn(!hasReturn)}
          />
          <SelectorBadge
            value={store?.name}
            placeholder="Select Store"
            onClick={onStoreBadgeClicked}
            onClearClick={() => setStore(null)}
          />
          <SelectorBadge
            value={user ? user?.firstName + ' ' + user?.lastName : undefined}
            placeholder="Select Cashier"
            onClick={onUserBadgeClicked}
            onClearClick={() => setUser(null)}
          />
          <SelectorBadge
            value={
              member
                ? member.memberUser.firstName + ' ' + member.memberUser.lastName
                : undefined
            }
            placeholder="Select Member"
            onClick={showMemberSelectorModal}
            onClearClick={() => setMember(null)}
          />
        </Group>
        <LoadingContainer loading={listSales.loading} minHeight={PX384.Number}>
          <Stack>
            {listSales.response?.count && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(
                    listSales.response?.count
                  )}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
            <Table highlightOnHover>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Created At</th>
                  <th>State</th>
                  <th>Created By</th>
                  <th>Total Amount</th>
                </tr>
              </thead>

              <tbody>
                {(listSales.response?.sales ?? []).map((s) => {
                  return (
                    <ClickableTableRow
                      key={s.sale.id}
                      targetURL={'/sales/' + s.sale.id}
                    >
                      <td>
                        <Code>{s.sale.number}</Code>
                      </td>
                      <td>{RFC3339ToEasyDateTime(s.sale.createdAt)}</td>
                      <td>
                        <StatusBadge state={s.sale.state} />
                      </td>
                      <td>
                        {s.cashier &&
                          s.cashier?.firstName + ' ' + s.cashier?.lastName}
                      </td>
                      <td>
                        {s.sale.totalAmount
                          ? formatBig(new Big(s.sale.totalAmount), s.currency)
                          : ''}
                      </td>
                    </ClickableTableRow>
                  );
                })}
              </tbody>
            </Table>
            {listSales.response?.count && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(
                    listSales.response?.count
                  )}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
