import { Button, ButtonProps } from '../button/Button';

export interface FormSubmitProps extends ButtonProps {
  value?: string;
  disabled?: boolean;
}

export function FormSubmit(props: FormSubmitProps) {
  return (
    <Button
      as="input"
      type="submit"
      value={props.value}
      disabled={props.disabled}
      color={props.color}
    />
  );
}
