import {
  Currency,
  Product,
  PurchaseOrder,
  PurchaseOrderLine,
  SellingMinAndMax,
} from '../../../../oas/codegen3';
import {
  Alert,
  Badge,
  Chip,
  Group,
  Paper,
  SegmentedControl,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';
import Big from 'big.js';
import { formatBig, formatToCurrency } from '@pcc/api/models/Currency';
import { IconAlertTriangle, IconSearch } from '@tabler/icons-react';
import { PX192, PX48, PX8 } from '../../../../components/Px';
import { ProfitabilityRangeBadge } from '@components/V2/ProfitabilityRangeBadge';
import { Link } from '@components/V2/Link';
import { useInputState } from '@mantine/hooks';

export const PurchaseOrderSellingAnalysisTab = ({
  purchaseOrderLines,
  currency,
}: {
  purchaseOrder: PurchaseOrder;
  purchaseOrderLines: {
    purchaseOrderLine: PurchaseOrderLine;
    product: Product;
    sellingMinAndMax?: SellingMinAndMax;
  }[];
  currency: Currency;
}) => {
  const [showTax, setShowTax] = useState(true);

  const totalMinMax = useMemo(() => {
    let minPreTax = Big(0);
    let maxPreTax = Big(0);
    let minWithTax = Big(0);
    let maxWithTax = Big(0);

    purchaseOrderLines.forEach((l) => {
      if (l.sellingMinAndMax) {
        minPreTax = minPreTax.add(l.sellingMinAndMax.minPreTax);
        maxPreTax = maxPreTax.add(l.sellingMinAndMax.maxPreTax);
        minWithTax = minWithTax.add(l.sellingMinAndMax.minWithTax);
        maxWithTax = maxWithTax.add(l.sellingMinAndMax.maxWithTax);
      }
    });

    return {
      minPreTax,
      maxPreTax,
      minWithTax,
      maxWithTax,
    };
  }, [purchaseOrderLines, showTax]);

  return (
    <Stack spacing={PX48.Number}>
      <Alert icon={<IconAlertTriangle />} color="red">
        <Stack spacing={PX8.Number}>
          <Text>
            Profitability is calculated{' '}
            <Text span fw={700}>
              WITHOUT TAX
            </Text>
            . Use the toggle below to show selling details without tax.
          </Text>
          <Chip
            variant="filled"
            color="red"
            checked={showTax}
            onChange={() => setShowTax((v) => !v)}
          >
            Show Calculations With Tax
          </Chip>
        </Stack>
      </Alert>
      <Group grow={true} align="stretch">
        <Paper withBorder radius="md" p="xs">
          <Group>
            <Stack spacing={0}>
              <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
                Order Total Min Selling Value
              </Text>
              <Text weight={700} size="lg">
                {formatBig(
                  showTax ? totalMinMax.minWithTax : totalMinMax.minPreTax,
                  currency
                )}
              </Text>
            </Stack>
          </Group>
        </Paper>
        <Paper withBorder radius="md" p="xs">
          <Group>
            <Stack spacing={0}>
              <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
                Order Total Max Selling Value
              </Text>
              <Text weight={700} size="lg">
                {formatBig(
                  showTax ? totalMinMax.maxWithTax : totalMinMax.maxPreTax,
                  currency
                )}
              </Text>
            </Stack>
          </Group>
        </Paper>
      </Group>

      <OrderLinesCostVsSalesTable
        purchaseOrderLines={purchaseOrderLines}
        currency={currency}
        calculateWithTax={showTax}
      />
    </Stack>
  );
};

const OrderLinesCostVsSalesTable = ({
  purchaseOrderLines,
  currency,
  calculateWithTax,
}: {
  purchaseOrderLines: {
    purchaseOrderLine: PurchaseOrderLine;
    product: Product;
    sellingMinAndMax?: SellingMinAndMax;
  }[];
  currency: Currency;
  calculateWithTax: boolean;
}) => {
  const [viewMode, setViewMode] = useState('by_product');
  const [productNameTarget, setProductNameTarget] = useInputState('');
  const [showOnlyFoC, setShowOnlyFoC] = useState(false);

  const lines: {
    id: string;
    productName: string;
    productID: string;
    quantity: string;
    countPerQuantity: string;
    preTaxCost: string;
    totalCost: string;
    minPreTax: string;
    maxPreTax: string;
    minWithTax: string;
    maxWithTax: string;
    isFoc: boolean;
    hasFoc: boolean;
    profit?: {
      minProfit: Big;
      maxProfit: Big;
      minProfitPercent: Big;
      maxProfitPercent: Big;
    };
  }[] = useMemo(() => {
    if (viewMode === 'by_line') {
      return purchaseOrderLines.map((l) => {
        let profit;
        if (l.sellingMinAndMax) {
          const cost = Big(l.purchaseOrderLine.preTaxTotal);
          const minSelling = Big(l.sellingMinAndMax.minPreTax);
          const maxSelling = Big(l.sellingMinAndMax.maxPreTax);

          const minProfit = minSelling.sub(cost);
          const maxProfit = maxSelling.sub(cost);

          const minProfitPercent = minProfit.div(minSelling);
          const maxProfitPercent = maxProfit.div(maxSelling);

          profit = {
            minProfit,
            maxProfit,
            minProfitPercent,
            maxProfitPercent,
          };
        }

        return {
          id: l.purchaseOrderLine.id,
          productName: l.purchaseOrderLine.productName,
          productID: l.purchaseOrderLine.productID,
          quantity: l.purchaseOrderLine.quantity,
          countPerQuantity: l.purchaseOrderLine.countPerQuantity,
          preTaxCost: formatToCurrency(
            l.purchaseOrderLine.preTaxTotal,
            currency
          ),
          totalCost: formatToCurrency(l.purchaseOrderLine.total, currency),
          minPreTax: formatToCurrency(l.sellingMinAndMax?.minPreTax, currency),
          maxPreTax: formatToCurrency(l.sellingMinAndMax?.maxPreTax, currency),
          minWithTax: formatToCurrency(
            l.sellingMinAndMax?.minWithTax,
            currency
          ),
          maxWithTax: formatToCurrency(
            l.sellingMinAndMax?.maxWithTax,
            currency
          ),
          isFoc: l.purchaseOrderLine.unitPrice === '0',
          hasFoc: false,
          profit,
        };
      });
    } else {
      const productNameToID: { [key: string]: string } = {};
      const productIDToValues: {
        [key: string]: {
          productName: string;
          quantityPCs: Big;
          preTaxCost: Big;
          totalCost: Big;
          minPreTax: Big;
          maxPreTax: Big;
          minWithTax: Big;
          maxWithTax: Big;
          hasFoc: boolean;
        };
      } = {};

      purchaseOrderLines.forEach((l) => {
        productNameToID[l.purchaseOrderLine.productName] =
          l.purchaseOrderLine.productID;
        const existing = productIDToValues[l.purchaseOrderLine.productID] ?? {
          productName: '',
          quantityPCs: Big(0),
          preTaxCost: Big(0),
          totalCost: Big(0),
          minPreTax: Big(0),
          maxPreTax: Big(0),
          minWithTax: Big(0),
          maxWithTax: Big(0),
          hasFoc: false,
        };

        existing.productName = l.purchaseOrderLine.productName;
        existing.quantityPCs = existing.quantityPCs.add(
          Big(l.purchaseOrderLine.quantity).mul(
            l.purchaseOrderLine.countPerQuantity
          )
        );
        existing.preTaxCost = existing.preTaxCost.add(
          l.purchaseOrderLine.preTaxTotal
        );
        existing.totalCost = existing.totalCost.add(l.purchaseOrderLine.total);

        existing.minPreTax = existing.minPreTax.add(
          l.sellingMinAndMax?.minPreTax ?? 0
        );
        existing.maxPreTax = existing.maxPreTax.add(
          l.sellingMinAndMax?.maxPreTax ?? 0
        );
        existing.minWithTax = existing.minWithTax.add(
          l.sellingMinAndMax?.minWithTax ?? 0
        );
        existing.maxWithTax = existing.maxWithTax.add(
          l.sellingMinAndMax?.maxWithTax ?? 0
        );

        if (l.purchaseOrderLine.unitPrice === '0') {
          existing.hasFoc = true;
        }

        productIDToValues[l.purchaseOrderLine.productID] = existing;
      });

      const productNamesSorted = Object.keys(productNameToID).sort();

      const returnLines: {
        id: string;
        productName: string;
        productID: string;
        quantity: string;
        countPerQuantity: string;
        preTaxCost: string;
        totalCost: string;
        minPreTax: string;
        maxPreTax: string;
        minWithTax: string;
        maxWithTax: string;
        isFoc: boolean;
        hasFoc: boolean;
        profit?: {
          minProfit: Big;
          maxProfit: Big;
          minProfitPercent: Big;
          maxProfitPercent: Big;
        };
      }[] = [];
      productNamesSorted.forEach((name) => {
        const pid = productNameToID[name];
        const l = productIDToValues[pid];

        let profit;
        if (!l.minPreTax.eq(0) && !l.maxPreTax.eq(0)) {
          const cost = Big(l.preTaxCost);
          const minSelling = Big(l.minPreTax);
          const maxSelling = Big(l.maxPreTax);

          const minProfit = minSelling.sub(cost);
          const maxProfit = maxSelling.sub(cost);

          const minProfitPercent = minProfit.div(minSelling);
          const maxProfitPercent = maxProfit.div(maxSelling);

          profit = {
            minProfit,
            maxProfit,
            minProfitPercent,
            maxProfitPercent,
          };
        }

        returnLines.push({
          countPerQuantity: '1',
          hasFoc: l.hasFoc,
          id: pid,
          isFoc: false,
          maxPreTax: formatToCurrency(l.maxPreTax, currency),
          maxWithTax: formatToCurrency(l.maxWithTax, currency),
          minPreTax: formatToCurrency(l.minPreTax, currency),
          minWithTax: formatToCurrency(l.minWithTax, currency),
          preTaxCost: formatToCurrency(l.preTaxCost, currency),
          productName: l.productName,
          productID: pid,
          quantity: l.quantityPCs.toString(),
          totalCost: formatToCurrency(l.totalCost, currency),
          profit,
        });
      });

      return returnLines;
    }
  }, [purchaseOrderLines, viewMode]);

  return (
    <Stack>
      <Title order={3}>Selling Value by Line</Title>
      <Group>
        <Tooltip
          multiline
          width={PX192.Number}
          withinPortal
          position="left"
          withArrow
          label={
            <Text>
              <Text span fw={700} td="underline">
                Group by Product
              </Text>{' '}
              to combine multiple lines of the same product into a single row
              (such as when there are Free/FOC products).
            </Text>
          }
        >
          <SegmentedControl
            onChange={(v) => {
              setViewMode(v);
            }}
            data={[
              { label: 'Group by Product', value: 'by_product' },
              { label: 'Group by Line', value: 'by_line' },
            ]}
          />
        </Tooltip>
      </Group>

      <TextInput
        label="Filter Product"
        placeholder="Filter Products"
        icon={<IconSearch />}
        value={productNameTarget}
        onChange={setProductNameTarget}
      />

      <Chip
        checked={showOnlyFoC}
        onChange={() => setShowOnlyFoC((v) => !v)}
        variant="filled"
        color="teal"
      >
        Show Only FOC
      </Chip>

      <Table withBorder striped>
        <thead>
          <tr>
            <th />
            <th>Product</th>
            <th>Quantity</th>
            <th>Cost</th>
            <th>Min Selling Value</th>
            <th>Max Selling Value</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          {lines
            .filter(
              (l) =>
                l.productName
                  .toLowerCase()
                  .indexOf(productNameTarget.toLowerCase()) !== -1 &&
                (!showOnlyFoC || l.hasFoc || l.isFoc)
            )
            .map((l) => {
              return (
                <tr key={l.id}>
                  <td>
                    {(l.isFoc || l.hasFoc) && (
                      <Badge color="green" variant="filled" size="xs">
                        FOC
                      </Badge>
                    )}
                  </td>
                  <td>
                    <Link to={`/products/${l.productID}`} hidden inline>
                      {l.productName}
                    </Link>
                  </td>
                  <td>
                    {l.quantity} (x
                    {l.countPerQuantity})
                  </td>
                  <td>{calculateWithTax ? l.totalCost : l.preTaxCost}</td>
                  <td>{calculateWithTax ? l.minWithTax : l.minPreTax}</td>
                  <td>{calculateWithTax ? l.maxWithTax : l.maxPreTax}</td>
                  <td>
                    {l.profit && (
                      <ProfitabilityRangeBadge
                        profitabilityRange={{
                          lowestProfitability: {
                            profit: l.profit.minProfit.toString(),
                            profitMarginPercent:
                              l.profit.minProfitPercent.toString(),
                          },
                          highestProfitability: {
                            profit: l.profit.maxProfit.toString(),
                            profitMarginPercent:
                              l.profit.maxProfitPercent.toString(),
                          },
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Stack>
  );
};
