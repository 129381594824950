import { Location, Router } from '@reach/router';
import React from 'react';
import { PaymentMethodsList } from './PaymentMethodsList';
import { PaymentMethodViewer } from './PaymentMethodViewer';

export const PaymentMethodRoutes = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <PaymentMethodsList path="/" />
          <PaymentMethodViewer path="/new" />
          <PaymentMethodViewer path="/:paymentMethodID" />
        </Router>
      )}
    </Location>
  );
};
