import { Badge } from '@mantine/core';
import React from 'react';
import Big from 'big.js';
import { toBigOrNull } from '../utils/toBigOrNull';

export const DaysToSellBadge = ({
  estimatedDaysToSellRange,
  onClick,
}: {
  estimatedDaysToSellRange?:
    | {
        maxDaysToSell?: Big | number | string | null | undefined;
        minDaysToSell?: Big | number | string | null | undefined;
      }
    | undefined;
  onClick?: () => void;
}) => {
  if (!estimatedDaysToSellRange) {
    return null;
  }

  const maxDaysToSellBig = toBigOrNull(estimatedDaysToSellRange.maxDaysToSell);
  const minDaysToSellBig = toBigOrNull(estimatedDaysToSellRange.minDaysToSell);

  const color =
    maxDaysToSellBig === null
      ? 'gray'
      : maxDaysToSellBig.lte(14)
      ? 'green'
      : maxDaysToSellBig.lte(21)
      ? 'yellow'
      : 'red';

  const minText =
    minDaysToSellBig === null ? '' : minDaysToSellBig.toFixed(0, 3);
  const maxText =
    maxDaysToSellBig === null ? '' : maxDaysToSellBig.toFixed(0, 3);

  let text;
  if (minText === '' || maxText === '') {
    text = 'No Sales';
  } else if (minText === maxText) {
    text = maxText + ' Days';
  } else {
    text = minText + '-' + maxText + ' Days';
  }

  return (
    <Badge
      variant="light"
      fullWidth
      color={color}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : undefined }}
    >
      {text}
    </Badge>
  );
};
