import { AlertTemplateProps } from 'react-alert';
import React from 'react';
import styled from 'styled-components';
import { VStack } from '../stack/VStack';
import { HStack } from '../stack/HStack';
import { Paragraph } from '../paragraph/Paragraph';
import { Header4 } from '../header/Header4';
import { IconWrapper } from '../icon/Icon';
import { AiOutlineCheckCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';

const ColorMap = {
  positive: '#0CCA4A',
  negative: '#EF476F',
  info: '#47AFF5',
};

const IconMap = {
  positive: <AiOutlineCheckCircle />,
  negative: <BiError />,
  info: <AiOutlineInfoCircle />,
};

export const AlertMessage = ({
  title,
  message,
  type,
}: {
  title: string;
  message?: string;
  type: 'positive' | 'negative' | 'info';
}) => {
  const color = ColorMap[type];
  const icon = IconMap[type];

  return (
    <AlertDivStyle color={color}>
      <VStack spacing="7px">
        <HStack spacing="4px">
          <IconWrapper color="#ffffff">{icon}</IconWrapper>
          <Header4 color="#ffffff">{title}</Header4>
        </HStack>
        {message && <Paragraph color="#ffffff">{message}</Paragraph>}
      </VStack>
    </AlertDivStyle>
  );
};

export const AlertTemplate = ({ message }: AlertTemplateProps) => {
  return <AlertContainer>{message}</AlertContainer>;
};

interface AlertDivStyleProps {
  color: string;
}

const AlertDivStyle = styled.div<AlertDivStyleProps>`
  width: 400px;
  padding: 14px;
  background: ${(props) => props.color};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
`;

const AlertContainer = styled.div`
  margin-bottom: 25px;
`;
