import {
  Timeline,
  Text,
  Stack,
  Title,
  Loader,
  Center,
  Spoiler,
} from '@mantine/core';
import { Changelog, UserWithNameOnly } from '../oas/codegen3';
import { RFC3339ToEasyDateTime } from '../utils/dateFormatting';
import { UserAvatar } from '@components/V2/UserAvatar';
import { PX128, PX96 } from '../components/Px';

export type Changelogs = {
  changelog: Changelog;
  user?: UserWithNameOnly | null;
}[];

export const ChangelogsTimelines = ({
  changelogs,
  loading,
}: {
  changelogs: Changelogs;
  loading?: boolean;
}) => {
  return (
    <Stack>
      {loading && (
        <Center>
          <Loader h={PX128.PX} />
        </Center>
      )}
      <Timeline active={changelogs.length} bulletSize={20} lineWidth={2} p={8}>
        {changelogs.map(({ changelog, user }) => {
          return (
            <Timeline.Item
              title={
                <Title order={6} ml={4} mb={0} pb={0}>
                  {changelog.title}
                </Title>
              }
              key={changelog.id}
              bullet={<UserAvatar user={user} />}
            >
              <Stack ml={4} spacing={0}>
                <Spoiler
                  maxHeight={PX96.Number}
                  hideLabel="Hide"
                  showLabel="Show More"
                >
                  <Text size="sm">{changelog.message}</Text>
                </Spoiler>
                <Text size="xs" c="dimmed" mt={2}>
                  {RFC3339ToEasyDateTime(changelog.createdAt)}
                </Text>
              </Stack>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Stack>
  );
};
