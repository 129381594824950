import { DefaultOperations, Member, MemberUser } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const MemberModalSelectorBody = ({
  onSelected,
}: {
  onSelected: (v: { member: Member; memberUser: MemberUser }) => void;
}) => {
  const listMembers = useStatefulAPIRequestMaker(DefaultOperations.listMembers);

  const { onChangeDeps, pagination, target, setTargetFromInput } =
    useListSearcherHelper({
      limitPerPage: 30,
      debounceDelay: 200,
    });

  useEffect(() => {
    listMembers.execute({
      target: target,
      limit: pagination.limit,
    });
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for User"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={listMembers.loading}>
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {(listMembers.response?.members || []).map((v) => {
              const onClick = async () => {
                onSelected({
                  member: v.member,
                  memberUser: v.user,
                });
              };

              return (
                <ClickableTableRow onClick={onClick} key={v.member.id}>
                  <td>{v.user.firstName + ' ' + v.user.lastName}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
