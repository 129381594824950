import { PX1024, PX32, PX64, PX768, PX8, PX96 } from '../../../components/Px';
import {
  Button,
  Card,
  Container,
  Group,
  Modal,
  Stack,
  Table,
  Tabs,
  Textarea,
  Text,
  Title,
  NumberInput,
  Center,
  Loader,
  Anchor,
  Alert,
  Menu,
  ActionIcon,
  ThemeIcon,
  Divider,
} from '@mantine/core';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { StockLocationSelectorModalBody } from '@components/V2/modal_selector/StockLocationSelectorModalBody';
import React, { useEffect } from 'react';
import { useForm } from '@mantine/form';
import {
  DefaultOperations,
  Product,
  StockAdjustmentGroup,
  StockAdjustmentGroupLine,
  StockAdjustmentGroupState,
  StockLocation,
} from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { showNotification } from '@mantine/notifications';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { useLocation, useNavigate } from '@reach/router';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import {
  IconCheck,
  IconCircleCheck,
  IconDots,
  IconList,
  IconNote,
  IconPencil,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { ModalSelectorInput } from '@components/V2/modal_selector/ModalSelectorInput';
import { ProductSelector } from '@components/V2/modal_selector/ProductSelector';
import { useDisclosure } from '@mantine/hooks';
import { capitalCase } from 'change-case';
import { StatusBadge } from '@components/V2/StatusBadge';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

export const StockAdjustmentGroupViewer = ({
  stockAdjustmentGroupID,
}: {
  path?: string;
  stockAdjustmentGroupID?: string;
}) => {
  const newDraftStockAdjustment = useStatefulAPIRequestMaker(
    DefaultOperations.draftNewStockAdjustmentGroup
  );

  const updateStockAdjGroup = useStatefulAPIRequestMaker(
    DefaultOperations.updateStockAdjustmentGroup
  );

  const getStockAdjustmentGroup = useStatefulAPIRequestMaker(
    DefaultOperations.getStockAdjustmentGroup
  );

  const updateStockAdjGroupState = useStatefulAPIRequestMaker(
    DefaultOperations.updateStockAdjustmentGroupState
  );

  useDocumentTitle(
    stockAdjustmentGroupID
      ? `Stock Adjustment: ${
          getStockAdjustmentGroup.response?.stockAdjustmentGroup.number ?? '...'
        }`
      : 'New Stock Adjustment'
  );

  const navigate = useNavigate();

  type FormFields = {
    stockLocation?: StockLocation | null;
    notes: string;
  };
  const form = useForm<FormFields>({});

  const location = useLocation();

  useEffect(() => {
    const locationState = location.state as
      | { stockLocation?: StockLocation }
      | null
      | undefined;
    if (locationState && locationState.stockLocation) {
      form.setValues({ stockLocation: locationState.stockLocation });
    }
  }, [location.state]);

  const loadStockAdj = async () => {
    if (!stockAdjustmentGroupID) {
      return;
    }

    const response = await getStockAdjustmentGroup.execute({
      stockAdjustmentGroupId: stockAdjustmentGroupID,
    });

    form.setValues({
      stockLocation: response.stockLocation,
      notes: response.stockAdjustmentGroup.notes,
    });
  };

  useEffect(() => {
    if (stockAdjustmentGroupID) {
      loadStockAdj();
    }
  }, [stockAdjustmentGroupID]);

  const showStockLocationSelectorModal = () => {
    openModal({
      modalId: 'stock-location-selector-modal',
      title: 'Select Stock Location',
      children: (
        <StockLocationSelectorModalBody
          onSelected={(v) => {
            form.setValues({
              stockLocation: v.stockLocation,
            });
            closeModal('stock-location-selector-modal');
          }}
        />
      ),
    });
  };

  const createOrUpdateAdjustment = form.onSubmit(async (values) => {
    if (!stockAdjustmentGroupID) {
      try {
        const response = await newDraftStockAdjustment.execute({
          requestBody: {
            stockLocationID: values.stockLocation!.id,
            notes: values.notes,
          },
        });

        showNotification({
          color: 'green',
          title: 'Draft Created',
          message: `Draft ${response.stockAdjustmentGroup.number} has been drafted.`,
        });

        navigate(`/stockAdjustmentGroups/${response.stockAdjustmentGroup.id}`);
      } catch (e) {
        handleApiErrorMessage(e);
      }
    } else {
      const response = await updateStockAdjGroup.execute({
        stockAdjustmentGroupId: stockAdjustmentGroupID,
        requestBody: {
          notes: values.notes,
        },
      });

      showNotification({
        color: 'green',
        title: 'Draft Updated',
        message: `Draft ${response.stockAdjustmentGroup.number} has been updated.`,
      });

      loadStockAdj();
    }
  });

  const postPriv = useFindPrivileges({
    action: 'trigger',
    resource: 'stock_adjustment_group.post',
  });

  const draftableStates = ['ready', 'cancelled'];
  const readyableStates = ['draft'];
  const cancellableStates = ['draft', 'ready'];
  const postableStates = ['ready'];

  const stockAdjustmentState =
    getStockAdjustmentGroup.response?.stockAdjustmentGroup.state ?? '';

  const changeStockAdjustmentState = async (
    stockAdjustmentID: string,
    state: StockAdjustmentGroupState
  ) => {
    openConfirmModal({
      title: <Title order={3}>Set to {capitalCase(state)}</Title>,
      children: `Are you sure you want to set this stock adjustment to ${state}?`,
      labels: {
        confirm: `Yes - Set to ${capitalCase(state)}`,
        cancel: 'Cancel',
      },
      confirmProps: { color: state === 'cancelled' ? 'red' : undefined },
      onConfirm: async () => {
        try {
          await updateStockAdjGroupState.execute({
            stockAdjustmentGroupId: stockAdjustmentID,
            requestBody: {
              state,
            },
          });

          showNotification({
            color: 'green',
            title: `Stock Adjustment Set to ${capitalCase(state)}`,
            message: 'Stock adjustment updated.',
          });

          loadStockAdj();
        } catch (e) {
          handleApiErrorMessage(e);
        }
      },
    });
  };

  return (
    <LoadingContainer
      loading={
        getStockAdjustmentGroup.loading ||
        newDraftStockAdjustment.loading ||
        updateStockAdjGroup.loading ||
        updateStockAdjGroupState.loading
      }
    >
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <Stack spacing={0}>
            {stockAdjustmentGroupID ? (
              <Stack spacing={0}>
                <Text size="sm" fw={700}>
                  Stock Adjustment
                </Text>
                <Title>
                  {getStockAdjustmentGroup.response?.stockAdjustmentGroup
                    .number ?? 'Loading...'}
                </Title>
              </Stack>
            ) : (
              <Title>New Stock Adjustment</Title>
            )}
          </Stack>

          {stockAdjustmentState && (
            <Group>
              <StatusBadge state={stockAdjustmentState} />

              {stockAdjustmentState !== 'posted' && (
                <Divider orientation="vertical" />
              )}

              {cancellableStates.find((v) => v === stockAdjustmentState) && (
                <Button
                  compact
                  variant="light"
                  color="red"
                  leftIcon={<IconX size={14} />}
                  onClick={() =>
                    changeStockAdjustmentState(
                      stockAdjustmentGroupID!,
                      'cancelled'
                    )
                  }
                >
                  Cancel
                </Button>
              )}

              {readyableStates.find((v) => v === stockAdjustmentState) && (
                <Button
                  compact
                  variant="light"
                  color="violet"
                  leftIcon={<IconCheck size={14} />}
                  onClick={() =>
                    changeStockAdjustmentState(stockAdjustmentGroupID!, 'ready')
                  }
                >
                  Set to Ready
                </Button>
              )}

              {draftableStates.find((v) => v === stockAdjustmentState) && (
                <Button
                  compact
                  variant="light"
                  color="blue"
                  leftIcon={<IconNote size={14} />}
                  onClick={() =>
                    changeStockAdjustmentState(stockAdjustmentGroupID!, 'draft')
                  }
                >
                  Back to Draft
                </Button>
              )}

              {postableStates.find((v) => v === stockAdjustmentState) && (
                <Button
                  compact
                  variant="light"
                  color="green"
                  disabled={!postPriv.hasPrivilege}
                  leftIcon={<IconCircleCheck size={14} />}
                  onClick={() =>
                    changeStockAdjustmentState(
                      stockAdjustmentGroupID!,
                      'posted'
                    )
                  }
                >
                  Post
                </Button>
              )}
            </Group>
          )}

          {getStockAdjustmentGroup.response?.postingUser &&
            getStockAdjustmentGroup.response?.stockAdjustmentGroup.postedAt && (
              <PostingUserComponent
                user={getStockAdjustmentGroup.response?.postingUser}
                postedAt={
                  getStockAdjustmentGroup.response?.stockAdjustmentGroup
                    .postedAt
                }
              />
            )}

          <form onSubmit={createOrUpdateAdjustment}>
            <Stack>
              <ModalSelectorInput
                label="Stock Location"
                placeholder="Stock Location"
                description="You cannot change the stock location after the stock adjustment is created."
                onClick={showStockLocationSelectorModal}
                disableOnClick={stockAdjustmentGroupID}
                value={form.values.stockLocation?.name}
                required
              />

              <Textarea
                placeholder="Notes"
                label="Notes"
                value={form.values.notes}
                readOnly={
                  !!stockAdjustmentGroupID && stockAdjustmentState !== 'draft'
                }
                onChange={(e) =>
                  form.setFieldValue('notes', e.currentTarget.value)
                }
              />

              {(!stockAdjustmentGroupID ||
                stockAdjustmentState === 'draft') && (
                <Group>
                  <Button color="green" type="submit">
                    {!stockAdjustmentGroupID ? 'Create Draft' : 'Update'}
                  </Button>
                </Group>
              )}
            </Stack>
          </form>

          {getStockAdjustmentGroup.response && (
            <Tabs defaultValue="stockAdjustmentGroupLines">
              <Tabs.List>
                <Tabs.Tab
                  value="stockAdjustmentGroupLines"
                  icon={<IconList size={14} />}
                >
                  Adjustment Lines
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="stockAdjustmentGroupLines" pt="lg">
                <AdjustmentLinesTab
                  refreshAdjustmentLines={loadStockAdj}
                  stockLocation={getStockAdjustmentGroup.response.stockLocation}
                  stockAdjustmentGroup={
                    getStockAdjustmentGroup.response.stockAdjustmentGroup
                  }
                  stockAdjustmentGroupLines={
                    getStockAdjustmentGroup.response?.stockAdjustmentGroupLines
                  }
                />
              </Tabs.Panel>
            </Tabs>
          )}
        </Stack>
      </Container>
    </LoadingContainer>
  );
};

export const AdjustmentLinesTab = ({
  stockAdjustmentGroup,
  stockLocation,
  stockAdjustmentGroupLines,
  refreshAdjustmentLines,
}: {
  stockAdjustmentGroup: StockAdjustmentGroup;
  stockLocation: StockLocation;
  stockAdjustmentGroupLines: {
    stockAdjustmentGroupLine: StockAdjustmentGroupLine;
    product: Product;
  }[];
  refreshAdjustmentLines: () => void;
}) => {
  const showAddLineModal = () => {
    openModal({
      modalId: 'add-line-modal',
      title: <Title order={3}>Add Stock Adjustment Line</Title>,
      children: (
        <AddOrUpdateStockAdjustmentLineModal
          stockLocation={stockLocation}
          stockAdjustmentGroup={stockAdjustmentGroup}
          refreshAdjustmentLines={refreshAdjustmentLines}
          closeModal={() => closeModal('add-line-modal')}
        />
      ),
    });
  };

  return (
    <Stack>
      <Group>
        {stockAdjustmentGroup.state === 'draft' && (
          <Button variant="light" onClick={showAddLineModal}>
            Add Line
          </Button>
        )}
      </Group>

      <Table withBorder captionSide="bottom">
        <thead>
          <tr>
            <th>Product</th>
            <th style={{ textAlign: 'right' }}>Starting Balance (PCs)</th>
            <th style={{ textAlign: 'right' }}>Target Balance (PCs)</th>
            <th style={{ textAlign: 'right' }}>Change in PCs</th>
            <th />
          </tr>
        </thead>

        {stockAdjustmentGroupLines.length === 0 &&
          stockAdjustmentGroup.state === 'draft' && (
            <caption>
              There are no stock adjustments lines.{' '}
              <Anchor onClick={showAddLineModal}>
                Click here to add a line.
              </Anchor>
            </caption>
          )}

        <tbody>
          {(stockAdjustmentGroupLines || []).map((sal) => {
            const showEditLineModal = () => {
              openModal({
                modalId: 'edit-line-modal',
                title: <Title order={3}>Add Stock Adjustment Line</Title>,
                children: (
                  <AddOrUpdateStockAdjustmentLineModal
                    stockLocation={stockLocation}
                    stockAdjustmentGroup={stockAdjustmentGroup}
                    refreshAdjustmentLines={refreshAdjustmentLines}
                    closeModal={() => closeModal('edit-line-modal')}
                    stockAdjustmentGroupLine={sal}
                  />
                ),
              });
            };

            const RowMenu = () => (
              <Menu shadow="md">
                <Menu.Target>
                  <ActionIcon>
                    <IconDots />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconPencil />}
                    onClick={showEditLineModal}
                    disabled={stockAdjustmentGroup.state !== 'draft'}
                  >
                    Edit Line
                  </Menu.Item>
                  <Menu.Item
                    color="red"
                    icon={<IconTrash />}
                    disabled={stockAdjustmentGroup.state !== 'draft'}
                  >
                    Remove Line
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );

            return (
              <tr key={sal.stockAdjustmentGroupLine.id}>
                <td>{sal.product.name}</td>
                <td style={{ textAlign: 'right' }}>
                  {sal.stockAdjustmentGroupLine.currentPCS}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {sal.stockAdjustmentGroupLine.targetPCS}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {sal.stockAdjustmentGroupLine.changeInPCS}
                </td>
                <td>
                  <RowMenu />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

export const AddOrUpdateStockAdjustmentLineModal = ({
  stockAdjustmentGroupLine,
  stockLocation,
  stockAdjustmentGroup,
  closeModal,
  refreshAdjustmentLines,
}: {
  stockAdjustmentGroup: StockAdjustmentGroup;
  stockLocation: StockLocation;
  stockAdjustmentGroupLine?: {
    stockAdjustmentGroupLine: StockAdjustmentGroupLine;
    product: Product;
  };
  closeModal: () => void;
  refreshAdjustmentLines: () => void;
}) => {
  const getProductBalance = useStatefulAPIRequestMaker(
    DefaultOperations.getProductStockBalance
  );

  const addStockAdjustmentLine = useStatefulAPIRequestMaker(
    DefaultOperations.addStockAdjustmentGroupLine
  );

  const updateStockAdjustmentLine = useStatefulAPIRequestMaker(
    DefaultOperations.updateStockAdjustmentGroupLine
  );

  const [productModalOpened, productModalHandler] = useDisclosure();
  const form = useForm<{ product?: Product; targetPCs: number | '' }>({
    initialValues: {
      targetPCs: '',
    },
  });

  const showProductSelectorModal = () => {
    productModalHandler.open();
  };

  useEffect(() => {
    getProductBalance.clearResponse();

    if (form.values.product) {
      const p = getProductBalance.execute({
        productId: form.values.product?.id,
        stockLocationId: stockLocation.id,
      });

      return () => p.cancel();
    }

    return () => null;
  }, [form.values.product?.id ?? '']);

  useEffect(() => {
    if (stockAdjustmentGroupLine) {
      form.setValues({
        product: stockAdjustmentGroupLine.product,
      });
    }
  }, [stockAdjustmentGroupLine]);

  const onSubmit = form.onSubmit(async (values) => {
    if (!values.product) {
      return;
    }

    if (!stockAdjustmentGroupLine) {
      try {
        await addStockAdjustmentLine.execute({
          requestBody: {
            stockAdjustmentGroupID: stockAdjustmentGroup.id,
            productID: values.product!.id,
            currentPCs:
              getProductBalance.response?.stockBalance?.balance ?? '0',
            targetPCs: values.targetPCs.toString(),
          },
        });

        showNotification({
          color: 'green',
          title: 'Line Added',
          message: `An adjustment for  ${values.product.name} has been added.`,
        });

        refreshAdjustmentLines();

        closeModal();
      } catch (e) {
        handleApiErrorMessage(e);
      }
    } else {
      try {
        await updateStockAdjustmentLine.execute({
          stockAdjustmentGroupLineId:
            stockAdjustmentGroupLine.stockAdjustmentGroupLine.id,
          requestBody: {
            productID: values.product.id,
            targetPCs: values.targetPCs.toString(),
          },
        });
        showNotification({
          color: 'green',
          title: 'Line Update',
          message: `The adjustment for ${values.product.name} has been updated.`,
        });

        refreshAdjustmentLines();

        closeModal();
      } catch (e) {
        handleApiErrorMessage(e);
      }
    }
  });

  return (
    <Stack>
      <Modal
        opened={productModalOpened}
        onClose={productModalHandler.close}
        zIndex={1000}
        size={PX768.Number}
        title={<Title order={3}>Product Selector</Title>}
      >
        <ProductSelector
          onSelected={(v) => {
            form.setValues({
              product: v.product,
            });
            productModalHandler.close();
          }}
        />
      </Modal>

      <form onSubmit={onSubmit}>
        <Stack>
          <ModalSelectorInput
            label="Product"
            placeholder="Product"
            onClick={showProductSelectorModal}
            value={form.values.product?.name}
            required
          />

          {!form.values.product && (
            <Alert title="Select a Product">
              Select a product to view its current stock balance.
            </Alert>
          )}

          {getProductBalance.loading && (
            <Center>
              <Loader />
            </Center>
          )}

          {form.values.product && !getProductBalance.loading && (
            <Card bg="orange.0" radius="md">
              <Text fw={700} color="orange" size="xs">
                Current Balance
              </Text>
              <Text fw={700} color="orange" size="xl">
                {getProductBalance.response?.stockBalance?.balance ?? '0'} PCs
              </Text>
            </Card>
          )}

          <NumberInput
            label="Target Balance"
            placeholder="Target Balance"
            description="In PCs, enter the correct stock balance."
            min={0}
            value={form.values.targetPCs}
            onChange={(value) => form.setFieldValue('targetPCs', value)}
          />

          <Button
            type="submit"
            color="green"
            loading={addStockAdjustmentLine.loading}
            disabled={
              form.values.targetPCs === '' ||
              getProductBalance.loading ||
              !form.values.product
            }
          >
            {stockAdjustmentGroupLine ? 'Update' : 'Add'}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

const PostingUserComponent = ({
  user,
  postedAt,
}: {
  user: { firstName: string; lastName: string };
  postedAt: string;
}) => {
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="green" radius="md" size="lg" variant="light">
        <IconCircleCheck />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {user.firstName} {user.lastName} posted this stock adjustment.
        </Text>
        <Text size="xs" c="dimmed">
          {RFC3339ToEasyDateTime(postedAt)}
        </Text>
      </Stack>
    </Group>
  );
};
