import { Box, Button, Center, Indicator, Stack } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import React, { useState } from 'react';

export const DatePickerModal = ({
  onDateRangeSelected,
}: {
  onDateRangeSelected: (v: Date) => void;
}) => {
  const [date, setDate] = useState<Date | null>(null);

  const submitDateRange = () => {
    if (date === null) {
      return;
    }

    onDateRangeSelected(date);
  };

  return (
    <Stack>
      <Center>
        <DatePicker
          size="md"
          firstDayOfWeek={0}
          weekendDays={[5, 6]}
          renderDay={(date) => {
            const day = date.getDate();
            const month = date.getMonth();

            const currentDate = new Date();
            const currentDay = currentDate.getDate();
            const currentMonth = currentDate.getMonth();
            return (
              <Indicator
                size={8}
                offset={-5}
                disabled={day !== currentDay || month !== currentMonth}
              >
                <Box style={{ border: '#a40000' }}>{day}</Box>
              </Indicator>
            );
          }}
          value={date}
          onChange={setDate}
        />
      </Center>
      <Button onClick={submitDateRange} disabled={date === null}>
        Submit
      </Button>
    </Stack>
  );
};
