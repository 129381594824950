import {
  PermissionAction,
  PermissionResource,
  Privilege,
  Store,
  UserType,
} from '../oas/codegen3';
import { usePccStoreSelector } from './PccStore';
import { UserSliceState } from './UserReducer';

export const canUser = ({
  requiredPrivileges,
  requiredUserType,
  storeID,
  user,
  checkType,
}: {
  requiredPrivileges?: {
    action: PermissionAction;
    resource: PermissionResource;
  }[];
  requiredUserType?: UserType[];
  storeID?: string;
  user: UserSliceState;
  checkType: 'any' | 'all';
}): boolean => {
  if (!user || !user.user) {
    return false;
  }

  const userType = user.user.type;
  // If admin, they always have permission.
  if (userType === 'admin') {
    return true;
  }

  // If a required user type is given, check to make sure we have the required user type.
  if (
    requiredUserType &&
    requiredUserType.find((v) => v === userType) === undefined
  ) {
    return false;
  }

  let userHasAllPermissions = true;
  let userHasAnyPermission = false;

  if (requiredPrivileges) {
    requiredPrivileges.forEach((p) => {
      const targetActionResource = `${p.action}:${p.resource}`;
      const hasSystemPrivilege =
        user.user!.systemPrivilegesMap[targetActionResource];
      if (hasSystemPrivilege) {
        userHasAnyPermission = true;
      }

      if (!hasSystemPrivilege && storeID) {
        const hasStorePrivilege =
          !user.user!.storePrivilegesMap[storeID][targetActionResource];

        userHasAllPermissions = hasStorePrivilege;
        if (hasStorePrivilege) {
          userHasAnyPermission = true;
        }
      } else if (!hasSystemPrivilege && !storeID) {
        userHasAllPermissions = false;
      }
    });
  }

  return checkType === 'all' ? userHasAllPermissions : userHasAnyPermission;
};
export const useCanUser = ({
  requiredPrivileges,

  checkType,
  storeID,
}: {
  requiredPrivileges?: {
    action: PermissionAction;
    resource: PermissionResource;
  }[];
  storeID?: string;
  checkType: 'any' | 'all';
}): boolean => {
  const user = usePccStoreSelector((state) => state.userStates);

  return canUser({
    requiredPrivileges,
    storeID,
    user,
    checkType,
  });
};
export const useFindPrivileges = ({
  action,
  resource,
  storeID,
  userType,
}: {
  action?: PermissionAction;
  resource?: PermissionResource;
  storeID?: string;
  userType?: UserType[];
}): {
  systemPrivileges: Privilege[];
  storePrivileges: Privilege[];
  privilegeStores: Store[];
  hasPrivilegeToAllStores: boolean;
  hasPrivilege: boolean;
  hasPrivilegeToStore: (storeID?: string, exactStoreCheck?: boolean) => boolean;
} => {
  const user = usePccStoreSelector((state) => state.userStates);

  if (!user.user) {
    return {
      systemPrivileges: [],
      storePrivileges: [],
      privilegeStores: [],
      hasPrivilegeToAllStores: false,
      hasPrivilege: false,
      hasPrivilegeToStore: () => false,
    };
  }

  if (user.user.type === 'admin') {
    return {
      systemPrivileges: [],
      storePrivileges: [],
      privilegeStores: [],
      hasPrivilegeToAllStores: true,
      hasPrivilege: true,
      hasPrivilegeToStore: () => true,
    };
  }

  if (userType && userType.find((v) => v === user.user?.type) === undefined) {
    return {
      systemPrivileges: [],
      storePrivileges: [],
      privilegeStores: [],
      hasPrivilegeToAllStores: false,
      hasPrivilege: false,
      hasPrivilegeToStore: () => false,
    };
  }

  const userStores = user.user.userStores;
  const privileges = user.user.privileges
    .filter((p) => {
      return action ? p.action === action : true;
    })
    .filter((p) => {
      return resource ? p.resource === resource : true;
    })
    .filter((p) => {
      return storeID ? p.storeID === storeID || p.storeID === undefined : true;
    });

  const storePrivileges: Privilege[] = [];
  const systemPrivileges: Privilege[] = [];

  const stores = new Set<Store>();

  privileges.forEach((p) => {
    if (p.storeID) {
      const aStore = userStores.find((s) => s.id === p.storeID);
      if (aStore) {
        stores.add(aStore);
      }
      storePrivileges.push(p);
    } else {
      systemPrivileges.push(p);
    }
  });

  const hasPrivilegeToAllStores = systemPrivileges.length > 0;
  return {
    systemPrivileges,
    storePrivileges,
    privilegeStores: Array.from(stores),
    hasPrivilegeToAllStores,
    hasPrivilege: storePrivileges.length > 0 || systemPrivileges.length > 0,
    hasPrivilegeToStore: (storeID?: string, exactStoreCheck?: boolean) => {
      let explicitCheck = false;
      if (exactStoreCheck === false) {
        explicitCheck = hasPrivilegeToAllStores;
      }

      return explicitCheck || storeID
        ? storePrivileges.find((p) => p.storeID === storeID) !== undefined
        : false;
    },
  };
};
