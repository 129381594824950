import { DefaultOperations, StockLocation, Store } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const StockLocationSelectorModalBody = ({
  onSelected,
  storeID,
}: {
  onSelected: (v: { stockLocation: StockLocation; store?: Store }) => void;
  storeID?: string;
}) => {
  const searchStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.searchStockLocations
  );

  const { debounceValue, onChangeDeps, target, setTargetFromInput } =
    useListSearcherHelper({
      limitPerPage: 30,
      debounceDelay: 200,
    });

  useEffect(() => {
    searchStockLocation.execute({ target: debounceValue, storeId: storeID });
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for Stock Location"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={searchStockLocation.loading}>
        <Table highlightOnHover withBorder>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {(searchStockLocation.response?.stockLocations || []).map((s) => {
              const onClick = async () => {
                onSelected(s);
              };

              return (
                <ClickableTableRow onClick={onClick} key={s.stockLocation.id}>
                  <td>{s.stockLocation.name}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
