import { DefaultOperations } from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Container,
  Flex,
  Group,
  Pagination,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { IconGitPullRequest, IconSearch } from '@tabler/icons-react';
import { useNavigate } from '@reach/router';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

export const ListGenericCodesPage = ({}: { path: string }) => {
  useDocumentTitle('Product Generic Codes');

  const navigate = useNavigate();
  const listProductGenericCodes = useStatefulAPIRequestMaker(
    DefaultOperations.listProductGenericCodes
  );

  const { pagination, debounceValue, target, setTargetFromInput } =
    useListSearcherHelper({
      debounceDelay: 200,
      limitPerPage: 30,
    });

  useEffect(() => {
    listProductGenericCodes.execute({
      genericCode: debounceValue,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [debounceValue, pagination.limit, pagination.offset]);

  const totalPaginationPages = pagination.calculateTotalPages(
    listProductGenericCodes.response?.totalCount
  );

  const canChangeProductMetadata = useFindPrivileges({
    resource: 'product.metadata',
    action: 'write',
  });

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Group position="apart">
          <Title>Product Generic Codes</Title>
          <Button
            color="green"
            hidden={!canChangeProductMetadata.hasPrivilege}
            onClick={() => navigate('/products/genericCodes/new')}
          >
            New Generic Code
          </Button>
        </Group>
        <Group>
          <Button
            compact
            variant="light"
            hidden={!canChangeProductMetadata.hasPrivilege}
            onClick={() => navigate('/products/genericCodes/merge')}
            leftIcon={<IconGitPullRequest size={14} />}
          >
            Merge Generic Codes
          </Button>
        </Group>

        <TextInput
          icon={<IconSearch />}
          label="Search"
          placeholder="Search Generic Code"
          onChange={setTargetFromInput}
          value={target}
        />

        <LoadingContainer
          loading={listProductGenericCodes.loading}
          minHeight={PX384.Number}
        >
          <Flex justify="flex-end">
            <Pagination
              size="sm"
              total={totalPaginationPages}
              value={pagination.currentPaginationPage}
              onChange={pagination.onPaginationPageChange}
            />
          </Flex>

          <Table highlightOnHover withBorder striped>
            <thead>
              <tr>
                <th>Generic Code</th>
                <th style={{ textAlign: 'end' }}>Products</th>
              </tr>
            </thead>

            <tbody>
              {(
                listProductGenericCodes.response?.productGenericCodes || []
              ).map((genericCode) => {
                const onClick = () => {
                  window.open(
                    `/products/genericCodes/${genericCode.productGenericCode.id}`
                  );
                };

                console.log(genericCode);

                return (
                  <ClickableTableRow
                    onClick={onClick}
                    key={genericCode.productGenericCode.id}
                  >
                    <td>{genericCode.productGenericCode.genericCode}</td>
                    <td style={{ textAlign: 'end' }}>
                      {genericCode.productGenericCode.productCount}
                    </td>
                  </ClickableTableRow>
                );
              })}
            </tbody>
          </Table>

          <Flex justify="flex-end">
            <Pagination
              size="sm"
              total={totalPaginationPages}
              value={pagination.currentPaginationPage}
              onChange={pagination.onPaginationPageChange}
            />
          </Flex>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
