import { useNavigate } from '@reach/router';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import React, { useEffect } from 'react';
import {
  Button,
  Container,
  Flex,
  Pagination,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { IconSearch } from '@tabler/icons-react';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';

export const ListProductClassCodesPage = ({}: { path: string }) => {
  const navigate = useNavigate();
  const listProductClassCodes = useStatefulAPIRequestMaker(
    DefaultOperations.listProductClassCodes
  );
  const { target, setTargetFromInput, pagination, debounceValue } =
    useListSearcherHelper({
      debounceDelay: 200,
      limitPerPage: 30,
    });

  useEffect(() => {
    listProductClassCodes.execute({
      classCode: debounceValue,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [debounceValue, pagination.limit, pagination.offset]);

  const rows = (listProductClassCodes.response?.productClassCodes || []).map(
    (productClassCode) => {
      const onClick = () => {
        navigate(`/products/classCodes/${productClassCode.id}`);
      };

      return (
        <tr onClick={onClick} key={productClassCode.id}>
          <td>{productClassCode.classCode}</td>
        </tr>
      );
    }
  );

  const totalPaginationPages = pagination.calculateTotalPages(
    listProductClassCodes.response?.totalCount
  );

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Product Class Codes</Title>
        <Flex>
          <Button
            color="green"
            onClick={() => navigate('/products/classCodes/new')}
          >
            New Class Code
          </Button>
        </Flex>

        <TextInput
          icon={<IconSearch />}
          label="Search"
          placeholder="Search Class Code"
          onChange={setTargetFromInput}
          value={target}
        />

        <LoadingContainer
          loading={listProductClassCodes.loading}
          minHeight={PX384.Number}
        >
          <Flex justify="flex-end">
            <Pagination
              size="sm"
              total={totalPaginationPages}
              value={pagination.currentPaginationPage}
              onChange={pagination.onPaginationPageChange}
            />
          </Flex>

          <Table highlightOnHover>
            <thead>
              <tr>
                <th>Class Code</th>
              </tr>
            </thead>

            <tbody>{rows}</tbody>
          </Table>

          <Flex justify="flex-end">
            <Pagination
              size="sm"
              total={totalPaginationPages}
              value={pagination.currentPaginationPage}
              onChange={pagination.onPaginationPageChange}
            />
          </Flex>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
