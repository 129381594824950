import {
  Center,
  Loader,
  SegmentedControl,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { PX12, PX24, PX384 } from '../../../../components/Px';
import React, { useEffect, useState } from 'react';
import { RFC3339ToPrettyMonthDontModifyTimezone } from '../../../../utils/dateFormatting';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../../oas/codegen3';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const ProductSellingCaseOfStatsOverTimeChart = ({
  productID,
  height,
}: {
  productID: string;
  height?: number;
}) => {
  const theme = useMantineTheme();
  const [graphBy, setGraphBy] = useState('by_qty');

  const getSellingCaseOfStats = useStatefulAPIRequestMaker(
    DefaultOperations.getProductSellingCaseOfStats
  );
  useEffect(() => {
    getSellingCaseOfStats.execute({
      productId: productID,
      timePeriod: 'month',
    });
  }, [productID]);

  const data = React.useMemo(() => {
    if (!getSellingCaseOfStats.response) {
      return { data: [], keys: [] };
    }

    const keysObj: { [key: string]: boolean } = {};
    const dataArr = getSellingCaseOfStats.response.stats.map((m) => {
      const caseOfObj: { [key: string]: number } = {};
      for (const caseOfStats of m.caseOfStats) {
        const title = `Case of ${caseOfStats.caseOf}`;
        caseOfObj[title] =
          graphBy === 'by_qty'
            ? Number(caseOfStats.quantitySold)
            : graphBy === 'by_pcs'
            ? Number(caseOfStats.pcsSold)
            : Number(caseOfStats.distinctSales);
        keysObj[title] = true;
      }

      return {
        date: RFC3339ToPrettyMonthDontModifyTimezone(m.date),
        ...caseOfObj,
      };
    });

    return { data: dataArr, keys: Object.keys(keysObj) };
  }, [getSellingCaseOfStats.response, graphBy]);

  const colors7 = [
    theme.colors.grape[7],
    theme.colors.teal[7],
    theme.colors.blue[7],
  ];
  const colors2 = [
    theme.colors.grape[7],
    theme.colors.teal[2],
    theme.colors.blue[2],
  ];

  return (
    <>
      {getSellingCaseOfStats.loading && (
        <Center h={height ?? PX384.Number}>
          <Loader />
        </Center>
      )}

      {getSellingCaseOfStats.response && !getSellingCaseOfStats.loading && (
        <Stack>
          <SegmentedControl
            onChange={setGraphBy}
            value={graphBy}
            data={[
              { label: 'By Quantity', value: 'by_qty' },
              { label: 'By PCs', value: 'by_pcs' },
              { label: 'By Unique Sales', value: 'by_sales' },
            ]}
          />

          <div style={{ position: 'relative', height: height ?? PX384.Number }}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={data.data}
                stackOffset="sign"
                height={PX384.Number}
                margin={{ top: PX24.Number, bottom: PX24.Number }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  padding={{ left: PX12.Number, right: PX12.Number }}
                />
                <YAxis type="number" />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />

                {data.keys.map((k, i) => {
                  return (
                    <Bar
                      key={k}
                      dataKey={k}
                      stackId="a"
                      fill={colors7[i % colors7.length]}
                      activeBar={
                        <Rectangle
                          fill={colors7[i % colors2.length]}
                          stroke={colors7[i % colors7.length]}
                        />
                      }
                    />
                  );
                })}
                <Tooltip />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Stack>
      )}
    </>
  );
};
