import { Location, Router } from '@reach/router';
import React from 'react';
import { OdooSyncProductsPage } from './OdooSyncProductPages/OdooSyncProductsPage';
import { SystemSettingsHomePage } from './SystemSettingsHomePage';

export const SystemSettingsRouter = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <OdooSyncProductsPage path="/odooProductSync" />
          <SystemSettingsHomePage path="/" />
        </Router>
      )}
    </Location>
  );
};
