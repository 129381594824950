import Big from 'big.js';

export const toPrettyPercentage = (
  s?: string | number | null,
  decimalPlaces?: number
): string | null => {
  if (s === null || s === undefined) {
    return null;
  }

  try {
    const b = new Big(s);
    return b.mul(100).toFixed(decimalPlaces ?? 2) + '%';
  } catch (e) {
    return null;
  }
};
