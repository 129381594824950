import { useInputState } from '@mantine/hooks';

export type VariablePagination = {
  limit: number;
  currentPaginationPage: number;
  onPaginationPageChange: (newPage: number) => void;
  calculateTotalPages: (totalElements?: number) => number;
  offset: number;
};

export const useVariablePagination = ({
  limit,
  startingPaginationPage,
}: {
  limit: number;
  startingPaginationPage?: number | null | undefined;
}): VariablePagination => {
  const [currentPaginationPage, setCurrentPaginationPage] = useInputState(
    startingPaginationPage ?? 1
  );

  return {
    limit: limit,
    currentPaginationPage,
    onPaginationPageChange: setCurrentPaginationPage,
    calculateTotalPages: (totalElements?: number) => {
      if (totalElements === undefined) {
        return 0;
      }

      return Math.ceil(totalElements / limit);
    },
    offset: (currentPaginationPage - 1) * limit,
  };
};
