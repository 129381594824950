import {
  Button,
  Container,
  Group,
  Stack,
  Switch,
  Table,
  Title,
} from '@mantine/core';
import { TextInput } from '@mantine/core';
import { useNavigate } from '@reach/router';
import { useEffect, useState } from 'react';

import { PX1536, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import {
  DefaultOperations,
  ProductClassCode,
  ProductGenericCode,
  Vendor,
} from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { closeModal, openModal } from '@mantine/modals';
import { GenericCodeSelectorModalBody } from '@components/V2/modal_selector/GenericCodeSelectorModalBody';
import { ClassCodeSelectorModalBody } from '@components/V2/modal_selector/ClassCodeSelectorModalBody';
import { VendorSelectorModalBody } from '@components/V2/modal_selector/VendorSelectorModalBody';
import { IconSearch, IconTableExport } from '@tabler/icons-react';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';

export const ListProducts = ({}: { path: string }) => {
  useDocumentTitle('Products');

  const navigate = useNavigate();
  const [enabledCheck, setEnabledCheck] = useState(true);
  const [classCode, setClassCode] = useState<ProductClassCode | null>(null);
  const [genericCode, setGenericCode] =
    useState<ProductGenericCode | null>(null);
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const listProducts = useStatefulAPIRequestMaker(
    DefaultOperations.listProducts
  );

  const {
    debounceValue,
    onChangeDeps,
    target,
    setTarget,
    pagination,
    paginationElement,
  } = useListSearcherHelper({
    debounceDelay: 200,
    limitPerPage: 30,
    additionalDeps: [enabledCheck, classCode, genericCode, vendor],
  });

  useEffect(() => {
    const p = listProducts.execute({
      target: debounceValue,
      enabled: enabledCheck,
      classCode: classCode?.classCode,
      genericCode: genericCode?.genericCode,
      primaryVendorId: vendor?.id,
      limit: pagination.limit,
      offset: pagination.offset,
      includeDisabledProductPrices: true,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  const onEnabledCheckChange = () => {
    setEnabledCheck(!enabledCheck);
  };

  const onVendorBadgeClicked = () => {
    openModal({
      modalId: 'vendor-selector-modal',
      title: 'Select Vendor',
      children: (
        <VendorSelectorModalBody
          onVendorSelected={(v) => {
            setVendor(v);
            closeModal('vendor-selector-modal');
          }}
        />
      ),
    });
  };

  const onVendorClearClicked = () => setVendor(null);

  const onGenericCodeBadgeClicked = () => {
    openModal({
      modalId: 'generic-code-selector-modal',
      title: 'Select Generic Code',
      children: (
        <GenericCodeSelectorModalBody
          onGenericCodeSelected={(gc) => {
            setGenericCode(gc);
            closeModal('generic-code-selector-modal');
          }}
        />
      ),
    });
  };

  const onClearGenericCodeBadgeClicked = () => setGenericCode(null);

  const onClassCodeBadgeClicked = () => {
    openModal({
      modalId: 'class-code-selector-modal',
      title: 'Select Class Code',
      children: (
        <ClassCodeSelectorModalBody
          onClassCodeSelected={(cc) => {
            setClassCode(cc);
            closeModal('class-code-selector-modal');
          }}
        />
      ),
    });
  };

  const onClearClassCodeClicked = () => setClassCode(null);
  const productsArr = listProducts.response?.products ?? [];

  return (
    <Container maw={PX1536.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Group position="apart">
          <Title>Products</Title>
          <Button color="green" onClick={() => navigate('/products/new')}>
            New Product
          </Button>
        </Group>
        <Group>
          <Button
            compact
            variant="light"
            onClick={() => navigate('/products/priceExporter')}
            leftIcon={<IconTableExport size={14} />}
          >
            Price Exporter
          </Button>
        </Group>

        <TextInput
          icon={<IconSearch />}
          label="Search"
          placeholder="Search"
          onChange={(e) => setTarget(e.target.value)}
          value={target}
        />

        <Group>
          <Switch
            label="Product Enabled"
            checked={enabledCheck}
            onChange={onEnabledCheckChange}
          />

          <SelectorBadge
            placeholder="Select Generic Code"
            onClick={onGenericCodeBadgeClicked}
            onClearClick={onClearGenericCodeBadgeClicked}
            value={genericCode?.genericCode}
          />
          <SelectorBadge
            placeholder="Select Class Code"
            onClick={onClassCodeBadgeClicked}
            onClearClick={onClearClassCodeClicked}
            value={classCode?.classCode}
          />
          <SelectorBadge
            placeholder="Select Vendor"
            onClick={onVendorBadgeClicked}
            onClearClick={onVendorClearClicked}
            value={vendor?.name}
          />
        </Group>

        <LoadingContainer
          loading={listProducts.loading}
          minHeight={PX384.Number}
        >
          <Stack>
            {paginationElement(listProducts.response?.count)}
            <Table highlightOnHover withBorder>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Reference</th>
                  <th>Barcode</th>
                </tr>
              </thead>

              <tbody>
                {productsArr.map((p) => {
                  const emptyName = p.product.name.trim() === '';

                  return (
                    <ClickableTableRow
                      targetURL={`/products/${p.product.id}`}
                      key={p.product.id}
                    >
                      <td>{emptyName ? '[No Name]' : p.product.name}</td>
                      <td>{p.product.reference}</td>
                      <td>{p.product.barcode}</td>
                    </ClickableTableRow>
                  );
                })}
              </tbody>
            </Table>
            {paginationElement(listProducts.response?.count)}
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
