import { Badge } from '@mantine/core';
import React from 'react';

export const PaymentMethodNameBadge = ({
  name,
  fullWidth,
}: {
  name?: string;
  fullWidth?: boolean;
}) => {
  if (!name) {
    return null;
  }

  if (name === 'cash') {
    return (
      <Badge color="green" variant="filled" fullWidth={fullWidth}>
        Cash
      </Badge>
    );
  } else if (name === 'benefit') {
    return (
      <Badge color="purple" variant="filled" fullWidth={fullWidth}>
        Benefit
      </Badge>
    );
  } else if (name === 'card') {
    return (
      <Badge color="teal" variant="filled" fullWidth={fullWidth}>
        Card
      </Badge>
    );
  } else {
    return (
      <Badge
        color="gray"
        variant="filled"
        fullWidth={fullWidth}
        tt="capitalize"
      >
        {name}
      </Badge>
    );
  }

  return null;
};
