import { Location, Router } from '@reach/router';
import React from 'react';
import { ListProducts } from './ListProducts';
import { ProductForm } from './ProductForm';
import { ListGenericCodesPage } from './ListGenericCodesPage';
import { ProductGenericCodeForm } from './ProductGenericCodeForm';
import { ListProductClassCodesPage } from './ListProductClassCodesPage';
import { ProductClassCodeFormPage } from './ProductClassCodeFormPage';
import { ProductPriceExporter } from './ProductPriceExporter';
import { MergeProductGenericCodePage } from './MergeProductGenericCodePage';
import { ListProductPriceMargins } from './ListProductPriceMargins';
import { ListBrandsPage } from './ListBrandsPage';
import { BrandFormPage } from './BrandFormPage';
import { MergeBrandsPage } from './MergeBrandsPage';

export const ProductRouter = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListProducts path="/" />
          <ProductForm path="/:productID" />
          <ProductForm path="/new" />
          <ListGenericCodesPage path="/genericCodes" />
          <ProductGenericCodeForm path="/genericCodes/new" />
          <ProductGenericCodeForm path="/genericCodes/:genericCodeID" />
          <MergeProductGenericCodePage path="/genericCodes/merge" />

          <ListProductClassCodesPage path="/classCodes/" />
          <ProductClassCodeFormPage path="/classCodes/new" />
          <ProductClassCodeFormPage path="/classCodes/:productClassCodeID" />
          <ProductPriceExporter path="/priceExporter" />
          <ListProductPriceMargins path="/priceMargins" />

          <ListBrandsPage path="/brands/" />
          <BrandFormPage path="/brands/new" />
          <MergeBrandsPage path="/brands/merge" />
          <BrandFormPage path="/brands/:brandID" />
        </Router>
      )}
    </Location>
  );
};
