import styled from 'styled-components';

export interface IconWrapperProps {
  onClick?: () => void;
  color?: string;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  height: fit-content;
  color: ${(props) => (props.color ? props.color : 'initial')};
`;
