import React, { useEffect } from 'react';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Alert,
  Box,
  Button,
  Container,
  Group,
  LoadingOverlay,
  Menu,
  Select,
  Stack,
  Switch,
  Tabs,
  TextInput,
  Title,
} from '@mantine/core';
import {
  IconBuildingWarehouse,
  IconCaretDown,
  IconLock,
} from '@tabler/icons-react';

import { showNotification } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, User, UserType } from '../../../oas/codegen3';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { StorePermissionsSection } from '@components/V2/PrivilegeController';
import { v4 } from 'uuid';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

export interface UserViewerProps {
  path?: string;
  userID?: string;
}

export const UserViewer = ({ userID }: UserViewerProps) => {
  const getUserByID = useStatefulAPIRequestMaker(DefaultOperations.getUserById);
  const setUserEnabled = useStatefulAPIRequestMaker(
    DefaultOperations.setUserEnabled
  );

  useEffect(() => {
    if (userID) {
      getUserByID.execute({ userId: userID });
    }
  }, [userID]);

  const userEnabled = getUserByID.response && getUserByID.response.user.enabled;

  const onCheckClicked = () => {
    if (!userID) {
      return;
    }

    openConfirmModal({
      title: (
        <Title order={3}>{userEnabled ? 'Disable User' : 'Enable User'}</Title>
      ),
      children: `Are you sure you want to ${
        userEnabled ? 'disable' : 'enable'
      } this user?`,
      labels: {
        confirm: `Yes - ${userEnabled ? 'Disable' : 'Enable'} User`,
        cancel: 'Go Back',
      },
      onConfirm: async () => {
        await setUserEnabled.execute({
          userId: userID,
          requestBody: { enabled: !userEnabled },
        });
        getUserByID.execute({ userId: userID });
      },
    });
  };

  const setPasswordPrivilege = useFindPrivileges({
    userType: ['admin'],
  });

  const onSetPasswordClicked = () => {
    if (!userID) {
      return;
    }
    const modalID = v4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Set User Password</Title>,
      closeOnClickOutside: false,
      children: (
        <SetUserPasswordModal
          userID={userID}
          closeModal={() => {
            closeModal(modalID);
          }}
        />
      ),
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={getUserByID.loading || setUserEnabled.loading} />
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <Group position="apart">
            <Title>
              {getUserByID.response?.user.firstName +
                ' ' +
                getUserByID.response?.user.lastName}
            </Title>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  variant="subtle"
                  rightIcon={<IconCaretDown size={14} />}
                >
                  Options
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  disabled={!setPasswordPrivilege.hasPrivilege}
                  onClick={onSetPasswordClicked}
                >
                  Set Password
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>

          <Switch
            label="Enabled"
            checked={userEnabled}
            onClick={onCheckClicked}
          />

          {getUserByID.response && !userEnabled && (
            <Alert title="User is disabled" color="red" icon={<IconLock />}>
              The user is disabled. They will not be able to login. Enable the
              user to edit the user's permissions and allow them to login.
            </Alert>
          )}

          {getUserByID.response && userEnabled && (
            <UserSettingsTables user={getUserByID.response.user} />
          )}

          {userID && userEnabled && (
            <Tabs defaultValue="permissions">
              <Tabs.List>
                <Tabs.Tab value="permissions" icon={<IconLock size={14} />}>
                  Permissions
                </Tabs.Tab>

                <Tabs.Tab
                  value="storePermissions"
                  icon={<IconBuildingWarehouse size={14} />}
                >
                  Store Permissions
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="permissions" pt="lg">
                <StorePermissionsSection
                  showStoreSelector={false}
                  entityID={userID}
                  entityType="user"
                />
              </Tabs.Panel>

              <Tabs.Panel value="storePermissions" pt="lg">
                <StorePermissionsSection
                  showStoreSelector={true}
                  entityID={userID}
                  entityType="user"
                />
              </Tabs.Panel>
            </Tabs>
          )}
        </Stack>
      </Container>
    </div>
  );
};

const SetUserPasswordModal = ({
  userID,
  closeModal,
}: {
  userID: string;
  closeModal: () => void;
}) => {
  const setUserPassword = useStatefulAPIRequestMaker(
    DefaultOperations.setUserPassword
  );

  const [firstPasswordInput, setFirstPasswordInput] = React.useState('');
  const [secondPasswordInput, setSecondPasswordInput] = React.useState('');

  const onSubmitClicked = async () => {
    if (!firstPasswordInput || !secondPasswordInput) {
      return;
    }

    await setUserPassword.execute({
      userId: userID,
      requestBody: {
        password: firstPasswordInput,
      },
    });

    showNotification({
      color: 'green',
      title: 'Password Updated',
      message: "The user's password has been updated",
    });

    closeModal();
  };

  return (
    <LoadingContainer loading={setUserPassword.loading}>
      <Stack>
        <Alert icon={<IconLock />} title="Set Password">
          Make sure you save the user's password. You won't be able to see it
          later.
        </Alert>
        <TextInput
          label="Password"
          required
          type="password"
          onChange={(e) => setFirstPasswordInput(e.target.value)}
          value={firstPasswordInput}
        />
        <TextInput
          label="Repeat Password"
          required
          type="password"
          onChange={(e) => setSecondPasswordInput(e.target.value)}
          value={secondPasswordInput}
        />
        <Button
          disabled={
            firstPasswordInput.length < 8 ||
            firstPasswordInput !== secondPasswordInput
          }
          onClick={onSubmitClicked}
        >
          Submit
        </Button>
      </Stack>
    </LoadingContainer>
  );
};

const UserSettingsTables = ({ user }: { user: User }) => {
  const updateUser = useStatefulAPIRequestMaker(DefaultOperations.updateUser);

  type FormFields = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    userType: UserType;
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = useForm<FormFields>({
    mode: 'onChange',
  });

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    phone,
    userType,
  }: FormFields) => {
    try {
      await updateUser.execute({
        userId: user.id,
        requestBody: {
          firstName,
          lastName,
          email,
          phoneNumberCountryCode: '973',
          phoneNumber: phone,
          userType: userType,
        },
      });

      showNotification({
        color: 'green',
        title: 'Update Successful',
        message: "The user's information has been updated",
      });
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Failed to Update User',
        message: JSON.stringify(e),
      });
    }
  };

  useEffect(() => {
    if (user) {
      const opt = {
        shouldDirty: true,
        shouldValidate: true,
      };
      setValue('firstName', user.firstName, opt);
      setValue('lastName', user.lastName, opt);
      setValue('email', user.email, opt);
      setValue('phone', user.phoneNumber, opt);
      setValue('userType', user.type, opt);
    }
  }, [user]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <TextInput
          disabled={updateUser.loading}
          label="Email"
          required={true}
          {...register('email', {
            required: true,
          })}
        />
        <TextInput
          disabled={updateUser.loading}
          label="Phone Number"
          {...register('phone')}
        />
        <TextInput
          disabled={updateUser.loading}
          label="First Name"
          required={true}
          {...register('firstName', {
            required: true,
          })}
        />
        <TextInput
          disabled={updateUser.loading}
          label="Last Name"
          required={true}
          {...register('lastName', {
            required: true,
          })}
        />
        <Select
          disabled={updateUser.loading}
          label="User Type"
          required={true}
          data={[
            {
              value: 'admin',
              label: 'Admin',
            },
            {
              value: 'employee',
              label: 'Employee',
            },
          ]}
          onChange={(value) => {
            setValue('userType', value as UserType);
          }}
          value={watch('userType')}
        />

        <Box>
          <Button
            color="green"
            type="submit"
            disabled={!isDirty || !isValid}
            loading={updateUser.loading}
          >
            Update User
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
