import React from 'react';
import { FlexBox } from '../flex/FlexBox';

export interface HStackProps {
  children?: React.ReactNode;
  spacing?: number | string;
  alignItems?:
    | 'stretch'
    | 'flex-start'
    | 'start'
    | 'self-start'
    | 'flex-end'
    | 'end'
    | 'self-end'
    | 'center'
    | 'baseline';
}

export function HStack(props: HStackProps) {
  return (
    <FlexBox
      columnGap={props.spacing}
      flexDirection="row"
      alignItems={props.alignItems}
    >
      {props.children}
    </FlexBox>
  );
}
