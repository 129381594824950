import { Location, Router } from '@reach/router';
import React from 'react';
import { ListVendors } from './ListVendors';
import { VendorForm } from './VendorForm';

export const VendorPages = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListVendors path="/" />
          <VendorForm path="/:vendorID" />
          <VendorForm path="/new" />
        </Router>
      )}
    </Location>
  );
};
