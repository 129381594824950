import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { CouponGroupState, DefaultOperations } from '../../../oas/codegen3';
import { useForm } from '@mantine/form';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Container,
  NumberInput,
  Stack,
  Text,
  Title,
  Textarea,
  Button,
  Group,
  Divider,
  Alert,
  Progress,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { dateToRFC3339 } from '../../../utils/dateFormatting';
import { useNavigate } from '@reach/router';
import { showNotification } from '@mantine/notifications';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { StatusBadge } from '@components/V2/StatusBadge';
import {
  IconAlertTriangleFilled,
  IconCircleCheck,
  IconPrinter,
  IconX,
} from '@tabler/icons-react';
import { openConfirmModal } from '@mantine/modals';
import { capitalCase } from 'change-case';
import { b64toBlob } from '../../../utils/b64toBlob';

import { useFindPrivileges } from '../../../pccstores/UserUtils';

export const CouponGroupForm = ({
  couponGroupID,
}: {
  path?: string;
  couponGroupID?: string;
}) => {
  const createCouponGroup = useStatefulAPIRequestMaker(
    DefaultOperations.createCouponGroup
  );
  const updateCouponGroup = useStatefulAPIRequestMaker(
    DefaultOperations.updateCouponGroup
  );
  const getCouponGroup = useStatefulAPIRequestMaker(
    DefaultOperations.getCouponGroup
  );
  const updateCouponGroupState = useStatefulAPIRequestMaker(
    DefaultOperations.updateCouponGroupState
  );
  const getCouponGroupCouponsPDF = useStatefulAPIRequestMaker(
    DefaultOperations.getCouponGroupCouponsPdf
  );

  useDocumentTitle(
    couponGroupID
      ? `Coupon Group: ${getCouponGroup.response?.couponGroup.number ?? '...'}`
      : 'New Coupon Group'
  );

  const loadCouponGroup = async () => {
    if (couponGroupID) {
      getCouponGroup.execute({ couponGroupId: couponGroupID });
    }
  };

  useEffect(() => {
    if (couponGroupID) {
      loadCouponGroup();
    }
  }, [couponGroupID]);

  const navigate = useNavigate();

  type FormFields = {
    numberOfCoupons: number | '';
    eachCouponAmount: number | '';
    notes: string;
    expiresAt: Date | null;
  };
  const form = useForm<FormFields>({});

  useEffect(() => {
    if (getCouponGroup.response) {
      form.setValues({
        numberOfCoupons: Number(
          getCouponGroup.response.couponGroup.numberOfCoupons
        ),
        eachCouponAmount: Number(
          getCouponGroup.response.couponGroup.eachCouponAmount
        ),
        notes: getCouponGroup.response.couponGroup.notes,
        expiresAt: new Date(getCouponGroup.response.couponGroup.expiresAt),
      });
    }
  }, [getCouponGroup.response]);

  const changeCouponGroupState = ({
    couponGroupID,
    state,
  }: {
    couponGroupID: string;
    state: CouponGroupState;
  }) => {
    if (state === 'coupons_created') {
      openConfirmModal({
        title: <Title order={3}>Create Coupons</Title>,
        children: (
          <Stack>
            <Text>
              Are you sure you want to create the coupons for this coupon group?
            </Text>
            <Alert
              color="red"
              title="This cannot be undone"
              icon={<IconAlertTriangleFilled />}
            >
              Once the coupons have been created, they cannot be deleted.
            </Alert>{' '}
          </Stack>
        ),
        labels: {
          confirm: `Yes - Create Coupons`,
          cancel: 'Cancel',
        },
        onConfirm: async () => {
          try {
            await updateCouponGroupState.execute({
              couponGroupId: couponGroupID,
              requestBody: {
                state,
              },
            });

            showNotification({
              color: 'green',
              title: `Coupons Created`,
              message: 'The coupons for this coupon group has been created.',
            });

            loadCouponGroup();
          } catch (e) {
            handleApiErrorMessage(e);
          }
        },
      });
    } else {
      openConfirmModal({
        title: <Title order={3}>Set to {capitalCase(state)}</Title>,
        children: `Are you sure you want to set this coupon group state to ${state}?`,
        labels: {
          confirm: `Yes - Set to ${capitalCase(state)}`,
          cancel: 'Cancel',
        },
        confirmProps: { color: state === 'cancelled' ? 'red' : undefined },
        onConfirm: async () => {
          try {
            await updateCouponGroupState.execute({
              couponGroupId: couponGroupID,
              requestBody: {
                state,
              },
            });

            showNotification({
              color: 'green',
              title: `Coupon Group Set to ${capitalCase(state)}`,
              message: 'Coupon Group State updated.',
            });

            loadCouponGroup();
          } catch (e) {
            handleApiErrorMessage(e);
          }
        },
      });
    }
  };

  const onSubmit = form.onSubmit(async (values) => {
    if (
      values.numberOfCoupons === '' ||
      values.eachCouponAmount === '' ||
      !values.expiresAt
    ) {
      return;
    }

    if (couponGroupID) {
      await updateCouponGroup.execute({
        couponGroupId: couponGroupID,
        requestBody: {
          numberOfCoupons: values.numberOfCoupons,
          eachCouponAmount: values.eachCouponAmount.toString(),
          notes: values.notes,
          expiresAt: dateToRFC3339(values.expiresAt)!,
        },
      });

      showNotification({
        color: 'green',
        title: 'Coupon Group Updated',
        message: 'The coupon group has been updated.',
      });
    } else {
      try {
        const response = await createCouponGroup.execute({
          requestBody: {
            numberOfCoupons: values.numberOfCoupons,
            eachCouponAmount: values.eachCouponAmount.toString(),
            notes: values.notes,
            expiresAt: dateToRFC3339(values.expiresAt)!,
            currency: 'BHD',
          },
        });

        showNotification({
          color: 'green',
          title: 'Coupon Group Drafted',
          message: 'The coupon group has been drafted.',
        });

        navigate(`/couponGroups/${response.couponGroup.id}`);
      } catch (e) {
        handleApiErrorMessage(e);
      }
    }
  });

  const getCouponsPDF = async () => {
    if (!couponGroupID) {
      return;
    }

    try {
      const response = await getCouponGroupCouponsPDF.execute({
        couponGroupId: couponGroupID,
      });

      const file = b64toBlob(response.pdfBase64, 'application/pdf');
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  const canPrintCouponPDF = useFindPrivileges({
    action: 'read',
    resource: 'coupons',
  });

  return (
    <LoadingContainer
      loading={
        createCouponGroup.loading ||
        getCouponGroup.loading ||
        updateCouponGroup.loading
      }
    >
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <Stack spacing={0}>
            {couponGroupID ? (
              <Stack spacing={0}>
                <Text size="sm" fw={700}>
                  Coupon Group
                </Text>
                <Title>
                  {getCouponGroup.response?.couponGroup.number ?? 'Loading...'}
                </Title>
              </Stack>
            ) : (
              <Title>New Coupon Group</Title>
            )}
          </Stack>

          {couponGroupID && getCouponGroup.response?.couponGroup.state && (
            <Group>
              <StatusBadge state={getCouponGroup.response?.couponGroup.state} />

              {(getCouponGroup.response?.couponGroup.state !==
                'coupons_created' ||
                canPrintCouponPDF.hasPrivilege) && (
                <Divider orientation="vertical" />
              )}

              {getCouponGroup.response?.couponGroup.state === 'draft' && (
                <Button
                  compact
                  variant="light"
                  color="red"
                  leftIcon={<IconX size={14} />}
                  onClick={() =>
                    changeCouponGroupState({
                      couponGroupID: couponGroupID,
                      state: 'cancelled',
                    })
                  }
                >
                  Cancel
                </Button>
              )}

              {getCouponGroup.response?.couponGroup.state === 'draft' && (
                <Button
                  compact
                  variant="light"
                  color="green"
                  leftIcon={<IconCircleCheck size={14} />}
                  onClick={() =>
                    changeCouponGroupState({
                      couponGroupID: couponGroupID,
                      state: 'coupons_created',
                    })
                  }
                >
                  Create Coupons
                </Button>
              )}

              {getCouponGroup.response?.couponGroup.state ===
                'coupons_created' && (
                <Button
                  compact
                  variant="light"
                  leftIcon={<IconPrinter size={14} />}
                  onClick={getCouponsPDF}
                  loading={getCouponGroupCouponsPDF.loading}
                >
                  Print Coupons
                </Button>
              )}
            </Group>
          )}

          {getCouponGroup.response?.couponsUsage && (
            <Stack>
              <Progress
                size="xl"
                sections={[
                  {
                    value:
                      (100 *
                        getCouponGroup.response.couponsUsage
                          .redeemedOrReservedCoupons) /
                      Number(
                        getCouponGroup.response.couponGroup.numberOfCoupons
                      ),
                    color: 'green',
                    label: 'Redeemed',
                    tooltip: `${getCouponGroup.response.couponsUsage.redeemedOrReservedCoupons} Coupons Redeemed`,
                  },
                  {
                    value:
                      (100 *
                        getCouponGroup.response.couponsUsage.expiredCoupons) /
                      Number(
                        getCouponGroup.response.couponGroup.numberOfCoupons
                      ),
                    color: 'red',
                    label: 'Expired',
                    tooltip: `${getCouponGroup.response.couponsUsage.expiredCoupons} Coupons Expired`,
                  },
                  {
                    value:
                      (100 *
                        getCouponGroup.response.couponsUsage.unusedCoupons) /
                      Number(
                        getCouponGroup.response.couponGroup.numberOfCoupons
                      ),
                    color: 'gray',
                    label: 'Unused Coupons',
                    tooltip: `${getCouponGroup.response.couponsUsage.unusedCoupons} Coupons Unused`,
                  },
                ]}
              />
            </Stack>
          )}

          <form onSubmit={onSubmit}>
            <Stack>
              <NumberInput
                label="Number of Coupons"
                placeholder="Number of Coupons"
                required
                description="The number of coupons that will be created for this coupon group."
                min={0}
                precision={0}
                readOnly={
                  getCouponGroup.response?.couponGroup.state ===
                  'coupons_created'
                }
                {...form.getInputProps('numberOfCoupons')}
              />
              <NumberInput
                label="Each Coupon Amount"
                placeholder="Each Coupon Amount"
                required
                description="The value of each coupon."
                min={0}
                precision={3}
                readOnly={
                  getCouponGroup.response?.couponGroup.state ===
                  'coupons_created'
                }
                {...form.getInputProps('eachCouponAmount')}
              />
              <Textarea
                label="Notes"
                placeholder="Notes"
                readOnly={
                  getCouponGroup.response?.couponGroup.state ===
                  'coupons_created'
                }
                {...form.getInputProps('notes')}
              />

              <DateInput
                required
                placeholder="Expires At"
                label="Expires At"
                readOnly={
                  getCouponGroup.response?.couponGroup.state ===
                  'coupons_created'
                }
                {...form.getInputProps('expiresAt')}
              />

              {(!couponGroupID ||
                getCouponGroup.response?.couponGroup.state !==
                  'coupons_created') && (
                <Group>
                  <Button type="submit" color="green">
                    {couponGroupID ? 'Update' : 'Create'}
                  </Button>
                </Group>
              )}
            </Stack>
          </form>
        </Stack>
      </Container>
    </LoadingContainer>
  );
};
