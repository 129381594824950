import { useDebouncedValue, useInputState } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { Select } from '@mantine/core';
import { InputRightSectionLoader } from '@components/V2/InputRightSectionLoader';

export const GenericSelector = <T,>({
  onItemSelected,
  searchItems,
  searchItemsResponse,
  getItemByID,
  itemID,
  disabled,
  label,
  readOnly,
  item,
  error,
  loading,
}: {
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  searchItems: (searchTarget: string) => void;
  searchItemsResponse?: { value: string; label: string; item: T }[];
  getItemByID: (id: string) => Promise<T>;
  onItemSelected: (t: T | null) => void;
  item?: { value: string; label: string; item: T } | null;
  itemID?: string | null;
  loading?: boolean;
  error?: string | null;
}) => {
  const [searchTarget, setSearchTarget] = useInputState('');
  const [searchTargetDebounce] = useDebouncedValue(searchTarget, 200);

  useEffect(() => {
    if (itemID) {
      const fn = async () => {
        const response = await getItemByID(itemID);
        onItemSelected(response);
      };

      fn();
    }
  }, [itemID]);

  useEffect(() => {
    searchItems(searchTarget);
  }, [searchTargetDebounce]);

  let dataArr: { value: string; label: string; item: T }[] = [];
  if (item) {
    dataArr.push(item);
  }

  if (searchItemsResponse) {
    dataArr.push(...searchItemsResponse);
  }

  if (item && searchItemsResponse) {
    dataArr = dataArr.filter(
      (i1, index, self) =>
        index === self.findIndex((i2) => i2.value === i1.value)
    );
  }
  return (
    <Select
      onChange={(value) => {
        const found = dataArr.find((i) => i.value === value);
        onItemSelected(found?.item || null);
        if (!found) {
          setSearchTarget('');
        }
      }}
      filter={() => true}
      searchable
      rightSection={InputRightSectionLoader(loading)}
      onSearchChange={setSearchTarget}
      searchValue={searchTarget}
      value={item?.value}
      data={dataArr}
      clearable
      label={label || ''}
      readOnly={readOnly}
      disabled={disabled}
      error={error}
    />
  );
};
