import {
  DefaultOperations,
  ProductBrands,
  ProductBrandWithMetadata,
} from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect, useState } from 'react';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Card,
  Center,
  Checkbox,
  Container,
  Group,
  Loader,
  Modal,
  SegmentedControl,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { IconArrowRight, IconSearch } from '@tabler/icons-react';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { showNotification } from '@mantine/notifications';

export const MergeBrandsPage = ({}: { path: string }) => {
  useDocumentTitle('Merge Product Brands');
  const [mergeBrandsModalOpened, mergeBrandsModalHandler] =
    useDisclosure(false);

  type ViewModes = 'all_brands' | 'selected_brands';
  const [viewMode, setViewMode] = useState<ViewModes>('all_brands');
  const [selectedBrands, setSelectedBrands] = useState<{
    [brandID: string]: ProductBrandWithMetadata;
  }>({});

  const listBrands = useStatefulAPIRequestMaker(
    DefaultOperations.listProductBrands
  );

  const {
    pagination,
    paginationElement,
    debounceValue,
    target,
    setTargetFromInput,
  } = useListSearcherHelper({
    debounceDelay: 200,
    limitPerPage: 30,
  });

  const loadBrands = () => {
    return listBrands.execute({
      target: debounceValue,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  };

  useEffect(() => {
    const p = loadBrands();

    return () => p.cancel();
  }, [debounceValue, pagination.limit, pagination.offset]);

  const toggleCheck = (productBrand: ProductBrandWithMetadata) => {
    const brandExists = selectedBrands[productBrand.id] !== undefined;

    if (!brandExists) {
      setSelectedBrands((prev) => {
        return { ...prev, [productBrand.id]: productBrand };
      });
    } else {
      setSelectedBrands((prev) => {
        delete prev[productBrand.id];
        return { ...prev };
      });
    }
  };

  const brands =
    viewMode === 'all_brands'
      ? listBrands.response?.productBrands.map((item) => item.productBrand) ??
        []
      : Object.values(selectedBrands).sort((a, b) =>
          a.brandName.localeCompare(b.brandName)
        );

  const allItemsChecked =
    brands.length > 0 &&
    brands.findIndex((pb) => {
      return selectedBrands[pb.id] === undefined;
    }) === -1;

  const selectAll = () => {
    if (allItemsChecked) {
      brands.forEach((b) => {
        toggleCheck(b);
      });
    } else {
      brands.forEach((b) => {
        if (selectedBrands[b.id] === undefined) {
          toggleCheck(b);
        }
      });
    }
  };

  const selectedItemsLength = Object.keys(selectedBrands).length;
  let productsAffect = 0;
  Object.values(selectedBrands).forEach((b) => {
    productsAffect += b.productCount;
  });

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Modal
        opened={mergeBrandsModalOpened}
        onClose={mergeBrandsModalHandler.close}
        title={<Title order={3}>Merge Brands</Title>}
      >
        <MergeBrandsModal
          onMerged={() => {
            mergeBrandsModalHandler.close();
            setSelectedBrands({});
            loadBrands();
          }}
          brandNamesToMerge={Object.values(selectedBrands).map(
            (b) => b.brandName
          )}
        />
      </Modal>
      <Stack>
        <Title>Merge Product Brands</Title>
        <Group grow>
          <Card withBorder radius="md" p="sm">
            <Text fw={700} color="dark" size="xs">
              Select Product Brands
            </Text>
            <Text fw={700} color="dark" size="xl">
              {selectedItemsLength === 1
                ? '1 Brand'
                : `${selectedItemsLength} Brands`}
            </Text>
          </Card>

          <Card withBorder radius="md" p="sm">
            <Text fw={700} size="xs">
              Products Affected
            </Text>
            <Text fw={700} size="xl">
              {productsAffect === 1
                ? '1 Product'
                : `${productsAffect} Products`}
            </Text>
          </Card>
        </Group>

        <Group position="apart">
          <SegmentedControl
            value={viewMode}
            onChange={(v) => setViewMode(v as ViewModes)}
            data={[
              { label: 'All Product Brands', value: 'all_brands' },
              { label: 'Selected Product Brands', value: 'selected_brands' },
            ]}
          />

          <Button
            color="green"
            rightIcon={<IconArrowRight size={16} />}
            disabled={Object.keys(selectedBrands).length <= 1}
            onClick={mergeBrandsModalHandler.open}
          >
            Next
          </Button>
        </Group>

        {viewMode === 'all_brands' && (
          <TextInput
            icon={<IconSearch />}
            label="Search"
            placeholder="Search Product Brands"
            onChange={setTargetFromInput}
            value={target}
          />
        )}

        {viewMode === 'all_brands' &&
          paginationElement(listBrands.response?.count)}

        <LoadingContainer loading={listBrands.loading} minHeight={PX384.Number}>
          <Table withBorder striped>
            <thead>
              <tr>
                <th style={{ width: PX32.PX, maxWidth: PX32.PX }}>
                  <Center>
                    <Checkbox onChange={selectAll} checked={allItemsChecked} />
                  </Center>
                </th>
                <th>Brands</th>
                <th style={{ textAlign: 'end' }}>Products</th>
              </tr>
            </thead>

            <tbody>
              {brands.map((pb) => {
                const selected = selectedBrands[pb.id] !== undefined;

                return (
                  <tr key={pb.id}>
                    <td style={{ width: PX32.PX, maxWidth: PX32.PX }}>
                      <Center>
                        <Checkbox
                          checked={selected}
                          onChange={() => toggleCheck(pb)}
                        />
                      </Center>
                    </td>
                    <td>{pb.brandName}</td>
                    <td style={{ textAlign: 'end' }}>{pb.productCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </LoadingContainer>

        {viewMode === 'all_brands' &&
          paginationElement(listBrands.response?.count)}
      </Stack>
    </Container>
  );
};

const MergeBrandsModal = ({
  brandNamesToMerge,
  onMerged,
}: {
  brandNamesToMerge: string[];
  onMerged: (productBrand: ProductBrands) => void;
}) => {
  const mergeBrands = useStatefulAPIRequestMaker(
    DefaultOperations.mergeProductBrands
  );
  const [viewMode, setViewMode] =
    useState<'target_brand_input' | 'submit'>('target_brand_input');
  const [targetBrand, setTargetBrand] = useInputState('');

  const onMergeBrandsClicked = async () => {
    try {
      const response = await mergeBrands.execute({
        requestBody: {
          brandNames: brandNamesToMerge,
          brandNameToMergeTo: targetBrand,
        },
      });

      onMerged(response.mergedToProductBrand);
      showNotification({
        color: 'green',
        title: 'Brands Merged',
        message: `Brands have been merged into ${response.mergedToProductBrand.brandName}.`,
      });
      setTargetBrand('');
      setViewMode('target_brand_input');
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  return (
    <Stack>
      {mergeBrands.loading && (
        <Center h={PX64.Number}>
          <Loader />
        </Center>
      )}
      {!mergeBrands.loading && viewMode === 'target_brand_input' && (
        <Stack>
          <TextInput
            label="New Brand Name"
            placeholder="New Brand Name"
            description="Name to merge all the brand into."
            value={targetBrand}
            onChange={setTargetBrand}
          />
          <Button
            onClick={() => setViewMode('submit')}
            rightIcon={<IconArrowRight />}
            disabled={targetBrand.trim() === ''}
          >
            Next
          </Button>
        </Stack>
      )}
      {!mergeBrands.loading && viewMode === 'submit' && (
        <Stack>
          <Text>
            Are you sure you want to merge{' '}
            <Text fw={700} span>
              {brandNamesToMerge.length}
            </Text>{' '}
            brands into{' '}
            <Text fw={700} span>
              {targetBrand}
            </Text>
            ?
          </Text>
          <Group position="right">
            <Button
              color="dark"
              variant="default"
              onClick={() => setViewMode('target_brand_input')}
            >
              Cancel
            </Button>
            <Button color="green" onClick={onMergeBrandsClicked}>
              Yes - Merge Brands
            </Button>
          </Group>
        </Stack>
      )}
    </Stack>
  );
};
