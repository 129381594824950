import { useNavigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { Box } from '../../../components/box/Box';
import { Store } from '../../../models/Store';
import {
  Button,
  Container,
  Flex,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { useDebouncedValue } from '@mantine/hooks';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const StoresViewer = ({}: { path: string }) => {
  const navigate = useNavigate();
  const [target, setTarget] = useState('');
  const [debounceTarget] = useDebouncedValue(target, 200);
  const searchStores = useStatefulAPIRequestMaker(
    DefaultOperations.searchStores
  );

  useEffect(() => {
    searchStores.execute({ target: debounceTarget });
  }, [debounceTarget]);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Stores</Title>
        <Flex>
          <Button
            color="green"
            onClick={() => navigate('/stores/new')}
            leftIcon={<IconPlus />}
          >
            New Store
          </Button>
        </Flex>
        <Box marginTop="10px">
          <TextInput
            label="Search"
            icon={<IconSearch />}
            placeholder="Search"
            onChange={(e: any) => setTarget(e.target.value)}
            value={target}
          />
        </Box>

        <StoresTable
          stores={searchStores.response?.stores || []}
          loading={searchStores.loading}
        />
      </Stack>
    </Container>
  );
};

const StoresTable = ({
  stores,
  loading,
}: {
  stores: Store[];
  loading: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <LoadingContainer loading={loading} minHeight={PX384.Number}>
      <Table highlightOnHover={true} withBorder>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>

        <tbody>
          {stores.map((s) => {
            const onClick = () => {
              navigate(`/stores/${s.id}`);
            };

            return (
              <ClickableTableRow onClick={onClick} key={s.id}>
                <td>{s.name}</td>
              </ClickableTableRow>
            );
          })}
        </tbody>
      </Table>
    </LoadingContainer>
  );
};
