import moment from 'moment';

export function RFC3339ToEasyDate(time?: string) {
  if (!time) {
    return '';
  }
  const m = moment(time);

  return m.format('MMM DD, YYYY');
}

// RFC3339ToPrettyMonthDontModifyTimezone extracts the
// month and year from the given time without modifying the timzone.
//
// In essence, this treats "time" as a date object and not a specific moment
// in time.
export function RFC3339ToPrettyMonthDontModifyTimezone(time?: string) {
  if (!time) {
    return '';
  }
  const m = moment.utc(time);

  return m.format(`MMM 'YY`);
}

export function RFC3339ToEasyDateTime(time: string) {
  const m = moment(time);

  return m.format("MMM DD, [']YY hh:mm a");
}

export const formatDate = (date: Date | null): string | null => {
  if (!date) {
    return null;
  }

  const m = moment(date);
  return m.format('YYYY-MM-DD');
};

export const dateToRFC3339 = (date: Date | null): string | null => {
  if (!date) {
    return null;
  }

  const m = moment(date);
  return m.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const RFC3339ToDate = (time: string): Date => {
  const m = moment(time);

  return m.toDate();
};
