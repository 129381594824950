import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import {
  Button,
  Code,
  Container,
  Group,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { PX1536, PX32, PX64, PX96 } from '../../../components/Px';
import { navigate } from '@reach/router';
import React, { useEffect } from 'react';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { StatusBadge } from '@components/V2/StatusBadge';
import { formatToCurrency } from '@pcc/api/models/Currency';

export const ListCouponGroups = ({}: { path?: string }) => {
  useDocumentTitle('Coupon Groups');

  return (
    <Container maw={PX1536.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Coupon Groups</Title>
        <Group>
          <Button color="green" onClick={() => navigate('/couponGroups/new')}>
            New Coupon Group
          </Button>
        </Group>

        <CouponGroupsList />
      </Stack>
    </Container>
  );
};

const CouponGroupsList = () => {
  const listCouponGroups = useStatefulAPIRequestMaker(
    DefaultOperations.listCouponGroups
  );

  const {
    target,
    setTargetFromInput,
    pagination,
    onChangeDeps,
    paginationElement,
  } = useListSearcherHelper({
    additionalDeps: [],
    debounceDelay: 200,
    limitPerPage: 50,
  });

  useEffect(() => {
    const p = listCouponGroups.execute({
      limit: pagination.limit,
      offset: pagination.offset,
      target,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={listCouponGroups.loading}>
        <Stack>
          {paginationElement(listCouponGroups.response?.count)}
          <Table highlightOnHover withBorder>
            <thead>
              <tr>
                <th>Number</th>
                <th>Created At</th>
                <th>Created By</th>
                <th>State</th>
                <th style={{ textAlign: 'right' }}>Number of Coupons</th>
                <th style={{ textAlign: 'right' }}>Each Coupon Amount</th>
              </tr>
            </thead>
            <tbody>
              {(listCouponGroups.response?.couponGroups || []).map((cg) => (
                <ClickableTableRow
                  targetURL={`/couponGroups/${cg.couponGroup.id}`}
                  key={cg.couponGroup.id}
                >
                  <td>
                    <Code>{cg.couponGroup.number}</Code>
                  </td>
                  <td>{RFC3339ToEasyDateTime(cg.couponGroup.createdAt)}</td>
                  <td>
                    {cg.creatingUser.firstName} {cg.creatingUser.lastName}
                  </td>
                  <td>
                    <StatusBadge state={cg.couponGroup.state} />
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {cg.couponGroup.numberOfCoupons}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatToCurrency(
                      cg.couponGroup.eachCouponAmount,
                      cg.currency
                    )}
                  </td>
                </ClickableTableRow>
              ))}
            </tbody>
          </Table>
          {paginationElement(listCouponGroups.response?.count)}
        </Stack>
      </LoadingContainer>
    </Stack>
  );
};
