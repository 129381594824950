import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import React, { useEffect } from 'react';
import {
  Button,
  Code,
  Container,
  Flex,
  Group,
  Pagination,
  Stack,
  Table,
  Title,
} from '@mantine/core';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { Link } from '@reach/router';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const ListBulkPurchaseOrderPaymentsPage = ({}: { path: string }) => {
  const listBulkPayments = useStatefulAPIRequestMaker(
    DefaultOperations.listBulkPurchaseOrderPayments
  );

  const { pagination, onChangeDeps } = useListSearcherHelper({
    additionalDeps: [],
    debounceDelay: 300,
    limitPerPage: 30,
  });

  useEffect(() => {
    listBulkPayments.execute({
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [...onChangeDeps]);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Bulk Payments</Title>

        <Group>
          <Button
            component={Link}
            to="/purchaseOrders/bulkPayments/new"
            color="green"
          >
            New Bulk Payment
          </Button>
        </Group>

        <LoadingContainer
          loading={listBulkPayments.loading}
          minHeight={PX384.Number}
        >
          <Stack>
            <Flex justify="flex-end">
              <Pagination
                size="sm"
                total={pagination.calculateTotalPages(
                  listBulkPayments.response?.count
                )}
                value={pagination.currentPaginationPage}
                onChange={pagination.onPaginationPageChange}
              />
            </Flex>

            <Table highlightOnHover>
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Created At</th>
                  <th style={{ textAlign: 'right' }}>Amount</th>
                </tr>
              </thead>

              <tbody>
                {(
                  listBulkPayments.response?.bulkPurchaseOrderPayments ?? []
                ).map((bulkPayment) => {
                  return (
                    <ClickableTableRow
                      targetURL={`/purchaseOrders/bulkPayments/${bulkPayment.bulkPurchaseOrderPayment.id}`}
                      key={bulkPayment.bulkPurchaseOrderPayment.id}
                    >
                      <td>
                        <Code style={{ whiteSpace: 'nowrap' }}>
                          {bulkPayment.bulkPurchaseOrderPayment.reference}
                        </Code>
                      </td>
                      <td>
                        {RFC3339ToEasyDateTime(
                          bulkPayment.bulkPurchaseOrderPayment.createdAt
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {formatToCurrency(
                          bulkPayment.bulkPurchaseOrderPayment.totalAmount,
                          bulkPayment.currency
                        )}
                      </td>
                    </ClickableTableRow>
                  );
                })}
              </tbody>
            </Table>

            <Flex justify="flex-end">
              <Pagination
                size="sm"
                total={pagination.calculateTotalPages(
                  listBulkPayments.response?.count
                )}
                value={pagination.currentPaginationPage}
                onChange={pagination.onPaginationPageChange}
              />
            </Flex>
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
