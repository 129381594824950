import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Box,
  Button,
  Container,
  Group,
  Stack,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { DefaultOperations } from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from '@reach/router';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { IconBuildingWarehouse, IconLock, IconUser } from '@tabler/icons-react';
import { StorePermissionsSection } from '@components/V2/PrivilegeController';
import { openConfirmModal, openModal } from '@mantine/modals';
import { v4 as uuidv4 } from 'uuid';
import { UserModalSelectorBody } from '@components/V2/modal_selector/UserModalSelectorBody';

export const TeamViewer = ({ teamID }: { path?: string; teamID?: string }) => {
  const navigate = useNavigate();
  const createTeam = useStatefulAPIRequestMaker(DefaultOperations.createTeam);
  const updateTeam = useStatefulAPIRequestMaker(DefaultOperations.updateTeam);
  const getTeam = useStatefulAPIRequestMaker(DefaultOperations.getTeam);
  const deleteTeam = useStatefulAPIRequestMaker(DefaultOperations.deleteTeam);
  type FormFields = {
    name: string;
    description: string;
  };

  const form = useForm<FormFields>({
    validate: {
      name: (value) => (value.trim().length === 0 ? 'Name is required' : null),
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (teamID) {
      await updateTeam.execute({
        teamId: teamID,
        requestBody: {
          name: values.name,
          description: values.description,
        },
      });

      showNotification({
        color: 'green',
        title: 'Team Updated',
        message: `Team ${values.name} has been updated.`,
      });
    } else {
      const response = await createTeam.execute({
        requestBody: {
          name: values.name,
          description: values.description,
        },
      });

      showNotification({
        color: 'green',
        title: 'Team Created',
        message: `Team ${values.name} has been created.`,
      });

      navigate(`/teams/${response.team.id}`);
    }
  });

  useEffect(() => {
    const fn = async () => {
      if (!teamID) {
        return;
      }

      const response = await getTeam.execute({ teamId: teamID });
      form.setValues({
        name: response.team.name,
        description: response.team.description,
      });
    };

    fn();
  }, [teamID]);

  const onDeleteClicked = () => {
    if (!teamID) {
      return;
    }

    openConfirmModal({
      title: <Title order={3}>Delete Team</Title>,
      children: 'Are you sure you want to delete this team?',
      labels: {
        confirm: 'Yes - Delete Team Code',
        cancel: 'Go Back',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        await deleteTeam.execute({
          teamId: teamID,
        });

        showNotification({
          color: 'green',
          title: 'Team Deleted',
          message: `The team has been successfully deleted.`,
        });

        navigate(`/teams`);
      },
    });
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer
        loading={createTeam.loading || getTeam.loading || updateTeam.loading}
      >
        <Stack>
          <Group position="apart">
            <Title>{!teamID ? 'New Team' : form.values.name ?? ''}</Title>
            {teamID && (
              <Button color="red" onClick={onDeleteClicked}>
                Delete Team
              </Button>
            )}
          </Group>
          <form onSubmit={onSubmit}>
            <Stack>
              <TextInput
                label="Name"
                placeholder="Team Name"
                required={true}
                {...form.getInputProps('name')}
              />

              <TextInput
                label="Description"
                placeholder="Team Description"
                {...form.getInputProps('description')}
              />

              <Box>
                <Button
                  color="green"
                  type="submit"
                  disabled={!form.isDirty || !form.isValid}
                >
                  {teamID ? 'Update Team' : 'Create Team'}
                </Button>
              </Box>
            </Stack>
          </form>

          {teamID && <TeamTabs teamID={teamID} />}
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const TeamTabs = ({ teamID }: { teamID: string }) => {
  return (
    <Tabs defaultValue="teamUsers">
      <Tabs.List>
        <Tabs.Tab value="teamUsers" icon={<IconUser size={14} />}>
          Team Users
        </Tabs.Tab>

        <Tabs.Tab value="permissions" icon={<IconLock size={14} />}>
          Permissions
        </Tabs.Tab>

        <Tabs.Tab
          value="storePermissions"
          icon={<IconBuildingWarehouse size={14} />}
        >
          Store Permissions
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="permissions" pt="lg">
        <StorePermissionsSection
          showStoreSelector={false}
          entityID={teamID}
          entityType="team"
        />
      </Tabs.Panel>

      <Tabs.Panel value="storePermissions" pt="lg">
        <StorePermissionsSection
          showStoreSelector={true}
          entityID={teamID}
          entityType="team"
        />
      </Tabs.Panel>

      <Tabs.Panel value="teamUsers" pt="lg">
        <TeamUsersViewer teamID={teamID} />
      </Tabs.Panel>
    </Tabs>
  );
};

const TeamUsersViewer = ({ teamID }: { teamID: string }) => {
  const listTeamUsers = useStatefulAPIRequestMaker(
    DefaultOperations.listTeamUsers
  );
  const addUserToTeam = useStatefulAPIRequestMaker(
    DefaultOperations.addTeamUser
  );
  const deleteTeamUser = useStatefulAPIRequestMaker(
    DefaultOperations.deleteTeamUser
  );

  useEffect(() => {
    listTeamUsers.execute({ teamId: teamID });
  }, [teamID]);

  const onAddUserClicked = () => {
    const modalID = uuidv4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Add User to Team</Title>,
      children: (
        <UserModalSelectorBody
          onUserSelected={async (u) => {
            await addUserToTeam.execute({
              teamId: teamID,
              userId: u.id,
            });

            listTeamUsers.execute({ teamId: teamID });

            showNotification({
              color: 'green',
              title: 'User Added to Team',
              message: 'User has been added to the team successfully.',
            });
          }}
        />
      ),
    });
  };

  return (
    <Stack>
      <Box>
        <Button onClick={onAddUserClicked}>Add User</Button>
      </Box>

      <LoadingContainer
        loading={deleteTeamUser.loading || listTeamUsers.loading}
      >
        <Table>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {(listTeamUsers.response?.users || []).map((u) => {
              const onRemoveClicked = async () => {
                openConfirmModal({
                  title: <Title order={3}>Remove From Team</Title>,
                  children: `Are you sure you want to remove ${
                    u.firstName + ' ' + u.lastName
                  } from this team?`,
                  labels: {
                    confirm: `Yes - Remove User`,
                    cancel: 'Go Back',
                  },
                  confirmProps: {
                    color: 'red',
                  },
                  onConfirm: async () => {
                    await deleteTeamUser.execute({
                      teamId: teamID,
                      userId: u.id,
                    });

                    await listTeamUsers.execute({ teamId: teamID });

                    showNotification({
                      color: 'green',
                      title: 'User Removed',
                      message:
                        'User has been removed from the team successfully.',
                    });
                  },
                });
              };

              return (
                <tr key={u.id}>
                  <td>
                    <Group position="apart">
                      <Text>{u.firstName + ' ' + u.lastName}</Text>
                      <Button
                        compact
                        variant="light"
                        color="red"
                        onClick={onRemoveClicked}
                      >
                        Remove
                      </Button>
                    </Group>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
