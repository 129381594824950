import { Router, Location } from '@reach/router';
import { ListSales } from './ListSales';
import { SaleViewer } from './SaleViewer';
import { SalePaymentRoutes } from '../sale_payment_pages/SalePaymentRoutes';
import React from 'react';

export const SalesRouter = ({}: { path?: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListSales path="/" />
          <SalePaymentRoutes path="/payments/*" />
          <SaleViewer path="/:saleID" />
        </Router>
      )}
    </Location>
  );
};
