import {
  Currency,
  DefaultOperations,
  Product,
  PurchaseOrder,
  PurchaseOrderLine,
} from '../../../../oas/codegen3';
import {
  Alert,
  Button,
  Menu,
  NumberInput,
  Stack,
  Table,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { PX640 } from '../../../../components/Px';
import { b64toBlob } from '../../../../utils/b64toBlob';
import React, { useEffect, useState } from 'react';
import {
  IconCaretDown,
  IconCheck,
  IconHourglassEmpty,
  IconNote,
  IconPrinter,
  IconRefresh,
  IconTrash,
  IconTruckDelivery,
} from '@tabler/icons-react';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import { formatBig } from '@pcc/api/models/Currency';
import Big from 'big.js';

export const PurchaseOrderMenu = ({
  purchaseOrder,
  currency,
  purchaseOrderLines,
  refreshPurchaseOrder,
}: {
  purchaseOrder?: PurchaseOrder;
  currency?: Currency;
  purchaseOrderLines?: {
    purchaseOrderLine: PurchaseOrderLine;
    product: Product;
  }[];
  refreshPurchaseOrder: () => void;
}) => {
  const updatePurchaseOrderState = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderState
  );

  const getPurchaseOrderPDF = useStatefulAPIRequestMaker(
    DefaultOperations.getPurchaseOrderPdf
  );

  if (!purchaseOrder || !currency || !purchaseOrderLines) {
    return null;
  }

  const purchaseOrderID = purchaseOrder.id;

  const showSetToReadyModal = ({
    purchaseOrderID,
  }: {
    purchaseOrderID: string;
  }) => {
    openConfirmModal({
      title: <Title order={3}>Set to Ready</Title>,
      children: 'Are you sure you want to set this purchase order to ready?',
      labels: {
        confirm: 'Yes - Set to Ready',
        cancel: 'Cancel',
      },
      onConfirm: async () => {
        const response = await updatePurchaseOrderState.execute({
          purchaseOrderId: purchaseOrderID!,
          requestBody: {
            state: 'ready',
          },
        });
        await refreshPurchaseOrder();

        if (response.purchaseOrder.state === 'ready') {
          showNotification({
            color: 'green',
            title: 'Purchase Order Set to Ready',
            message:
              'The purchase order has been set to ready and is now ready for collection.',
          });
        } else if (response.purchaseOrder.state === 'waiting_approval') {
          showNotification({
            color: 'grape',
            title: 'Approval Required',
            message:
              'This purchase order must be approved before it can be collected.',
          });
        }
      },
    });
  };

  const showSetToDraftModal = ({
    purchaseOrderID,
  }: {
    purchaseOrderID: string;
  }) => {
    openConfirmModal({
      title: <Title order={3}>Set to Draft</Title>,
      children:
        'Are you sure you want to return this purchase order back to Draft?',
      labels: {
        confirm: 'Yes - Set to Draft',
        cancel: 'Cancel',
      },
      onConfirm: async () => {
        await updatePurchaseOrderState.execute({
          purchaseOrderId: purchaseOrderID!,
          requestBody: {
            state: 'draft',
          },
        });
        await refreshPurchaseOrder();
      },
    });
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button variant="subtle" rightIcon={<IconCaretDown size={14} />}>
          Actions
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Actions</Menu.Label>

        <Menu.Item
          icon={<IconNote size={14} />}
          disabled={purchaseOrder.state !== 'ready'}
          onClick={() =>
            showSetToDraftModal({ purchaseOrderID: purchaseOrderID! })
          }
        >
          Return to Draft
        </Menu.Item>

        <Menu.Item
          icon={<IconHourglassEmpty size={14} />}
          disabled={purchaseOrder.state !== 'draft'}
          onClick={() =>
            showSetToReadyModal({ purchaseOrderID: purchaseOrderID! })
          }
        >
          Set to Ready
        </Menu.Item>

        <Menu.Item
          icon={<IconTruckDelivery size={14} />}
          disabled={purchaseOrder.state !== 'ready'}
          onClick={() => {
            const collectionExpiryDateStr = purchaseOrder.collectionExpiryDate;
            const collectionExpiryDate = new Date(collectionExpiryDateStr || 0);

            if (!collectionExpiryDateStr) {
              showNotification({
                title: 'Collection Expiry Date Required',
                message:
                  'Please set a collection expiry date before collecting this purchase order.',
                color: 'red',
              });
            } else if (collectionExpiryDate < new Date()) {
              showNotification({
                title: 'Collection Expiry Date Expired',
                message:
                  'The collection expiry date for this purchase order has expired. Please set a new collection expiry date before collecting this purchase order.',
                color: 'red',
              });
            } else {
              const modalID = uuidv4();

              openModal({
                modalId: modalID,
                size: PX640.Number,
                title: <Title order={3}>Collect Purchase Order</Title>,
                closeOnEscape: false,
                closeOnClickOutside: false,
                children: (
                  <CollectPurchaseOrderModal
                    currency={currency}
                    purchaseOrderLines={purchaseOrderLines}
                    purchaseOrderID={purchaseOrderID}
                    closeModal={() => {
                      closeModal(modalID);
                      refreshPurchaseOrder();
                    }}
                  />
                ),
              });
            }
          }}
        >
          Set Completed
        </Menu.Item>

        <Menu.Item
          icon={<IconCheck size={14} />}
          disabled={purchaseOrder.state !== 'completed'}
          onClick={() => {
            openConfirmModal({
              title: <Title order={3}>Post Purchase Order</Title>,
              children: 'Are you sure you want to post this purchase order?',
              labels: {
                confirm: 'Yes - Post Purchase Order',
                cancel: 'Cancel',
              },
              onConfirm: async () => {
                await updatePurchaseOrderState.execute({
                  purchaseOrderId: purchaseOrderID!,
                  requestBody: {
                    state: 'posted',
                  },
                });
                refreshPurchaseOrder();
              },
            });
          }}
        >
          Set Posted
        </Menu.Item>

        <Menu.Item
          color="red"
          icon={<IconTrash size={14} />}
          disabled={
            purchaseOrder.state !== 'ready' && purchaseOrder.state !== 'draft'
          }
          onClick={() => {
            openConfirmModal({
              title: <Title order={3}>Cancel Purchase Order</Title>,
              children: 'Are you sure you want to cancel this purchase order?',
              labels: {
                confirm: 'Yes - Cancel Purchase Order',
                cancel: 'Cancel',
              },
              confirmProps: { color: 'red' },
              onConfirm: async () => {
                await updatePurchaseOrderState.execute({
                  purchaseOrderId: purchaseOrderID!,
                  requestBody: {
                    state: 'cancelled',
                  },
                });
                refreshPurchaseOrder();
              },
            });
          }}
        >
          Set Cancelled
        </Menu.Item>

        <Menu.Divider />
        <Menu.Label>Print</Menu.Label>

        <Menu.Item
          icon={<IconPrinter size={14} />}
          disabled={!purchaseOrderID}
          onClick={async () => {
            const response = await getPurchaseOrderPDF.execute({
              purchaseOrderId: purchaseOrderID!,
            });

            const file = b64toBlob(response.pdfBase64, 'application/pdf');
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }}
        >
          Print
        </Menu.Item>

        <Menu.Divider />

        <Menu.Label>Refresh</Menu.Label>
        <Menu.Item icon={<IconRefresh />} onClick={refreshPurchaseOrder}>
          Refresh
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const CollectPurchaseOrderModal = ({
  purchaseOrderID,
  purchaseOrderLines,
  currency,
  closeModal,
}: {
  purchaseOrderLines: {
    purchaseOrderLine: PurchaseOrderLine;
    product: Product;
  }[];
  purchaseOrderID: string;
  currency: Currency;
  closeModal: () => void;
}) => {
  const updatePurchaseOrderState = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderState
  );
  const [collectedQuantityValues, setCollectedQuantityValues] = useState<{
    [key: string]: number | undefined;
  }>({});

  const [invalidQuantityCollected, setInvalidQuantityCollected] =
    useState(false);

  const handleInputChange = (plID: string, collectedQuantity?: number | '') => {
    setCollectedQuantityValues({
      ...collectedQuantityValues,
      [plID]: collectedQuantity === '' ? undefined : Number(collectedQuantity),
    });
  };

  useEffect(() => {
    let invalidQtyCollected = false;
    for (const plID in collectedQuantityValues) {
      if (!invalidQtyCollected) {
        const collectedQuantity = collectedQuantityValues[plID];

        if (collectedQuantity !== undefined) {
          const poLine = purchaseOrderLines.find(
            (v) => plID === v.purchaseOrderLine.id
          );
          if (poLine) {
            if (Number(poLine.purchaseOrderLine.quantity) < collectedQuantity) {
              invalidQtyCollected = true;
            }
          }
        } else {
          invalidQtyCollected = true;
        }
      }
    }

    setInvalidQuantityCollected(invalidQtyCollected);
  }, [collectedQuantityValues]);

  return (
    <Stack>
      <Table verticalSpacing="lg">
        <thead>
          <tr>
            <th>Product</th>
            <th>Unit Price</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrderLines.map((l) => {
            const pl = l.purchaseOrderLine;
            const quantityNumber = Number(pl.quantity);
            const collectedQuantity = collectedQuantityValues[pl.id];

            return (
              <tr>
                <td>{pl.productName}</td>
                <td>{formatBig(new Big(pl.unitPrice), currency)}</td>
                <td>
                  <NumberInput
                    error={
                      collectedQuantity && collectedQuantity > quantityNumber
                    }
                    defaultValue={Number(pl.quantity)}
                    onChange={(v) => {
                      handleInputChange(pl.id, v);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Alert
        hidden={!invalidQuantityCollected}
        title="Invalid Quantity Collected"
        color="red"
        variant="filled"
      >
        Quantity collected cannot be more than the quantity set in the purchase
        order. Make sure every row has a quantity collected.
      </Alert>

      <Button
        disabled={invalidQuantityCollected}
        loading={updatePurchaseOrderState.loading}
        onClick={() => {
          openConfirmModal({
            title: <Title order={3}>Collect Purchase Order</Title>,
            children: 'Are you sure you want to collect this purchase order?',
            labels: {
              confirm: 'Yes - Collect Purchase Order',
              cancel: 'Cancel',
            },
            confirmProps: {
              loading: updatePurchaseOrderState.loading,
            },
            onConfirm: async () => {
              const purchaseOrderLineToCollectedQuantity: {
                [key: string]: number;
              } = {};

              for (const k in collectedQuantityValues) {
                const v = collectedQuantityValues[k];
                if (v !== undefined) {
                  purchaseOrderLineToCollectedQuantity[k] = v;
                }
              }

              await updatePurchaseOrderState.execute({
                purchaseOrderId: purchaseOrderID,
                requestBody: {
                  purchaseOrderLineToCollectedQuantity,
                  state: 'completed',
                },
              });

              closeModal();
            },
          });
        }}
      >
        Collect Purchase Order
      </Button>
    </Stack>
  );
};
