import {
  Currency,
  DefaultOperations,
  Product,
  ProfitabilityRange,
  PurchaseOrder,
  PurchaseOrderLine,
  StockBalance,
  StockLocation,
  Store,
  Vendor,
  VendorContact,
} from '../../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import { v4 as uuidv4 } from 'uuid';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Flex,
  Group,
  Modal,
  Popover,
  SegmentedControl,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  PX12,
  PX192,
  PX24,
  PX256,
  PX4,
  PX48,
  PX512,
  PX64,
  PX768,
} from '../../../../components/Px';
import Big from 'big.js';
import {
  findCurrency,
  formatBig,
  formatToCurrency,
} from '@pcc/api/models/Currency';
import { toBig, toBigOrNull } from '../../../../utils/toBigOrNull';
import { Link } from '@components/V2/Link';
import { ProfitabilityRangeBadge } from '@components/V2/ProfitabilityRangeBadge';
import { DaysToSellBadge } from '@components/V2/EstimatedDaysToSellRangeBadge';
import React, { ReactNode, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { LabeledText } from '@components/V2/LabeledText';
import { ProductPurchasingAndSalesChart } from '../../product_pages/components/ProductPurchasingAndSalesChart';
import { useDisclosure } from '@mantine/hooks';
import { Amounts, calculateAmounts } from '../../../../utils/calculateAmounts';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { ProductSelector as ProductSelector2 } from '@components/V2/modal_selector/ProductSelector';
import { VStack } from '../../../../components/stack/VStack';
import { ModalSelectorInput } from '@components/V2/modal_selector/ModalSelectorInput';
import { RFC3339ToEasyDate } from '../../../../utils/dateFormatting';
import { FormSubmit } from '../../../../components/formsubmit/FormSubmit';
import { IconChartBar, IconPencil, IconTrash } from '@tabler/icons-react';
import { useFindPrivileges } from '../../../../pccstores/UserUtils';

type CompletePurchaseOrderLine = {
  purchaseOrderLine: PurchaseOrderLine;
  product: Product;
  currentProfitabilityRange?: ProfitabilityRange;
  productSaleStatistics?: {
    monthlyPCsSold: {
      monthDate: string;
      pcsSold: string;
    }[];
  };
  estimatedDaysToSell?: string;
  stockBalances?: {
    stockLocationStockBalance?: StockBalance;
    storeStockBalances: {
      stockBalance: StockBalance;
      stockLocation: StockLocation;
    }[];
  };
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const PurchaseOrderLinesTable = ({
  purchaseOrder,
  store,
  currency,
  editable,
  lines,
  reloadPurchaseOrder,
  vendor,
  vendorContact,
  showStockBalance,
}: {
  purchaseOrder: PurchaseOrder;
  vendor?: Vendor;
  vendorContact?: VendorContact;
  store?: Store;
  currency: Currency;
  editable: boolean;
  lines: CompletePurchaseOrderLine[];
  reloadPurchaseOrder: () => void;
  showStockBalance?: boolean;
}) => {
  const [hideZeroQuantity, setHideZeroQuantity] = useState(true);
  const [tableViewMode, setTableViewMode] =
    useState<'order_details' | 'stock_details'>('order_details');
  const deletePurchaseOrderLine = useStatefulAPIRequestMaker(
    DefaultOperations.deletePurchaseOrderLine
  );

  const canViewProfitability = useFindPrivileges({
    resource: 'profitability_analytics',
    action: 'read',
  });

  const showAddPurchaseOrderLineEditorModal = () => {
    const modalID = uuidv4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Add Purchase Order Line</Title>,
      size: PX512.Number,
      children: (
        <PurchaseOrderLineEditorModal
          purchaseOrder={purchaseOrder}
          vendor={vendor}
          vendorContact={vendorContact}
          currency={currency}
          modalID={modalID}
        />
      ),
      onClose: () => {
        reloadPurchaseOrder();
      },
    });
  };

  const showConfirmDeleteLine = (lineID: string) => {
    openConfirmModal({
      title: <Title order={3}>Remove Line</Title>,
      children: 'Are you sure you want to delete this line item?',
      labels: {
        confirm: 'Yes - Remove Line',
        cancel: 'Cancel',
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        await deletePurchaseOrderLine.execute({
          purchaseOrderLineId: lineID,
        });
        reloadPurchaseOrder();
      },
    });
  };

  const showEditPurchaseOrderLineEditorModal = ({
    purchaseOrderLine,
    product,
  }: {
    purchaseOrderLine: PurchaseOrderLine;
    product: Product;
  }) => {
    if (purchaseOrder.state === 'draft') {
      const modalID = uuidv4();

      openModal({
        modalId: modalID,
        title: <Title order={3}>Update Purchase Order Line</Title>,
        size: PX512.Number,
        children: (
          <PurchaseOrderLineEditorModal
            currency={currency}
            modalID={modalID}
            purchaseOrder={purchaseOrder}
            product={product}
            purchaseOrderLine={purchaseOrderLine}
          />
        ),
        onClose: () => {
          reloadPurchaseOrder();
        },
      });
    }
  };

  const showMonthlySalesChartModal = ({
    purchaseOrderLine,
    product,
    estimatedDaysToSell,
  }: {
    purchaseOrderLine: PurchaseOrderLine;
    product: Product;
    estimatedDaysToSell?: Big | null;
  }) => {
    openModal({
      modalId: 'monthly-sales-chart-modal',
      title: <Title order={3}>Monthly Sales</Title>,
      size: PX768.Number,
      children: (
        <ProductMonthlySalesModal
          purchaseOrder={purchaseOrder}
          product={product}
          purchaseOrderLine={purchaseOrderLine}
          estimatedDaysToSell={estimatedDaysToSell}
          store={store}
        />
      ),
    });
  };

  const isOrderDetailViewMode = tableViewMode === 'order_details';
  const isStockDetailViewMode = tableViewMode === 'stock_details';

  const tableHeaders = [];
  tableHeaders.push(
    <th />,
    <th>Product</th>,
    <th style={{ textAlign: 'right' }}>Qty</th>
  );
  if (isOrderDetailViewMode) {
    tableHeaders.push(
      <th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>Unit Price</th>,
      <th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>Unit Tax</th>,
      <th style={{ textAlign: 'right' }}>Total</th>
    );

    if (canViewProfitability.hasPrivilege) {
      tableHeaders.push(<th style={{ textAlign: 'center' }}>Profitability</th>);
    }
  } else if (isStockDetailViewMode) {
    tableHeaders.push(
      <th style={{ textAlign: 'right' }}>Location Stock Balance</th>,
      <th style={{ textAlign: 'right' }}>Store Stock Balance</th>
    );
  }

  if (canViewProfitability.hasPrivilege) {
    tableHeaders.push(<th style={{ textAlign: 'center' }}>Days to Sell</th>);
  }

  return (
    <Stack spacing={PX24.PX}>
      {editable && (
        <Flex>
          <Button onClick={showAddPurchaseOrderLineEditorModal}>
            Add Line
          </Button>
        </Flex>
      )}

      <Group>
        <Tooltip
          label="Hide purchase order lines with 0 quantity. This will hide any items the vendor did not supply."
          withArrow
          position="left"
          multiline
          width={PX192.Number}
        >
          <Box>
            <Chip
              checked={hideZeroQuantity}
              onChange={() =>
                setHideZeroQuantity((prev) => {
                  return !prev;
                })
              }
              color="teal"
              variant="filled"
            >
              Hide 0 Quantity Lines
            </Chip>
          </Box>
        </Tooltip>
      </Group>

      <Group>
        {showStockBalance && (
          <SegmentedControl
            onChange={(v) => {
              setTableViewMode(v as any);
            }}
            data={[
              { label: 'Order Details', value: 'order_details' },
              { label: 'Stock Details', value: 'stock_details' },
            ]}
          />
        )}
      </Group>

      <Table withBorder striped>
        <thead>
          <tr>
            {tableHeaders}
            <th />
          </tr>
        </thead>
        <tbody>
          {lines
            .filter(
              (l) => !hideZeroQuantity || l.purchaseOrderLine.quantity !== '0'
            )
            .map((poLine) => {
              const l = poLine.purchaseOrderLine;
              const c = findCurrency(l.currency);
              const estimatedDaysToSell = toBigOrNull(
                poLine.estimatedDaysToSell
              );

              let storeStockBalanceTotal = new Big(0);
              const stockBalanceTableRows: ReactNode[] = [];
              (poLine.stockBalances?.storeStockBalances ?? []).forEach((sb) => {
                storeStockBalanceTotal = storeStockBalanceTotal.add(
                  sb.stockBalance.balance
                );

                stockBalanceTableRows.push(
                  <tr key={sb.stockLocation.id}>
                    <td>{sb.stockLocation.name}</td>
                    <td style={{ textAlign: 'right' }}>
                      {sb.stockBalance.balance} Pcs
                    </td>
                  </tr>
                );
              });

              const popoverContent =
                stockBalanceTableRows.length > 0 ? (
                  <Table striped withBorder withColumnBorders>
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th style={{ textAlign: 'right' }}>Balance</th>
                      </tr>
                    </thead>
                    <tbody>{stockBalanceTableRows}</tbody>
                  </Table>
                ) : (
                  <Text>There is not stock for this product.</Text>
                );

              const isFoc = toBig(l.unitPrice, 0).eq(0);

              return (
                <tr key={l.id}>
                  <td style={{ textAlign: 'center' }}>
                    {isFoc && (
                      <Badge color="green" variant="filled" size="xs">
                        FOC
                      </Badge>
                    )}
                  </td>
                  <td>
                    <Stack spacing={PX4.PX}>
                      <Group>
                        <Link
                          to={`/products/${poLine.product.id}`}
                          hidden
                          inline
                        >
                          {l.productName}
                        </Link>
                      </Group>
                      <Group spacing={PX4.PX}>
                        {poLine.product.barcode ? (
                          <Text c="dimmed" size="xs" maw={PX192.PX} truncate>
                            {poLine.product.barcode}
                          </Text>
                        ) : (
                          <Text c="red" size="xs">
                            Barcode Missing
                          </Text>
                        )}
                        <Text c="dimmed" size="xs">
                          ⦁
                        </Text>
                        {poLine.product.reference ? (
                          <Text c="dimmed" size="xs" maw={PX192.PX} truncate>
                            {poLine.product.reference}
                          </Text>
                        ) : (
                          <Text c="red" size="xs">
                            Reference Missing
                          </Text>
                        )}
                      </Group>
                    </Stack>
                  </td>
                  <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {l.quantity} (x{l.countPerQuantity})
                  </td>
                  {isOrderDetailViewMode && (
                    <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                      {formatBig(Big(l.unitPrice), c)}
                    </td>
                  )}
                  {isOrderDetailViewMode && (
                    <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                      {formatBig(Big(l.unitTax), c)}
                    </td>
                  )}
                  {isOrderDetailViewMode && (
                    <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                      {formatBig(Big(l.total), c)}
                    </td>
                  )}
                  {isStockDetailViewMode && (
                    <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                      {poLine.stockBalances?.stockLocationStockBalance
                        ?.balance ?? 0}{' '}
                      Pcs
                    </td>
                  )}
                  {isStockDetailViewMode && (
                    <td>
                      <Group position="right">
                        {storeStockBalanceTotal.toString()} Pcs
                        <Popover
                          width={PX256.PX}
                          position="top"
                          withArrow
                          shadow="md"
                        >
                          <Popover.Target>
                            <Button size="xs" compact variant="light">
                              View
                            </Button>
                          </Popover.Target>

                          <Popover.Dropdown>
                            <Stack>{popoverContent}</Stack>
                          </Popover.Dropdown>
                        </Popover>
                      </Group>
                    </td>
                  )}
                  {canViewProfitability.hasPrivilege && isOrderDetailViewMode && (
                    <td>
                      <ProfitabilityRangeBadge
                        profitabilityRange={poLine.currentProfitabilityRange}
                      />
                    </td>
                  )}
                  {canViewProfitability.hasPrivilege && (
                    <td>
                      <DaysToSellBadge
                        onClick={() =>
                          showMonthlySalesChartModal({
                            purchaseOrderLine: l,
                            product: poLine.product,
                            estimatedDaysToSell: estimatedDaysToSell,
                          })
                        }
                        estimatedDaysToSellRange={{
                          minDaysToSell: poLine.estimatedDaysToSell,
                          maxDaysToSell: poLine.estimatedDaysToSell,
                        }}
                      />
                    </td>
                  )}
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <Group spacing={PX4.Number} noWrap>
                      {canViewProfitability.hasPrivilege && (
                        <ActionIcon
                          variant="outline"
                          color="orange"
                          onClick={() =>
                            showMonthlySalesChartModal({
                              purchaseOrderLine: l,
                              product: poLine.product,
                              estimatedDaysToSell: estimatedDaysToSell,
                            })
                          }
                        >
                          <IconChartBar size={18} />
                        </ActionIcon>
                      )}

                      {purchaseOrder.state === 'draft' && (
                        <ActionIcon
                          variant="outline"
                          color="orange"
                          onClick={() =>
                            showEditPurchaseOrderLineEditorModal({
                              purchaseOrderLine: l,
                              product: poLine.product,
                            })
                          }
                        >
                          <IconPencil size={18} />
                        </ActionIcon>
                      )}
                      {purchaseOrder.state === 'draft' && (
                        <ActionIcon
                          variant="outline"
                          color="red"
                          onClick={() => showConfirmDeleteLine(l.id)}
                        >
                          <IconTrash size={18} />
                        </ActionIcon>
                      )}
                    </Group>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Stack>
  );
};

const ProductMonthlySalesModal = ({
  product,
  purchaseOrderLine,
  estimatedDaysToSell,
  store,
  purchaseOrder,
}: {
  purchaseOrder: PurchaseOrder;
  product: Product;
  purchaseOrderLine: PurchaseOrderLine;
  estimatedDaysToSell?: Big | null;
  store?: Store;
}) => {
  let endDate = purchaseOrder.collectedAt
    ? DateTime.fromISO(purchaseOrder.collectedAt)
    : DateTime.now();
  endDate = endDate.plus({ month: -1 });
  let startDate = endDate;
  startDate = startDate.plus({ month: -14 });
  const endDateLastYear = endDate.plus({ year: -1 });

  const purchasedPCs = new Big(purchaseOrderLine.countPerQuantity).mul(
    purchaseOrderLine.quantity
  );

  const [storeFilterMode, setStoreFilterMode] = useState(
    store ? 'single_store_sale' : 'all_store_sale'
  );

  const segmentControlData = [];
  if (store) {
    segmentControlData.push({
      label: `Sales for ${store.name}`,
      value: 'single_store_sale',
    });
  }

  segmentControlData.push({ label: 'All Stores', value: 'all_store_sale' });

  return (
    <Stack>
      <Alert title="Product Sales" color="violet">
        The chart below shows the PCs sold for{' '}
        <Text fw={700} span>
          {product.name}
        </Text>{' '}
        {store && (
          <>
            from{' '}
            <Text fw={700} span>
              {store.name}
            </Text>
          </>
        )}{' '}
        1 year before the purchase order.
      </Alert>
      <Group align="flex-start" position="center" spacing={PX64.Number}>
        <LabeledText label="PCs Purchased" value={`${purchasedPCs}`} />
        {estimatedDaysToSell && (
          <LabeledText
            label="Days to Sell"
            value={
              <DaysToSellBadge
                estimatedDaysToSellRange={{
                  maxDaysToSell: estimatedDaysToSell,
                  minDaysToSell: estimatedDaysToSell,
                }}
              />
            }
          />
        )}
      </Group>
      <SegmentedControl
        data={segmentControlData}
        value={storeFilterMode}
        onChange={setStoreFilterMode}
      />
      <ProductPurchasingAndSalesChart
        storeID={
          storeFilterMode === 'single_store_sale' ? store?.id : undefined
        }
        productID={product.id}
        dateRange={{
          startMonth: startDate,
          endMonth: endDate,
        }}
        highlightMonths={[endDate, endDateLastYear]}
      />
    </Stack>
  );
};

const PurchaseOrderLineEditorModal = ({
  modalID,
  purchaseOrder,
  currency,
  purchaseOrderLine,
  vendor,
  vendorContact,
  product,
}: {
  modalID: string;
  purchaseOrder: PurchaseOrder;
  currency: Currency;
  purchaseOrderLine?: PurchaseOrderLine;
  product?: Product;
  vendor?: Vendor;
  vendorContact?: VendorContact;
}) => {
  const [productModalOpened, productModalHandler] = useDisclosure(false);
  const [amounts, setAmounts] = useState<Amounts | null>(null);
  const getProduct = useStatefulAPIRequestMaker(DefaultOperations.getProduct);
  const listPurchaseOrderLines = useStatefulAPIRequestMaker(
    DefaultOperations.listRecentPurchaseOrderLines
  );
  const insertPurchaseOrderLine = useStatefulAPIRequestMaker(
    DefaultOperations.createPurchaseOrderLine
  );
  const updatePurchaseOrderLine = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderLine
  );
  const listProductCosts = useStatefulAPIRequestMaker(
    DefaultOperations.listProductPurchasingCost
  );

  const busyWithAPI = insertPurchaseOrderLine.loading;
  const [productCostView, setProductCostView] =
    useState<'product_costs' | 'recent_purchases'>('product_costs');

  const form = useForm<{
    product?: Product | null;
    countPerQuantity?: string;
    quantity?: string;
    unitPrice?: string;
    taxIncluded?: boolean;
  }>({
    validate: {
      product: (v: any) => (v ? null : 'Product required'),
      countPerQuantity: (v: any) => (v ? null : 'Count per Quantity required'),
      quantity: (v: any) => (v ? null : 'Quantity required'),
      unitPrice: (v: any) => (v ? null : 'Unit Price required'),
    },
  });

  useEffect(() => {
    if (form.values.product) {
      listPurchaseOrderLines.execute({
        productId: form.values.product.id,
        currency: purchaseOrder.currency,
      });
      getProduct.execute({ productId: form.values.product.id });
    } else {
      listPurchaseOrderLines.clearResponse();
      getProduct.clearResponse();
    }
  }, [form.values.product]);

  useEffect(() => {
    if (getProduct.response && form.values.quantity && form.values.unitPrice) {
      try {
        const newAmounts = calculateAmounts({
          currency,
          quantity: new Big(form.values.quantity),
          taxIncludedInPrice: form.values.taxIncluded!,
          taxes: getProduct.response.purchasingTaxes,
          unitPrice: new Big(form.values.unitPrice),
        });

        setAmounts(newAmounts);
      } catch (e) {
        setAmounts(null);
      }
    } else if (amounts !== null) {
      setAmounts(null);
    }
  }, [form.values.quantity, form.values.unitPrice, getProduct.response]);

  useEffect(() => {
    listProductCosts.clearResponse();

    if (form.values.product) {
      const p = listProductCosts.execute({
        productId: form.values.product.id,
        currency: purchaseOrder.currency,
      });

      return () => p.cancel();
    }
  }, [form.values.product?.id]);

  useEffect(() => {
    if (purchaseOrderLine) {
      const taxIncluded = purchaseOrderLine?.taxIncluded;

      const unitPrice = taxIncluded
        ? new Big(purchaseOrderLine?.unitPrice).add(
            new Big(purchaseOrderLine?.unitTax)
          )
        : purchaseOrderLine?.unitPrice;

      form.setValues({
        countPerQuantity: purchaseOrderLine?.countPerQuantity,
        quantity: purchaseOrderLine?.quantity,
        unitPrice: `${unitPrice}`,
        taxIncluded,
      });
    }
  }, [purchaseOrderLine]);

  useEffect(() => {
    if (product) {
      form.setValues({
        product,
      });
    }
  }, [product]);

  const onSubmit = form.onSubmit(async (values) => {
    if (!purchaseOrderLine) {
      await insertPurchaseOrderLine.execute({
        requestBody: {
          purchaseOrderID: purchaseOrder.id,
          countPerQuantity: values.countPerQuantity!,
          productID: values.product!.id,
          quantity: values.quantity!,
          taxIncluded: values.taxIncluded!,
          unitPrice: values.unitPrice!,
        },
      });

      showNotification({
        color: 'green',
        title: 'Purchase Order Line Added',
        message: 'The purchase order line has successfully been added.',
      });
    } else {
      await updatePurchaseOrderLine.execute({
        purchaseOrderLineId: purchaseOrderLine.id,
        requestBody: {
          countPerQuantity: values.countPerQuantity!,
          productID: values.product!.id,
          quantity: values.quantity!,
          taxIncluded: values.taxIncluded || false,
          unitPrice: values.unitPrice!,
        },
      });
      showNotification({
        color: 'green',
        title: 'Purchase Order Line Updated',
        message: 'The purchase order line has been updated.',
      });
    }

    closeModal(modalID);
  });

  const quickSetCost = ({
    taxIncluded,
    unitPrice,
    caseOf,
  }: {
    taxIncluded: boolean;
    unitPrice: string;
    caseOf: string;
  }) => {
    form.setValues({
      countPerQuantity: caseOf,
      unitPrice,
      taxIncluded,
    });
  };

  const productCostsArr =
    listProductCosts.response?.productPurchasingCosts ?? [];

  return (
    <Stack spacing={PX48.Number}>
      <Modal
        onClose={productModalHandler.close}
        opened={productModalOpened}
        zIndex={1000}
        size={PX768.Number}
        title={<Title order={3}>Select Product</Title>}
      >
        <ProductSelector2
          onSelected={(p) => {
            form.setValues({
              product: p.product,
            });
            productModalHandler.close();
          }}
          vendor={vendor}
          vendorContact={vendorContact}
        />
      </Modal>
      <form onSubmit={onSubmit}>
        <VStack spacing={PX12.PX}>
          <ModalSelectorInput
            label="Product"
            placeholder="Select Product"
            onClick={productModalHandler.open}
            value={form.values.product?.name}
          />
          <Collapse in={!!listPurchaseOrderLines.response}>
            <Alert variant="outline" color="blue" title="Product Cost">
              <Stack>
                <Group>
                  <SegmentedControl
                    onChange={(v) => setProductCostView(v as any)}
                    value={productCostView}
                    data={[
                      { label: 'Product Costs', value: 'product_costs' },
                      { label: 'Recent Purchases', value: 'recent_purchases' },
                    ]}
                  />
                </Group>
                {productCostView === 'product_costs' && (
                  <Table withBorder striped>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'right' }}>Case of</th>
                        <th style={{ textAlign: 'right' }}>Unit Price</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {productCostsArr.map((l) => {
                        const onApply = () => {
                          quickSetCost({
                            taxIncluded: false,
                            unitPrice: l.productPurchasingCost.unitCost,
                            caseOf: l.productPurchasingCost.caseOf,
                          });
                        };

                        return (
                          <tr key={l.productPurchasingCost.id}>
                            <td style={{ textAlign: 'right' }}>
                              {l.productPurchasingCost.caseOf}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {formatToCurrency(
                                l.productPurchasingCost.unitCost,
                                currency
                              )}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <Button
                                variant="subtle"
                                compact
                                onClick={onApply}
                              >
                                Apply
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {productCostView === 'recent_purchases' && (
                  <Table withBorder striped>
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Date</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {listPurchaseOrderLines.response?.purchaseOrderLines.map(
                        (l) => {
                          const onApply = () => {
                            const {
                              taxIncluded,
                              unitPrice: lineUnitPrice,
                              unitTax: lineUnitTax,
                              countPerQuantity,
                            } = l.purchaseOrderLine;
                            const unitPrice = !taxIncluded
                              ? new Big(lineUnitPrice)
                              : new Big(lineUnitPrice).add(
                                  new Big(lineUnitTax)
                                );

                            quickSetCost({
                              taxIncluded,
                              unitPrice: unitPrice.toString(),
                              caseOf: countPerQuantity,
                            });
                          };

                          return (
                            <tr key={l.purchaseOrderLine.id}>
                              <td>
                                {l.purchaseOrderLine.quantity} (x
                                {l.purchaseOrderLine.countPerQuantity})
                              </td>

                              <td>
                                {formatToCurrency(
                                  l.purchaseOrderLine.unitPrice,
                                  currency
                                )}
                              </td>
                              <td>
                                <Link
                                  to={`/purchaseOrders/${l.purchaseOrderLine.purchaseOrderID}`}
                                  withIcon
                                >
                                  {RFC3339ToEasyDate(
                                    l.purchaseOrderLine.createdAt
                                  )}
                                </Link>
                              </td>
                              <td>
                                <Button
                                  variant="subtle"
                                  compact
                                  onClick={onApply}
                                >
                                  Apply
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                )}
              </Stack>
            </Alert>
          </Collapse>

          <TextInput
            autoComplete="off"
            label="Count per Quantity"
            {...form.getInputProps('countPerQuantity')}
          />

          <TextInput
            autoComplete="off"
            label="Quantity"
            {...form.getInputProps('quantity')}
          />
          <TextInput
            autoComplete="off"
            label="Unit Price"
            {...form.getInputProps('unitPrice')}
          />
          <Checkbox
            label="Tax Included"
            checked={form.values.taxIncluded}
            {...form.getInputProps('taxIncluded')}
          />
          <TextInput
            readOnly
            autoComplete="off"
            label="Unit Tax"
            value={amounts ? formatToCurrency(amounts?.unitTax, currency) : ''}
          />
          <TextInput
            readOnly
            autoComplete="off"
            label="Total"
            value={amounts ? formatToCurrency(amounts?.total, currency) : ''}
          />

          <FormSubmit
            value={purchaseOrderLine ? 'Update Line' : 'Add Line'}
            disabled={busyWithAPI || !form.isValid()}
          />
        </VStack>
      </form>
    </Stack>
  );
};
