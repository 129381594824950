import {
  Alert,
  Button,
  Collapse,
  Container,
  Flex,
  Group,
  Pagination,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { PX1024, PX32, PX64, PX8, PX96 } from '../../../components/Px';
import { useInputState } from '@mantine/hooks';
import { StockLocation } from '@pcc/api/models/StockLocation';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { StatusBadge } from '@components/V2/StatusBadge';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  useSerialEffect,
  useURLQueryParamSetter,
} from '../../../hooks/useURLSearchQueryParamSetter';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { closeModal, openModal } from '@mantine/modals';
import { StockLocationSelectorModalBody } from '@components/V2/modal_selector/StockLocationSelectorModalBody';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const ListStockMoves = ({}: { path?: string }) => {
  const location = useLocation();
  const currentURL = location.pathname;
  const currentUrlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [stockLocation, setStockLocation] =
    useInputState<StockLocation | null>(null);
  const listStockMoveGroups = useStatefulAPIRequestMaker(
    DefaultOperations.listStockMoveGroups
  );
  const getStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.getStockLocation
  );
  const [setQueryParams] = useURLQueryParamSetter();

  const { onChangeDeps, pagination, target, setTargetFromInput } =
    useListSearcherHelper({
      additionalDeps: [stockLocation],
      limitPerPage: 30,
      debounceDelay: 200,
    });

  useSerialEffect(
    [stockLocation],
    async () => {
      const urlStockLocationID = currentUrlParams.get('stockLocationID');
      if (urlStockLocationID) {
        const response = await getStockLocation.execute({
          stockLocationId: urlStockLocationID,
        });
        setStockLocation(response.stockLocation);
      }
    },
    () => {
      setQueryParams({
        stockLocationID: stockLocation ? stockLocation.id : '',
      });
    }
  );

  useEffect(() => {
    if (stockLocation) {
      listStockMoveGroups.execute({
        stockLocationId: stockLocation.id,
        limit: pagination.limit,
        offset: pagination.offset,
        target,
      });
    }
  }, [...onChangeDeps]);

  const showStockLocationSelectionModal = () => {
    openModal({
      modalId: 'stock-location-selector-modal',
      title: <Title order={3}>Select Stock Location</Title>,
      children: (
        <StockLocationSelectorModalBody
          onSelected={(v) => {
            closeModal('stock-location-selector-modal');
            setStockLocation(v.stockLocation);
          }}
        />
      ),
    });
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Stock Moves</Title>
        <Group>
          <Button
            color="green"
            onClick={() => {
              navigate(currentURL + 'new');
            }}
          >
            New Stock Move
          </Button>
        </Group>
        <Flex gap={PX8.Number}>
          <TextInput
            style={{ flex: 0.3 }}
            onClick={showStockLocationSelectionModal}
            readOnly
            required
            label="Stock Location"
            value={stockLocation?.name ?? ''}
          />
          <TextInput
            value={target}
            onChange={setTargetFromInput}
            disabled={!stockLocation}
            label="Search"
            placeholder="Search Reference"
            style={{ flex: 0.7 }}
          />
        </Flex>

        <Collapse in={!stockLocation}>
          <Alert
            variant="outline"
            title="Select Stock Location"
            color="blue"
            icon={<IconInfoCircle />}
          >
            Select a stock location to view its stock moves.
          </Alert>
        </Collapse>

        <LoadingContainer
          loading={listStockMoveGroups.loading || getStockLocation.loading}
        >
          <Stack>
            {listStockMoveGroups.response?.count && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(
                    listStockMoveGroups.response?.count
                  )}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
            <Table highlightOnHover>
              <thead>
                <tr>
                  <th>Reference </th>
                  <th>Source</th>
                  <th>Destination</th>
                  <th>Date</th>
                  <th>State</th>
                </tr>
              </thead>
              <tbody>
                {(listStockMoveGroups.response?.stockMoveGroups || []).map(
                  (i) => {
                    return (
                      <ClickableTableRow
                        key={i.stockMoveGroup.id}
                        targetURL={`/stockMoves/${i.stockMoveGroup.id}`}
                      >
                        <td>{i.stockMoveGroup.reference}</td>
                        <td>{i.sourceLocation.name}</td>
                        <td>{i.destinationLocation.name}</td>
                        <td>
                          {RFC3339ToEasyDateTime(i.stockMoveGroup.createdAt)}
                        </td>
                        <td>
                          <StatusBadge
                            state={i.stockMoveGroup.state}
                            fullWidth
                          />
                        </td>
                      </ClickableTableRow>
                    );
                  }
                )}
              </tbody>
            </Table>
            {listStockMoveGroups.response?.count && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(
                    listStockMoveGroups.response?.count
                  )}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
