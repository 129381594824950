import {
  DefaultOperations,
  PaymentMethod,
  PaymentMethodTargetPaymentType,
} from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import React, { useEffect } from 'react';
import { Stack, Table } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const PaymentMethodModalSelector = ({
  onPaymentMethodSelected,
  targetPaymentType,
}: {
  onPaymentMethodSelected: (v: PaymentMethod | null) => void;
  targetPaymentType: PaymentMethodTargetPaymentType;
}) => {
  const searchPaymentMethods = useStatefulAPIRequestMaker(
    DefaultOperations.getPaymentMethods
  );

  const { onChangeDeps } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
  });

  useEffect(() => {
    searchPaymentMethods.execute({ targetPaymentType, enabled: true });
  }, [...onChangeDeps]);

  return (
    <Stack>
      <LoadingContainer loading={searchPaymentMethods.loading}>
        <Table highlightOnHover withBorder>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <ClickableTableRow onClick={() => onPaymentMethodSelected(null)}>
              <td>Cash</td>
            </ClickableTableRow>
            {(searchPaymentMethods.response?.paymentMethods || []).map((pm) => {
              const onClick = async () => {
                onPaymentMethodSelected(pm.paymentMethod);
              };

              return (
                <ClickableTableRow onClick={onClick} key={pm.paymentMethod.id}>
                  <td>{pm.paymentMethod.name}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
