import { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { Tax } from '../../../models/Tax';
import {
  Button,
  Container,
  Flex,
  Input,
  Stack,
  Table,
  Title,
} from '@mantine/core';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { useDebouncedValue } from '@mantine/hooks';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const TaxViewer = ({}: { path?: string }) => {
  const navigate = useNavigate();
  const [target, setTarget] = useState('');
  const [debounceTarget] = useDebouncedValue(target, 200);
  const searchTaxes = useStatefulAPIRequestMaker(DefaultOperations.searchTaxes);

  useEffect(() => {
    searchTaxes.execute({ target: debounceTarget });
  }, [debounceTarget]);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Taxes</Title>
        <Flex>
          <Button color="green" onClick={() => navigate('/taxes/new')}>
            New Tax
          </Button>
        </Flex>
        <Input
          placeholder="Search"
          onChange={(e: any) => setTarget(e.target.value)}
          value={target}
        />
        <TaxesTable
          taxes={searchTaxes.response || []}
          loading={searchTaxes.loading}
        />
      </Stack>
    </Container>
  );
};

const TaxesTable = ({ taxes, loading }: { taxes: Tax[]; loading: boolean }) => {
  const navigate = useNavigate();

  return (
    <LoadingContainer loading={loading} minHeight={PX384.Number}>
      <Table highlightOnHover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Percent</th>
          </tr>
        </thead>

        <tbody>
          {taxes.map((t) => {
            const onClick = () => {
              navigate(`/taxes/${t.id}`);
            };

            return (
              <tr onClick={onClick} key={t.id}>
                <td>{t.name}</td>
                <td>{t.percent}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </LoadingContainer>
  );
};
