import React, { useEffect, useState } from 'react';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import {
  RFC3339ToEasyDate,
  RFC3339ToPrettyMonthDontModifyTimezone,
} from '../../../utils/dateFormatting';
import { Center, Group, Loader, SegmentedControl, Stack } from '@mantine/core';
import { PX384 } from '../../../components/Px';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const StockBalanceHistory = ({
  stockLocationID,
  currency,
  productID,
}: {
  stockLocationID?: string;
  currency: string;
  productID: string;
}) => {
  type ViewMode = 'daily' | 'weekly' | 'monthly';
  const [viewMode, setViewMode] = useState<ViewMode>('daily');
  const getStockBalanceHistory = useStatefulAPIRequestMaker(
    DefaultOperations.getStockBalanceHistory
  );

  useEffect(() => {
    getStockBalanceHistory.execute({
      currency,
      stockLocationId: stockLocationID,
      productId: productID,
    });
  }, [currency, stockLocationID, productID]);

  const chartData = React.useMemo(() => {
    if (getStockBalanceHistory.response) {
      if (viewMode === 'daily') {
        return getStockBalanceHistory.response.dailyStockBalance.map((sb) => {
          return {
            date: RFC3339ToEasyDate(sb.date),
            'Ending Balance': Number(sb.endingBalance),
          };
        });
      } else if (viewMode === 'weekly') {
        return getStockBalanceHistory.response.weeklyStockBalance.map((sb) => {
          return {
            date: RFC3339ToEasyDate(sb.date),
            'Ending Balance': Number(sb.endingBalance),
          };
        });
      } else if (viewMode === 'monthly') {
        return getStockBalanceHistory.response.monthlyStockBalance.map((sb) => {
          return {
            date: RFC3339ToPrettyMonthDontModifyTimezone(sb.date),
            'Ending Balance': Number(sb.endingBalance),
          };
        });
      }
    } else {
      return [];
    }
  }, [getStockBalanceHistory.responseID, viewMode]);

  return (
    <Stack>
      {getStockBalanceHistory.loading && (
        <Center h={PX384.Number}>
          <Loader />
        </Center>
      )}
      {!getStockBalanceHistory.loading && getStockBalanceHistory.response && (
        <Stack>
          <Group>
            <SegmentedControl
              data={[
                { value: 'daily', label: 'Last 30 Days' },
                { value: 'weekly', label: 'Last 12 Weeks' },
                { value: 'monthly', label: 'Last 12 Months' },
              ]}
              value={viewMode}
              onChange={(v) => {
                setViewMode(v as ViewMode);
              }}
            />
          </Group>
          <div style={{ position: 'relative', height: PX384.Number }}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis domain={['auto', 'auto']} />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  strokeWidth={2}
                  animationDuration={200}
                  dataKey="Ending Balance"
                  stroke="#845EF7"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Stack>
      )}
    </Stack>
  );
};
