import { Location, Router } from '@reach/router';
import React from 'react';
import { MemberHomePage } from './MemberHomePage';

export const MemberPageRouter = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <MemberHomePage path="/" />
        </Router>
      )}
    </Location>
  );
};
