import React from 'react';
import { Group, Paper, RingProgress, Text, Tooltip } from '@mantine/core';

export const RingProgressCard = ({
  label,
  amount,
  progress,
  tooltip,
}: {
  label: string;
  amount: string;
  progress?: number;
  tooltip?: string;
}) => {
  return (
    <Tooltip label={tooltip} multiline withArrow disabled={!tooltip}>
      <Paper withBorder radius="md" p="xs" key={label}>
        <Group>
          {progress && (
            <RingProgress
              size={80}
              roundCaps
              thickness={8}
              sections={[{ value: progress, color: 'orange' }]}
            />
          )}

          <div>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              {label}
            </Text>
            <Text weight={700} size="xl">
              {amount}
            </Text>
          </div>
        </Group>
      </Paper>
    </Tooltip>
  );
};
