import { Location, Router } from '@reach/router';
import React from 'react';
import { ListStockMoves } from './ListStockMoves';
import { StockMoveViewer } from './StockMoveViewer';

export const StockMovePagesRouter = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListStockMoves path="/" />
          <StockMoveViewer path="/:id" />
          <StockMoveViewer path="/new" />
        </Router>
      )}
    </Location>
  );
};
