import { PaymentMethod } from '../oas/codegen3';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { Stack, TextInput } from '@mantine/core';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const PaymentMethodMetadataManager = ({
  metadata,
  paymentMethod,
  onUpdated,
  disabled,
  readonly,
}: {
  readonly?: boolean;
  paymentMethod: PaymentMethod | null;
  onUpdated: (values: {
    metadata: { [key: string]: string };
    valid: boolean;
  }) => void;
  metadata: { [key: string]: string };
  disabled?: boolean;
}) => {
  const form = useForm<{
    paymentMethodMetadata: { [key: string]: string };
  }>({
    initialValues: {
      paymentMethodMetadata: {},
    },
    validate: {
      paymentMethodMetadata: (value: any) => {
        if (!paymentMethod) {
          return null;
        }

        paymentMethod.requiredMetadataFields?.forEach((fieldName) => {
          if (!value[fieldName] || value[fieldName] === '') {
            return 'Required.';
          }
        });
      },
    },
  });

  useEffect(() => {
    let valid = true;

    const metadata: { [key: string]: string } = {};
    if (paymentMethod && valid) {
      paymentMethod.requiredMetadataFields?.forEach((fieldName) => {
        if (
          !form.values.paymentMethodMetadata[fieldName] ||
          form.values.paymentMethodMetadata[fieldName] === ''
        ) {
          valid = false;
        }

        metadata[fieldName] = form.values.paymentMethodMetadata[fieldName];
      });
    }

    onUpdated({
      metadata,
      valid: valid,
    });
  }, [form.values, paymentMethod]);

  useEffect(() => {
    form.setValues({
      paymentMethodMetadata: metadata,
    });
  }, [paymentMethod]);

  return (
    <Stack>
      {paymentMethod &&
        paymentMethod.requiredMetadataFields?.map((fieldName) => {
          return (
            <TextInput
              key={paymentMethod.id}
              label={fieldName}
              required={true}
              readOnly={readonly}
              disabled={disabled}
              value={form.values.paymentMethodMetadata[fieldName] ?? ''}
              onChange={(e) => {
                form.setValues({
                  paymentMethodMetadata: {
                    ...form.values.paymentMethodMetadata,
                    [fieldName]: e.target.value,
                  },
                });
              }}
              error={form.errors['paymentMethodMetadata']}
            />
          );
        })}
    </Stack>
  );
};
