import { useNavigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { useURLSearchQueryParamSetter } from '../../../hooks/useURLSearchQueryParamSetter';
import {
  Button,
  Container,
  Flex,
  Group,
  Stack,
  Switch,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { PX1024, PX32, PX384, PX64, PX8, PX96 } from '../../../components/Px';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { LoadingContainer } from '@components/V2/LoadingContainer';

export interface UsersHomePageProps {
  path?: string;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const UsersListPage = ({}: UsersHomePageProps) => {
  const navigate = useNavigate();
  const currentUrlParams = new URLSearchParams(window.location.search);
  const search = currentUrlParams.get('search');
  const [target, setTarget] = useState(search || '');
  const searchUsers = useStatefulAPIRequestMaker(DefaultOperations.listUsers);
  const [showDisabledUsers, setShowDisabledUsers] = useState(false);

  useURLSearchQueryParamSetter({ search: target }, [target]);

  useEffect(() => {
    const t = setTimeout(() => {
      searchUsers.execute({ name: target, userEnabled: !showDisabledUsers });
    }, 200);

    return () => {
      clearTimeout(t);
    };
  }, [target, showDisabledUsers]);

  const onShowDisabledUserCheckChange = () => {
    setShowDisabledUsers(!showDisabledUsers);
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Users</Title>
        <Flex>
          <Button color="green" onClick={() => navigate('/users/new')}>
            New User
          </Button>
        </Flex>
        <Flex gap={PX8.Number} align="end">
          <TextInput
            style={{
              flex: 1,
            }}
            label="Search"
            placeholder="Search for a User"
            onChange={(e: any) => setTarget(e.target.value)}
            value={target}
          />
        </Flex>
        <Group>
          <Switch
            label="Show Disabled Users"
            checked={showDisabledUsers}
            onChange={onShowDisabledUserCheckChange}
          />
        </Group>
        <LoadingContainer
          loading={searchUsers.loading}
          minHeight={PX384.Number}
        >
          <UsersTable users={searchUsers.response?.users || []} />
        </LoadingContainer>
      </Stack>
    </Container>
  );
};

interface UsersTableProps {
  users: {
    firstName: string;
    lastName: string;
    id: string;
  }[];
}

const UsersTable = ({ users }: UsersTableProps) => {
  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {users.map((u) => {
          const onClick = () => {
            window.open(`/users/${u.id}`, '_blank');
          };

          return (
            <tr onClick={onClick} key={u.id}>
              <td>{u.firstName + ' ' + u.lastName}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
