import React from 'react';
import { Router, Location, useNavigate } from '@reach/router';
import { LoginPage } from './pages/LoginPage';
import { AdminPanelRouter } from './pages/adminpanel';
import { useAxiosAuthRefresher } from './hooks/useAxiosAuthRefresher';
import {
  useAuthStateNavigator,
  useAutoRefreshUserOnAuthChange,
} from './hooks/useNavigateOnAuthState';
import { usePccStoreSelector } from './pccstores/PccStore';
import { Center, Loader, Stack } from '@mantine/core';

function App() {
  const navigate = useNavigate();
  useAxiosAuthRefresher();
  useAuthStateNavigator(navigate);
  useAutoRefreshUserOnAuthChange();

  const authenticated = usePccStoreSelector(
    (state) => state.userStates.authentication.authenticated
  );

  if (authenticated === null) {
    return <CenterPageLoader />;
  }

  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <LoginPage path="/login" />
          <AdminPanelRouter default />
        </Router>
      )}
    </Location>
  );
}

export default App;

const CenterPageLoader = () => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'fixed' }}>
      <Center h="100%">
        <Stack align="center">
          <Loader />
          Loading your account...
        </Stack>
      </Center>
    </div>
  );
};
