import React, { useEffect, useState } from 'react';
import {
  BHDCurrency,
  formatBig,
  formatToCurrency,
} from '../../../models/Currency';
import { useNavigate } from '@reach/router';
import {
  PX1024,
  PX12,
  PX32,
  PX64,
  PX640,
  PX8,
  PX96,
} from '../../../components/Px';
import Big from 'big.js';

import {
  Button,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  Paper,
  RingProgress,
  Select,
  Stack,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
  Checkbox,
  Alert,
  Card,
  Loader,
  Box,
  ThemeIcon,
} from '@mantine/core';
import {
  IconCalendar,
  IconCash,
  IconCheck,
  IconCircleMinus,
  IconAdjustmentsDollar,
  IconInfoCircle,
  IconList,
  IconPencil,
  IconPrinter,
  IconRefresh,
  IconTruckDelivery,
  IconPlus,
  IconShieldCheckFilled,
  IconHistory,
  IconForms,
  IconCurrencyDollar,
} from '@tabler/icons-react';

import { useForm } from '@mantine/form';
import { closeModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { StatusBadge } from '@components/V2/StatusBadge';
import {
  RFC3339ToEasyDate,
  RFC3339ToEasyDateTime,
} from '../../../utils/dateFormatting';
import { Link } from '@components/V2/Link';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  Currency,
  DefaultOperations,
  ProfitabilityRange,
  PurchaseOrder,
  PurchaseOrderCredit,
  PurchaseOrderPaymentTerm,
  StockLocation,
  Store,
  User,
  UserWithNameOnly,
  Vendor,
  VendorContact,
} from '../../../oas/codegen3';
import { DateInput } from '@mantine/dates';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { b64toBlob } from '../../../utils/b64toBlob';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { VendorSelectorModalBody } from '@components/V2/modal_selector/VendorSelectorModalBody';
import { VendorContactSelectorModalBody } from '@components/V2/modal_selector/VendorContactSelectorModalBody';
import { StoreSelectorModalBody } from '@components/V2/modal_selector/StoreSelectorModalBody';
import { StockLocationSelectorModalBody } from '@components/V2/modal_selector/StockLocationSelectorModalBody';
import { ModalSelector } from '@components/V2/modal_selector/ModalSelectorBody';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { ChangelogsTimelines } from '@components/V2/ChangelogsTimelines';
import { ModalSelectorInput } from '@components/V2/modal_selector/ModalSelectorInput';
import { PurchaseOrderLinesTable } from './components/PurchaseOrderLinesTable';
import { useFindPrivileges } from '../../../pccstores/UserUtils';
import { ProfitabilityRangeBadge } from '@components/V2/ProfitabilityRangeBadge';
import { ApprovePurchaseOrderAlert } from './components/ApprovePurchaseOrderAlert';
import { PurchaseOrderDiscountsTab } from './components/PurchaseOrderDiscountsTab';
import { PurchaseOrderAppliedCreditNotes } from './components/PurchaseOrderAppliedCreditNotes';
import { PurchaseOrderPaymentsTable } from './components/PurchaseOrderPaymentsTable';
import { PurchaseOrderMenu } from './components/PurchaseOrderMenu';
import { PurchaseOrderSellingAnalysisTab } from './components/PurchaseOrderSellingAnalysisTab';
import { PurchaseOrderRejectionReasonAlert } from './components/PurchaseOrderRejectionReasonAlert';

export const PurchaseOrderForm = ({
  purchaseOrderID,
}: {
  path?: string;
  purchaseOrderID?: string;
}) => {
  const navigate = useNavigate();

  const getPurchaseOrder = useStatefulAPIRequestMaker(
    DefaultOperations.getPurchaseOrder
  );
  useDocumentTitle(
    purchaseOrderID
      ? 'Purchase Order: ' +
          (getPurchaseOrder.response?.purchaseOrder.number ?? 'Loading...')
      : 'New Purchase Order'
  );

  const updatePurchaseOrder = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrder
  );
  const getPurchaseOrderPDF = useStatefulAPIRequestMaker(
    DefaultOperations.getPurchaseOrderPdf
  );

  const draftPurchaseOrder = useStatefulAPIRequestMaker(
    DefaultOperations.draftPurchaseOrder
  );
  const searchVendors = useStatefulAPIRequestMaker(
    DefaultOperations.searchVendors
  );
  const updateCollectionDate = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderCollectionDate
  );

  const form =
    useForm<{
      vendor: Vendor | null;
      vendorContact: VendorContact | null;
      store: Store | null;
      vendorInvoiceReference: string;
      stockLocation: StockLocation | null;
      collectionExpiryDate: Date | null;
      isFreshOrder: boolean;
      paymentTerms: PurchaseOrderPaymentTerm;
    }>();

  const onUpdateCollectionDateClicked = async () => {
    try {
      await updateCollectionDate.execute({
        purchaseOrderId: purchaseOrderID!,
        requestBody: {
          collectionExpiryDate: form.values.collectionExpiryDate!.toISOString(),
        },
      });

      showNotification({
        color: 'green',
        title: 'Collection Expiry Date Updated',
        message: 'Collection Expiry Date has been updated successfully',
      });
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Error Update Completion Expiry Date',
        message:
          'Make sure that the date you provided is at most 1 week from today',
      });
    }
  };

  const canViewProfitability = useFindPrivileges({
    // userType: ['admin', 'employee'],
    resource: 'profitability_analytics',
    action: 'read',
  });

  const canViewStockBalance = useFindPrivileges({
    // userType: ['admin', 'employee'],
    resource: 'stock_balance',
    action: 'read',
  });

  const refreshPurchaseOrder = async () => {
    getPurchaseOrder.execute({
      purchaseOrderId: purchaseOrderID!,
      includeProfitability: canViewProfitability.hasPrivilege,
      includeEstimatedDaysToSell: canViewProfitability.hasPrivilege,
      includeStockBalance: canViewStockBalance.hasPrivilege,
    });
  };

  useEffect(() => {
    if (purchaseOrderID) {
      refreshPurchaseOrder();
    }
  }, [purchaseOrderID]);

  useEffect(() => {
    const purchaseOrder = getPurchaseOrder.response;
    if (purchaseOrder) {
      const collectionExpiryDate = purchaseOrder.purchaseOrder
        .collectionExpiryDate
        ? new Date(purchaseOrder.purchaseOrder.collectionExpiryDate)
        : null;

      form.setValues({
        vendor: purchaseOrder.vendor,
        vendorContact: purchaseOrder.vendorContact,
        store: purchaseOrder.store,
        stockLocation: purchaseOrder.stockLocation,
        vendorInvoiceReference:
          purchaseOrder.purchaseOrder.vendorInvoiceReference ?? '',
        collectionExpiryDate,
        isFreshOrder: purchaseOrder.purchaseOrder.isFreshOrder,
        paymentTerms: purchaseOrder.purchaseOrder.paymentTerms,
      });
    }
  }, [getPurchaseOrder.response]);

  useEffect(() => {
    if (!searchVendors.response && !purchaseOrderID) {
      searchVendors.execute({ target: '' });
    }
  }, [searchVendors.response, purchaseOrderID]);

  const onSubmit = form.onSubmit(async (values) => {
    if (purchaseOrderID) {
    } else {
      try {
        const newPO = await draftPurchaseOrder.execute({
          requestBody: {
            additionalCosts: '0',
            currency: BHDCurrency.iso4217Code,
            storeID: values.store!.id,
            stockLocationID: values.stockLocation!.id,
            vendorID: values.vendor!.id,
            vendorContactID: values.vendorContact!.id,
            isFreshOrder: false,
            paymentTerms: values.paymentTerms,
          },
        });

        showNotification({
          color: 'green',
          title: 'Purchase Order Created',
          message: 'Purchase order created successfully.',
        });
        navigate(`/purchaseOrders/${newPO.purchaseOrder.id}`);
      } catch (e) {
        handleApiErrorMessage(e);
      }
    }
  });

  const purchaseOrderState = getPurchaseOrder.response?.purchaseOrder.state;

  const isCreditNote = new Big(
    getPurchaseOrder.response?.purchaseOrder.totalAmount || 0
  ).lt(new Big(0));

  const onVendorInvoiceReferenceInputClicked = () => {
    if (!getPurchaseOrder.response) {
      return;
    }

    openModal({
      modalId: 'set-vendor-invoice-reference',
      title: <Title order={3}>Vendor Invoice Reference</Title>,
      children: (
        <SetVendorInvoiceReferenceModal
          currentVendorInvoiceReference={
            getPurchaseOrder.response.purchaseOrder.vendorInvoiceReference ?? ''
          }
          purchaseOrderID={getPurchaseOrder.response.purchaseOrder.id}
          closeModal={() => {
            refreshPurchaseOrder();
            closeModal('set-vendor-invoice-reference');
          }}
        />
      ),
    });
  };

  const showVendorSelectorModal = () => {
    openModal({
      modalId: 'vendor-selector-modal',
      title: 'Select Vendor',
      children: (
        <VendorSelectorModalBody
          onVendorSelected={(v) => {
            form.setValues({
              vendor: v,
              vendorContact: null,
            });
            closeModal('vendor-selector-modal');
          }}
        />
      ),
    });
  };

  const showPaymentTermChangeModal = () => {
    if (!getPurchaseOrder.response || !purchaseOrderID) {
      return;
    }
    const currentVendorID = getPurchaseOrder.response.vendor.id;

    openModal({
      modalId: 'pt-change-selector-modal',
      title: 'Change Vendor',
      children: (
        <ModalSelector
          items={[
            { label: 'Credit', value: 'credit' },
            { label: 'Cash', value: 'cash' },
          ]}
          onItemSelected={async (v) => {
            try {
              await updatePurchaseOrder.execute({
                purchaseOrderId: purchaseOrderID!,
                requestBody: {
                  vendorID: currentVendorID,
                  paymentTerms: v.value! as PurchaseOrderPaymentTerm,
                },
              });

              await refreshPurchaseOrder();
              showNotification({
                color: 'green',
                title: 'Payment Term Updated',
                message: `The payment term has been changed to ${v.label}.`,
              });
              closeModal('pt-change-selector-modal');
            } catch (e) {
              handleApiErrorMessage(e);
            }
          }}
        />
      ),
    });
  };

  const showVendorChangeModal = () => {
    if (!getPurchaseOrder.response || !purchaseOrderID) {
      return;
    }

    if (getPurchaseOrder.response.creditingPurchaseOrderCredits.length > 0) {
      showNotification({
        color: 'red',
        title: 'Purchase Order Has Credit Notes',
        message: 'Remove all credit notes before changing the vendor.',
      });
      return;
    }

    if (getPurchaseOrder.response.creditedPurchaseOrderCredits.length > 0) {
      showNotification({
        color: 'red',
        title: 'Purchase Order Is A Credit Notes',
        message: 'Remove all credit notes before changing the vendor.',
      });
      return;
    }

    if (getPurchaseOrder.response.payments.length > 0) {
      showNotification({
        color: 'red',
        title: 'Purchase Order Has Payments',
        message: 'Remove all payments before changing the vendor.',
      });
      return;
    }

    const currentPaymentTerms =
      getPurchaseOrder.response.purchaseOrder.paymentTerms;

    openModal({
      modalId: 'vendor-change-selector-modal',
      title: 'Change Vendor',
      children: (
        <VendorSelectorModalBody
          onVendorSelected={async (v) => {
            try {
              await updatePurchaseOrder.execute({
                purchaseOrderId: purchaseOrderID!,
                requestBody: {
                  vendorID: v.id,
                  paymentTerms: currentPaymentTerms!,
                },
              });

              await refreshPurchaseOrder();
              showNotification({
                color: 'green',
                title: 'Vendor Updated',
                message: `The vendor has been changed to ${v.name}.`,
              });
              closeModal('vendor-change-selector-modal');
            } catch (e) {
              handleApiErrorMessage(e);
            }
          }}
        />
      ),
    });
  };

  const showStoreSelectorModal = () => {
    openModal({
      modalId: 'store-selector-modal',
      title: 'Select Vendor',
      children: (
        <StoreSelectorModalBody
          onStoreSelected={(v) => {
            form.setValues({
              store: v,
              stockLocation: null,
            });
            closeModal('store-selector-modal');
          }}
        />
      ),
    });
  };

  const showStockLocationSelectorModal = () => {
    if (!form.values.store) {
      return;
    }

    openModal({
      modalId: 'stock-location-selector-modal',
      title: 'Select Stock Location',
      children: (
        <StockLocationSelectorModalBody
          storeID={form.values.store.id}
          onSelected={(v) => {
            form.setValues({
              stockLocation: v.stockLocation,
            });
            closeModal('stock-location-selector-modal');
          }}
        />
      ),
    });
  };

  const showVendorContactSelectorModal = () => {
    if (!form.values.vendor) {
      return;
    }

    openModal({
      modalId: 'vendor-contact-selector-modal',
      title: 'Select Vendor',
      children: (
        <VendorContactSelectorModalBody
          vendorID={form.values.vendor.id}
          onSelected={(v) => {
            form.setValues({
              vendorContact: v,
            });
            closeModal('vendor-contact-selector-modal');
          }}
        />
      ),
    });
  };

  const canUpdateCompletedPO = useFindPrivileges({
    resource: 'completed_purchase_order',
    action: 'write',
  });

  const showChangelogsModal = () => {
    openModal({
      modalId: 'changelogs-modal',
      title: <Title order={3}>Changelogs</Title>,
      size: PX640.Number,
      children: <ChangelogsModal purchaseOrderID={purchaseOrderID!} />,
    });
  };

  const doPrint = async () => {
    const response = await getPurchaseOrderPDF.execute({
      purchaseOrderId: purchaseOrderID!,
    });

    const file = b64toBlob(response.pdfBase64, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay
          visible={getPurchaseOrder.loading || updatePurchaseOrder.loading}
          overlayBlur={2}
        />
        <Stack>
          <Button.Group>
            <Button
              compact
              variant="light"
              leftIcon={<IconHistory size={16} />}
              onClick={showChangelogsModal}
            >
              History
            </Button>

            <Button
              compact
              variant="light"
              leftIcon={<IconPrinter size={16} />}
              onClick={doPrint}
              loading={getPurchaseOrderPDF.loading}
            >
              Print
            </Button>

            <Button
              compact
              variant="light"
              leftIcon={<IconRefresh size={16} />}
              onClick={refreshPurchaseOrder}
            >
              Refresh
            </Button>
          </Button.Group>
          <Group position="apart">
            <Stack spacing={0}>
              {purchaseOrderID ? (
                <Stack spacing={0}>
                  <Text size="sm" fw={700}>
                    Purchase Order
                  </Text>
                  <Title>
                    {getPurchaseOrder.response?.purchaseOrder.number ??
                      'Loading...'}
                  </Title>
                  <Text>{getPurchaseOrder.response?.vendor.name ?? ''}</Text>
                </Stack>
              ) : (
                <Title>New Product</Title>
              )}
            </Stack>
            <PurchaseOrderMenu
              purchaseOrder={getPurchaseOrder.response?.purchaseOrder}
              currency={getPurchaseOrder.response?.currency}
              purchaseOrderLines={getPurchaseOrder.response?.purchaseOrderLines}
              refreshPurchaseOrder={refreshPurchaseOrder}
            />
          </Group>
          {getPurchaseOrder.response && (
            <Flex>
              <StatusBadge
                state={getPurchaseOrder.response.purchaseOrder.state}
              />
            </Flex>
          )}

          <PurchaseOrderRejectionReasonAlert
            purchaseOrder={getPurchaseOrder.response?.purchaseOrder}
            rejectingUser={getPurchaseOrder.response?.lastRejectedByUser}
          />

          <ApprovePurchaseOrderAlert
            purchaseOrder={getPurchaseOrder.response?.purchaseOrder}
            reloadPurchaseOrder={refreshPurchaseOrder}
          />

          {getPurchaseOrder.response && (
            <CardsRow
              currency={getPurchaseOrder.response.currency}
              purchaseOrder={getPurchaseOrder.response.purchaseOrder}
              profitabilityRange={
                getPurchaseOrder.response.currentProfitability
              }
            />
          )}

          <Tabs defaultValue="form">
            <Tabs.List>
              <Tabs.Tab value="form" icon={<IconForms size={14} />}>
                Order Details
              </Tabs.Tab>
              <Tabs.Tab value="lines" icon={<IconList size={14} />}>
                Order Lines
              </Tabs.Tab>
              {canViewProfitability.hasPrivilege && getPurchaseOrder.response && (
                <Tabs.Tab
                  value="sellingDetails"
                  icon={<IconCurrencyDollar size={14} />}
                >
                  Selling Details
                </Tabs.Tab>
              )}
              {getPurchaseOrder.response &&
                !isCreditNote &&
                (purchaseOrderState === 'completed' ||
                  purchaseOrderState === 'posted') && (
                  <Tabs.Tab value="payments" icon={<IconCash size={14} />}>
                    Payments ({getPurchaseOrder.response.payments.length})
                  </Tabs.Tab>
                )}
              {getPurchaseOrder.response &&
                !isCreditNote &&
                purchaseOrderState !== 'draft' && (
                  <Tabs.Tab
                    value="appliedCreditNotes"
                    icon={<IconCircleMinus size={14} />}
                  >
                    Credit Note Discounts (
                    {
                      getPurchaseOrder.response.creditedPurchaseOrderCredits
                        .length
                    }
                    )
                  </Tabs.Tab>
                )}
              {getPurchaseOrder.response &&
                !isCreditNote &&
                (purchaseOrderState === 'completed' ||
                  purchaseOrderState === 'posted') && (
                  <Tabs.Tab
                    value="discounts"
                    icon={<IconAdjustmentsDollar size={14} />}
                  >
                    Discounts (
                    {getPurchaseOrder.response.purchaseOrderDiscounts.length})
                  </Tabs.Tab>
                )}
              {getPurchaseOrder.response && isCreditNote && (
                <Tabs.Tab
                  value="creditingPurchaseOrderCredits"
                  icon={<IconCircleMinus size={14} />}
                >
                  Purchase Order Credits (
                  {
                    getPurchaseOrder.response.creditingPurchaseOrderCredits
                      .length
                  }
                  )
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="form" pt="lg">
              <Stack>
                <Stack>
                  <Title order={5}>Timeline</Title>
                  <CreatingUserCard
                    creatingUser={getPurchaseOrder.response?.creatingUser}
                    createdAt={
                      getPurchaseOrder.response?.purchaseOrder.createdAt
                    }
                  />

                  <ApprovingUserCard
                    approvingUser={getPurchaseOrder.response?.approvingUser}
                    approvedAt={
                      getPurchaseOrder.response?.purchaseOrder.approvedAt
                    }
                  />

                  <CollectingUserCard
                    collectedAt={
                      getPurchaseOrder.response?.purchaseOrder.collectedAt
                    }
                    collectingUser={getPurchaseOrder.response?.collectingUser}
                  />

                  <PostingUserCard
                    postingUser={getPurchaseOrder.response?.postingUser}
                    postedAt={getPurchaseOrder.response?.purchaseOrder.postedAt}
                  />
                </Stack>

                <form onSubmit={onSubmit}>
                  <Stack>
                    {!purchaseOrderID && (
                      <Flex>
                        <Button
                          type="submit"
                          loading={draftPurchaseOrder.loading}
                        >
                          Draft Purchase Order
                        </Button>
                      </Flex>
                    )}
                    <Group align="flex-end">
                      <ModalSelectorInput
                        style={{ flex: 1 }}
                        label="Vendor"
                        placeholder="Vendor"
                        onClick={showVendorSelectorModal}
                        value={form.values.vendor?.name ?? ''}
                        disableOnClick={purchaseOrderID !== undefined}
                      />
                      {canUpdateCompletedPO &&
                        getPurchaseOrder.response?.purchaseOrder.state ===
                          'completed' && (
                          <Button
                            variant="light"
                            leftIcon={<IconPencil />}
                            onClick={showVendorChangeModal}
                          >
                            Change
                          </Button>
                        )}
                    </Group>

                    <ModalSelectorInput
                      label="Vendor Contact"
                      placeholder="Vendor Contact"
                      onClick={showVendorContactSelectorModal}
                      value={form.values.vendorContact?.name ?? ''}
                      disableOnClick={
                        !form.values.vendor || purchaseOrderID !== undefined
                      }
                    />

                    <ModalSelectorInput
                      label="Store"
                      placeholder="Store"
                      onClick={showStoreSelectorModal}
                      value={form.values.store?.name ?? ''}
                      disableOnClick={purchaseOrderID !== undefined}
                    />

                    <ModalSelectorInput
                      label="Stock Location"
                      placeholder="Stock Location"
                      onClick={showStockLocationSelectorModal}
                      value={form.values.stockLocation?.name ?? ''}
                      disableOnClick={
                        !form.values.store || purchaseOrderID !== undefined
                      }
                    />

                    {(getPurchaseOrder.response?.purchaseOrder.state ===
                      'completed' ||
                      getPurchaseOrder.response?.purchaseOrder.state ===
                        'posted') && (
                      <TextInput
                        onClick={onVendorInvoiceReferenceInputClicked}
                        readOnly
                        label="Vendor Invoice Reference"
                        placeholder="Vendor Invoice Reference..."
                        {...form.getInputProps('vendorInvoiceReference')}
                      />
                    )}

                    <Checkbox
                      disabled={purchaseOrderID !== undefined}
                      checked={form.values.isFreshOrder}
                      label="Is Fresh Order"
                      {...form.getInputProps('isFreshOrder')}
                    />

                    <Group align="flex-end">
                      <Select
                        style={{ flex: 1 }}
                        readOnly={purchaseOrderID !== undefined}
                        data={[
                          { label: 'Credit', value: 'credit' },
                          { label: 'Cash', value: 'cash' },
                        ]}
                        required
                        label="Payment Term"
                        onChange={(value) => {
                          form.setValues({
                            paymentTerms: value as PurchaseOrderPaymentTerm,
                          });
                        }}
                        clearable={true}
                        disabled={purchaseOrderID !== undefined}
                        value={form.values.paymentTerms}
                      />

                      {canUpdateCompletedPO &&
                        getPurchaseOrder.response?.purchaseOrder.state ===
                          'completed' && (
                          <Button
                            variant="light"
                            leftIcon={<IconPencil />}
                            onClick={showPaymentTermChangeModal}
                          >
                            Change
                          </Button>
                        )}
                    </Group>

                    {getPurchaseOrder.response?.purchaseOrder.state ===
                      'ready' && (
                      <Card
                        withBorder
                        shadow="md"
                        style={{ overflow: 'visible' }}
                      >
                        {updateCollectionDate.loading && <Loader />}
                        <Stack>
                          <Alert
                            color="orange"
                            title="Collection Expiry Date"
                            icon={<IconCalendar />}
                          >
                            You cannot collect this purchase order after the
                            collection expiry date. You can only update the
                            collection expiry date to be at most 1 week from
                            today.
                          </Alert>
                          <DateInput
                            label="Collection Expiry Date"
                            placeholder="Collection Expiry Date"
                            minDate={new Date(Date.now())}
                            disabled={updateCollectionDate.loading}
                            maxDate={
                              new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                            }
                            {...form.getInputProps('collectionExpiryDate')}
                          />
                          <Group>
                            <Button
                              onClick={onUpdateCollectionDateClicked}
                              disabled={updateCollectionDate.loading}
                            >
                              Update Expire Date
                            </Button>
                          </Group>
                        </Stack>
                      </Card>
                    )}
                  </Stack>
                </form>
                {getPurchaseOrder.response?.purchaseOrder && (
                  <Tabs defaultValue="lines">
                    <Tabs.List>
                      <Tabs.Tab value="lines" icon={<IconList size={14} />}>
                        Order Lines
                      </Tabs.Tab>
                      {!isCreditNote &&
                        (purchaseOrderState === 'completed' ||
                          purchaseOrderState === 'posted') && (
                          <Tabs.Tab
                            value="payments"
                            icon={<IconCash size={14} />}
                          >
                            Payments (
                            {getPurchaseOrder.response.payments.length})
                          </Tabs.Tab>
                        )}
                      {!isCreditNote && purchaseOrderState !== 'draft' && (
                        <Tabs.Tab
                          value="appliedCreditNotes"
                          icon={<IconCircleMinus size={14} />}
                        >
                          Credit Note Discounts (
                          {
                            getPurchaseOrder.response
                              .creditedPurchaseOrderCredits.length
                          }
                          )
                        </Tabs.Tab>
                      )}
                      {!isCreditNote &&
                        (purchaseOrderState === 'completed' ||
                          purchaseOrderState === 'posted') && (
                          <Tabs.Tab
                            value="discounts"
                            icon={<IconAdjustmentsDollar size={14} />}
                          >
                            Discounts (
                            {
                              getPurchaseOrder.response.purchaseOrderDiscounts
                                .length
                            }
                            )
                          </Tabs.Tab>
                        )}
                      {isCreditNote && (
                        <Tabs.Tab
                          value="creditingPurchaseOrderCredits"
                          icon={<IconCircleMinus size={14} />}
                        >
                          Purchase Order Credits (
                          {
                            getPurchaseOrder.response
                              .creditingPurchaseOrderCredits.length
                          }
                          )
                        </Tabs.Tab>
                      )}
                    </Tabs.List>

                    <Tabs.Panel value="lines" pt="lg">
                      <PurchaseOrderLinesTable
                        currency={getPurchaseOrder.response.currency}
                        lines={getPurchaseOrder.response.purchaseOrderLines}
                        store={getPurchaseOrder.response.store}
                        vendor={getPurchaseOrder.response.vendor}
                        vendorContact={getPurchaseOrder.response.vendorContact}
                        reloadPurchaseOrder={refreshPurchaseOrder}
                        purchaseOrder={getPurchaseOrder.response.purchaseOrder}
                        editable={
                          getPurchaseOrder.response?.purchaseOrder.state ===
                          'draft'
                        }
                        showStockBalance={canViewStockBalance.hasPrivilege}
                      />
                    </Tabs.Panel>

                    {getPurchaseOrder.response.purchaseOrder.state !==
                      'draft' && (
                      <Tabs.Panel value="payments" pt="lg">
                        <PurchaseOrderPaymentsTable
                          purchaseOrder={
                            getPurchaseOrder.response.purchaseOrder
                          }
                          vendor={getPurchaseOrder.response.vendor}
                          payments={getPurchaseOrder.response.payments}
                          reloadPurchaseOrder={() =>
                            getPurchaseOrder.execute({
                              purchaseOrderId: purchaseOrderID!,
                            })
                          }
                        />
                      </Tabs.Panel>
                    )}

                    {getPurchaseOrder.response.purchaseOrder.state !==
                      'draft' && (
                      <Tabs.Panel value="appliedCreditNotes" pt="lg">
                        <PurchaseOrderAppliedCreditNotes
                          currency={getPurchaseOrder.response.currency}
                          purchaseOrder={
                            getPurchaseOrder.response.purchaseOrder
                          }
                          purchaseOrderCredits={
                            getPurchaseOrder.response
                              .creditedPurchaseOrderCredits
                          }
                          refreshPurchaseOrder={() =>
                            getPurchaseOrder.execute({
                              purchaseOrderId: purchaseOrderID!,
                            })
                          }
                        />
                      </Tabs.Panel>
                    )}

                    {getPurchaseOrder.response.purchaseOrder.state !==
                      'draft' && (
                      <Tabs.Panel value="creditingPurchaseOrderCredits" pt="lg">
                        <CreditNoteApplications
                          currency={getPurchaseOrder.response.currency}
                          creditingPurchaseOrderCredits={
                            getPurchaseOrder.response
                              .creditingPurchaseOrderCredits
                          }
                        />
                      </Tabs.Panel>
                    )}

                    {(getPurchaseOrder.response.purchaseOrder.state ===
                      'completed' ||
                      getPurchaseOrder.response.purchaseOrder.state ===
                        'posted') && (
                      <Tabs.Panel value="discounts" pt="lg">
                        <PurchaseOrderDiscountsTab
                          purchaseOrderID={
                            getPurchaseOrder.response.purchaseOrder.id
                          }
                          currency={getPurchaseOrder.response.currency}
                          discounts={getPurchaseOrder.response.purchaseOrderDiscounts.map(
                            (d) => d.purchaseOrderDiscount
                          )}
                          refreshPurchaseOrder={() =>
                            getPurchaseOrder.execute({
                              purchaseOrderId: purchaseOrderID!,
                            })
                          }
                        />
                      </Tabs.Panel>
                    )}
                  </Tabs>
                )}
              </Stack>
            </Tabs.Panel>

            {getPurchaseOrder.response && (
              <Tabs.Panel value="sellingDetails" pt="lg">
                <PurchaseOrderSellingAnalysisTab
                  purchaseOrder={getPurchaseOrder.response.purchaseOrder}
                  purchaseOrderLines={
                    getPurchaseOrder.response.purchaseOrderLines
                  }
                  currency={getPurchaseOrder.response.currency}
                />
              </Tabs.Panel>
            )}

            {getPurchaseOrder.response && (
              <Tabs.Panel value="lines" pt="lg">
                <PurchaseOrderLinesTable
                  currency={getPurchaseOrder.response.currency}
                  lines={getPurchaseOrder.response.purchaseOrderLines}
                  store={getPurchaseOrder.response.store}
                  vendor={getPurchaseOrder.response.vendor}
                  vendorContact={getPurchaseOrder.response.vendorContact}
                  reloadPurchaseOrder={refreshPurchaseOrder}
                  purchaseOrder={getPurchaseOrder.response.purchaseOrder}
                  editable={
                    getPurchaseOrder.response?.purchaseOrder.state === 'draft'
                  }
                  showStockBalance={canViewStockBalance.hasPrivilege}
                />
              </Tabs.Panel>
            )}

            {getPurchaseOrder.response &&
              getPurchaseOrder.response?.purchaseOrder.state !== 'draft' && (
                <Tabs.Panel value="payments" pt="lg">
                  <PurchaseOrderPaymentsTable
                    purchaseOrder={getPurchaseOrder.response.purchaseOrder}
                    vendor={getPurchaseOrder.response.vendor}
                    payments={getPurchaseOrder.response.payments}
                    reloadPurchaseOrder={() =>
                      getPurchaseOrder.execute({
                        purchaseOrderId: purchaseOrderID!,
                      })
                    }
                  />
                </Tabs.Panel>
              )}

            {getPurchaseOrder.response &&
              getPurchaseOrder.response?.purchaseOrder.state !== 'draft' && (
                <Tabs.Panel value="appliedCreditNotes" pt="lg">
                  <PurchaseOrderAppliedCreditNotes
                    currency={getPurchaseOrder.response.currency}
                    purchaseOrder={getPurchaseOrder.response.purchaseOrder}
                    purchaseOrderCredits={
                      getPurchaseOrder.response.creditedPurchaseOrderCredits
                    }
                    refreshPurchaseOrder={() =>
                      getPurchaseOrder.execute({
                        purchaseOrderId: purchaseOrderID!,
                      })
                    }
                  />
                </Tabs.Panel>
              )}

            {getPurchaseOrder.response &&
              getPurchaseOrder.response?.purchaseOrder.state !== 'draft' && (
                <Tabs.Panel value="creditingPurchaseOrderCredits" pt="lg">
                  <CreditNoteApplications
                    currency={getPurchaseOrder.response.currency}
                    creditingPurchaseOrderCredits={
                      getPurchaseOrder.response.creditingPurchaseOrderCredits
                    }
                  />
                </Tabs.Panel>
              )}

            {getPurchaseOrder.response &&
              (getPurchaseOrder.response?.purchaseOrder.state === 'completed' ||
                getPurchaseOrder.response?.purchaseOrder.state ===
                  'posted') && (
                <Tabs.Panel value="discounts" pt="lg">
                  <PurchaseOrderDiscountsTab
                    purchaseOrderID={getPurchaseOrder.response.purchaseOrder.id}
                    currency={getPurchaseOrder.response.currency}
                    discounts={getPurchaseOrder.response.purchaseOrderDiscounts.map(
                      (d) => d.purchaseOrderDiscount
                    )}
                    refreshPurchaseOrder={() =>
                      getPurchaseOrder.execute({
                        purchaseOrderId: purchaseOrderID!,
                      })
                    }
                  />
                </Tabs.Panel>
              )}
          </Tabs>
        </Stack>
      </div>
    </Container>
  );
};

const ChangelogsModal = ({ purchaseOrderID }: { purchaseOrderID: string }) => {
  const listPOChangelogs = useStatefulAPIRequestMaker(
    DefaultOperations.listPurchaseOrderChangelogs
  );
  useEffect(() => {
    listPOChangelogs.clearResponse();
    listPOChangelogs.execute({
      purchaseOrderId: purchaseOrderID,
    });
  }, [purchaseOrderID]);

  return (
    <Box p={PX12.Number}>
      <ChangelogsTimelines
        loading={listPOChangelogs.loading}
        changelogs={listPOChangelogs.response?.changelogs ?? []}
      />
    </Box>
  );
};

const ApprovingUserCard = ({
  approvingUser,
  approvedAt,
}: {
  approvingUser?: User;
  approvedAt?: string;
}) => {
  if (!approvingUser) {
    return null;
  }
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="grape" radius="md" size="lg" variant="light">
        <IconShieldCheckFilled />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {approvingUser.firstName} {approvingUser.lastName} approved this
          purchase order.
        </Text>
        {approvedAt && (
          <Text size="xs" c="dimmed">
            {RFC3339ToEasyDateTime(approvedAt)}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const PostingUserCard = ({
  postingUser,
  postedAt,
}: {
  postingUser?: UserWithNameOnly;
  postedAt?: string;
}) => {
  if (!postingUser) {
    return null;
  }
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="green" radius="md" size="lg" variant="light">
        <IconCheck />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {postingUser.firstName} {postingUser.lastName} posted this purchase
          order.
        </Text>
        {postedAt && (
          <Text size="xs" c="dimmed">
            {RFC3339ToEasyDateTime(postedAt)}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const CreatingUserCard = ({
  creatingUser,
  createdAt,
}: {
  creatingUser?: UserWithNameOnly;
  createdAt?: string;
}) => {
  if (!creatingUser) {
    return null;
  }
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="orange" radius="md" size="lg" variant="light">
        <IconPlus />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {creatingUser.firstName} {creatingUser.lastName} created this purchase
          order.
        </Text>
        {createdAt && (
          <Text size="xs" c="dimmed">
            {RFC3339ToEasyDateTime(createdAt)}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const CollectingUserCard = ({
  collectingUser,
  collectedAt,
}: {
  collectingUser?: UserWithNameOnly;
  collectedAt?: string;
}) => {
  if (!collectingUser) {
    return null;
  }
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="blue" radius="md" size="lg" variant="light">
        <IconTruckDelivery />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {collectingUser.firstName} {collectingUser.lastName} collected this
          purchase order.
        </Text>
        {collectedAt && (
          <Text size="xs" c="dimmed">
            {RFC3339ToEasyDateTime(collectedAt)}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const SetVendorInvoiceReferenceModal = ({
  currentVendorInvoiceReference,
  purchaseOrderID,
  closeModal,
}: {
  closeModal: () => void;
  purchaseOrderID: string;
  currentVendorInvoiceReference: string;
}) => {
  const [vendorInvoiceReference, setVendorInvoiceReference] = useState(
    currentVendorInvoiceReference
  );

  const updatePurchaseOrderVendorInvoiceReference = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderVendorInvoiceReference
  );

  const onSaveClicked = async () => {
    await updatePurchaseOrderVendorInvoiceReference.execute({
      purchaseOrderId: purchaseOrderID,
      requestBody: {
        vendorInvoiceReference,
      },
    });

    closeModal();
  };

  return (
    <LoadingContainer
      loading={updatePurchaseOrderVendorInvoiceReference.loading}
    >
      <Stack>
        <TextInput
          label="Vendor Invoice Reference"
          placeholder="Vendor Invoice Reference..."
          value={vendorInvoiceReference}
          onChange={(e) => setVendorInvoiceReference(e.target.value)}
        />
        <Group>
          <Button onClick={onSaveClicked}>Save</Button>
        </Group>
      </Stack>
    </LoadingContainer>
  );
};

const CreditNoteApplications = ({
  currency,
  creditingPurchaseOrderCredits,
}: {
  currency: Currency;
  creditingPurchaseOrderCredits: {
    purchaseOrderCredit: PurchaseOrderCredit;
    creditedPurchaseOrder: PurchaseOrder;
  }[];
}) => {
  return (
    <Stack spacing={PX8.Number}>
      <Alert variant="outline" icon={<IconInfoCircle />} color="blue">
        Below are purchase orders that have been discounted by this credit note.
      </Alert>

      <Table>
        <thead>
          <tr>
            <th>Purchase Order</th>
            <th>Credited At</th>
            <th>Credit Amount</th>
          </tr>
        </thead>
        <tbody>
          {creditingPurchaseOrderCredits.map((c) => {
            const createdAt = RFC3339ToEasyDate(
              c.purchaseOrderCredit.createdAt
            );
            const amountStr = formatToCurrency(
              c.purchaseOrderCredit.amount,
              currency
            );

            return (
              <tr key={c.purchaseOrderCredit.id}>
                <td>
                  <Link
                    to={`/purchaseOrders/${c.creditedPurchaseOrder.id}`}
                    hidden
                    withIcon
                  >
                    {c.creditedPurchaseOrder.number}
                  </Link>
                </td>
                <td>{createdAt}</td>
                <td>{amountStr}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

const CardsRow = ({
  purchaseOrder,
  currency,
  profitabilityRange,
}: {
  purchaseOrder: PurchaseOrder;
  currency: Currency;
  profitabilityRange?: ProfitabilityRange;
}) => {
  const totalAmount = Big(purchaseOrder.totalAmount || 0);
  const balanceAmount = Big(purchaseOrder.balanceAmount ?? 0);

  const statCards = [];
  if (purchaseOrder.preTaxAmount) {
    statCards.push(
      <Paper withBorder radius="md" p="xs" key="Total">
        <Group>
          <Stack spacing={0}>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              Before Tax
            </Text>
            <Text weight={700} size="lg">
              {formatToCurrency(purchaseOrder.preTaxAmount, currency)}
            </Text>
          </Stack>
        </Group>
      </Paper>
    );
  }

  if (purchaseOrder.totalAmount) {
    statCards.push(
      <Paper withBorder radius="md" p="xs" key="Total">
        <Group>
          <Stack spacing={0}>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              Total
            </Text>
            <Text weight={700} size="lg">
              {formatBig(totalAmount, currency)}
            </Text>
          </Stack>
        </Group>
      </Paper>
    );
  }

  if (purchaseOrder.balanceAmount) {
    statCards.push(
      <Paper withBorder radius="md" p="xs" key="balance">
        <Group>
          <RingProgress
            size={50}
            roundCaps
            thickness={5}
            sections={[
              {
                value: totalAmount.eq(new Big(0))
                  ? totalAmount.toNumber()
                  : balanceAmount.mul(100).div(totalAmount).toNumber(),
                color: 'orange',
              },
            ]}
          />

          <Stack spacing={0}>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              Balance
            </Text>
            <Text weight={700} size="lg">
              {formatBig(balanceAmount, currency)}
            </Text>
          </Stack>
        </Group>
      </Paper>
    );
  }

  if (profitabilityRange) {
    statCards.push(
      <Paper withBorder radius="md" p="xs" key="profitRange">
        <Group>
          <Stack spacing={PX8.Number}>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              Profit Margin
            </Text>
            <Text weight={700} size="lg">
              <ProfitabilityRangeBadge
                profitabilityRange={profitabilityRange}
              />
            </Text>
          </Stack>
        </Group>
      </Paper>
    );
  }

  if (statCards.length === 0) {
    return null;
  }

  return (
    <Group grow={true} align="stretch">
      {statCards}
    </Group>
  );
};
