import { Button, Container, Flex, Stack, Textarea, Title } from '@mantine/core';
import { PX1024, PX32, PX64, PX96 } from '../../../../components/Px';
import useWebSocket from 'react-use-websocket';
import { BackendBaseWebSocketURL } from '../../../../envvar/envvar';
import { useEffect, useRef, useState } from 'react';

export const OdooSyncProductsPage = ({}: { path?: string }) => {
  const [connectToWS, setConnectToWS] = useState(false);
  const [logs, setLogs] = useState('');
  const { sendMessage, lastMessage } = useWebSocket(
    BackendBaseWebSocketURL + '/ws/odoo/syncProducts',
    {
      onError: (e) => {
        console.log('websocket error:', e);
      },
      onClose: (e) => {
        console.log('websocket close:', e);
      },
    },
    connectToWS
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (lastMessage && lastMessage.data !== 'PING') {
      let newLogs = logs + '\n' + lastMessage.data;

      if (newLogs.length > 500) {
        const trimIndex = newLogs.length - 500;
        newLogs = newLogs.substring(trimIndex);
      }
      setLogs(newLogs);
      textAreaRef.current!.scrollTop = textAreaRef.current!.scrollHeight;
    } else if (lastMessage && lastMessage.data === 'PING') {
      console.log('pinging...');
      sendMessage('PONG');
    }
  }, [lastMessage]);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Odoo Product Sync</Title>
        <Flex>
          <Button onClick={() => setConnectToWS(true)} disabled={connectToWS}>
            Start Sync
          </Button>
        </Flex>
        <Textarea
          ref={textAreaRef}
          value={logs}
          autosize
          minRows={2}
          maxRows={20}
        />
      </Stack>
    </Container>
  );
};
