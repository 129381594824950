import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, PosAppVersionStatus } from '../../../oas/codegen3';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  FileInput,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { PX1024, PX24, PX32, PX64, PX96 } from '../../../components/Px';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconFile } from '@tabler/icons-react';
import { openConfirmModal } from '@mantine/modals';
import { useNavigate } from '@reach/router';

export const PosAppVersionViewer = ({ id }: { path: string; id?: string }) => {
  const navigate = useNavigate();

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const getPosAppVersion = useStatefulAPIRequestMaker(
    DefaultOperations.getPosAppVersion
  );
  const createPosAppVersion = useStatefulAPIRequestMaker(
    DefaultOperations.createPosAppVersion
  );
  const updatePosAppVersion = useStatefulAPIRequestMaker(
    DefaultOperations.updatePosAppVersion
  );
  const uploadAndSetPosAppVersionFile = useStatefulAPIRequestMaker(
    DefaultOperations.uploadAndSetPosAppVersionFile
  );

  const downloadPosAppVersionFile = useStatefulAPIRequestMaker(
    DefaultOperations.getPosAppVersionFile
  );

  const deletePosAppVersionFile = useStatefulAPIRequestMaker(
    DefaultOperations.deletePosAppVersionFile
  );

  const form =
    useForm<{
      version?: string;
      status?: PosAppVersionStatus;
      appFileName?: string;
    }>();

  useEffect(() => {
    if (id) {
      getPosAppVersion.execute({ posAppVersionId: id });
    }
  }, []);

  useEffect(() => {
    if (getPosAppVersion.response) {
      form.setValues({
        version: getPosAppVersion.response.posAppVersion.version,
        status: getPosAppVersion.response.posAppVersion.status,
        appFileName: getPosAppVersion.response.posAppVersion.appFileName,
      });
    }
  }, [getPosAppVersion.response]);

  const onSubmit = form.onSubmit(async (v) => {
    if (id) {
      await updatePosAppVersion.execute({
        posAppVersionId: id,
        requestBody: {
          status: v.status!,
          version: v.version!,
          appFileName: v.appFileName!,
        },
      });
      showNotification({
        color: 'green',
        title: 'App Version Updated',
        message: 'The app version has successfully been updated.',
      });
      await getPosAppVersion.execute({ posAppVersionId: id });
    } else {
      const response = await createPosAppVersion.execute({
        requestBody: {
          version: v.version!,
          appFileName: v.appFileName!,
        },
      });
      navigate('/posAppVersions/' + response.posAppVersion.id);
    }
  });

  const uploadNewFile = async () => {
    if (fileToUpload && id) {
      await uploadAndSetPosAppVersionFile.execute({
        posAppVersionId: id,
        requestBody: fileToUpload,
      });

      showNotification({
        color: 'green',
        title: 'File Upload Successful',
        message: 'The file has been loaded successfully.',
      });

      await getPosAppVersion.execute({ posAppVersionId: id });
    }
  };

  const downloadAppFile = async () => {
    if (id) {
      const response = await downloadPosAppVersionFile.execute({
        posAppVersionId: id,
      });

      window.open(response.url);
    }
  };

  const deleteUploadedFile = async () => {
    if (id) {
      await deletePosAppVersionFile.execute({ posAppVersionId: id });
      showNotification({
        color: 'green',
        title: 'Remove File Deleted',
        message: 'The file has been removed from the app version.',
      });
      await getPosAppVersion.execute({ posAppVersionId: id });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay
        visible={
          getPosAppVersion.loading ||
          updatePosAppVersion.loading ||
          uploadAndSetPosAppVersionFile.loading
        }
      />
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <form onSubmit={onSubmit}>
            <Stack>
              <Title>
                {getPosAppVersion.response?.posAppVersion.version || ''}
              </Title>

              <TextInput
                autoComplete="off"
                label="Version"
                {...form.getInputProps('version')}
              />

              <TextInput
                autoComplete="off"
                label="App File Name"
                {...form.getInputProps('appFileName')}
              />

              {id && (
                <Select
                  data={[
                    { value: 'hidden', label: 'Hidden' },
                    { value: 'dev', label: 'Dev' },
                    { value: 'active', label: 'Active' },
                    { value: 'end_of_life', label: 'End of Life' },
                    { value: 'deprecated', label: 'Deprecated' },
                  ]}
                  label="Status"
                  value={form.values.status}
                  onChange={(v) => {
                    form.setValues({
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      status: v,
                    });
                  }}
                />
              )}

              <Group>
                <Button color="green" type="submit">
                  {id ? 'Update' : 'Create'}
                </Button>
              </Group>
            </Stack>
          </form>
        </Stack>
        {id && (
          <Stack mt={PX24.Number}>
            {getPosAppVersion.response?.posAppVersion.s3AppFileName ? (
              <Alert
                color="blue"
                title="App Has File Uploaded"
                icon={<IconFile />}
              >
                <Stack>
                  This app has a file uploaded. Use the below buttons to
                  download or delete the uploaded file.
                  <Group>
                    <Button compact color="green" onClick={downloadAppFile}>
                      Download File
                    </Button>
                    <Button
                      compact
                      color="red"
                      onClick={() => {
                        openConfirmModal({
                          title: (
                            <Title order={3}>Delete App Version File</Title>
                          ),
                          children: (
                            <Text size="sm">
                              Are you sure you want to delete this app version
                              file?
                            </Text>
                          ),
                          labels: {
                            confirm: 'Delete App File',
                            cancel: 'Cancel',
                          },
                          onConfirm: deleteUploadedFile,
                        });
                      }}
                    >
                      Delete File
                    </Button>
                  </Group>
                </Stack>
              </Alert>
            ) : (
              <Alert
                color="red"
                title="No App File Detected"
                icon={<IconFile />}
              >
                No app file has been set for this POS App Verison. Upload a file
                using the below input field to set an app.
              </Alert>
            )}
            <FileInput
              label="Select File to Upload"
              value={fileToUpload}
              onChange={setFileToUpload}
            />
            <Button
              style={{ alignSelf: 'flex-start' }}
              disabled={fileToUpload === null}
              onClick={uploadNewFile}
            >
              Upload File
            </Button>
          </Stack>
        )}
      </Container>
    </div>
  );
};
