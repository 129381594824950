import {
  PX1024,
  PX16,
  PX256,
  PX32,
  PX384,
  PX4,
  PX64,
  PX96,
} from '../../../components/Px';
import React from 'react';
import {
  Button,
  Container,
  Flex,
  Group,
  Stack,
  Table,
  TextInput,
  Title,
  Text,
  Divider,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useListState } from '@mantine/hooks';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  Product,
  ProductPrice,
} from '../../../oas/codegen3';
import { showNotification } from '@mantine/notifications';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { CSVLink } from 'react-csv';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const ProductPriceExporter = ({}: { path: string }) => {
  const [productsSelected, handler] = useListState<{
    product: Product;
    prices: ProductPrice[];
  }>([]);
  const [target, setTarget] = React.useState('');

  const listProducts = useStatefulAPIRequestMaker(
    DefaultOperations.listProducts
  );

  const onSearchInputKeyDown = async (e: any) => {
    const key = e.key;
    if (key === 'Enter') {
      const response = await listProducts.execute({
        target,
        limit: 100,
      });

      if (response.products.length === 1) {
        const res = productsSelected.find(
          (ps) => ps.product.id === response.products[0].product.id
        );

        if (!res) {
          handler.append({
            product: response.products[0].product,
            prices: response.products[0].prices.map((p) => p.price),
          });
          showNotification({
            color: 'green',
            title: 'Product Added',
            message: 'Product added to the selected list.',
          });
        } else {
          showNotification({
            color: 'blue',
            title: 'Product Already In List',
            message: 'The product found is already in the selected list.',
          });
        }

        setTarget('');
        listProducts.clearResponse();
      }
    }
  };

  const csvData = () => {
    const data: string[][] = [];
    data.push([
      'product',
      'productBarcode',
      'productReference',
      'priceUnitPrice',
      'priceCaseOf',
      'priceBarcode',
      'priceTaxIncluded',
    ]);
    productsSelected.forEach((p) => {
      p.prices.forEach((price) => {
        data.push([
          p.product.name,
          p.product.barcode ?? '',
          p.product.reference ?? '',
          price.unitPrice,
          price.countPerUnit,
          price.barcode ?? '',
          `${price.taxIncluded}`,
        ]);
      });
    });

    return data;
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Price Exporter</Title>
        <Group>
          <Button
            component={CSVLink}
            filename={'product_prices.csv'}
            data={csvData()}
            color="green"
          >
            Export to CSV
          </Button>
        </Group>
        <TextInput
          icon={<IconSearch />}
          label="Search"
          placeholder="Search"
          onChange={(e) => setTarget(e.target.value)}
          onKeyDown={onSearchInputKeyDown}
          value={target}
        />
        <Flex gap={PX16.Number}>
          <Container style={{ flex: 1 }} miw={PX256.Number}>
            <Stack spacing={PX4.Number}>
              <Text>Click on a product to select it.</Text>

              <LoadingContainer
                loading={listProducts.loading}
                minHeight={PX384.Number}
              >
                <Table highlightOnHover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(listProducts.response?.products ?? []).map((p) => {
                      const onClick = () => {
                        const res = productsSelected.find(
                          (ps) => ps.product.id === p.product.id
                        );

                        if (!res) {
                          handler.append({
                            product: p.product,
                            prices: p.prices.map((p) => p.price),
                          });
                        } else {
                          showNotification({
                            color: 'blue',
                            title: 'Product Already In List',
                            message:
                              'The product found is already in the selected list.',
                          });
                        }
                      };

                      const emptyName = p.product.name.trim() === '';

                      return (
                        <tr
                          onClick={onClick}
                          key={p.product.id}
                          style={{ cursor: 'pointer' }}
                        >
                          <td>{emptyName ? '[No Name]' : p.product.name}</td>
                          <td>{p.product.reference}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </LoadingContainer>
            </Stack>
          </Container>
          <Divider orientation="vertical" />
          <Container style={{ flex: 1 }} miw={PX256.Number}>
            <Stack spacing={PX4.Number}>
              <Text>Products selected will appear in the list below.</Text>
              <Table highlightOnHover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Reference</th>
                  </tr>
                </thead>

                <tbody>
                  {productsSelected.map((p, i) => {
                    const onClick = () => {
                      handler.remove(i);
                    };
                    const emptyName = p.product.name.trim() === '';

                    return (
                      <tr
                        key={p.product.id}
                        style={{ cursor: 'pointer' }}
                        onClick={onClick}
                      >
                        <td>{emptyName ? '[No Name]' : p.product.name}</td>
                        <td>{p.product.reference}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Stack>
          </Container>
        </Flex>
      </Stack>
    </Container>
  );
};
