import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GlobalStyles } from './styles/GlobalStyles';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import { AlertTemplate } from './components/alert/Alert';
import { ModalProvider } from 'react-modal-hook';
import { PointsConversionProvider } from './providers/PointsConversionProvider';
import { MantineProvider } from '@mantine/core';
import { MantineThemeOverride } from '@mantine/styles/lib/theme/types';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { Location } from '@reach/router';
import { Provider } from 'react-redux';
import { pccStore } from './pccstores/PccStore';
import { ModalManagerLoadingModal } from '@components/V2/ModalManagerLoadingModal';

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 4000,
  // you can also just use 'scale'
  transition: transitions.FADE,
};

const theme: MantineThemeOverride = {
  primaryColor: 'orange',
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={pccStore}>
      <MantineProvider theme={theme}>
        <Notifications position="bottom-right" />
        <GlobalStyles />
        <Location>
          {() => (
            <AlertProvider template={AlertTemplate} {...options}>
              <PointsConversionProvider>
                <ModalProvider>
                  <ModalsProvider
                    modals={{ loadingModal: ModalManagerLoadingModal }}
                  >
                    <App />
                  </ModalsProvider>
                </ModalProvider>
              </PointsConversionProvider>
            </AlertProvider>
          )}
        </Location>
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
