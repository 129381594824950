import Big from 'big.js';
import { Currency } from '../oas/codegen3';

export const BHDCurrency: Currency = Object.freeze({
  iso4217Code: 'BHD',
  name: 'Bahraini dinar',
  symbol: 'BD',
  decimalPlaces: 3,
  denominations: [0.005, 0.01, 0.025, 0.05, 0.1, 0.5, 1, 5, 10, 20],
});

const iso4217CodeToCurrency: { [key: string]: Currency } = {
  BHD: BHDCurrency,
};

export function findCurrency(iso4217Code: string): Currency {
  const currency = iso4217CodeToCurrency[iso4217Code];

  if (!currency) {
    throw new Error('unsupported currency ' + iso4217Code);
  }

  return currency;
}

export function formatBig(b: Big, c: Currency): string {
  const numberFormatted = b.toFixed(c.decimalPlaces, 0);

  return `${c.symbol} ${numberFormatted}`;
}

export function formatToCurrency(
  v: Big | number | string | null | undefined,
  c: Currency | null | undefined
): string {
  if (v === undefined || v === null || c === undefined || c === null) {
    return ``;
  }
  const b = new Big(v);

  return formatBig(b, c);
}
