import { ReactNode } from 'react';
import { Group, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconExternalLink } from '@tabler/icons-react';
import { PX4 } from '../components/Px';
import { navigate } from '@reach/router';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const Link = ({
  children,
  to,
  samePageRerender,
  hidden,
  withIcon,
  onClick,
  inline,
}: {
  children?: ReactNode;
  to?: string;
  onClick?: () => void;
  samePageRerender?: any;
  hidden?: any;
  withIcon?: any;
  inline?: boolean;
}) => {
  const { hovered, ref } = useHover<HTMLAnchorElement>();

  const onTextClick = (e: any) => {
    if (onClick) {
      onClick();
    }
    if (to) {
      if (!e.ctrlKey && !e.metaKey && !e.shiftKey && samePageRerender) {
        e.preventDefault();
        navigate(to);
      }
    }
  };

  return (
    <Text
      ref={ref}
      component="a"
      color={hidden ? (hovered ? 'blue' : undefined) : 'blue'}
      underline={hovered}
      href={to}
      span={inline}
      display={inline ? 'inline-flex' : undefined}
      target={samePageRerender ? '_self' : '_blank'}
      onClick={onTextClick}
    >
      <Group spacing={PX4.Number}>
        {withIcon ? <IconExternalLink size={16} /> : null}
        {children}
      </Group>
    </Text>
  );
};
