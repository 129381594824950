import { DefaultOperations, ProductClassCode } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';

export const ClassCodeSelectorModalBody = ({
  onClassCodeSelected,
}: {
  onClassCodeSelected: (g: ProductClassCode) => void;
}) => {
  const listProductClassCode = useStatefulAPIRequestMaker(
    DefaultOperations.listProductClassCodes
  );

  const {
    debounceValue,
    onChangeDeps,
    pagination,
    target,
    setTargetFromInput,
  } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
  });

  useEffect(() => {
    listProductClassCode.execute({
      classCode: debounceValue,
      limit: pagination.limit,
    });
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for Class Code"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={listProductClassCode.loading}>
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>Class Code</th>
            </tr>
          </thead>
          <tbody>
            {(listProductClassCode.response?.productClassCodes || []).map(
              (cc) => {
                const onClick = async () => {
                  onClassCodeSelected(cc);
                };

                return (
                  <tr onClick={onClick} key={cc.id}>
                    <td>{cc.classCode}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
