import { Badge, Text } from '@mantine/core';
import React from 'react';
import { StockCheckStatus } from '@pcc/api/models/StockCheck';

export const StockCheckStatusBadge = ({
  state,
  fullWidth,
}: {
  state?: StockCheckStatus;
  fullWidth?: boolean;
}) => {
  if (!state) {
    return null;
  }

  let color: string;

  if (state === 'draft') {
    color = 'blue';
  } else if (state === 'processing') {
    color = 'red';
  } else if (state === 'posted') {
    color = 'green';
  } else if (state === 'cancelled') {
    color = 'gray';
  } else {
    return null;
  }

  return (
    <Badge color={color} variant="light" fullWidth={fullWidth} size="sm">
      <Text fw={700}>{state}</Text>
    </Badge>
  );
};
