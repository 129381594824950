import { ProfitabilityRange } from '../oas/codegen3';
import { toBig } from '../utils/toBigOrNull';
import { Badge } from '@mantine/core';
import { toPrettyPercentage } from '../utils/toPercentage';
import React from 'react';

export const ProfitabilityRangeBadge = ({
  profitabilityRange,
}: {
  profitabilityRange?: ProfitabilityRange | null | undefined;
}) => {
  const lowestProfitability = profitabilityRange?.lowestProfitability;
  const highestProfitability = profitabilityRange?.highestProfitability;

  const lowestProfitabilityBig = toBig(
    lowestProfitability?.profitMarginPercent,
    0
  );

  const color = lowestProfitabilityBig.lte(0.1)
    ? 'red'
    : lowestProfitabilityBig.lte(0.25)
    ? 'yellow'
    : 'green';

  let profitabilityBadge = null;
  if (lowestProfitability && highestProfitability) {
    const lowerProfitabilityMarginStr = toPrettyPercentage(
      lowestProfitability.profitMarginPercent,
      0
    );

    const higherProfitabilityMarginStr = toPrettyPercentage(
      highestProfitability.profitMarginPercent,
      0
    );

    profitabilityBadge = (
      <Badge variant="light" fullWidth color={color}>
        {lowerProfitabilityMarginStr === higherProfitabilityMarginStr
          ? lowerProfitabilityMarginStr
          : `${lowerProfitabilityMarginStr} to ${higherProfitabilityMarginStr}`}
      </Badge>
    );
  }

  return profitabilityBadge;
};
