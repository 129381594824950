import { useState } from 'react';
import { CancelablePromise } from '../oas/codegen3/core/CancelablePromise';
import { v4 } from 'uuid';

export const useStatefulAPIRequestMaker = <T extends unknown[], R>(
  func: (...args: T) => CancelablePromise<R>
): {
  execute: (...t: T) => CancelablePromise<R>;
  loading: boolean;
  response?: R | undefined;
  responseID?: string | undefined;
  clearResponse: () => void;
  activeRequests: number;
} => {
  const [activeRequests, setActiveRequests] = useState(0);
  const [responseID, setResponseID] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<R | undefined>(undefined);

  const execute = (...t: T): CancelablePromise<R> => {
    setActiveRequests((s) => s + 1);

    const r = func(...t);
    r.then((r) => {
      setActiveRequests((s) => s - 1);
      setResponse(r);
      setResponseID(v4());
    }).catch((e) => {
      setActiveRequests((s) => s - 1);
      throw e;
    });

    return r;
  };

  const clearResponse = () => {
    setResponse(undefined);
    setResponseID(undefined);
  };

  return {
    execute,
    loading: activeRequests > 0,
    response,
    clearResponse,
    responseID,
    activeRequests,
  };
};
