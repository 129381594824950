import { Location, Router } from '@reach/router';
import React from 'react';
import { TaxForm } from './TaxForm';
import { TaxViewer } from './TaxViewer';

export const TaxRouter = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <TaxViewer path="/" />
          <TaxForm path="/:taxID" />
          <TaxForm path="/new" />
        </Router>
      )}
    </Location>
  );
};
