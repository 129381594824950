import { ActionIcon, Badge, Box, Group } from '@mantine/core';
import React from 'react';
import { IconX } from '@tabler/icons-react';

export const SelectorBadge = ({
  value,
  placeholder,
  onClearClick,
  onClick,
}: {
  value?: string | null;
  placeholder: string;
  onClick: () => void;
  onClearClick: () => void;
}) => {
  return (
    <Badge size="lg" color={value ? 'orange' : 'gray'} variant="light">
      <Group>
        <Box onClick={onClick} style={{ cursor: 'pointer' }}>
          {value || placeholder}
        </Box>
        {value ? (
          <ActionIcon
            size="xs"
            radius="xl"
            color="orange"
            variant="transparent"
            onClick={onClearClick}
          >
            <IconX />
          </ActionIcon>
        ) : null}
      </Group>
    </Badge>
  );
};
