import { PX1024, PX32, PX64, PX8, PX96 } from '../../../components/Px';
import {
  ActionIcon,
  Code,
  Container,
  CopyButton,
  Flex,
  Group,
  LoadingOverlay,
  Skeleton,
  Space,
  Stack,
  Table,
  Tabs,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { IconCash, IconCheck, IconCopy, IconList } from '@tabler/icons-react';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { formatToCurrency } from '../../../models/Currency';
import { PaymentMethodNameBadge } from '@components/V2/PaymentMethodNameBadge';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { StatusBadge } from '@components/V2/StatusBadge';
import { RingProgressCard } from '@components/V2/RingProgressCard';
import { toBig } from '../../../utils/toBigOrNull';

export const SaleViewer = ({ saleID }: { path?: string; saleID?: string }) => {
  const getSale = useStatefulAPIRequestMaker(DefaultOperations.getSaleById);

  useEffect(() => {
    if (saleID) {
      getSale.execute({ saleId: saleID });
    }
  }, [saleID]);

  const showAmountBalance =
    getSale.response?.sale.totalAmount &&
    getSale.response?.sale.amountPaid &&
    getSale.response?.sale.amountRefunded;
  const amountBalance = toBig(getSale.response?.sale.totalAmount, 0)
    .sub(toBig(getSale.response?.sale.amountPaid, 0))
    .sub(toBig(getSale.response?.sale.amountRefunded, 0));

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={getSale.loading} />
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <Skeleton radius="sm" visible={!getSale.response?.sale}>
            <Title order={1}>Sale: {getSale.response?.sale.number}</Title>
          </Skeleton>
          <Flex>
            <StatusBadge
              state={getSale.response?.sale.state}
              fullWidth={false}
            />
          </Flex>
          <Group grow spacing={PX8.Number}>
            {getSale.response && getSale.response.sale.totalAmount && (
              <RingProgressCard
                label="Total Amount"
                amount={formatToCurrency(
                  getSale.response.sale.totalAmount,
                  getSale.response.currency
                )}
              />
            )}
            {getSale.response && getSale.response.sale.amountPaid && (
              <RingProgressCard
                label="Amount Paid"
                amount={formatToCurrency(
                  getSale.response.sale.amountPaid,
                  getSale.response.currency
                )}
              />
            )}
            {getSale.response && showAmountBalance && (
              <RingProgressCard
                label="Balance"
                amount={formatToCurrency(
                  amountBalance,
                  getSale.response.currency
                )}
              />
            )}
            {getSale.response && getSale.response.sale.amountRefunded && (
              <RingProgressCard
                label="Amount Refunded"
                amount={formatToCurrency(
                  getSale.response.sale.amountRefunded,
                  getSale.response.currency
                )}
              />
            )}
          </Group>
          <TextInput
            rightSection={
              getSale.response && (
                <CopyButton value={getSale.response.sale.number} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? 'Copied' : 'Click to Copy'}>
                      <ActionIcon
                        onClick={copy}
                        color={copied ? 'green' : 'orange'}
                      >
                        {copied ? <IconCheck /> : <IconCopy />}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              )
            }
            label="Sale Number"
            readOnly
            defaultValue={getSale.response?.sale.number}
          />
          <Group grow>
            <TextInput
              label="Created At"
              defaultValue={
                getSale.response
                  ? RFC3339ToEasyDateTime(getSale.response.sale.createdAt)
                  : ''
              }
              readOnly
            />
            <TextInput
              label="Posted At"
              defaultValue={
                getSale.response?.sale.postedAt
                  ? RFC3339ToEasyDateTime(getSale.response.sale.postedAt)
                  : ''
              }
              readOnly
            />
          </Group>
          <TextInput
            label="Created By"
            defaultValue={
              getSale.response?.cashier
                ? getSale.response.cashier.firstName +
                  ' ' +
                  getSale.response.cashier.lastName
                : ''
            }
            readOnly
          />
          <Group grow>
            <TextInput
              label="Store"
              defaultValue={getSale.response?.store?.name || ''}
              readOnly
            />
            <TextInput
              label="Stock Location"
              defaultValue={getSale.response?.stockLocation?.name || ''}
              readOnly
            />
          </Group>

          <Space h="lg" />

          <Tabs defaultValue="lines">
            <Tabs.List>
              <Tabs.Tab value="lines" icon={<IconList size={14} />}>
                Sale Lines
              </Tabs.Tab>

              <Tabs.Tab value="payments" icon={<IconCash size={14} />}>
                Payments
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="lines" pt="lg">
              <Table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Count Per Quantity</th>
                    <th>Unit Price</th>
                    <th>Unit Tax</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {getSale.response?.saleLines &&
                    getSale.response.saleLines.map((sl) => {
                      return (
                        <tr>
                          <td>{sl.productName}</td>
                          <td>{sl.quantity}</td>
                          <td>{sl.countPerUnit}x</td>
                          <td>
                            {formatToCurrency(
                              sl.unitPrice,
                              getSale.response!.currency
                            )}
                          </td>
                          <td>
                            {formatToCurrency(
                              sl.unitTax,
                              getSale.response!.currency
                            )}
                          </td>
                          <td>
                            {formatToCurrency(
                              sl.total,
                              getSale.response!.currency
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tabs.Panel>

            <Tabs.Panel value="payments" pt="lg">
              <Table>
                <thead>
                  <tr>
                    <th>Reference</th>
                    <th>Payment Method</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {getSale.response?.payments &&
                    getSale.response.payments.map((p) => {
                      return (
                        <tr>
                          <td>
                            <Code>{p.salePayment.reference}</Code>
                          </td>
                          <td>
                            <PaymentMethodNameBadge
                              name={p.paymentMethod?.name || 'Cash'}
                            />
                          </td>
                          <td>
                            {formatToCurrency(
                              p.corePayment.amount,
                              getSale.response!.currency
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tabs.Panel>
          </Tabs>
        </Stack>
      </Container>
    </div>
  );
};
