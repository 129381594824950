import { PX1536, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Container,
  Flex,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
  DefaultOperations,
  Store,
  UserWithNameOnly,
} from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { useInputState } from '@mantine/hooks';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { closeModal, openModal } from '@mantine/modals';
import { UserModalSelectorBody } from '@components/V2/modal_selector/UserModalSelectorBody';
import Big from 'big.js';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { toBigOrNull } from '../../../utils/toBigOrNull';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { StatusBadge } from '@components/V2/StatusBadge';

export const ListCashierSessions = ({}: { path: string }) => {
  const [store, setStore] = useState<Store | null>(null);
  const [cashierUser, setCashierUser] = useState<UserWithNameOnly | null>(null);

  const showStoreSelectorModal = () => {
    openModal({
      modalId: 'select-store-modal',
      title: <Title order={3}>Select Store</Title>,
      children: (
        <StoreModalSelectorBody
          onStoreSelected={(s) => {
            setStore(s);
            closeModal('select-store-modal');
          }}
        />
      ),
    });
  };

  const showCashierUserSelectorModal = () => {
    openModal({
      modalId: 'set-cashier-user-modal',
      title: <Title order={3}>Select Cashier</Title>,
      children: (
        <UserModalSelectorBody
          onUserSelected={(u) => {
            setCashierUser(u);
            closeModal('set-cashier-user-modal');
          }}
        />
      ),
    });
  };

  return (
    <Container maw={PX1536.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Cashier Sessions</Title>

        <Group>
          <SelectorBadge
            placeholder="Store"
            onClick={showStoreSelectorModal}
            onClearClick={() => setStore(null)}
            value={store?.name}
          />
          <SelectorBadge
            placeholder="Cashier"
            onClick={showCashierUserSelectorModal}
            onClearClick={() => setCashierUser(null)}
            value={
              cashierUser
                ? cashierUser.firstName + ' ' + cashierUser.lastName
                : null
            }
          />
        </Group>

        <CashierSessionsTable storeID={store?.id} userID={cashierUser?.id} />
      </Stack>
    </Container>
  );
};

const CashierSessionsTable = ({
  storeID,
  userID,
}: {
  storeID?: string;
  userID?: string;
}) => {
  const listCashierWorkSessions = useStatefulAPIRequestMaker(
    DefaultOperations.listCashierWorkSessions
  );

  const { onChangeDeps, pagination } = useListSearcherHelper({
    debounceDelay: 200,
    limitPerPage: 30,
    additionalDeps: [storeID, userID],
  });

  useEffect(() => {
    listCashierWorkSessions.execute({
      storeId: storeID,
      cashierUserId: userID,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [...onChangeDeps]);

  return (
    <LoadingContainer loading={listCashierWorkSessions.loading}>
      <Stack>
        {listCashierWorkSessions.response?.count && (
          <Flex justify="flex-end">
            <Pagination
              size="sm"
              total={pagination.calculateTotalPages(
                listCashierWorkSessions.response?.count
              )}
              value={pagination.currentPaginationPage}
              onChange={pagination.onPaginationPageChange}
            />
          </Flex>
        )}
        <Table striped withBorder highlightOnHover>
          <thead>
            <tr>
              <th />
              <th>User</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th style={{ textAlign: 'center' }}>State</th>
              <th style={{ textAlign: 'right' }}>Cash Sales</th>
              <th style={{ textAlign: 'right' }}>Cash Difference</th>
              <th style={{ textAlign: 'right' }}>Non-Cash Sales</th>
            </tr>
          </thead>

          <tbody>
            {(listCashierWorkSessions.response?.cashierWorkSessions || []).map(
              (s) => {
                const startingBalance = new Big(
                  s.cashierWorkSession.startingBalance
                );
                const endingBalance = toBigOrNull(
                  s.cashierWorkSession.endingBalance
                );
                const reviewedEndingBalance = toBigOrNull(
                  s.cashierWorkSession.reviewedEndingBalance
                );

                let cashBalanceIsZero = null;
                let cashBalanceStr;
                if (endingBalance === null) {
                  cashBalanceStr = '';
                } else {
                  const cashBalanceBig = (
                    reviewedEndingBalance ?? endingBalance
                  )
                    .sub(startingBalance)
                    .sub(s.totalCashSales)
                    .add(s.totalSaleReturns);

                  cashBalanceStr = formatToCurrency(cashBalanceBig, s.currency);
                  cashBalanceIsZero = cashBalanceBig.eq(new Big(0));
                }

                return (
                  <ClickableTableRow
                    key={s.cashierWorkSession.id}
                    targetURL={`/stores/cashierSessions/${s.cashierWorkSession.id}`}
                  >
                    <td></td>
                    <td>
                      {s.cashierUser.firstName + ' ' + s.cashierUser.lastName}
                    </td>
                    <td>
                      {RFC3339ToEasyDateTime(s.cashierWorkSession.startedAt)}
                    </td>
                    <td>
                      {s.cashierWorkSession.endedAt
                        ? RFC3339ToEasyDateTime(s.cashierWorkSession.endedAt)
                        : null}{' '}
                    </td>
                    <td>
                      <StatusBadge
                        state={s.cashierWorkSession.state}
                        fullWidth
                      />
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(s.totalCashSales, s.currency)}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Text
                        color={
                          cashBalanceIsZero === true
                            ? 'green'
                            : cashBalanceIsZero === false
                            ? 'red'
                            : undefined
                        }
                        fw={700}
                      >
                        {cashBalanceStr}
                      </Text>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(s.totalNonCashSales, s.currency)}
                    </td>
                  </ClickableTableRow>
                );
              }
            )}
          </tbody>
        </Table>
        {listCashierWorkSessions.response?.count && (
          <Flex justify="flex-end">
            <Pagination
              size="sm"
              total={pagination.calculateTotalPages(
                listCashierWorkSessions.response?.count
              )}
              value={pagination.currentPaginationPage}
              onChange={pagination.onPaginationPageChange}
            />
          </Flex>
        )}
      </Stack>
    </LoadingContainer>
  );
};

const StoreModalSelectorBody = ({
  onStoreSelected,
}: {
  onStoreSelected: (s: Store) => void;
}) => {
  const listStores = useStatefulAPIRequestMaker(DefaultOperations.searchStores);

  const [target, setTarget] = useInputState('');

  useEffect(() => {
    const t = setTimeout(() => {
      listStores.execute({ target });
    }, 200);

    return () => {
      clearTimeout(t);
    };
  }, [target]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for Generic Code"
        value={target}
        onChange={setTarget}
      />

      <LoadingContainer loading={listStores.loading}>
        <Table highlightOnHover withBorder striped>
          <thead>
            <tr>
              <th>Store</th>
            </tr>
          </thead>
          <tbody>
            {(listStores.response?.stores || []).map((s) => {
              const onClick = async () => {
                onStoreSelected(s);
              };

              return (
                <ClickableTableRow onClick={onClick} key={s.id}>
                  <td>{s.name}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
