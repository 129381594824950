import Big from 'big.js';
import { Currency, Tax } from '../oas/codegen3';

export type Amounts = {
  unitPrice: Big;
  unitTax: Big;
  quantity: Big;
  preTaxTotal: Big;
  taxTotal: Big;
  total: Big;
};

export const calculateAmounts = ({
  unitPrice,
  taxIncludedInPrice,
  quantity,
  currency,
  taxes,
}: {
  unitPrice: Big | string | number;
  taxIncludedInPrice: boolean;
  quantity: Big | string | number;
  currency: Currency;
  taxes: Tax[];
}): Amounts => {
  const amounts: Amounts = {
    unitPrice: new Big(0),
    unitTax: new Big(0),
    quantity: new Big(0),
    preTaxTotal: new Big(0),
    taxTotal: new Big(0),
    total: new Big(0),
  };

  const unitPriceBig = new Big(unitPrice).round(
    Number(currency.decimalPlaces),
    Big.roundDown
  );
  const quantityBig = new Big(quantity);

  if (!taxIncludedInPrice) {
    const preTaxTotal = unitPriceBig.mul(quantityBig);
    let taxTotal = new Big(0);

    taxes.forEach((t) => {
      const percentDecimal = new Big(t.percent).mul(new Big(0.01));
      taxTotal = taxTotal.add(preTaxTotal.mul(percentDecimal));
    });

    amounts.unitPrice = unitPriceBig;
    amounts.unitTax = taxTotal.div(quantityBig);
    amounts.quantity = quantityBig;
    amounts.preTaxTotal = preTaxTotal;
    amounts.taxTotal = taxTotal.round(
      Number(currency.decimalPlaces),
      Big.roundHalfUp
    );
    amounts.total = amounts.preTaxTotal.add(amounts.taxTotal);
  } else {
    let totalPercent = new Big(0);

    taxes.forEach((t) => {
      const percentDecimal = new Big(t.percent).mul(new Big(0.01));
      totalPercent = totalPercent.add(percentDecimal);
    });

    totalPercent = totalPercent.add(new Big(1));
    let realUnitPrice = unitPriceBig.div(totalPercent);
    realUnitPrice = realUnitPrice.round(
      currency.decimalPlaces,
      Big.roundHalfUp
    );

    const unitTax = unitPriceBig.sub(realUnitPrice);

    amounts.unitPrice = realUnitPrice;
    amounts.unitTax = unitTax;
    amounts.quantity = quantityBig;
    amounts.preTaxTotal = realUnitPrice.mul(amounts.quantity);
    amounts.taxTotal = unitTax.mul(amounts.quantity);
    amounts.total = amounts.preTaxTotal.add(amounts.taxTotal);
  }

  return amounts;
};
