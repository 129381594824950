import { useAlert } from 'react-alert';
import { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { AlertMessage } from '../../../components/alert/Alert';
import { Header1 } from '../../../components/header/Header1';
import { Stack, Container, TextInput, Button, Flex } from '@mantine/core';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';

export const TaxForm = ({ taxID }: { path?: string; taxID?: string }) => {
  const alert = useAlert();
  const createTax = useStatefulAPIRequestMaker(DefaultOperations.createTax);
  const updateTax = useStatefulAPIRequestMaker(DefaultOperations.updateTax);
  const getTax = useStatefulAPIRequestMaker(DefaultOperations.getTax);

  const [h1Text, setH1Text] = useState(taxID ? '...' : 'New Tax');
  const navigate = useNavigate();

  const busyWithAPICalls =
    createTax.loading ||
    updateTax.loading ||
    getTax.loading ||
    h1Text === '...';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<{ name: string; percent: number }>({
    mode: 'onChange',
  });

  const fetchProduct = async () => {
    try {
      const product = await getTax.execute({ taxId: taxID! });
      setValue('name', product.name, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('percent', product.percent, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setH1Text(product.name);
    } catch (e) {}
  };

  useEffect(() => {
    if (taxID) {
      fetchProduct();
    }
  }, [taxID]);

  const onSaveOrUpdate = async ({
    name,
    percent,
  }: {
    name: string;
    percent: number;
  }) => {
    if (taxID) {
      const tax = await updateTax.execute({
        taxId: taxID,
        requestBody: {
          name,
          percent: Number(percent),
        },
      });

      setH1Text(tax.name);
      alert.show(<AlertMessage title="Tax Updated" type="positive" />);
    } else {
      const product = await createTax.execute({
        requestBody: {
          name,
          percent: Number(percent),
        },
      });
      alert.show(<AlertMessage title="Tax Created" type="positive" />);
      navigate(`/taxes/${product.id}`);
    }
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Header1>{h1Text}</Header1>
        <Flex>
          <Button
            color="green"
            onClick={handleSubmit(onSaveOrUpdate)}
            disabled={!isDirty || !isValid || busyWithAPICalls}
          >
            {taxID ? 'Update' : 'Create'}
          </Button>
        </Flex>
        <TextInput
          autoComplete="off"
          label="Tax Name"
          required={true}
          {...register('name', {
            required: true,
          })}
        />
        <TextInput
          autoComplete="off"
          label="Percent (15 = 15%)"
          required={true}
          {...register('percent', {
            required: true,
            pattern: /^[0-9]+(\.[0-9]+)?$/g,
          })}
        />
      </Stack>
    </Container>
  );
};
