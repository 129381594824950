/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkPurchaseOrderPayment } from '../models/BulkPurchaseOrderPayment';
import type { CashierSession } from '../models/CashierSession';
import type { Changelog } from '../models/Changelog';
import type { CorePayment } from '../models/CorePayment';
import type { Coupon } from '../models/Coupon';
import type { CouponGroup } from '../models/CouponGroup';
import type { CouponGroupState } from '../models/CouponGroupState';
import type { Currency } from '../models/Currency';
import type { ExtendedProductGenericCode } from '../models/ExtendedProductGenericCode';
import type { ForeignProduct } from '../models/ForeignProduct';
import type { ForeignProductSource } from '../models/ForeignProductSource';
import type { Member } from '../models/Member';
import type { MemberOneTimePointsRedemptionPin } from '../models/MemberOneTimePointsRedemptionPin';
import type { MemberUser } from '../models/MemberUser';
import type { PaymentMethod } from '../models/PaymentMethod';
import type { PaymentMethodTargetPaymentType } from '../models/PaymentMethodTargetPaymentType';
import type { PeriodOfTime } from '../models/PeriodOfTime';
import type { PermissionAction } from '../models/PermissionAction';
import type { PermissionResource } from '../models/PermissionResource';
import type { PosAppVersion } from '../models/PosAppVersion';
import type { PosAppVersionStatus } from '../models/PosAppVersionStatus';
import type { Privilege } from '../models/Privilege';
import type { Product } from '../models/Product';
import type { ProductBrands } from '../models/ProductBrands';
import type { ProductBrandWithMetadata } from '../models/ProductBrandWithMetadata';
import type { ProductClassCode } from '../models/ProductClassCode';
import type { ProductGenericCode } from '../models/ProductGenericCode';
import type { ProductPrice } from '../models/ProductPrice';
import type { ProductPriceMargin } from '../models/ProductPriceMargin';
import type { ProductPurchasingCost } from '../models/ProductPurchasingCost';
import type { ProductTag } from '../models/ProductTag';
import type { ProfitabilityRange } from '../models/ProfitabilityRange';
import type { PurchaseOrder } from '../models/PurchaseOrder';
import type { PurchaseOrderCredit } from '../models/PurchaseOrderCredit';
import type { PurchaseOrderDiscount } from '../models/PurchaseOrderDiscount';
import type { PurchaseOrderDiscountState } from '../models/PurchaseOrderDiscountState';
import type { PurchaseOrderLine } from '../models/PurchaseOrderLine';
import type { PurchaseOrderPayment } from '../models/PurchaseOrderPayment';
import type { PurchaseOrderPaymentTerm } from '../models/PurchaseOrderPaymentTerm';
import type { PurchaseOrderState } from '../models/PurchaseOrderState';
import type { PurchaseOrderToPurchaseOrderPaymentRel } from '../models/PurchaseOrderToPurchaseOrderPaymentRel';
import type { Sale } from '../models/Sale';
import type { SaleLine } from '../models/SaleLine';
import type { SalePayment } from '../models/SalePayment';
import type { SaleReturn } from '../models/SaleReturn';
import type { SaleReturnCode } from '../models/SaleReturnCode';
import type { SaleReturnCodeAuthorization } from '../models/SaleReturnCodeAuthorization';
import type { SaleReturnLine } from '../models/SaleReturnLine';
import type { SellingMinAndMax } from '../models/SellingMinAndMax';
import type { StockAdjustmentGroup } from '../models/StockAdjustmentGroup';
import type { StockAdjustmentGroupLine } from '../models/StockAdjustmentGroupLine';
import type { StockAdjustmentGroupState } from '../models/StockAdjustmentGroupState';
import type { StockBalance } from '../models/StockBalance';
import type { StockCheck } from '../models/StockCheck';
import type { StockLocation } from '../models/StockLocation';
import type { StockLocationRole } from '../models/StockLocationRole';
import type { StockLocationUserPrivilege } from '../models/StockLocationUserPrivilege';
import type { StockMoveGroup } from '../models/StockMoveGroup';
import type { StockMoveGroupLine } from '../models/StockMoveGroupLine';
import type { StockMoveGroupState } from '../models/StockMoveGroupState';
import type { Store } from '../models/Store';
import type { Tax } from '../models/Tax';
import type { Team } from '../models/Team';
import type { User } from '../models/User';
import type { UserType } from '../models/UserType';
import type { UserWithNameOnly } from '../models/UserWithNameOnly';
import type { Vendor } from '../models/Vendor';
import type { VendorContact } from '../models/VendorContact';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultOperations {
    /**
     * Authenticate
     * @returns any OK
     * @throws ApiError
     */
    public static postAuthAuthenticate({
        requestBody,
    }: {
        requestBody?: {
            email: string;
            password: string;
            temporaryPasswordReplacement?: string | null;
            /**
             * Defaults to 'cookie' when empty
             */
            authReturnLocation?: 'cookie' | 'body';
        },
    }): CancelablePromise<{
        sessionID?: string;
        sessionToken?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/authenticate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Get Product
     * @returns any OK
     * @throws ApiError
     */
    public static getProduct({
        productId,
        includeTargetMargin,
        requestBody,
    }: {
        productId: string,
        includeTargetMargin?: boolean,
        requestBody?: any,
    }): CancelablePromise<{
        product: Product;
        saleTaxes: Array<Tax>;
        purchasingTaxes: Array<Tax>;
        productTargetMargin?: string;
        primaryVendor?: Vendor;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}',
            path: {
                'productID': productId,
            },
            query: {
                'includeTargetMargin': includeTargetMargin,
            },
            body: requestBody,
        });
    }
    /**
     * Update Product
     * @returns any OK
     * @throws ApiError
     */
    public static updateProduct({
        productId,
        requestBody,
    }: {
        productId: string,
        requestBody?: {
            name: string;
            barcode: string | null;
            reference: string | null;
            isFreshProduct: boolean;
            genericCode: string | null;
            classCode: string | null;
            earnsPoints: boolean;
            primaryVendorID?: string;
            brandName?: string;
        },
    }): CancelablePromise<{
        product: Product;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/{productID}',
            path: {
                'productID': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Modify Product Sale Taxes
     * @returns void
     * @throws ApiError
     */
    public static addProductSalesTax({
        productId,
        requestBody,
    }: {
        productId: string,
        requestBody?: {
            taxID: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/{productID}/saleTaxes',
            path: {
                'productID': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Product Sales Tax
     * @returns void
     * @throws ApiError
     */
    public static deleteProductSalesTax({
        productId,
        taxId,
        requestBody,
    }: {
        productId: string,
        taxId: string,
        requestBody?: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/{productID}/saleTaxes/{taxID}',
            path: {
                'productID': productId,
                'taxID': taxId,
            },
            body: requestBody,
        });
    }
    /**
     * Delete Product Purchasing Tax
     * @returns void
     * @throws ApiError
     */
    public static deleteProductPurchasingTax({
        productId,
        taxId,
        requestBody,
    }: {
        productId: string,
        taxId: string,
        requestBody?: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/{productID}/purchasingTaxes/{taxID}',
            path: {
                'productID': productId,
                'taxID': taxId,
            },
            body: requestBody,
        });
    }
    /**
     * Create Product
     * @returns any OK
     * @throws ApiError
     */
    public static createProduct({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            barcode?: string | null;
            reference: string | null;
            isFreshProduct: boolean;
            genericCode: string | null;
            classCode: string | null;
            earnsPoints: boolean;
            primaryVendorID?: string;
            brandName?: string;
        },
    }): CancelablePromise<{
        product: Product;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * Search Products
     * @returns any OK
     * @throws ApiError
     */
    public static searchProducts({
        target,
        limit,
        signTaxes,
        signPrices,
        isFresh,
        enabled,
        genericCode,
        classCode,
        vendorId,
        primaryVendorId,
        offset,
        vendorContactId,
    }: {
        target?: string,
        /**
         * Sets to 30 if value not set or value greater than 30.
         */
        limit?: number,
        signTaxes?: boolean,
        signPrices?: boolean,
        isFresh?: boolean,
        /**
         * Setting to false shows disabled products.
         */
        enabled?: boolean,
        genericCode?: string,
        classCode?: string,
        /**
         * Use "primaryVendorID".
         */
        vendorId?: string,
        primaryVendorId?: string,
        offset?: number,
        vendorContactId?: string,
    }): CancelablePromise<Array<{
        product: Product;
        saleTaxes: Array<Tax>;
        prices: Array<{
            price: ProductPrice;
            priceSignature?: string;
            priceSignatureExpiresAtUnix?: number;
            currency: Currency;
        }>;
        /**
         * Signature for sales taxes.
         */
        taxSignature?: string;
        /**
         * Signature for sales taxes.
         */
        taxSignatureExpiresAtUnix?: number;
        purchasingTaxes: Array<Tax>;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products',
            query: {
                'target': target,
                'limit': limit,
                'signTaxes': signTaxes,
                'signPrices': signPrices,
                'isFresh': isFresh,
                'enabled': enabled,
                'genericCode': genericCode,
                'classCode': classCode,
                'vendorID': vendorId,
                'primaryVendorID': primaryVendorId,
                'offset': offset,
                'vendorContactID': vendorContactId,
            },
        });
    }
    /**
     * Modify Product Purchasing Taxes
     * @returns void
     * @throws ApiError
     */
    public static addProductPurchasingTax({
        productId,
        requestBody,
    }: {
        productId: string,
        requestBody?: {
            taxID: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/{productID}/purchasingTaxes',
            path: {
                'productID': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Members
     * @returns any OK
     * @throws ApiError
     */
    public static listMembers({
        target,
        offset,
        limit,
        requestBody,
    }: {
        target?: string,
        offset?: number,
        limit?: number,
        requestBody?: any,
    }): CancelablePromise<{
        members: Array<{
            member: Member;
            user: MemberUser;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/members',
            query: {
                'target': target,
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
        });
    }
    /**
     * Create Member
     * @returns void
     * @throws ApiError
     */
    public static postMembers({
        requestBody,
    }: {
        requestBody?: {
            phoneNumber: string;
            phoneNumberCountryCode: string;
            firstName: string;
            lastName: string;
            personalID: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/members',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Member
     * @returns any OK
     * @throws ApiError
     */
    public static getMembersMemberId({
        memberId,
    }: {
        memberId: string,
    }): CancelablePromise<{
        member: Member;
        user: {
            id: string;
            firstName: string;
            lastName: string;
            phoneNumber: string;
            phoneNumberCountryCode: string;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/members/{memberID}',
            path: {
                'memberID': memberId,
            },
        });
    }
    /**
     * Validate Member Points Redemption
     * Validates the pin for the given member and confirms if the member is authorized to redeem the given number of points.
     * @returns any Created
     * @throws ApiError
     */
    public static validateMemberPointsRedemption({
        memberId,
        requestBody,
    }: {
        memberId: string,
        requestBody?: {
            pin: string;
            pointsToRedeem: number;
            oneTimePointsRedemptionPinID?: string;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/members/{memberID}/redemptionValidation',
            path: {
                'memberID': memberId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid Data. View error message for failure reason.`,
            },
        });
    }
    /**
     * Get Current Cashier Work Session
     * Get the current user's work session.
     * @returns any OK
     * @throws ApiError
     */
    public static getCashierWorkSessionCurrent(): CancelablePromise<{
        cashierWorkSession: CashierSession;
        store: Store;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cashierWorkSession/current',
            errors: {
                401: `Unauthorized.`,
                404: `Cashier work session not found.`,
            },
        });
    }
    /**
     * Start Cashier Work Session
     * @returns any OK
     * @throws ApiError
     */
    public static postCashierWorkSessionStart({
        requestBody,
    }: {
        requestBody?: {
            storeID?: string;
            startingBalance?: string;
        },
    }): CancelablePromise<{
        cashierWorkSession: CashierSession;
        store: Store;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cashierWorkSession/start',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Get Product Price by Barcode
     * @returns any OK
     * @throws ApiError
     */
    public static getProductPriceBarcode({
        barcode,
        currency,
    }: {
        barcode: string,
        currency: string,
    }): CancelablePromise<{
        targetPrice: ProductPrice;
        product: Product;
        priceSignature: string;
        priceSignatureExpiresAtUnix: number;
        taxes: Array<Tax>;
        taxSignature: string;
        taxSignatureExpiresAtUnix: number;
        prices: Array<{
            price: ProductPrice;
            priceSignature: string;
            priceSignatureExpiresAtUnix: number;
            currency: Currency;
        }>;
        currency: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product/price/barcode',
            query: {
                'barcode': barcode,
                'currency': currency,
            },
            errors: {
                404: `Price with barcode not found.`,
            },
        });
    }
    /**
     * List Payment Methods
     * @returns any OK
     * @throws ApiError
     */
    public static getPaymentMethods({
        targetPaymentType,
        enabled,
    }: {
        targetPaymentType?: 'sale_payment' | 'purchase_order_payment',
        enabled?: boolean,
    }): CancelablePromise<{
        paymentMethods: Array<{
            paymentMethod: PaymentMethod;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/paymentMethods',
            query: {
                'targetPaymentType': targetPaymentType,
                'enabled': enabled,
            },
        });
    }
    /**
     * Create Payment Method
     * @returns any OK
     * @throws ApiError
     */
    public static createPaymentMethod({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            requiredFields: Array<string>;
            targetPaymentType: PaymentMethodTargetPaymentType;
        },
    }): CancelablePromise<{
        paymentMethod: PaymentMethod;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/paymentMethods',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create POS Sale
     * @returns any OK
     * @throws ApiError
     */
    public static postSalePos({
        requestBody,
    }: {
        requestBody?: {
            storeID: string;
            currency: string;
            totalAmount: string;
            memberID?: string;
            pointsSpent?: string | null;
            memberPin?: string;
            saleLines: Array<{
                productID: string;
                productName: string;
                productEnabled: string;
                quantity: string;
                taxes: Array<{
                    taxID: string;
                    taxName: string;
                    taxPercent: string;
                }>;
                taxSignatureExpiresAtUnix: string;
                taxSignature: string;
                priceID: string;
                unitPrice: string;
                countPerUnit: string;
                taxIncluded: string;
                priceSignatureExpiresAtUnix: string;
                priceSignature: string;
            }>;
            payments: Array<{
                amount: string;
                paymentMethodID: string;
                metadata: Record<string, any>;
            }>;
            /**
             * When true, the server will respond with 202 as quickly as possible and then process the request. This is to avoid the POS hanging until the request is processed.
             */
            async?: boolean;
            coupons: Array<{
                couponID: string;
                couponAmount: string;
            }>;
            memberOneTimePointsRedemptionPinID?: string;
        },
    }): CancelablePromise<{
        saleNumber: string;
        pointsEarned: string;
        memberEndingPointsBalance: string;
        posSaleLogID: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sales/pos',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Sale Return Authorization
     * @returns any OK
     * @throws ApiError
     */
    public static postSaleReturnCodesAuthorization({
        requestBody,
    }: {
        requestBody?: {
            code: string;
        },
    }): CancelablePromise<{
        saleReturnAuthorization: SaleReturnCodeAuthorization;
        saleReturnAuthorizationSignature: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/saleReturnCodes/authorization',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create & Post Sale Return
     * @returns any OK
     * @throws ApiError
     */
    public static postSaleReturns({
        requestBody,
    }: {
        requestBody?: {
            saleID: string;
            saleLineIDToQuantityToReturn: Record<string, any>;
            saleReturnAuthorization: SaleReturnCodeAuthorization;
            saleReturnAuthorizationSignature: string;
        },
    }): CancelablePromise<{
        saleReturn: SaleReturn;
        saleReturnLines: Array<SaleReturnLine>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/saleReturns',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Sale by ID
     * @returns any OK
     * @throws ApiError
     */
    public static getSaleById({
        saleId,
        requestBody,
    }: {
        saleId: string,
        requestBody?: any,
    }): CancelablePromise<{
        sale: Sale;
        cashier?: User;
        store?: Store;
        stockLocation?: StockLocation;
        saleLines: Array<SaleLine>;
        currency: Currency;
        payments: Array<{
            salePayment: SalePayment;
            corePayment: CorePayment;
            paymentMethod?: PaymentMethod;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sale/{saleID}',
            path: {
                'saleID': saleId,
            },
            body: requestBody,
        });
    }
    /**
     * Get Sale by Sale Number
     * @returns any OK
     * @throws ApiError
     */
    public static getSaleBySaleNumber({
        saleNumber,
        requestBody,
    }: {
        saleNumber: string,
        requestBody?: any,
    }): CancelablePromise<{
        sale: Sale;
        cashier?: User;
        store?: Store;
        stockLocation?: StockLocation;
        saleLines: Array<SaleLine>;
        currency: Currency;
        payments: Array<{
            salePayment: SalePayment;
            corePayment: CorePayment;
            paymentMethod?: PaymentMethod;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sale',
            query: {
                'saleNumber': saleNumber,
            },
            body: requestBody,
        });
    }
    /**
     * End Cashier Work Session
     * @returns any OK
     * @throws ApiError
     */
    public static endCashierWorkSession({
        requestBody,
    }: {
        requestBody?: {
            endingBalance: string;
        },
    }): CancelablePromise<{
        cashierWorkSession: CashierSession;
        store: Store;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cashierWorkSession/end',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get My User's Detail
     * @returns any OK
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<{
        userID: string;
        userType: UserType;
        userStores: Array<Store>;
        privileges: Array<Privilege>;
        userFirstName: string;
        userLastName: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me',
        });
    }
    /**
     * Get Points Currency Factor
     * @returns any OK
     * @throws ApiError
     */
    public static getPointsCurrencyFactor(): CancelablePromise<{
        currencyPointsFactors: Record<string, any>;
        earnRate: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/points/factors',
        });
    }
    /**
     * Create Vendor
     * @returns any OK
     * @throws ApiError
     */
    public static createVendor({
        requestBody,
    }: {
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        vendor: Vendor;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/vendor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Vendor
     * @returns any OK
     * @throws ApiError
     */
    public static updateVendor({
        requestBody,
    }: {
        requestBody?: {
            id?: string;
            name?: string;
        },
    }): CancelablePromise<{
        vendor: Vendor;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/vendor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Vendors
     * @returns any OK
     * @throws ApiError
     */
    public static searchVendors({
        target,
        limit,
        offset,
    }: {
        target: string,
        limit?: number,
        offset?: number,
    }): CancelablePromise<{
        vendors: Array<Vendor>;
        count: number;
        vendors2: Array<{
            vendor: Vendor;
            lastPurchaseOrderCollectedAt?: string;
            lastPurchaseOrderCollectedID?: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendors',
            query: {
                'target': target,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Get Vendor Contacts for Vendor
     * @returns any OK
     * @throws ApiError
     */
    public static getVendorContactsForVendor({
        vendorId,
    }: {
        vendorId: string,
    }): CancelablePromise<{
        vendorContacts: Array<VendorContact>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendor/contacts',
            query: {
                'vendorID': vendorId,
            },
        });
    }
    /**
     * Get Vendor Contact
     * @returns any OK
     * @throws ApiError
     */
    public static getVendorContact({
        vendorContactId,
    }: {
        vendorContactId: string,
    }): CancelablePromise<{
        vendorContact: VendorContact;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendor/contact',
            query: {
                'vendorContactID': vendorContactId,
            },
        });
    }
    /**
     * Create Vendor Contact
     * @returns any OK
     * @throws ApiError
     */
    public static createVendorContact({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            vendorID: string;
            phoneNumberWithCountryCode: string;
        },
    }): CancelablePromise<{
        vendorContact: VendorContact;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/vendor/contact',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create User
     * @returns any OK
     * @throws ApiError
     */
    public static createUser({
        requestBody,
    }: {
        requestBody?: {
            email: string;
            phoneNumber: string;
            firstName: string;
            lastName: string;
            userType: 'employee' | 'admin';
            /**
             * Do not include the "+" sign.
             */
            phoneCountryCode: string;
        },
    }): CancelablePromise<{
        userID: string;
        temporaryPassword?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get User by ID
     * @returns any OK
     * @throws ApiError
     */
    public static getUserById({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<{
        user: User;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
            query: {
                'userID': userId,
            },
        });
    }
    /**
     * List Users
     * @returns any OK
     * @throws ApiError
     */
    public static listUsers({
        name,
        limit,
        offset,
        userEnabled,
    }: {
        name: string,
        limit?: number,
        offset?: number,
        userEnabled?: boolean,
    }): CancelablePromise<{
        users: Array<User>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
            query: {
                'name': name,
                'limit': limit,
                'offset': offset,
                'userEnabled': userEnabled,
            },
        });
    }
    /**
     * List All Permissions
     * @returns any OK
     * @throws ApiError
     */
    public static listAllPermissions(): CancelablePromise<{
        permissions: Array<{
            categoryName: string;
            description: string;
            permissions: Array<{
                resource: PermissionResource;
                actions: Array<{
                    action: PermissionAction;
                    description: string;
                    storePermission: boolean;
                    systemPermission: boolean;
                }>;
            }>;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/permissions',
        });
    }
    /**
     * Update User Privilege
     * @returns any OK
     * @throws ApiError
     */
    public static updateUserPrivilege({
        userId,
        requestBody,
    }: {
        userId: string,
        requestBody?: {
            action: PermissionAction;
            resource: PermissionResource;
            /**
             * When true, the permission will be removed. When false, then the permission will be added.
             */
            remove: boolean;
            storeID?: string;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/{userID}/privilege',
            path: {
                'userID': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update User
     * @returns any OK
     * @throws ApiError
     */
    public static updateUser({
        userId,
        requestBody,
    }: {
        userId: string,
        requestBody?: {
            email: string;
            phoneNumber: string;
            phoneNumberCountryCode: string;
            firstName: string;
            lastName: string;
            userType: UserType;
        },
    }): CancelablePromise<{
        userID: string;
        temporaryPassword: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{userID}',
            path: {
                'userID': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Search Stores
     * @returns any OK
     * @throws ApiError
     */
    public static searchStores({
        target,
    }: {
        target: string,
    }): CancelablePromise<{
        stores: Array<Store>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stores',
            query: {
                'target': target,
            },
        });
    }
    /**
     * Create Tax
     * @returns Tax OK
     * @throws ApiError
     */
    public static createTax({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            percent: number;
        },
    }): CancelablePromise<Tax> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tax',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Tax
     * @returns Tax OK
     * @throws ApiError
     */
    public static updateTax({
        taxId,
        requestBody,
    }: {
        taxId: string,
        requestBody?: {
            name: string;
            percent: number;
        },
    }): CancelablePromise<Tax> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tax',
            query: {
                'taxID': taxId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Tax
     * @returns Tax OK
     * @throws ApiError
     */
    public static getTax({
        taxId,
    }: {
        taxId: string,
    }): CancelablePromise<Tax> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tax',
            query: {
                'taxID': taxId,
            },
        });
    }
    /**
     * Search Taxes
     * @returns Tax OK
     * @throws ApiError
     */
    public static searchTaxes({
        target,
    }: {
        target: string,
    }): CancelablePromise<Array<Tax>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/taxes',
            query: {
                'target': target,
            },
        });
    }
    /**
     * Get Store
     * @returns any OK
     * @throws ApiError
     */
    public static getStoreById({
        storeId,
        requestBody,
    }: {
        storeId: string,
        requestBody?: any,
    }): CancelablePromise<{
        store: Store;
        sellingStockLocation?: StockLocation;
        stockLocations: Array<StockLocation>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/store',
            query: {
                'storeID': storeId,
            },
            body: requestBody,
        });
    }
    /**
     * Create Store
     * @returns any OK
     * @throws ApiError
     */
    public static createStore({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            currency: string;
            phoneNumber: string;
            companyRegistrationNumber?: string;
        },
    }): CancelablePromise<{
        store: Store;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/store',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Store
     * @returns any OK
     * @throws ApiError
     */
    public static updateStore({
        storeId,
        requestBody,
    }: {
        storeId: string,
        requestBody?: {
            name: string;
            phoneNumber: string;
            companyRegistrationNumber?: string;
        },
    }): CancelablePromise<{
        store: Store;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/store',
            query: {
                'storeID': storeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set Store Stock Location
     * @returns any OK
     * @throws ApiError
     */
    public static setStoreStockLocation({
        requestBody,
    }: {
        requestBody?: {
            storeID: string;
            stockLocationID: string;
            remove: boolean;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/store/stockLocations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Store Stock Locations
     * @returns any OK
     * @throws ApiError
     */
    public static getStoreStockLocations({
        storeId,
    }: {
        storeId?: string,
    }): CancelablePromise<{
        stockLocations: Array<StockLocation>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/store/stockLocations',
            query: {
                'storeID': storeId,
            },
        });
    }
    /**
     * Set Store Selling Stock Location
     * @returns any OK
     * @throws ApiError
     */
    public static setStoreSellingStockLocation({
        requestBody,
    }: {
        requestBody?: {
            storeID: string;
            stockLocationID: string;
            remove: boolean;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/store/stockLocations/sellingLocation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Search Stock Locations
     * @returns any OK
     * @throws ApiError
     */
    public static searchStockLocations({
        target,
        hasNoStore,
        storeId,
    }: {
        target: string,
        /**
         * When true, return stock locations that are not assigned to a store.
         */
        hasNoStore?: boolean,
        /**
         * When set, filters stock locations that are assigned this storeID.
         */
        storeId?: string,
    }): CancelablePromise<{
        stockLocations?: Array<{
            stockLocation: StockLocation;
            store?: Store;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stock/locations',
            query: {
                'target': target,
                'hasNoStore': hasNoStore,
                'storeID': storeId,
            },
        });
    }
    /**
     * Get Stock Location
     * @returns any OK
     * @throws ApiError
     */
    public static getStockLocation({
        stockLocationId,
    }: {
        stockLocationId: string,
    }): CancelablePromise<{
        stockLocation: StockLocation;
        store?: Store;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stock/location',
            query: {
                'stockLocationID': stockLocationId,
            },
        });
    }
    /**
     * List Stock Move Groups
     * @returns any OK
     * @throws ApiError
     */
    public static listStockMoveGroups({
        stockLocationId,
        limit,
        offset,
        states,
        target,
    }: {
        stockLocationId?: string,
        limit?: number,
        offset?: number,
        /**
         * Comma separated states to include in result.
         */
        states?: any[],
        target?: string,
    }): CancelablePromise<{
        stockMoveGroups: Array<{
            stockMoveGroup: StockMoveGroup;
            sourceLocation: StockLocation;
            destinationLocation: StockLocation;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockMoveGroups',
            query: {
                'stockLocationID': stockLocationId,
                'limit': limit,
                'offset': offset,
                'states': states,
                'target': target,
            },
        });
    }
    /**
     * Create Stock Move Group
     * @returns any OK
     * @throws ApiError
     */
    public static createStockMoveGroup({
        requestBody,
    }: {
        requestBody?: {
            sourceLocationID: string;
            destinationLocationID: string;
        },
    }): CancelablePromise<{
        stockMoveGroup: StockMoveGroup;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stockMoveGroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Stock Move Group by ID
     * @returns any OK
     * @throws ApiError
     */
    public static getStockMoveGroupById({
        stockMoveGroupId,
        requestBody,
    }: {
        stockMoveGroupId: string,
        requestBody?: any,
    }): CancelablePromise<{
        stockMoveGroup: StockMoveGroup;
        stockMoveGroupLines: Array<{
            stockMoveGroupLine: StockMoveGroupLine;
            product: Product;
        }>;
        sourceLocation: StockLocation;
        destinationLocation: StockLocation;
        sourceStore: Store;
        destinationStore: Store;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockMoveGroups/{stockMoveGroupID}',
            path: {
                'stockMoveGroupID': stockMoveGroupId,
            },
            body: requestBody,
        });
    }
    /**
     * Update Stock Move Group
     * @returns any OK
     * @throws ApiError
     */
    public static updateStockMoveGroup({
        stockMoveGroupId,
        requestBody,
    }: {
        stockMoveGroupId: string,
        requestBody?: {
            sourceLocationID: string;
            destinationLocationID: string;
        },
    }): CancelablePromise<{
        stockMoveGroup: StockMoveGroup;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/stockMoveGroups/{stockMoveGroupID}',
            path: {
                'stockMoveGroupID': stockMoveGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Stock Move Group State
     * @returns void
     * @throws ApiError
     */
    public static updateStockMoveGroupState({
        stockMoveGroupId,
        requestBody,
    }: {
        stockMoveGroupId: string,
        requestBody?: {
            state: StockMoveGroupState;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/stockMoveGroups/{stockMoveGroupID}/state',
            path: {
                'stockMoveGroupID': stockMoveGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Stock Move Group Line
     * @returns void
     * @throws ApiError
     */
    public static deleteStockMoveGroupLine({
        stockMoveGroupLineId,
    }: {
        stockMoveGroupLineId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/stockMoveGroupLines/{stockMoveGroupLineID}',
            path: {
                'stockMoveGroupLineID': stockMoveGroupLineId,
            },
        });
    }
    /**
     * Update Stock Move Group Line
     * @returns any OK
     * @throws ApiError
     */
    public static updateStockMoveGroupLine({
        stockMoveGroupLineId,
        requestBody,
    }: {
        stockMoveGroupLineId: string,
        requestBody?: {
            productID: string;
            countPerQuantity: string;
            quantity: string;
        },
    }): CancelablePromise<{
        stockMoveGroupLine: StockMoveGroupLine;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/stockMoveGroupLines/{stockMoveGroupLineID}',
            path: {
                'stockMoveGroupLineID': stockMoveGroupLineId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Stock Move Group Line
     * @returns any OK
     * @throws ApiError
     */
    public static createStockMoveGroupLine({
        requestBody,
    }: {
        requestBody?: {
            stockMoveGroupID: string;
            productID: string;
            countPerQuantity: string;
            quantity: string;
        },
    }): CancelablePromise<{
        stockMoveGroupLine: StockMoveGroupLine;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stockMoveGroupLines',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Product Stock Balance
     * @returns any OK
     * @throws ApiError
     */
    public static getProductStockBalance({
        productId,
        stockLocationId,
    }: {
        productId: string,
        stockLocationId: string,
    }): CancelablePromise<{
        stockBalance?: StockBalance;
        stockLocation?: StockLocation;
        store?: Store;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockBalance',
            query: {
                'productID': productId,
                'stockLocationID': stockLocationId,
            },
        });
    }
    /**
     * List Stock Checks
     * @returns any OK
     * @throws ApiError
     */
    public static listStockChecks({
        stockLocationId,
        storeId,
        productId,
        limit,
        offset,
        status,
    }: {
        stockLocationId: string,
        storeId?: string,
        productId?: string,
        limit?: number,
        offset?: number,
        status?: any[],
    }): CancelablePromise<{
        stockChecks: Array<{
            stockCheck: StockCheck;
            stockLocation: StockLocation;
            store: Store;
            creatingUser: User;
            product: Product;
        }>;
        totalCount: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockChecks',
            query: {
                'storeID': storeId,
                'stockLocationID': stockLocationId,
                'productID': productId,
                'limit': limit,
                'offset': offset,
                'status': status,
            },
        });
    }
    /**
     * Create Stock Check
     * @returns any OK
     * @throws ApiError
     */
    public static createStockCheck({
        requestBody,
    }: {
        requestBody?: {
            stockLocationID: string;
            productID: string;
            stockBalanceChange: string;
            postImmediately?: boolean;
            comments: string;
        },
    }): CancelablePromise<{
        stockCheck: StockCheck;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stockCheck',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Stock Location
     * @returns any OK
     * @throws ApiError
     */
    public static updateStockLocation({
        stockLocationId,
        requestBody,
    }: {
        stockLocationId: string,
        requestBody?: {
            name: string;
            currency: string;
        },
    }): CancelablePromise<{
        stockLocation: StockLocation;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/stockLocations/{stockLocationID}',
            path: {
                'stockLocationID': stockLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Stock Location
     * @returns any OK
     * @throws ApiError
     */
    public static createStockLocation({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            currency: string;
        },
    }): CancelablePromise<{
        stockLocation: StockLocation;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stockLocations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Post Stock Check
     * @returns any OK
     * @throws ApiError
     */
    public static postStockCheck({
        requestBody,
    }: {
        requestBody?: {
            stockCheckID: string;
        },
    }): CancelablePromise<{
        stockCheck: StockCheck;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/stockCheck/post',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cancel Stock Check
     * @returns any OK
     * @throws ApiError
     */
    public static cancelStockCheck({
        requestBody,
    }: {
        requestBody?: {
            stockCheckID: string;
        },
    }): CancelablePromise<{
        stockCheck: StockCheck;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/stockCheck/cancelled',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Stock Balances
     * @returns any OK
     * @throws ApiError
     */
    public static getStockBalances({
        stockLocationId,
        productName,
        target,
        limit,
        offset,
    }: {
        stockLocationId: string,
        /**
         * Use "target" instead.
         * @deprecated
         */
        productName?: string,
        target?: string,
        limit?: number,
        offset?: number,
    }): CancelablePromise<{
        stockBalances: Array<{
            product: Product;
            stockBalance: StockBalance;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockBalances',
            query: {
                'stockLocationID': stockLocationId,
                'productName': productName,
                'target': target,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Get Stock Balance History
     * @returns any OK
     * @throws ApiError
     */
    public static getStockBalanceHistory({
        productId,
        currency,
        stockLocationId,
    }: {
        productId: string,
        currency: string,
        stockLocationId?: string,
    }): CancelablePromise<{
        dailyStockBalance: Array<{
            date: string;
            endingBalance: string;
            changeInBalanceFromPreviousDate: string;
        }>;
        weeklyStockBalance: Array<{
            date: string;
            endingBalance: string;
            changeInBalanceFromPreviousDate: string;
        }>;
        monthlyStockBalance: Array<{
            date: string;
            endingBalance: string;
            changeInBalanceFromPreviousDate: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockBalance/history',
            query: {
                'productID': productId,
                'stockLocationID': stockLocationId,
                'currency': currency,
            },
        });
    }
    /**
     * List Sales
     * @returns any OK
     * @throws ApiError
     */
    public static listSales({
        limit,
        offset,
        mySales,
        target,
        userId,
        storeId,
        hasReturn,
        memberId,
    }: {
        limit?: number,
        offset?: number,
        /**
         * When true, list sales will only return sales created by the user.
         */
        mySales?: boolean,
        target?: string,
        userId?: string,
        storeId?: string,
        hasReturn?: boolean,
        memberId?: string,
    }): CancelablePromise<{
        sales: Array<{
            sale: Sale;
            cashier?: User;
            store?: Store;
            currency: Currency;
            saleLines: Array<{
                saleLine: SaleLine;
            }>;
            member?: Member;
            memberUser?: MemberUser;
            salePayments: Array<{
                salePayment: SalePayment;
                corePayment: CorePayment;
                paymentMethod: PaymentMethod;
            }>;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sales',
            query: {
                'limit': limit,
                'offset': offset,
                'mySales': mySales,
                'target': target,
                'userID': userId,
                'storeID': storeId,
                'hasReturn': hasReturn,
                'memberID': memberId,
            },
        });
    }
    /**
     * Get Sale Return Code
     * @returns any OK
     * @throws ApiError
     */
    public static getSaleReturnCode({
        storeId,
    }: {
        storeId: string,
    }): CancelablePromise<{
        saleReturnCode: SaleReturnCode;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/saleReturnCodes/{storeID}',
            path: {
                'storeID': storeId,
            },
        });
    }
    /**
     * Generate Sale Return Code
     * @returns any OK
     * @throws ApiError
     */
    public static generateSaleReturnCode({
        storeId,
    }: {
        storeId: string,
    }): CancelablePromise<{
        saleReturnCode: SaleReturnCode;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/saleReturnCodes/{storeID}',
            path: {
                'storeID': storeId,
            },
        });
    }
    /**
     * Get Purchase Order
     * @returns any OK
     * @throws ApiError
     */
    public static getPurchaseOrder({
        purchaseOrderId,
        includeProfitability,
        includeProductMonthlySales,
        includeEstimatedDaysToSell,
        includeStockBalance,
    }: {
        purchaseOrderId: string,
        includeProfitability?: boolean,
        includeProductMonthlySales?: boolean,
        includeEstimatedDaysToSell?: boolean,
        includeStockBalance?: boolean,
    }): CancelablePromise<{
        purchaseOrder: PurchaseOrder;
        vendor: Vendor;
        vendorContact: VendorContact;
        store?: Store;
        payments: Array<{
            corePayment: CorePayment;
            purchaseOrderPayment: PurchaseOrderPayment;
            purchaseOrderToPurchaseOrderPaymentRel: PurchaseOrderToPurchaseOrderPaymentRel;
        }>;
        purchaseOrderLines: Array<{
            purchaseOrderLine: PurchaseOrderLine;
            product: Product;
            taxes: Array<Tax>;
            currentProfitabilityRange?: ProfitabilityRange;
            productSaleStatistics?: {
                monthlyPCsSold: Array<{
                    monthDate: string;
                    pcsSold: string;
                    productID: string;
                }>;
            };
            estimatedDaysToSell?: string;
            stockBalances?: {
                stockLocationStockBalance?: StockBalance;
                storeStockBalances: Array<{
                    stockBalance: StockBalance;
                    stockLocation: StockLocation;
                }>;
            };
            sellingMinAndMax?: SellingMinAndMax;
        }>;
        stockLocation: StockLocation;
        currency: Currency;
        creditedPurchaseOrderCredits: Array<{
            purchaseOrderCredit: PurchaseOrderCredit;
            creditingPurchaseOrder: PurchaseOrder;
        }>;
        creditingPurchaseOrderCredits: Array<{
            purchaseOrderCredit: PurchaseOrderCredit;
            creditedPurchaseOrder: PurchaseOrder;
        }>;
        purchaseOrderDiscounts: Array<{
            purchaseOrderDiscount: PurchaseOrderDiscount;
        }>;
        approvingUser?: User;
        collectingUser?: UserWithNameOnly;
        creatingUser: UserWithNameOnly;
        postingUser?: UserWithNameOnly;
        currentProfitability?: ProfitabilityRange;
        lastRejectedByUser?: UserWithNameOnly;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrder/{purchaseOrderID}',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            query: {
                'includeProfitability': includeProfitability,
                'includeProductMonthlySales': includeProductMonthlySales,
                'includeEstimatedDaysToSell': includeEstimatedDaysToSell,
                'includeStockBalance': includeStockBalance,
            },
        });
    }
    /**
     * Get Purchase Order PDF
     * @returns any OK
     * @throws ApiError
     */
    public static getPurchaseOrderPdf({
        purchaseOrderId,
    }: {
        purchaseOrderId: string,
    }): CancelablePromise<{
        pdfBase64: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrder/pdf',
            query: {
                'purchaseOrderID': purchaseOrderId,
            },
        });
    }
    /**
     * Update Purchase Order State
     * @returns any OK
     * @throws ApiError
     */
    public static updatePurchaseOrderState({
        purchaseOrderId,
        requestBody,
    }: {
        purchaseOrderId: string,
        requestBody?: {
            state: PurchaseOrderState;
            /**
             * Required when state is "collected".
             */
            purchaseOrderLineToCollectedQuantity?: Record<string, any>;
            purchaseOrderLineToCollectionConfirmationMethod?: Record<string, any>;
        },
    }): CancelablePromise<{
        purchaseOrder: PurchaseOrder;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchaseOrders/{purchaseOrderID}/state',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Draft Purchase Order
     * @returns any OK
     * @throws ApiError
     */
    public static draftPurchaseOrder({
        requestBody,
    }: {
        requestBody?: {
            currency: string;
            vendorID: string;
            vendorContactID: string;
            additionalCosts: string;
            storeID: string;
            stockLocationID: string;
            isFreshOrder: boolean;
            paymentTermPeriodOfTime?: PeriodOfTime;
            paymentTermPeriodCount?: number;
            paymentTerms?: PurchaseOrderPaymentTerm;
        },
    }): CancelablePromise<{
        purchaseOrder: PurchaseOrder;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchaseOrder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Purchase Order Line
     * @returns void
     * @throws ApiError
     */
    public static deletePurchaseOrderLine({
        purchaseOrderLineId,
    }: {
        purchaseOrderLineId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/purchaseOrderLines/{purchaseOrderLineID}',
            path: {
                'purchaseOrderLineID': purchaseOrderLineId,
            },
        });
    }
    /**
     * Get Purchase Order Line
     * @returns any OK
     * @throws ApiError
     */
    public static getPurchaseOrderLine({
        purchaseOrderLineId,
    }: {
        purchaseOrderLineId: string,
    }): CancelablePromise<{
        purchaseOrderLine: PurchaseOrderLine;
        product: {
            product: Product;
            purchasingTaxes: Array<Tax>;
        };
        currency: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrderLines/{purchaseOrderLineID}',
            path: {
                'purchaseOrderLineID': purchaseOrderLineId,
            },
        });
    }
    /**
     * Update Purchase Order Line
     * @returns any OK
     * @throws ApiError
     */
    public static updatePurchaseOrderLine({
        purchaseOrderLineId,
        requestBody,
    }: {
        purchaseOrderLineId: string,
        requestBody?: {
            productID: string;
            countPerQuantity: string;
            quantity: string;
            unitPrice: string;
            taxIncluded: boolean;
        },
    }): CancelablePromise<{
        purchaseOrderLine: PurchaseOrderLine;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/purchaseOrderLines/{purchaseOrderLineID}',
            path: {
                'purchaseOrderLineID': purchaseOrderLineId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Recent Purchase Order Lines
     * @returns any OK
     * @throws ApiError
     */
    public static listRecentPurchaseOrderLines({
        productId,
        currency,
        requestBody,
    }: {
        productId: string,
        currency: string,
        requestBody?: any,
    }): CancelablePromise<{
        purchaseOrderLines: Array<{
            purchaseOrderLine: PurchaseOrderLine;
            product: Product;
            productPurchasingTaxes: Array<Tax>;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrderLines/recent',
            query: {
                'productID': productId,
                'currency': currency,
            },
            body: requestBody,
        });
    }
    /**
     * Create Purchase Order Line
     * @returns any OK
     * @throws ApiError
     */
    public static createPurchaseOrderLine({
        requestBody,
    }: {
        requestBody?: {
            purchaseOrderID: string;
            productID: string;
            countPerQuantity: string;
            quantity: string;
            unitPrice: string;
            taxIncluded: boolean;
        },
    }): CancelablePromise<{
        purchaseOrderLine: PurchaseOrderLine;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchaseOrderLines',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Purchase Order Payment
     * @returns void
     * @throws ApiError
     */
    public static updatePurchaseOrderPayment({
        purchaseOrderPaymentId,
        requestBody,
    }: {
        purchaseOrderPaymentId: string,
        requestBody?: {
            amount: string;
            metadata: Record<string, any>;
            paymentMethodID?: string;
            vendorID: string;
            voucherReference?: string;
            paymentDate?: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/purchaseOrderPayments/{purchaseOrderPaymentID}',
            path: {
                'purchaseOrderPaymentID': purchaseOrderPaymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Purchase Order Payment
     * @returns any OK
     * @throws ApiError
     */
    public static getPurchaseOrderPayment({
        purchaseOrderPaymentId,
    }: {
        purchaseOrderPaymentId: string,
    }): CancelablePromise<{
        purchaseOrderPayment: PurchaseOrderPayment;
        corePayment: CorePayment;
        vendor?: Vendor;
        paymentMethod: PaymentMethod;
        currency: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrderPayments/{purchaseOrderPaymentID}',
            path: {
                'purchaseOrderPaymentID': purchaseOrderPaymentId,
            },
        });
    }
    /**
     * List Purchase Orders
     * @returns any OK
     * @throws ApiError
     */
    public static listPurchaseOrders({
        number,
        vendorId,
        limit,
        offset,
        storeId,
        state,
        isCreditNote,
        hasUnusedCreditAmount,
        target,
        paymentTerms,
        hasBalanceRemaining,
        startDate,
        endDate,
        totalAmount,
        balanceAmount,
        includeProfitability,
        includeEstimatedDaysToSell,
        requestBody,
    }: {
        /**
         * Use "target" instead.
         * @deprecated
         */
        number?: string,
        vendorId?: string,
        limit?: number,
        offset?: number,
        storeId?: string,
        state?: string,
        isCreditNote?: boolean,
        hasUnusedCreditAmount?: boolean,
        target?: string,
        /**
         * Matching PurchaseOrderPaymentTerm
         */
        paymentTerms?: string,
        hasBalanceRemaining?: boolean,
        startDate?: string,
        endDate?: string,
        /**
         * Numeric comparator format
         */
        totalAmount?: string,
        /**
         * Numeric comparator format
         */
        balanceAmount?: string,
        includeProfitability?: boolean,
        includeEstimatedDaysToSell?: boolean,
        requestBody?: any,
    }): CancelablePromise<{
        purchaseOrders: Array<{
            purchaseOrder: PurchaseOrder;
            vendor: Vendor;
            vendorContact: VendorContact;
            store: Store;
            currency: Currency;
            /**
             * Requires profitability analytics permission.
             */
            currentProfitability?: ProfitabilityRange;
            estimatedDaysToSellRange?: {
                minDaysToSell: string;
                maxDaysToSell: string;
            };
        }>;
        purchaseOrdersTotalCount: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrders',
            query: {
                'number': number,
                'vendorID': vendorId,
                'limit': limit,
                'offset': offset,
                'storeID': storeId,
                'state': state,
                'isCreditNote': isCreditNote,
                'hasUnusedCreditAmount': hasUnusedCreditAmount,
                'target': target,
                'paymentTerms': paymentTerms,
                'hasBalanceRemaining': hasBalanceRemaining,
                'startDate': startDate,
                'endDate': endDate,
                'totalAmount': totalAmount,
                'balanceAmount': balanceAmount,
                'includeProfitability': includeProfitability,
                'includeEstimatedDaysToSell': includeEstimatedDaysToSell,
            },
            body: requestBody,
        });
    }
    /**
     * Set Product Enabled
     * @returns any OK
     * @throws ApiError
     */
    public static setProductEnabled({
        productId,
        requestBody,
    }: {
        productId: string,
        requestBody?: {
            enabled: boolean;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/product/enabled',
            query: {
                'productID': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Product Prices
     * @returns any OK
     * @throws ApiError
     */
    public static getProductPrices({
        productId,
    }: {
        productId: string,
    }): CancelablePromise<{
        prices: Array<{
            price: ProductPrice;
            currency: Currency;
        }>;
        saleTaxes: Array<Tax>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product/prices',
            query: {
                'productID': productId,
            },
        });
    }
    /**
     * Create Product Price
     * @returns ProductPrice OK
     * @throws ApiError
     */
    public static createProductPrice({
        requestBody,
    }: {
        requestBody?: {
            productID: string;
            currency: string;
            unitPrice: string;
            countPerUnit: number;
            barcode: string;
            taxIncluded: boolean;
        },
    }): CancelablePromise<ProductPrice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/product/price',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Product Price
     * @returns ProductPrice OK
     * @throws ApiError
     */
    public static updateProductPrice({
        productPriceId,
        requestBody,
    }: {
        productPriceId?: string,
        requestBody?: {
            unitPrice: string;
            countPerUnit: string;
            barcode: string;
            taxIncluded: boolean;
        },
    }): CancelablePromise<ProductPrice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/product/price',
            query: {
                'productPriceID': productPriceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Product Price
     * @returns any OK
     * @throws ApiError
     */
    public static deleteProductPrice({
        productPriceId,
    }: {
        productPriceId?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/product/price',
            query: {
                'productPriceID': productPriceId,
            },
        });
    }
    /**
     * Get Payment Method
     * @returns any OK
     * @throws ApiError
     */
    public static getPaymentMethod({
        paymentMethodId,
    }: {
        paymentMethodId: string,
    }): CancelablePromise<{
        paymentMethod: PaymentMethod;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/paymentMethods/{paymentMethodID}',
            path: {
                'paymentMethodID': paymentMethodId,
            },
        });
    }
    /**
     * Update Payment Method
     * @returns any OK
     * @throws ApiError
     */
    public static updatePaymentMethod({
        paymentMethodId,
        requestBody,
    }: {
        paymentMethodId: string,
        requestBody?: {
            name: string;
            requiredFields: Array<string>;
            targetPaymentType: PaymentMethodTargetPaymentType;
        },
    }): CancelablePromise<{
        paymentMethod: PaymentMethod;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/paymentMethods/{paymentMethodID}',
            path: {
                'paymentMethodID': paymentMethodId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set Payment Method Enabled
     * @returns any OK
     * @throws ApiError
     */
    public static setPaymentMethodEnabled({
        paymentMethodId,
        requestBody,
    }: {
        paymentMethodId: string,
        requestBody?: {
            enabled: boolean;
        },
    }): CancelablePromise<{
        paymentMethod: PaymentMethod;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/paymentMethods/{paymentMethodID}/enabled',
            path: {
                'paymentMethodID': paymentMethodId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create POS App Version
     * Creates a new pos app version. The status will be set to hidden.
     * @returns any OK
     * @throws ApiError
     */
    public static createPosAppVersion({
        requestBody,
    }: {
        requestBody?: {
            version: string;
            appFileName: string;
        },
    }): CancelablePromise<{
        posAppVersion: PosAppVersion;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/posAppVersions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List POS App Versions
     * @returns any OK
     * @throws ApiError
     */
    public static listPosAppVersions({
        includedStatuses,
    }: {
        /**
         * Comma separated statuses to return.
         */
        includedStatuses?: string,
    }): CancelablePromise<{
        posAppVersions: Array<PosAppVersion>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/posAppVersions',
            query: {
                'includedStatuses': includedStatuses,
            },
        });
    }
    /**
     * Get Latest POS App Version
     * Get the latest POS app version. "dev" app versions are not included unless you set the query param `includeDev` to true. Hidden, end of life, and deprecated are also not included.
     * @returns any OK
     * @throws ApiError
     */
    public static getLatestPosAppVersion({
        includeDev,
        currentVersion,
    }: {
        /**
         * Default is false
         */
        includeDev?: boolean,
        currentVersion?: string,
    }): CancelablePromise<{
        posAppVersion: PosAppVersion;
        currentVersion?: PosAppVersion;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/posAppVersions/latest',
            query: {
                'includeDev': includeDev,
                'currentVersion': currentVersion,
            },
        });
    }
    /**
     * Get POS App Version
     * @returns any OK
     * @throws ApiError
     */
    public static getPosAppVersion({
        posAppVersionId,
    }: {
        posAppVersionId: string,
    }): CancelablePromise<{
        posAppVersion: PosAppVersion;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/posAppVersions/{posAppVersionID}',
            path: {
                'posAppVersionID': posAppVersionId,
            },
        });
    }
    /**
     * Update POS App Version
     * @returns any OK
     * @throws ApiError
     */
    public static updatePosAppVersion({
        posAppVersionId,
        requestBody,
    }: {
        posAppVersionId: string,
        requestBody?: {
            status: PosAppVersionStatus;
            version: string;
            appFileName: string;
        },
    }): CancelablePromise<{
        posAppVersion: PosAppVersion;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/posAppVersions/{posAppVersionID}',
            path: {
                'posAppVersionID': posAppVersionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get POS App Version App File
     * Returns the URL from which to download the pos app file.
     * @returns any OK
     * @throws ApiError
     */
    public static getPosAppVersionFile({
        posAppVersionId,
    }: {
        posAppVersionId: string,
    }): CancelablePromise<{
        url: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/posAppVersions/{posAppVersionID}/file',
            path: {
                'posAppVersionID': posAppVersionId,
            },
        });
    }
    /**
     * Upload & Set POS App Version File
     * @returns any OK
     * @throws ApiError
     */
    public static uploadAndSetPosAppVersionFile({
        posAppVersionId,
        requestBody,
    }: {
        posAppVersionId: string,
        requestBody?: Blob,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/posAppVersions/{posAppVersionID}/file',
            path: {
                'posAppVersionID': posAppVersionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete POS App Version File
     * @returns any OK
     * @throws ApiError
     */
    public static deletePosAppVersionFile({
        posAppVersionId,
    }: {
        posAppVersionId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/posAppVersions/{posAppVersionID}/file',
            path: {
                'posAppVersionID': posAppVersionId,
            },
        });
    }
    /**
     * Get User Privileges
     * @returns any OK
     * @throws ApiError
     */
    public static getUserPrivileges({
        userId,
        storeId,
    }: {
        userId: string,
        storeId?: string,
    }): CancelablePromise<{
        privileges: Array<Privilege>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{userID}/privileges',
            path: {
                'userID': userId,
            },
            query: {
                'storeID': storeId,
            },
        });
    }
    /**
     * Update Collection Expiry Date
     * Updates the collection expiry date on the given purcahse order. The purchase order must be in the ready state.
     * @returns any OK
     * @throws ApiError
     */
    public static updatePurchaseOrderCollectionDate({
        purchaseOrderId,
        requestBody,
    }: {
        purchaseOrderId: string,
        requestBody?: {
            /**
             * Must be within 1 week from when the request is made.
             */
            collectionExpiryDate: string;
        },
    }): CancelablePromise<{
        purchaseOrder: PurchaseOrder;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchaseOrders/{purchaseOrderID}/collectionExpiryDate',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Authenticate Member
     * @returns any OK
     * @throws ApiError
     */
    public static authenticateMember({
        requestBody,
    }: {
        requestBody?: {
            phoneNumberCountryCode: string;
            /**
             * Can be the phone number (paired with phoneNumberCountryCode) or the member's personal ID.
             */
            memberIdentifier: string;
            pin: string;
            authReturnLocation: string;
        },
    }): CancelablePromise<{
        rt?: string;
        jwt?: string;
        jwtExpiresAtUnix: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/authenticate/member',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Verify Member
     * @returns any OK
     * @throws ApiError
     */
    public static verifyMember({
        requestBody,
    }: {
        requestBody?: {
            verificationCode: string;
            personalID?: string;
            phoneNumber?: string;
            phoneNumberCountryCode?: string;
        },
    }): CancelablePromise<{
        pin: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/members/verify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Logout
     * @returns any OK
     * @throws ApiError
     */
    public static logout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/logout',
        });
    }
    /**
     * Get Member Points
     * @returns any OK
     * @throws ApiError
     */
    public static getMemberPoints({
        currency,
    }: {
        currency: string,
    }): CancelablePromise<{
        pointsAvailable: string;
        currencyValue: string;
        currency: Currency;
        valueFormatted: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/member/points',
            query: {
                'currency': currency,
            },
        });
    }
    /**
     * Get Member Recent Sales
     * @returns any OK
     * @throws ApiError
     */
    public static getMemberRecentSales({
        offset,
    }: {
        offset?: number,
    }): CancelablePromise<{
        sales: Array<{
            netPoints: number;
            createdAt: string;
            id: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/member/recent-sales',
            query: {
                'offset': offset,
            },
        });
    }
    /**
     * Request Forget Password Verification Code
     * @returns any OK
     * @throws ApiError
     */
    public static requestForgetPasswordVerificaitonCode({
        requestBody,
    }: {
        requestBody?: {
            personalID: string;
            phoneNumber: string;
            phoneNumberCountryCode: string;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/members/forgot-pin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reset Forgotten Pin
     * Reset member pin using the verification code sent via SMS on /members/forgot-pin.
     * @returns any OK
     * @throws ApiError
     */
    public static resetForgottenMemberPin({
        requestBody,
    }: {
        requestBody?: {
            personalID: string;
            verificationCode: string;
        },
    }): CancelablePromise<{
        pin: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/members/reset-forgotten-pin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set User Enabled
     * @returns any OK
     * @throws ApiError
     */
    public static setUserEnabled({
        userId,
        requestBody,
    }: {
        userId: string,
        requestBody?: {
            enabled: boolean;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users/{userID}/enabled',
            path: {
                'userID': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Team
     * @returns any OK
     * @throws ApiError
     */
    public static getTeam({
        teamId,
    }: {
        teamId: string,
    }): CancelablePromise<{
        team: Team;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teams/{teamID}',
            path: {
                'teamID': teamId,
            },
        });
    }
    /**
     * Update Team
     * @returns any OK
     * @throws ApiError
     */
    public static updateTeam({
        teamId,
        requestBody,
    }: {
        teamId: string,
        requestBody?: {
            name: string;
            description: string;
        },
    }): CancelablePromise<{
        team: Team;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/teams/{teamID}',
            path: {
                'teamID': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Team
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTeam({
        teamId,
    }: {
        teamId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/teams/{teamID}',
            path: {
                'teamID': teamId,
            },
        });
    }
    /**
     * Get Team Privileges
     * @returns any OK
     * @throws ApiError
     */
    public static getTeamPrivileges({
        teamId,
        storeId,
    }: {
        teamId: string,
        storeId?: string,
    }): CancelablePromise<{
        privileges: Array<Privilege>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teams/{teamID}/privileges',
            path: {
                'teamID': teamId,
            },
            query: {
                'storeID': storeId,
            },
        });
    }
    /**
     * Update Team Privilege
     * @returns void
     * @throws ApiError
     */
    public static updateTeamPrivilege({
        teamId,
        requestBody,
    }: {
        teamId: string,
        requestBody?: {
            action: PermissionAction;
            resource: PermissionResource;
            storeID?: string;
            remove: boolean;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/teams/{teamID}/privileges',
            path: {
                'teamID': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Team
     * @returns any OK
     * @throws ApiError
     */
    public static createTeam({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            description: string;
        },
    }): CancelablePromise<{
        team: Team;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/teams',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Teams
     * @returns any OK
     * @throws ApiError
     */
    public static listTeams({
        name,
    }: {
        name?: string,
    }): CancelablePromise<{
        teams: Array<Team>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teams',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Add Team User
     * @returns void
     * @throws ApiError
     */
    public static addTeamUser({
        teamId,
        userId,
    }: {
        teamId: string,
        userId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/teams/{teamID}/teamUsers/{userID}',
            path: {
                'teamID': teamId,
                'userID': userId,
            },
        });
    }
    /**
     * Delete Team User
     * @returns void
     * @throws ApiError
     */
    public static deleteTeamUser({
        teamId,
        userId,
    }: {
        teamId: string,
        userId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/teams/{teamID}/teamUsers/{userID}',
            path: {
                'teamID': teamId,
                'userID': userId,
            },
        });
    }
    /**
     * List Team Users
     * @returns any OK
     * @throws ApiError
     */
    public static listTeamUsers({
        teamId,
    }: {
        teamId: string,
    }): CancelablePromise<{
        users?: Array<User>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teams/{teamID}/teamUsers',
            path: {
                'teamID': teamId,
            },
        });
    }
    /**
     * List Product Generic Codes
     * @returns any OK
     * @throws ApiError
     */
    public static listProductGenericCodes({
        genericCode,
        limit,
        offset,
        requestBody,
    }: {
        genericCode?: string,
        limit?: number,
        offset?: number,
        requestBody?: any,
    }): CancelablePromise<{
        productGenericCodes: Array<{
            productGenericCode: ExtendedProductGenericCode;
        }>;
        totalCount: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productGenericCodes',
            query: {
                'genericCode': genericCode,
                'limit': limit,
                'offset': offset,
            },
            body: requestBody,
        });
    }
    /**
     * Create Product Generic Code
     * @returns any OK
     * @throws ApiError
     */
    public static createProductGenericCode({
        requestBody,
    }: {
        requestBody?: {
            genericCode: string;
        },
    }): CancelablePromise<{
        productGenericCode: ProductGenericCode;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/productGenericCodes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Product Generic Code
     * @returns any OK
     * @throws ApiError
     */
    public static getProductGenericCode({
        genericCodeId,
        requestBody,
    }: {
        genericCodeId: string,
        requestBody?: any,
    }): CancelablePromise<{
        productGenericCode: ProductGenericCode;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productGenericCodes/{genericCodeID}',
            path: {
                'genericCodeID': genericCodeId,
            },
            body: requestBody,
        });
    }
    /**
     * Update Product Generic Code
     * @returns any OK
     * @throws ApiError
     */
    public static updateProductGenericCode({
        genericCodeId,
        requestBody,
    }: {
        genericCodeId: string,
        requestBody?: {
            genericCode?: string;
        },
    }): CancelablePromise<{
        productGenericCode: ProductGenericCode;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/productGenericCodes/{genericCodeID}',
            path: {
                'genericCodeID': genericCodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Product Generic Code
     * @returns void
     * @throws ApiError
     */
    public static deleteProductGenericCode({
        genericCodeId,
    }: {
        genericCodeId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/productGenericCodes/{genericCodeID}',
            path: {
                'genericCodeID': genericCodeId,
            },
        });
    }
    /**
     * List Product Class Codes
     * @returns any OK
     * @throws ApiError
     */
    public static listProductClassCodes({
        classCode,
        limit,
        offset,
        requestBody,
    }: {
        classCode?: string,
        limit?: number,
        offset?: number,
        requestBody?: any,
    }): CancelablePromise<{
        productClassCodes: Array<ProductClassCode>;
        totalCount: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productClassCodes',
            query: {
                'classCode': classCode,
                'limit': limit,
                'offset': offset,
            },
            body: requestBody,
        });
    }
    /**
     * Create Product Class Code
     * @returns any OK
     * @throws ApiError
     */
    public static createProductClassCode({
        requestBody,
    }: {
        requestBody?: {
            classCode: string;
        },
    }): CancelablePromise<{
        productClassCode: ProductClassCode;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/productClassCodes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Product Class Code
     * @returns any OK
     * @throws ApiError
     */
    public static getProductClassCode({
        classCodeId,
    }: {
        classCodeId: string,
    }): CancelablePromise<{
        productClassCode: ProductClassCode;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productClassCodes/{classCodeID}',
            path: {
                'classCodeID': classCodeId,
            },
        });
    }
    /**
     * Update Product Class Code
     * @returns any OK
     * @throws ApiError
     */
    public static updateProductClassCode({
        classCodeId,
        requestBody,
    }: {
        classCodeId: string,
        requestBody?: {
            classCode?: string;
        },
    }): CancelablePromise<{
        productClassCode: ProductClassCode;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/productClassCodes/{classCodeID}',
            path: {
                'classCodeID': classCodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Product Class Code
     * @returns void
     * @throws ApiError
     */
    public static deleteProductClassCode({
        classCodeId,
    }: {
        classCodeId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/productClassCodes/{classCodeID}',
            path: {
                'classCodeID': classCodeId,
            },
        });
    }
    /**
     * Delete User QR Login
     * Clears any QR login for the given user.
     * @returns void
     * @throws ApiError
     */
    public static deleteUserQrLogin({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{userID}/QRLogin',
            path: {
                'userID': userId,
            },
        });
    }
    /**
     * Generate User QR Login
     * @returns any OK
     * @throws ApiError
     */
    public static generateUserQrLogin({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<{
        qrLoginToken: string;
        qrLoginPin: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{userID}/QRLogin',
            path: {
                'userID': userId,
            },
        });
    }
    /**
     * QR Login
     * @returns any OK
     * @throws ApiError
     */
    public static qrLogin({
        requestBody,
    }: {
        requestBody?: {
            userID: string;
            qrLoginToken: string;
            qrLoginPin: string;
        },
    }): CancelablePromise<{
        rt: string;
        jwt: string;
        jwtExpiresAtUnix: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/QRLogin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * Get Store Analytics (DEPRECATED)
     * @returns any OK
     * @throws ApiError
     */
    public static getStoreAnalytics({
        storeId,
    }: {
        storeId: string,
    }): CancelablePromise<{
        currency: Currency;
        dailySales: Array<{
            date: string;
            amount: string;
        }>;
        monthlySales: Array<{
            date: string;
            amount: string;
        }>;
        quarterlySales: Array<{
            date: string;
            amount: string;
        }>;
        dailyPurchases: Array<{
            date: string;
            amount: string;
        }>;
        monthlyPurchases: Array<{
            date: string;
            amount: string;
        }>;
        quarterlyPurchases: Array<{
            date: string;
            amount: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stores/{storeID}/analytics',
            path: {
                'storeID': storeId,
            },
        });
    }
    /**
     * Set User Password
     * Sets the user's password. Sets "temporary password" to false (ie. the password is fixed). Can only be triggered by an admin.
     * @returns void
     * @throws ApiError
     */
    public static setUserPassword({
        userId,
        requestBody,
    }: {
        userId: string,
        requestBody?: {
            password: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users/{userID}/password',
            path: {
                'userID': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Product Stock Balances
     * Returns the aggregate stock balance of a given product at a given store.
     * @returns any OK
     * @throws ApiError
     */
    public static getProductStockBalances({
        productId,
        stockLocationId,
        storeId,
    }: {
        productId: string,
        stockLocationId?: string,
        storeId?: string,
    }): CancelablePromise<{
        stockBalances: Array<{
            stockBalance: StockBalance;
            currency: Currency;
            stockLocation: StockLocation;
            store?: Store;
        }>;
        product: Product;
        /**
         * Only provided if storeID query param given.
         */
        store?: Store;
        /**
         * Only provided if stockLocationID query param given.
         */
        stockLocation?: StockLocation;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/stockBalances',
            path: {
                'productID': productId,
            },
            query: {
                'stockLocationID': stockLocationId,
                'storeID': storeId,
            },
        });
    }
    /**
     * Get Product Analytics
     * @returns any OK
     * @throws ApiError
     */
    public static getProductAnalytics({
        productId,
        storeId,
    }: {
        productId: string,
        storeId?: string,
    }): CancelablePromise<{
        weeklySales: Array<{
            unitsSold: string;
            date: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/analytics',
            path: {
                'productID': productId,
            },
            query: {
                'storeID': storeId,
            },
        });
    }
    /**
     * Update Purchase Order Payment State
     * @returns any OK
     * @throws ApiError
     */
    public static updatePurchaseOrderPaymentState({
        purchaseOrderPaymentId,
        requestBody,
    }: {
        purchaseOrderPaymentId: string,
        requestBody?: {
            state: 'cancelled' | 'posted';
        },
    }): CancelablePromise<{
        purchaseOrderPayment: PurchaseOrderPayment;
        corePayment: CorePayment;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchaseOrderPayments/{purchaseOrderPaymentID}/state',
            path: {
                'purchaseOrderPaymentID': purchaseOrderPaymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Purchase Order Amount Credited
     * @returns any OK
     * @throws ApiError
     */
    public static updatePurchaseOrderAmountCredited({
        purchaseOrderId,
        requestBody,
    }: {
        purchaseOrderId: string,
        requestBody?: {
            creditingPurchaseOrderID: string;
            /**
             * Setting the amount to 0 is the equivalent to removing the amount credited from the given creditingPurchaseOrderID.
             */
            amount: string;
        },
    }): CancelablePromise<{
        purchaseOrderCredit?: PurchaseOrderCredit;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchaseOrders/{purchaseOrderID}/amountCredited',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Purchase Order Discount
     * @returns any OK
     * @throws ApiError
     */
    public static createPurchaseOrderDiscount({
        requestBody,
    }: {
        requestBody?: {
            purchaseOrderID: string;
            amount: string;
            note: string;
        },
    }): CancelablePromise<{
        purchaseOrderDiscount: PurchaseOrderDiscount;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchaseOrderDiscounts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Purchase Order Discount
     * @returns any OK
     * @throws ApiError
     */
    public static getPurchaseOrderDiscount({
        purchaseOrderDiscountId,
    }: {
        purchaseOrderDiscountId: string,
    }): CancelablePromise<{
        purchaseOrderDiscount: PurchaseOrderDiscount;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrderDiscounts/{purchaseOrderDiscountID}',
            path: {
                'purchaseOrderDiscountID': purchaseOrderDiscountId,
            },
        });
    }
    /**
     * Update Purchase Order Discount
     * @returns any OK
     * @throws ApiError
     */
    public static updatePurchaseOrderDiscount({
        purchaseOrderDiscountId,
        requestBody,
    }: {
        purchaseOrderDiscountId: string,
        requestBody?: {
            amount: string;
            note: string;
        },
    }): CancelablePromise<{
        purchaseOrderDiscount: PurchaseOrderDiscount;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/purchaseOrderDiscounts/{purchaseOrderDiscountID}',
            path: {
                'purchaseOrderDiscountID': purchaseOrderDiscountId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Purchase Order Discount State
     * @returns any OK
     * @throws ApiError
     */
    public static updatePurchaseOrderDiscountState({
        purchaseOrderDiscountId,
        requestBody,
    }: {
        purchaseOrderDiscountId: string,
        requestBody?: {
            state: PurchaseOrderDiscountState;
        },
    }): CancelablePromise<{
        purchaseOrderDiscount: PurchaseOrderDiscount;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchaseOrderDiscounts/{purchaseOrderDiscountID}/state',
            path: {
                'purchaseOrderDiscountID': purchaseOrderDiscountId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Purchase Order From Uncollected Lines
     * @returns any OK
     * @throws ApiError
     */
    public static createPurchaseOrderFromUncollectedLines({
        purchaseOrderId,
        requestBody,
    }: {
        purchaseOrderId: string,
        requestBody?: any,
    }): CancelablePromise<{
        purchaseOrderID: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchaseOrders/{purchaseOrderID}/newFromUncollectedLines',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            body: requestBody,
        });
    }
    /**
     * Update Purchase Order Vendor Invoice Reference
     * @returns void
     * @throws ApiError
     */
    public static updatePurchaseOrderVendorInvoiceReference({
        purchaseOrderId,
        requestBody,
    }: {
        purchaseOrderId: string,
        requestBody?: {
            /**
             * An empty string is considered as null.
             */
            vendorInvoiceReference: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchaseOrders/{purchaseOrderID}/vendorInvoiceReference',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Stock Move Group PDF
     * @returns any OK
     * @throws ApiError
     */
    public static getStockMoveGroupPdf({
        stockMoveGroupId,
    }: {
        stockMoveGroupId: string,
    }): CancelablePromise<{
        pdfBase64: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockMoveGroups/{stockMoveGroupID}/pdf',
            path: {
                'stockMoveGroupID': stockMoveGroupId,
            },
        });
    }
    /**
     * Create Bulk Purchase Order Payment
     * @returns any OK
     * @throws ApiError
     */
    public static createBulkPurchaseOrderPayment({
        requestBody,
    }: {
        requestBody?: {
            bulkPurchaseOrderPayment: Array<{
                purchaseOrderID: string;
                paymentAmount: string;
                paymentMetadata?: Record<string, any>;
            }>;
            paymentMethodID?: string;
            currency: string;
            voucherReference?: string;
            paymentDate?: string;
        },
    }): CancelablePromise<{
        bulkPurchaseOrderPaymentID: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bulkPurchaseOrderPayments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Bulk Purchase Order Payments
     * @returns any OK
     * @throws ApiError
     */
    public static listBulkPurchaseOrderPayments({
        limit,
        offset,
    }: {
        limit?: number,
        offset?: number,
    }): CancelablePromise<{
        bulkPurchaseOrderPayments: Array<{
            bulkPurchaseOrderPayment: BulkPurchaseOrderPayment;
            currency: Currency;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bulkPurchaseOrderPayments',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Link Purchase Order to Purchase Order Payment
     * @returns void
     * @throws ApiError
     */
    public static linkPurchaseOrderToPurchaseOrderPayment({
        requestBody,
    }: {
        requestBody?: {
            purchaseOrderID: string;
            purchaseOrderPaymentID: string;
            amount: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchaseOrderToPurchaseOrderPayments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Purchase Order to Purchase Order Payment Link
     * @returns void
     * @throws ApiError
     */
    public static deletePurchaseOrderToPurchaseOrderPaymentLink({
        purchaseOrderId,
        purchaseOrderPaymentId,
    }: {
        purchaseOrderId: string,
        purchaseOrderPaymentId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/purchaseOrderToPurchaseOrderPayments/{purchaseOrderID}/{purchaseOrderPaymentID}',
            path: {
                'purchaseOrderID': purchaseOrderId,
                'purchaseOrderPaymentID': purchaseOrderPaymentId,
            },
        });
    }
    /**
     * List Purchase Order Payment
     * @returns any OK
     * @throws ApiError
     */
    public static listPurchaseOrderPayments({
        target,
        limit,
        offset,
        vendorId,
        hasBalance,
        state,
        balance,
        createDateStartDate,
        createDateEndDate,
        requestBody,
    }: {
        target?: string,
        limit?: number,
        offset?: number,
        vendorId?: string,
        hasBalance?: boolean,
        state?: string,
        /**
         * Use numeric operator format (symbol with space)
         */
        balance?: string,
        createDateStartDate?: string,
        createDateEndDate?: string,
        requestBody?: any,
    }): CancelablePromise<{
        purchaseOrderPayments: Array<{
            purchaseOrderPayment: PurchaseOrderPayment;
            corePayment: CorePayment;
            currency: Currency;
            vendor?: Vendor;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrderPayments',
            query: {
                'target': target,
                'limit': limit,
                'offset': offset,
                'vendorID': vendorId,
                'hasBalance': hasBalance,
                'state': state,
                'balance': balance,
                'createDateStartDate': createDateStartDate,
                'createDateEndDate': createDateEndDate,
            },
            body: requestBody,
        });
    }
    /**
     * Create Purchase Order Payment
     * @returns any OK
     * @throws ApiError
     */
    public static createPurchaseOrderPayment({
        requestBody,
    }: {
        requestBody?: {
            paymentMethodID?: string;
            amount: string;
            metadata: Record<string, any>;
            currency: string;
            vendorID: string;
            voucherReference?: string;
            paymentDate?: string;
        },
    }): CancelablePromise<{
        purchaseOrderPayment: PurchaseOrderPayment;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchaseOrderPayments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Bulk PO Payment
     * @returns any OK
     * @throws ApiError
     */
    public static getBulkPurchaseOrderPayment({
        bulkPurchaseOrderPaymentId,
    }: {
        bulkPurchaseOrderPaymentId: string,
    }): CancelablePromise<{
        bulkPurchaseOrderPayment: BulkPurchaseOrderPayment;
        currency: Currency;
        purchaseOrderPayments: Array<{
            purchaseOrderPayment: PurchaseOrderPayment;
            linkedPurchaseOrders: Array<{
                purchaseOrderToPurchaseOrderPaymentRel: PurchaseOrderToPurchaseOrderPaymentRel;
                purchaseOrder: PurchaseOrder;
            }>;
            vendor: Vendor;
            corePayment: CorePayment;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bulkPurchaseOrderPayments/{bulkPurchaseOrderPaymentID}',
            path: {
                'bulkPurchaseOrderPaymentID': bulkPurchaseOrderPaymentId,
            },
        });
    }
    /**
     * List Sale Payments
     * @returns any OK
     * @throws ApiError
     */
    public static listSalePayments({
        limit,
        offset,
        target,
        cashierUserId,
        paymentMethodId,
        startDate,
        endDate,
    }: {
        limit?: number,
        offset?: number,
        target?: string,
        cashierUserId?: string,
        paymentMethodId?: string,
        startDate?: string,
        endDate?: string,
    }): CancelablePromise<{
        salePayments: Array<{
            salePayment: SalePayment;
            currency: Currency;
            sale: Sale;
            paymentMethod: PaymentMethod;
            corePayment: CorePayment;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/salePayments',
            query: {
                'limit': limit,
                'offset': offset,
                'target': target,
                'cashierUserID': cashierUserId,
                'paymentMethodID': paymentMethodId,
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }
    /**
     * Get Sale Payment
     * @returns any OK
     * @throws ApiError
     */
    public static getSalePayment({
        salePaymentId,
        requestBody,
    }: {
        salePaymentId: string,
        requestBody?: any,
    }): CancelablePromise<{
        salePayment: SalePayment;
        corePayment: CorePayment;
        currency: Currency;
        paymentMethod?: PaymentMethod;
        sale: Sale;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/salePayments/{salePaymentID}',
            path: {
                'salePaymentID': salePaymentId,
            },
            body: requestBody,
        });
    }
    /**
     * Update Sale Payment Payment Method
     * @returns void
     * @throws ApiError
     */
    public static updateSalePaymentPaymentMethod({
        salePaymentId,
        requestBody,
    }: {
        salePaymentId: string,
        requestBody?: {
            paymentMethodID?: string;
            metadata?: Record<string, any>;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/salePayments/{salePaymentID}/paymentMethod',
            path: {
                'salePaymentID': salePaymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Product Brands
     * @returns any OK
     * @throws ApiError
     */
    public static listProductBrands({
        target,
        offset,
        limit,
        requestBody,
    }: {
        target?: string,
        offset?: number,
        limit?: number,
        requestBody?: any,
    }): CancelablePromise<{
        productBrands: Array<{
            productBrand: ProductBrandWithMetadata;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productBrands',
            query: {
                'target': target,
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
        });
    }
    /**
     * Create Product Brand
     * @returns any OK
     * @throws ApiError
     */
    public static createProductBrand({
        requestBody,
    }: {
        requestBody?: {
            brandName: string;
        },
    }): CancelablePromise<{
        productBrand: ProductBrands;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/productBrands',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Product Brand
     * @returns void
     * @throws ApiError
     */
    public static updateProductBrand({
        productBrandId,
        requestBody,
    }: {
        productBrandId: string,
        requestBody?: {
            brandName?: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/productBrands/{productBrandID}',
            path: {
                'productBrandID': productBrandId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Product Brand
     * @returns void
     * @throws ApiError
     */
    public static deleteProductBrand({
        productBrandId,
        requestBody,
    }: {
        productBrandId: string,
        requestBody?: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/productBrands/{productBrandID}',
            path: {
                'productBrandID': productBrandId,
            },
            body: requestBody,
        });
    }
    /**
     * Get Product Brand
     * @returns any OK
     * @throws ApiError
     */
    public static getProductBrand({
        productBrandId,
        requestBody,
    }: {
        productBrandId: string,
        requestBody?: any,
    }): CancelablePromise<{
        productBrand: ProductBrands;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productBrands/{productBrandID}',
            path: {
                'productBrandID': productBrandId,
            },
            body: requestBody,
        });
    }
    /**
     * Update Purchase Order Payment Date
     * @returns void
     * @throws ApiError
     */
    public static updatePurchaseOrderPaymentDate({
        purchaseOrderPaymentId,
        requestBody,
    }: {
        purchaseOrderPaymentId: string,
        requestBody?: {
            paymentDate: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchaseOrderPayments/{purchaseOrderPaymentID}/paymentDate',
            path: {
                'purchaseOrderPaymentID': purchaseOrderPaymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Sale Payment Amount
     * @returns void
     * @throws ApiError
     */
    public static updateSalePaymentAmount({
        salePaymentId,
        requestBody,
    }: {
        salePaymentId: string,
        requestBody?: {
            amount: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/salePayments/{salePaymentID}/amount',
            path: {
                'salePaymentID': salePaymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Sale Payment Changelogs
     * @returns any OK
     * @throws ApiError
     */
    public static listSalePaymentChangelogs({
        salePaymentId,
        limit,
        offset,
        requestBody,
    }: {
        salePaymentId: string,
        limit?: number,
        offset?: number,
        requestBody?: any,
    }): CancelablePromise<{
        changelogs: Array<{
            changelog: Changelog;
            user?: User;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/salePayments/{salePaymentID}/changelogs',
            path: {
                'salePaymentID': salePaymentId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            body: requestBody,
        });
    }
    /**
     * Get Bulk Purchase Order Payment PDF
     * @returns any OK
     * @throws ApiError
     */
    public static getBulkPurchaseOrderPaymentPdf({
        bulkPurchaseOrderPaymentId,
    }: {
        bulkPurchaseOrderPaymentId: string,
    }): CancelablePromise<{
        pdfBase64: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bulkPurchaseOrderPayments/{bulkPurchaseOrderPaymentID}/pdf',
            path: {
                'bulkPurchaseOrderPaymentID': bulkPurchaseOrderPaymentId,
            },
        });
    }
    /**
     * Update Purchase Order
     * @returns void
     * @throws ApiError
     */
    public static updatePurchaseOrder({
        purchaseOrderId,
        requestBody,
    }: {
        purchaseOrderId: string,
        requestBody?: {
            vendorID: string;
            paymentTerms: PurchaseOrderPaymentTerm;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/purchaseOrders/{purchaseOrderID}',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Member Sales
     * Returns the member's sales from the last 3 days.
     * @returns any OK
     * @throws ApiError
     */
    public static listMemberSales({
        memberId,
    }: {
        memberId: string,
    }): CancelablePromise<{
        sales: Array<{
            sale: Sale;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/members/{memberID}/sales',
            path: {
                'memberID': memberId,
            },
        });
    }
    /**
     * Get Currency
     * @returns any OK
     * @throws ApiError
     */
    public static getCurrency({
        iso4217Code,
    }: {
        iso4217Code: string,
    }): CancelablePromise<{
        currency: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/currencies/{iso4217Code}',
            path: {
                'iso4217Code': iso4217Code,
            },
        });
    }
    /**
     * Merge Generic Codes
     * @returns any OK
     * @throws ApiError
     */
    public static mergeProductGenericCodes({
        requestBody,
    }: {
        requestBody?: {
            genericCodes: Array<string>;
            genericCodeToMergeTo: string;
        },
    }): CancelablePromise<{
        mergedProductGenericCodeID: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/productGenericCodes/merge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Stock Location User Privilege
     * @returns any Created
     * @throws ApiError
     */
    public static createStockLocationUserPrivilege({
        requestBody,
    }: {
        requestBody?: {
            userID: string;
            stockLocationID: string;
            role: StockLocationRole;
        },
    }): CancelablePromise<{
        stockLocationUserPrivilege: StockLocationUserPrivilege;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stockLocationUserPrivileges',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Stock Location User Privileges
     * @returns any OK
     * @throws ApiError
     */
    public static listStockLocationUserPrivileges({
        stockLocationId,
    }: {
        stockLocationId?: string,
    }): CancelablePromise<{
        stockLocationUserPrivileges: Array<{
            stockLocationUserPrivilege: StockLocationUserPrivilege;
            user: User;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockLocationUserPrivileges',
            query: {
                'stockLocationID': stockLocationId,
            },
        });
    }
    /**
     * Delete Stock Location User Privilege
     * @returns void
     * @throws ApiError
     */
    public static deleteStockLocationUserPrivilege({
        stockLocationUserPrivilegeId,
    }: {
        stockLocationUserPrivilegeId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/stockLocationUserPrivileges/{stockLocationUserPrivilegeID}',
            path: {
                'stockLocationUserPrivilegeID': stockLocationUserPrivilegeId,
            },
        });
    }
    /**
     * Get Purchase Order Line Monthly PCs Sold
     * Returns the monthly pcs sold of the product in the given purchase order line. It returns around 1 year of monthly pcs sold before the purchase order line was collected (or from now if it sin't collected yet).
     * @returns any OK
     * @throws ApiError
     */
    public static getPurchaseOrderLineMonthlyPcsSold({
        purchaseOrderLineId,
    }: {
        purchaseOrderLineId: string,
    }): CancelablePromise<{
        monthlyPCsSold: Array<{
            monthDate: string;
            pcsSold: string;
            productID: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrderLines/{purchaseOrderLineID}/monthlyPCsSold',
            path: {
                'purchaseOrderLineID': purchaseOrderLineId,
            },
        });
    }
    /**
     * Approve Purchase Order
     * @returns void
     * @throws ApiError
     */
    public static approvePurchaseOrder({
        purchaseOrderId,
        requestBody,
    }: {
        purchaseOrderId: string,
        requestBody?: {
            approved: boolean;
            rejectionReason?: string;
            rejectionDescription?: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchaseOrders/{purchaseOrderID}/approve',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Purchase Order Changelogs
     * @returns any
     * @throws ApiError
     */
    public static listPurchaseOrderChangelogs({
        purchaseOrderId,
        limit,
        offset,
    }: {
        purchaseOrderId: string,
        limit?: number,
        offset?: number,
    }): CancelablePromise<{
        changelogs: Array<{
            changelog: Changelog;
            user: User;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchaseOrders/{purchaseOrderID}/changelogs',
            path: {
                'purchaseOrderID': purchaseOrderId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * List Stock Adjustments
     * @returns any OK
     * @throws ApiError
     */
    public static listStockAdjustments({
        limit,
        offset,
        stockLocationId,
        target,
    }: {
        limit?: number,
        offset?: number,
        stockLocationId?: string,
        target?: string,
    }): CancelablePromise<{
        stockAdjustmentGroups: Array<{
            stockAdjustmentGroup: StockAdjustmentGroup;
            stockLocation: StockLocation;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockAdjustmentGroups',
            query: {
                'limit': limit,
                'offset': offset,
                'stockLocationID': stockLocationId,
                'target': target,
            },
        });
    }
    /**
     * Draft New Stock Adjustment Group
     * @returns any Created
     * @throws ApiError
     */
    public static draftNewStockAdjustmentGroup({
        requestBody,
    }: {
        requestBody?: {
            stockLocationID: string;
            notes: string;
        },
    }): CancelablePromise<{
        stockAdjustmentGroup: StockAdjustmentGroup;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stockAdjustmentGroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Stock Adjustment Group
     * @returns any OK
     * @throws ApiError
     */
    public static getStockAdjustmentGroup({
        stockAdjustmentGroupId,
    }: {
        stockAdjustmentGroupId: string,
    }): CancelablePromise<{
        stockAdjustmentGroup: StockAdjustmentGroup;
        stockAdjustmentGroupLines: Array<{
            stockAdjustmentGroupLine: StockAdjustmentGroupLine;
            product: Product;
        }>;
        stockLocation: StockLocation;
        postingUser?: UserWithNameOnly;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockAdjustmentGroups/{stockAdjustmentGroupID}',
            path: {
                'stockAdjustmentGroupID': stockAdjustmentGroupId,
            },
        });
    }
    /**
     * Update Stock Adjustment Group
     * @returns any OK
     * @throws ApiError
     */
    public static updateStockAdjustmentGroup({
        stockAdjustmentGroupId,
        requestBody,
    }: {
        stockAdjustmentGroupId: string,
        requestBody?: {
            notes: string;
        },
    }): CancelablePromise<{
        stockAdjustmentGroup: StockAdjustmentGroup;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/stockAdjustmentGroups/{stockAdjustmentGroupID}',
            path: {
                'stockAdjustmentGroupID': stockAdjustmentGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Stock Adjustment Group State
     * @returns any OK
     * @throws ApiError
     */
    public static updateStockAdjustmentGroupState({
        stockAdjustmentGroupId,
        requestBody,
    }: {
        stockAdjustmentGroupId: string,
        requestBody?: {
            state: StockAdjustmentGroupState;
        },
    }): CancelablePromise<{
        stockAdjustmentGroup: StockAdjustmentGroup;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/stockAdjustmentGroups/{stockAdjustmentGroupID}/state',
            path: {
                'stockAdjustmentGroupID': stockAdjustmentGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add Stock Adjustment Group Line
     * @returns any OK
     * @throws ApiError
     */
    public static addStockAdjustmentGroupLine({
        requestBody,
    }: {
        requestBody?: {
            productID: string;
            stockAdjustmentGroupID: string;
            currentPCs: string;
            targetPCs: string;
        },
    }): CancelablePromise<{
        stockAdjustmentGroupLine: StockAdjustmentGroupLine;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stockAdjustmentGroupLines',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Stock Adjustment Group Line
     * @returns any OK
     * @throws ApiError
     */
    public static updateStockAdjustmentGroupLine({
        stockAdjustmentGroupLineId,
        requestBody,
    }: {
        stockAdjustmentGroupLineId: string,
        requestBody?: {
            productID?: string;
            currentPCs?: string;
            targetPCs?: string;
        },
    }): CancelablePromise<{
        stockAdjustmentGroupLine: StockAdjustmentGroupLine;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/stockAdjustmentGroupLines/{stockAdjustmentGroupLineID}',
            path: {
                'stockAdjustmentGroupLineID': stockAdjustmentGroupLineId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Stock Adjustment Group Line
     * @returns void
     * @throws ApiError
     */
    public static deleteStockAdjustmentGroupLine({
        stockAdjustmentGroupLineId,
    }: {
        stockAdjustmentGroupLineId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/stockAdjustmentGroupLines/{stockAdjustmentGroupLineID}',
            path: {
                'stockAdjustmentGroupLineID': stockAdjustmentGroupLineId,
            },
        });
    }
    /**
     * Generate Stock Location Stock Balance PDF
     * @returns any OK
     * @throws ApiError
     */
    public static generateStockLocationStockBalancePdf({
        stockLocationId,
        limit,
        offset,
    }: {
        stockLocationId: string,
        limit?: number,
        offset?: number,
    }): CancelablePromise<{
        pdfBase64: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stockLocations/{stockLocationID}/stockBalances/pdf',
            path: {
                'stockLocationID': stockLocationId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Get Product Recent Purchases
     * @returns any OK
     * @throws ApiError
     */
    public static getProductRecentPurchases({
        productId,
        currency,
        storeId,
    }: {
        productId: string,
        currency: string,
        storeId?: string,
    }): CancelablePromise<{
        recentPurchases: Array<{
            purchaseOrderID: string;
            purchaseOrderNumber: string;
            storeID?: string;
            storeName?: string;
            collectedAt: string;
            pcsQuantity: string;
            preTaxTotal: string;
        }>;
        currency: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/recentPurchases',
            path: {
                'productID': productId,
            },
            query: {
                'storeID': storeId,
                'currency': currency,
            },
        });
    }
    /**
     * Get Product Selling Price Stats
     * @returns any OK
     * @throws ApiError
     */
    public static getProductSellingPriceStats({
        productId,
        currency,
        storeId,
    }: {
        productId: string,
        currency: string,
        storeId?: string,
    }): CancelablePromise<{
        sellingStatistics: Array<{
            caseOf: string;
            preTaxTotalSold: string;
            pcsSold: string;
            distinctSales: string;
        }>;
        currency: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/sellingPriceStats',
            path: {
                'productID': productId,
            },
            query: {
                'storeID': storeId,
                'currency': currency,
            },
        });
    }
    /**
     * Get Product Changelogs
     * @returns any OK
     * @throws ApiError
     */
    public static listProductChangelogs({
        productId,
    }: {
        productId: string,
    }): CancelablePromise<{
        changelogs: Array<{
            changelog: Changelog;
            user: UserWithNameOnly;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/changelogs',
            path: {
                'productID': productId,
            },
        });
    }
    /**
     * Update Product Target Margin
     * @returns void
     * @throws ApiError
     */
    public static updateProductTargetMargin({
        productId,
        requestBody,
    }: {
        productId: string,
        requestBody?: {
            targetMargin: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/products/{productID}/targetMargin',
            path: {
                'productID': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Coupon
     * @returns any OK
     * @throws ApiError
     */
    public static getCoupon({
        couponId,
    }: {
        couponId: string,
    }): CancelablePromise<{
        coupon: Coupon;
        currency: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/coupons/{couponID}',
            path: {
                'couponID': couponId,
            },
        });
    }
    /**
     * List Coupon Groups
     * @returns any OK
     * @throws ApiError
     */
    public static listCouponGroups({
        limit,
        offset,
        target,
    }: {
        limit?: number,
        offset?: number,
        target?: string,
    }): CancelablePromise<{
        couponGroups: Array<{
            couponGroup: CouponGroup;
            currency: Currency;
            creatingUser: UserWithNameOnly;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/couponGroups',
            query: {
                'limit': limit,
                'offset': offset,
                'target': target,
            },
        });
    }
    /**
     * Create Coupon Group
     * @returns any OK
     * @throws ApiError
     */
    public static createCouponGroup({
        requestBody,
    }: {
        requestBody?: {
            numberOfCoupons: number;
            currency: string;
            eachCouponAmount: string;
            notes: string;
            expiresAt: string;
        },
    }): CancelablePromise<{
        couponGroup: CouponGroup;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/couponGroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Coupon Group
     * @returns any OK
     * @throws ApiError
     */
    public static getCouponGroup({
        couponGroupId,
    }: {
        couponGroupId: string,
    }): CancelablePromise<{
        couponGroup: CouponGroup;
        currency: Currency;
        couponsUsage?: {
            expiredCoupons: number;
            unusedCoupons: number;
            redeemedOrReservedCoupons: number;
        };
        creatingUser: UserWithNameOnly;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/couponGroups/{couponGroupID}',
            path: {
                'couponGroupID': couponGroupId,
            },
        });
    }
    /**
     * Update Coupon Group
     * @returns any OK
     * @throws ApiError
     */
    public static updateCouponGroup({
        couponGroupId,
        requestBody,
    }: {
        couponGroupId: string,
        requestBody?: {
            numberOfCoupons: number;
            eachCouponAmount: string;
            notes: string;
            expiresAt: string;
        },
    }): CancelablePromise<{
        couponGroup: CouponGroup;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/couponGroups/{couponGroupID}',
            path: {
                'couponGroupID': couponGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Coupon Group State
     * @returns void
     * @throws ApiError
     */
    public static updateCouponGroupState({
        couponGroupId,
        requestBody,
    }: {
        couponGroupId: string,
        requestBody?: {
            state: CouponGroupState;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/couponGroups/{couponGroupID}/state',
            path: {
                'couponGroupID': couponGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Coupon Group Coupons PDF
     * @returns any OK
     * @throws ApiError
     */
    public static getCouponGroupCouponsPdf({
        couponGroupId,
    }: {
        couponGroupId: string,
    }): CancelablePromise<{
        pdfBase64: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/couponGroups/{couponGroupID}/couponsPDF',
            path: {
                'couponGroupID': couponGroupId,
            },
        });
    }
    /**
     * List Coupons
     * @returns any OK
     * @throws ApiError
     */
    public static listCoupons({
        limit,
        offset,
        barcodeReference,
    }: {
        limit?: number,
        offset?: number,
        barcodeReference?: string,
    }): CancelablePromise<{
        coupons: Array<{
            coupon: Coupon;
            currency: Currency;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/coupons',
            query: {
                'limit': limit,
                'offset': offset,
                'barcodeReference': barcodeReference,
            },
        });
    }
    /**
     * Get Product Monthly PCs Stats
     * @returns any OK
     * @throws ApiError
     */
    public static getProductMonthlyPcsStats({
        productId,
        storeId,
        startMonth,
        endMonth,
    }: {
        productId: string,
        storeId?: string,
        startMonth?: string,
        endMonth?: string,
    }): CancelablePromise<{
        monthlyPCsSold: Array<{
            monthDate: string;
            pcsSold: string;
            productID: string;
        }>;
        monthlyPCsPurchased: Array<{
            monthDate: string;
            pcsPurchased: string;
            pcsReturnedPurchase: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/monthlyPCsStats',
            path: {
                'productID': productId,
            },
            query: {
                'storeID': storeId,
                'startMonth': startMonth,
                'endMonth': endMonth,
            },
        });
    }
    /**
     * List Product Purchasing Costs
     * @returns any OK
     * @throws ApiError
     */
    public static listProductPurchasingCost({
        productId,
        currency,
        limit,
        offset,
    }: {
        productId: string,
        currency: string,
        limit?: number,
        offset?: number,
    }): CancelablePromise<{
        productPurchasingCosts: Array<{
            productPurchasingCost: ProductPurchasingCost;
        }>;
        currency: Currency;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productPurchasingCosts',
            query: {
                'productID': productId,
                'limit': limit,
                'offset': offset,
                'currency': currency,
            },
        });
    }
    /**
     * Create Product Purchasing Cost
     * @returns any OK
     * @throws ApiError
     */
    public static createProductPurchasingCost({
        requestBody,
    }: {
        requestBody?: {
            productID: string;
            currency: string;
            caseOf: string;
            unitCost: string;
        },
    }): CancelablePromise<{
        productPurchasingCost: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/productPurchasingCosts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Product Purchasing Cost
     * @returns any OK
     * @throws ApiError
     */
    public static updateProductPurchasingCost({
        productPurchasingCostId,
        requestBody,
    }: {
        productPurchasingCostId: string,
        requestBody?: {
            caseOf: string;
            unitCost: string;
        },
    }): CancelablePromise<{
        productPurchasingCost: ProductPurchasingCost;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/productPurchasingCosts/{productPurchasingCostID}',
            path: {
                'productPurchasingCostID': productPurchasingCostId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Product Purchasing Cost
     * @returns void
     * @throws ApiError
     */
    public static deleteProductPurchasingCost({
        productPurchasingCostId,
    }: {
        productPurchasingCostId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/productPurchasingCosts/{productPurchasingCostID}',
            path: {
                'productPurchasingCostID': productPurchasingCostId,
            },
        });
    }
    /**
     * Set Product Strict Purchasing Unit Price
     * @returns void
     * @throws ApiError
     */
    public static setProductStrictPurchasingCost({
        productId,
        requestBody,
    }: {
        productId: string,
        requestBody?: {
            strictPurchasingUnitPrice: boolean;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/products/{productID}/strictPurchasingUnitPrice',
            path: {
                'productID': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Product Similar Foreign Products
     * @returns any OK
     * @throws ApiError
     */
    public static listProductSimilarForeignProducts({
        productId,
        currency,
    }: {
        productId: string,
        currency?: string,
    }): CancelablePromise<{
        similarForeignProducts: Array<{
            foreignProductSource: ForeignProductSource;
            foreignProduct: ForeignProduct;
            similarity: string;
        }>;
        currency?: Currency;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/similarForeignProducts',
            path: {
                'productID': productId,
            },
            query: {
                'currency': currency,
            },
        });
    }
    /**
     * List Products
     * @returns any OK
     * @throws ApiError
     */
    public static listProducts({
        target,
        limit,
        offset,
        signTaxes,
        signPrices,
        isFresh,
        enabled,
        genericCode,
        classCode,
        primaryVendorId,
        vendorContactId,
        hasProductCosts,
        includeDisabledProductPrices,
    }: {
        target?: string,
        limit?: number,
        offset?: number,
        signTaxes?: boolean,
        signPrices?: boolean,
        isFresh?: boolean,
        enabled?: boolean,
        genericCode?: string,
        classCode?: string,
        primaryVendorId?: string,
        vendorContactId?: string,
        hasProductCosts?: boolean,
        includeDisabledProductPrices?: boolean,
    }): CancelablePromise<{
        products: Array<{
            product: Product;
            saleTaxes: Array<Tax>;
            prices: Array<{
                price: ProductPrice;
                priceSignature?: string;
                priceSignatureExpiresAtUnix?: number;
                currency: Currency;
            }>;
            taxSignature?: string;
            taxSignatureExpiresAtUnix?: number;
            purchasingTaxes: Array<Tax>;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/products',
            query: {
                'target': target,
                'limit': limit,
                'offset': offset,
                'signTaxes': signTaxes,
                'signPrices': signPrices,
                'isFresh': isFresh,
                'enabled': enabled,
                'genericCode': genericCode,
                'classCode': classCode,
                'primaryVendorID': primaryVendorId,
                'vendorContactID': vendorContactId,
                'hasProductCosts': hasProductCosts,
                'includeDisabledProductPrices': includeDisabledProductPrices,
            },
        });
    }
    /**
     * List Product Price Margins
     * @returns any OK
     * @throws ApiError
     */
    public static listProductPriceMargins({
        currency,
        limit,
        offset,
        orderBy,
        genericCode,
        soldSince,
    }: {
        currency: string,
        limit?: number,
        offset?: number,
        /**
         * Use "recentPurchasesMargin" or "productCostMargin".
         */
        orderBy?: string,
        genericCode?: string,
        soldSince?: string,
    }): CancelablePromise<{
        productPriceMargins: Array<{
            productPriceMargin: ProductPriceMargin;
            productPrice: ProductPrice;
            product: Product;
            saleTaxes: Array<Tax>;
        }>;
        currency: Currency;
        count: number;
        productPriceTimesSold: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productPriceMargins',
            query: {
                'limit': limit,
                'offset': offset,
                'currency': currency,
                'orderBy': orderBy,
                'genericCode': genericCode,
                'soldSince': soldSince,
            },
        });
    }
    /**
     * Patch Product Generic Code
     * @returns void
     * @throws ApiError
     */
    public static patchProductGenericCode({
        productId,
        requestBody,
    }: {
        productId: string,
        requestBody?: {
            genericCode?: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/products/{productID}/genericCode',
            path: {
                'productID': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Vendor Balance
     * @returns any OK
     * @throws ApiError
     */
    public static getVendorBalance({
        vendorId,
        currency,
    }: {
        vendorId: string,
        currency: string,
    }): CancelablePromise<{
        currency: Currency;
        totalBalance: string;
        balancesWithMonth: Array<{
            month: string;
            balance: string;
            numberOfPurchaseOrdersWithBalance: number;
        }>;
        numberOfPurchaseOrdersWithBalance: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendors/{vendorID}/balance',
            path: {
                'vendorID': vendorId,
            },
            query: {
                'currency': currency,
            },
        });
    }
    /**
     * Get Vendor Monthly Purchases & Payments
     * @returns any OK
     * @throws ApiError
     */
    public static getVendorMonthlyPurchasesAndPayments({
        vendorId,
        currency,
    }: {
        vendorId: string,
        currency: string,
    }): CancelablePromise<{
        currency: Currency;
        monthlyPurchasesAndPayments: Array<{
            month: string;
            paymentsCount: number;
            paymentsAmount: string;
            purchaseOrdersCount: number;
            purchaseOrdersAmount: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendors/{vendorID}/monthlyPurchasesAndPayments',
            path: {
                'vendorID': vendorId,
            },
            query: {
                'currency': currency,
            },
        });
    }
    /**
     * Merge Product Brands
     * @returns any OK
     * @throws ApiError
     */
    public static mergeProductBrands({
        requestBody,
    }: {
        requestBody?: {
            brandNameToMergeTo: string;
            brandNames: Array<string>;
        },
    }): CancelablePromise<{
        mergedToProductBrand: ProductBrands;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/productBrands/merge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Cashier Work Session
     * @returns any OK
     * @throws ApiError
     */
    public static getCashierWorkSession({
        cashierWorkSessionId,
    }: {
        cashierWorkSessionId: string,
    }): CancelablePromise<{
        cashierWorkSession: CashierSession;
        cashierUser: UserWithNameOnly;
        currency: Currency;
        totalSaleReturns: string;
        reviewingUser: UserWithNameOnly;
        totalCashSales: string;
        totalNonCashSales: string;
        paymentMethodTotals: Array<{
            paymentMethod: PaymentMethod;
            totalSales: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cashierWorkSessions/{cashierWorkSessionID}',
            path: {
                'cashierWorkSessionID': cashierWorkSessionId,
            },
        });
    }
    /**
     * List Cashier Work Sessions
     * @returns any OK
     * @throws ApiError
     */
    public static listCashierWorkSessions({
        storeId,
        cashierUserId,
        limit,
        offset,
        isReviewed,
    }: {
        storeId?: string,
        cashierUserId?: string,
        limit?: number,
        offset?: number,
        isReviewed?: boolean,
    }): CancelablePromise<{
        cashierWorkSessions: Array<{
            cashierWorkSession: CashierSession;
            cashierUser: UserWithNameOnly;
            currency: Currency;
            totalSaleReturns: string;
            reviewingUser: UserWithNameOnly;
            totalCashSales: string;
            totalNonCashSales: string;
        }>;
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cashierWorkSessions',
            query: {
                'storeID': storeId,
                'cashierUserID': cashierUserId,
                'limit': limit,
                'offset': offset,
                'isReviewed': isReviewed,
            },
        });
    }
    /**
     * Review Cashier Work Session
     * @returns void
     * @throws ApiError
     */
    public static reviewCashierWorkSession({
        cashierWorkSessionId,
        requestBody,
    }: {
        cashierWorkSessionId: string,
        requestBody?: {
            reviewedEndingBalance: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cashierWorkSessions/{cashierWorkSessionID}/review',
            path: {
                'cashierWorkSessionID': cashierWorkSessionId,
            },
            body: requestBody,
            mediaType: 'application/x-bittorrent',
        });
    }
    /**
     * Create Member One Time Points Redemption Pin
     * @returns any OK
     * @throws ApiError
     */
    public static createMemberOneTimePointsRedemptionPin({
        requestBody,
    }: {
        requestBody?: {
            memberID: string;
        },
    }): CancelablePromise<{
        memberOneTimePointsRedemptionPin: MemberOneTimePointsRedemptionPin;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/memberOneTimePointsRedemptionPins',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Product Tag
     * @returns any OK
     * @throws ApiError
     */
    public static createProductTag({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            description: string;
        },
    }): CancelablePromise<{
        productTag: ProductTag;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/productTags',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Product Tags
     * @returns any OK
     * @throws ApiError
     */
    public static listProductTags({
        limit,
        offset,
        target,
        isAutomated,
    }: {
        limit?: number,
        offset?: number,
        target?: string,
        isAutomated?: boolean,
    }): CancelablePromise<{
        productTags: Array<{
            productTag: ProductTag;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productTags',
            query: {
                'limit': limit,
                'offset': offset,
                'target': target,
                'isAutomated': isAutomated,
            },
        });
    }
    /**
     * Get Product Tag
     * @returns any OK
     * @throws ApiError
     */
    public static getProductTag({
        productTagId,
    }: {
        productTagId: string,
    }): CancelablePromise<{
        productTag: ProductTag;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/productTags/{productTagID}',
            path: {
                'productTagID': productTagId,
            },
        });
    }
    /**
     * Update Product Tag
     * @returns any OK
     * @throws ApiError
     */
    public static updateProductTag({
        productTagId,
        requestBody,
    }: {
        productTagId: string,
        requestBody?: {
            name: string;
            description: string;
        },
    }): CancelablePromise<{
        productTag: ProductTag;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/productTags/{productTagID}',
            path: {
                'productTagID': productTagId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Product Tag
     * @returns void
     * @throws ApiError
     */
    public static deleteProductTag({
        productTagId,
    }: {
        productTagId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/productTags/{productTagID}',
            path: {
                'productTagID': productTagId,
            },
        });
    }
    /**
     * Set Product Price Enabled
     * @returns void
     * @throws ApiError
     */
    public static setProductPriceEnabled({
        productPriceId,
        requestBody,
    }: {
        productPriceId: string,
        requestBody?: {
            enabled: boolean;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/productPrices/{productPriceID}/enabled',
            path: {
                'productPriceID': productPriceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Product Selling Case of Stats
     * @returns any OK
     * @throws ApiError
     */
    public static getProductSellingCaseOfStats({
        productId,
        timePeriod,
    }: {
        productId: string,
        /**
         * monthly or weekly
         */
        timePeriod?: string,
    }): CancelablePromise<{
        stats: Array<{
            date: string;
            caseOfStats: Array<{
                caseOf: string;
                distinctSales: string;
                quantitySold: string;
                pcsSold: string;
            }>;
        }>;
        timeperiod: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{productID}/sellingCaseOfStats',
            path: {
                'productID': productId,
            },
            query: {
                'timePeriod': timePeriod,
            },
        });
    }
}
