import { PX1024, PX32, PX384, PX64, PX8, PX96 } from '../../../components/Px';
import {
  Button,
  Checkbox,
  Code,
  Container,
  Flex,
  Group,
  Text,
  NumberInput,
  Pagination,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  Currency,
  DefaultOperations,
  PaymentMethod,
  PurchaseOrder,
  PurchaseOrderPaymentTerm,
  Store,
  Vendor,
} from '../../../oas/codegen3';
import { findCurrency, formatToCurrency } from '@pcc/api/models/Currency';
import {
  dateToRFC3339,
  RFC3339ToEasyDate,
} from '../../../utils/dateFormatting';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { StoreSelectorModalBody } from '@components/V2/modal_selector/StoreSelectorModalBody';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { PaymentMethodModalSelector } from '@components/V2/modal_selector/PaymentMethodModalSelector';
import { useForm } from '@mantine/form';
import Big from 'big.js';
import { toBig, toBigOrNull } from '../../../utils/toBigOrNull';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowLeft,
  IconArrowRight,
  IconRefresh,
  IconTrash,
} from '@tabler/icons-react';
import { useNavigate } from '@reach/router';
import { useInputState } from '@mantine/hooks';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { ModalSelector } from '@components/V2/modal_selector/ModalSelectorBody';
import { RingProgressCard } from '@components/V2/RingProgressCard';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { Link } from '@components/V2/Link';
import { formatDateRange } from '../../../utils/formatStartAndEndDate';
import { DateRangePickerModal } from '@components/V2/modal_selector/DateRangePickerModal';
import { DatePickerInput } from '@mantine/dates';
import { PaymentMethodMetadataManager } from '@components/V2/PaymentMethodMetadataManager';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const CreatePurchaseOrderBulkPaymentsPage = ({}: { path: string }) => {
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = React.useState<{
    [key: string]: {
      purchaseOrder: PurchaseOrder;
      currency: Currency;
      vendor: Vendor;
      store?: Store;
    };
  }>({});

  const togglePurchaseOrderSelected = (po: {
    purchaseOrder: PurchaseOrder;
    currency: Currency;
    vendor: Vendor;
    store?: Store;
  }) => {
    const poExists = selectedPurchaseOrders[po.purchaseOrder.id] !== undefined;

    if (poExists) {
      setSelectedPurchaseOrders((prev) => {
        delete prev[po.purchaseOrder.id];
        return { ...prev };
      });
    } else {
      setSelectedPurchaseOrders((prev) => {
        return {
          ...prev,
          [po.purchaseOrder.id]: po,
        };
      });
    }
  };

  const resetBulkPaymentSubmitter = () => {
    setSelectedPurchaseOrders({});
    setPage(1);
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer loading={submitting}>
        <Stack>
          <Title>Bulk Payments</Title>
          {page === 1 && (
            <PurchaseOrdersListTable
              clearPOSelection={() => setSelectedPurchaseOrders({})}
              goToNextPage={() => setPage(2)}
              selectedPurchaseOrders={selectedPurchaseOrders}
              togglePurchaseOrderSelected={togglePurchaseOrderSelected}
            />
          )}
          {page === 2 && (
            <PurchaseOrderBulkPaymentSubmitter
              currency={Object.values(selectedPurchaseOrders)[0].currency}
              resetBulkPaymentSubmitter={resetBulkPaymentSubmitter}
              submitting={(b) => setSubmitting(b)}
              goBack={() => setPage(1)}
              purchaseOrders={Object.values(selectedPurchaseOrders)}
            />
          )}
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const PurchaseOrderBulkPaymentSubmitter = ({
  currency,
  purchaseOrders,
  goBack,
  submitting,
}: {
  currency: Currency;
  goBack: () => void;
  purchaseOrders: {
    purchaseOrder: PurchaseOrder;
    currency: Currency;
    vendor: Vendor;
    store?: Store;
  }[];
  submitting: (b: boolean) => void;
  resetBulkPaymentSubmitter: () => void;
}) => {
  const navigate = useNavigate();
  const [searchTarget, setSearchTarget] = useInputState('');
  const [paymentDate, setPaymentDate] = useState<Date | null>(null);
  const [voucherReference, setVoucherReference] = useInputState('');
  const [paymentDetails, setPaymentDetails] = useState<{
    [key: string]: {
      purchaseOrder: PurchaseOrder;
      amount: Big | null;
      metadata: { [key: string]: string };
      valid: boolean;
    };
  }>({});
  const [targetPaymentMethod, setPaymentMethod] =
    useState<PaymentMethod | null>(null);

  const createBulkPurchaseOrderPayment = useStatefulAPIRequestMaker(
    DefaultOperations.createBulkPurchaseOrderPayment
  );

  useEffect(() => {
    const initialPaymentDetails: {
      [key: string]: {
        purchaseOrder: PurchaseOrder;
        amount: Big | null;
        metadata: { [key: string]: string };
        valid: boolean;
      };
    } = {};

    purchaseOrders.forEach((po) => {
      initialPaymentDetails[po.purchaseOrder.id] = {
        purchaseOrder: po.purchaseOrder,
        amount: toBigOrNull(po.purchaseOrder.balanceAmount),
        metadata: {},
        valid: true,
      };
    });

    setPaymentDetails(initialPaymentDetails);
  }, [purchaseOrders]);

  const showPaymentMethodSelectorModal = () => {
    openModal({
      modalId: 'payment-method-selector-modal',
      title: 'Select Payment Method',
      children: (
        <PaymentMethodModalSelector
          targetPaymentType="purchase_order_payment"
          onPaymentMethodSelected={(pm) => {
            setPaymentMethod(pm);
            closeModal('payment-method-selector-modal');
          }}
        />
      ),
    });
  };

  let totalAmount = new Big(0);
  let allPaymentsValid = true;
  Object.values(purchaseOrders).forEach((po) => {
    const details = paymentDetails[po.purchaseOrder.id];
    if (!details) {
      allPaymentsValid = false;
    } else if (!details.valid) {
      allPaymentsValid = false;
    }

    totalAmount = totalAmount.add(toBig(details?.amount, 0));
  });

  const onSubmit = async () => {
    if (!allPaymentsValid) {
      return;
    }

    openConfirmModal({
      title: 'Confirm Bulk Payment',
      children: 'Are you sure you want to submit this bulk payment?',
      labels: {
        confirm: 'Yes - Create Bulk Payment',
        cancel: 'Cancel',
      },
      confirmProps: {
        color: 'green',
      },
      onConfirm: async () => {
        submitting(true);
        try {
          await createBulkPurchaseOrderPayment.execute({
            requestBody: {
              paymentMethodID: targetPaymentMethod?.id,
              currency: currency.iso4217Code,
              bulkPurchaseOrderPayment: Object.values(paymentDetails).map(
                (i) => {
                  return {
                    purchaseOrderID: i.purchaseOrder.id,
                    paymentAmount: i.amount!.toString(),
                    paymentMetadata: i.metadata ?? {},
                  };
                }
              ),
              voucherReference:
                voucherReference !== '' ? voucherReference : undefined,
              paymentDate: paymentDate?.toISOString() ?? undefined,
            },
          });

          showNotification({
            color: 'green',
            title: 'Payments Submitted',
            message: 'The bulk payment has been submitted.',
          });

          navigate('/purchaseOrders/bulkPayments');
        } catch (e) {
          handleApiErrorMessage(e);
        }

        submitting(false);
      },
    });
  };

  return (
    <Stack>
      <Group>
        <Button onClick={goBack} variant="light" leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Group>
      <Group grow>
        <RingProgressCard
          label="Payment Amount"
          amount={formatToCurrency(totalAmount, currency)}
        />
      </Group>
      <Group grow>
        <TextInput
          onClick={showPaymentMethodSelectorModal}
          readOnly
          label="Payment Method"
          value={targetPaymentMethod?.name ?? 'Cash'}
        />

        <TextInput
          label="Voucher Reference"
          placeholder="Voucher Reference"
          value={voucherReference}
          onChange={setVoucherReference}
        />

        <DatePickerInput
          firstDayOfWeek={0}
          weekendDays={[5, 6]}
          value={paymentDate}
          onChange={setPaymentDate}
          label="Payment Date"
          placeholder="Select a payment date"
        />
      </Group>
      <Group>
        <Button disabled={!allPaymentsValid} onClick={onSubmit} color="green">
          Submit
        </Button>
      </Group>
      <TextInput
        label="Search"
        placeholder="Search"
        value={searchTarget}
        onChange={setSearchTarget}
      />
      <Table>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Vendor Invoice Reference</th>
            <th style={{ textAlign: 'right' }}>Amount</th>
            <th style={{ textAlign: 'right' }}>Balance</th>
            <th style={{ textAlign: 'right' }}>Payment Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {purchaseOrders
            .filter(
              (po) =>
                po.purchaseOrder.number
                  .toLowerCase()
                  .startsWith(searchTarget.toLowerCase()) ||
                (po.purchaseOrder.vendorInvoiceReference ?? '')
                  .toLowerCase()
                  .startsWith(searchTarget.toLowerCase())
            )
            .map((i) => {
              const paymentDetail = paymentDetails[i.purchaseOrder.id];

              const openPaymentEditorModal = () => {
                openModal({
                  modalId: 'edit-purchase-order-payment-modal',
                  title: <Title order={3}>Edit Payment</Title>,
                  children: (
                    <EditPurchaseOrderPaymentModal
                      currency={i.currency}
                      purchaseOrder={i.purchaseOrder}
                      paymentMethod={targetPaymentMethod}
                      currentPaymentDetails={paymentDetail}
                      onUpdated={(values) => {
                        setPaymentDetails((prev) => {
                          return {
                            ...prev,
                            [i.purchaseOrder.id]: {
                              purchaseOrder: i.purchaseOrder,
                              amount: values.amount,
                              metadata: values.metadata,
                              valid: values.valid,
                            },
                          };
                        });
                        closeModal('edit-purchase-order-payment-modal');
                      }}
                    />
                  ),
                });
              };

              const valid = paymentDetail?.valid ?? false;

              return (
                <tr key={i.purchaseOrder.id}>
                  <td>
                    <Link to={`/purchaseOrders/${i.purchaseOrder.id}`} withIcon>
                      <Code>{i.purchaseOrder.number}</Code>
                    </Link>
                  </td>
                  <td>{i.purchaseOrder.vendorInvoiceReference}</td>
                  <td style={{ textAlign: 'right' }}>
                    {formatToCurrency(i.purchaseOrder.totalAmount, i.currency)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatToCurrency(
                      i.purchaseOrder.balanceAmount,
                      i.currency
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Text color={valid ? 'green' : 'red'} fw="bold">
                      {formatToCurrency(paymentDetail?.amount, i.currency)}
                    </Text>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Button
                      compact
                      variant="light"
                      onClick={openPaymentEditorModal}
                    >
                      Edit Payment
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Stack>
  );
};

const EditPurchaseOrderPaymentModal = ({
  purchaseOrder,
  currency,
  paymentMethod,
  onUpdated,
  currentPaymentDetails,
}: {
  purchaseOrder: PurchaseOrder;
  currency: Currency;
  paymentMethod: PaymentMethod | null;
  onUpdated: (values: {
    amount: Big | null;
    metadata: { [key: string]: string };
    valid: boolean;
  }) => void;
  currentPaymentDetails: {
    amount: Big | null;
    metadata: { [key: string]: string };
    valid: boolean;
  };
}) => {
  const form = useForm<{
    amount?: number;
    paymentMethodMetadata: { [key: string]: string };
    valid: boolean;
  }>({
    initialValues: {
      paymentMethodMetadata: {},
      valid: true,
    },
    validate: {
      amount: (value: any) =>
        !value || value <= 0 ? 'Must be greater than 0.' : null,
      paymentMethodMetadata: (value: any) => {
        if (!paymentMethod) {
          return null;
        }

        paymentMethod.requiredMetadataFields?.forEach((fieldName) => {
          if (!value[fieldName] || value[fieldName] === '') {
            return 'Required.';
          }
        });
      },
    },
  });

  useEffect(() => {
    form.setValues({
      amount: currentPaymentDetails.amount?.toNumber(),
      paymentMethodMetadata: currentPaymentDetails.metadata,
      valid: currentPaymentDetails.valid,
    });
  }, [currentPaymentDetails]);

  const onMetadataUpdated = (values: {
    metadata: { [key: string]: string };
    valid: boolean;
  }) => {
    form.setValues({
      paymentMethodMetadata: values.metadata,
      valid: values.valid,
    });
  };

  const balance = purchaseOrder.balanceAmount
    ? formatToCurrency(purchaseOrder.balanceAmount, currency)
    : '';

  const balanceAmount = toBig(purchaseOrder.balanceAmount, 0);
  const amount = toBig(form.values.amount, 0);

  const amountError = amount!.gt(balanceAmount!)
    ? 'Cannot be greater than balance amount.'
    : !form.values.amount
    ? 'Required.'
    : amount!.lte(new Big(0)!)
    ? 'Required.'
    : null;

  const updatePaymentDetails = () => {
    onUpdated({
      amount: form.values.amount ? new Big(form.values.amount) : null,
      metadata: form.values.paymentMethodMetadata,
      valid: form.values.valid,
    });
  };

  return (
    <Stack spacing={PX8.Number}>
      <TextInput readOnly label="Purchase Order Balance" value={balance} />
      <NumberInput
        min={0}
        precision={currency.decimalPlaces}
        label="Payment Amount"
        placeholder="Payment Amount"
        {...form.getInputProps('amount')}
        error={amountError}
      />
      <PaymentMethodMetadataManager
        paymentMethod={paymentMethod}
        onUpdated={onMetadataUpdated}
        metadata={currentPaymentDetails.metadata}
      />
      <Button
        onClick={updatePaymentDetails}
        disabled={!form.values.valid || amountError !== null}
      >
        Submit
      </Button>
    </Stack>
  );
};

const PurchaseOrdersListTable = ({
  selectedPurchaseOrders,
  togglePurchaseOrderSelected,
  goToNextPage,
  clearPOSelection,
}: {
  clearPOSelection: () => void;
  goToNextPage: () => void;
  selectedPurchaseOrders: {
    [key: string]: {
      purchaseOrder: PurchaseOrder;
      currency: Currency;
      vendor: Vendor;
      store?: Store;
    };
  };
  togglePurchaseOrderSelected: (po: {
    purchaseOrder: PurchaseOrder;
    currency: Currency;
    vendor: Vendor;
    store?: Store;
  }) => void;
}) => {
  const currency = findCurrency('BHD');
  const listPurchaseOrders = useStatefulAPIRequestMaker(
    DefaultOperations.listPurchaseOrders
  );
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [store, setStore] = useState<Store | null>(null);

  const [paymentTerm, setPaymentTerm] =
    useState<PurchaseOrderPaymentTerm | null>(null);
  const { target, setTargetFromInput, onChangeDeps, pagination } =
    useListSearcherHelper({
      limitPerPage: 30,
      debounceDelay: 300,
      additionalDeps: [dateRange[0], dateRange[1], store, paymentTerm],
    });

  const refresh = () => {
    if (listPurchaseOrders.loading) {
      return;
    }
    listPurchaseOrders.execute({
      paymentTerms: paymentTerm ?? undefined,
      isCreditNote: false,
      hasBalanceRemaining: true,
      state: 'posted',
      storeId: store?.id,
      limit: pagination.limit,
      offset: pagination.offset,
      target: target,
      startDate: dateToRFC3339(dateRange[0]) ?? undefined,
      endDate: dateToRFC3339(dateRange[1]) ?? undefined,
    });
  };

  useEffect(() => {
    listPurchaseOrders.execute({
      paymentTerms: paymentTerm ?? undefined,
      isCreditNote: false,
      hasBalanceRemaining: true,
      state: 'posted',
      storeId: store?.id,
      limit: pagination.limit,
      offset: pagination.offset,
      target: target,
      startDate: dateToRFC3339(dateRange[0]) ?? undefined,
      endDate: dateToRFC3339(dateRange[1]) ?? undefined,
    });
  }, [...onChangeDeps]);

  const selectedPOKeys = Object.keys(selectedPurchaseOrders);
  const numberOfSelectedPurchaseOrders = selectedPOKeys.length;

  let balanceAmount = new Big(0);
  const selectedPoValues = Object.values(selectedPurchaseOrders);
  selectedPoValues.forEach((po) => {
    balanceAmount = balanceAmount.add(
      new Big(po.purchaseOrder.balanceAmount ?? 0)
    );
  });

  const onStoreBadgeClicked = () => {
    openModal({
      modalId: 'store-selector-modal',
      title: 'Select Vendor',
      children: (
        <StoreSelectorModalBody
          onStoreSelected={(s) => {
            setStore(s);
            closeModal('store-selector-modal');
          }}
        />
      ),
    });
  };

  const showPaymentTermSelectorModal = () => {
    openModal({
      modalId: 'payment-terms-modal',
      title: 'Select Vendor',
      children: (
        <ModalSelector
          items={[
            { label: 'Cash', value: 'cash' },
            { label: 'Credit', value: 'credit' },
          ]}
          onItemSelected={(i) => {
            setPaymentTerm(i.value as PurchaseOrderPaymentTerm);
            closeModal('payment-terms-modal');
          }}
        />
      ),
    });
  };

  const allItemsChecked =
    (listPurchaseOrders.response?.purchaseOrders.length ?? 0) > 0 &&
    listPurchaseOrders.response?.purchaseOrders.findIndex((po) => {
      return selectedPurchaseOrders[po.purchaseOrder.id] === undefined;
    }) === -1;

  const toggleAllItemCheck = useCallback(() => {
    if (allItemsChecked) {
      listPurchaseOrders.response?.purchaseOrders.forEach((po) => {
        togglePurchaseOrderSelected({
          purchaseOrder: po.purchaseOrder,
          currency: po.currency,
          vendor: po.vendor,
          store: po.store,
        });
      });
    } else {
      listPurchaseOrders.response?.purchaseOrders.forEach((po) => {
        if (selectedPurchaseOrders[po.purchaseOrder.id] === undefined) {
          togglePurchaseOrderSelected({
            purchaseOrder: po.purchaseOrder,
            currency: po.currency,
            vendor: po.vendor,
            store: po.store,
          });
        }
      });
    }
  }, [allItemsChecked, listPurchaseOrders.response?.purchaseOrders]);

  const showDatePickerModal = () => {
    openModal({
      modalId: 'date-range-modal',
      title: <Title order={3}>Select Date Range</Title>,
      size: 'sm',
      children: (
        <DateRangePickerModal
          onDateRangeSelected={(dr) => {
            setDateRange(dr);
            closeModal('date-range-modal');
          }}
        />
      ),
    });
  };

  return (
    <Stack>
      <Group position="apart" align="flex-end">
        <Group>
          <Button
            onClick={clearPOSelection}
            color="red"
            variant="light"
            leftIcon={<IconTrash />}
          >
            Clear
          </Button>

          <Button
            color="blue"
            variant="light"
            onClick={refresh}
            leftIcon={<IconRefresh />}
          >
            Refresh
          </Button>
        </Group>
        <Button
          onClick={goToNextPage}
          disabled={selectedPoValues.length === 0}
          variant="light"
          rightIcon={<IconArrowRight />}
        >
          Next
        </Button>
      </Group>
      <Group align="flex-end">
        <RingProgressCard
          label="Purchase Orders"
          amount={numberOfSelectedPurchaseOrders.toString()}
        />
        <RingProgressCard
          label="Balance Amount"
          amount={formatToCurrency(balanceAmount, currency)}
        />
      </Group>

      <TextInput
        label="Search"
        placeholder="Search"
        value={target}
        onChange={setTargetFromInput}
      />

      <Group>
        <SelectorBadge
          onClick={showPaymentTermSelectorModal}
          onClearClick={() => setPaymentTerm(null)}
          placeholder="Select Payment Term"
          value={paymentTerm}
        />

        <SelectorBadge
          onClick={onStoreBadgeClicked}
          onClearClick={() => setStore(null)}
          placeholder="Select Store"
          value={store?.name}
        />

        <SelectorBadge
          value={formatDateRange(dateRange)}
          placeholder="Date Range"
          onClick={showDatePickerModal}
          onClearClick={() => setDateRange([null, null])}
        />
      </Group>

      <LoadingContainer
        loading={listPurchaseOrders.loading}
        minHeight={PX384.Number}
      >
        <Stack>
          {listPurchaseOrders.response?.purchaseOrdersTotalCount && (
            <Flex justify="flex-end">
              <Pagination
                size="sm"
                total={pagination.calculateTotalPages(
                  listPurchaseOrders.response?.purchaseOrdersTotalCount
                )}
                value={pagination.currentPaginationPage}
                onChange={pagination.onPaginationPageChange}
              />
            </Flex>
          )}
          <Table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={allItemsChecked}
                    onChange={toggleAllItemCheck}
                  />
                </th>
                <th>Order Number</th>
                <th>Created At</th>
                <th>Vendor</th>
                <th>Store</th>
                <th style={{ textAlign: 'right' }}>Amount</th>
                <th style={{ textAlign: 'right' }}>Balance</th>
              </tr>
            </thead>
            <tbody>
              {(listPurchaseOrders.response?.purchaseOrders || []).map((i) => (
                <PurchaseOrdersTableRow
                  item={i}
                  checked={
                    selectedPurchaseOrders[i.purchaseOrder.id] !== undefined
                  }
                  togglePurchaseOrderSelected={togglePurchaseOrderSelected}
                />
              ))}
            </tbody>
          </Table>
          {listPurchaseOrders.response?.purchaseOrdersTotalCount && (
            <Flex justify="flex-end">
              <Pagination
                size="sm"
                total={pagination.calculateTotalPages(
                  listPurchaseOrders.response?.purchaseOrdersTotalCount
                )}
                value={pagination.currentPaginationPage}
                onChange={pagination.onPaginationPageChange}
              />
            </Flex>
          )}
        </Stack>
      </LoadingContainer>
    </Stack>
  );
};

const PurchaseOrdersTableRow = memo(
  ({
    item,
    checked,
    togglePurchaseOrderSelected,
  }: {
    item: {
      vendor: Vendor;
      store?: Store;
      purchaseOrder: PurchaseOrder;
      currency: Currency;
    };
    checked: boolean;
    togglePurchaseOrderSelected: (po: {
      purchaseOrder: PurchaseOrder;
      currency: Currency;
      vendor: Vendor;
      store?: Store;
    }) => void;
  }) => {
    const { purchaseOrder, currency, store, vendor } = item;

    const totalAmount = purchaseOrder.totalAmount
      ? formatToCurrency(purchaseOrder.totalAmount, currency)
      : '';
    const balance = purchaseOrder.balanceAmount
      ? formatToCurrency(purchaseOrder.balanceAmount, currency)
      : '';

    const managePOSelection = () => {
      togglePurchaseOrderSelected({
        purchaseOrder: purchaseOrder,
        currency: currency,
        vendor: vendor,
        store: store,
      });
    };

    return (
      <tr key={purchaseOrder.id}>
        <td>
          <Checkbox checked={checked} onClick={managePOSelection} />
        </td>
        <td>
          <Link to={`/purchaseOrders/${purchaseOrder.id}`} withIcon hidden>
            <Code style={{ whiteSpace: 'nowrap' }}>{purchaseOrder.number}</Code>
          </Link>
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {RFC3339ToEasyDate(purchaseOrder.createdAt)}
        </td>
        <td>{vendor.name}</td>
        <td>{store?.name || 'No Store'}</td>
        <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
          {totalAmount}
        </td>
        <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{balance}</td>
      </tr>
    );
  },
  (prev, next) => {
    return prev.checked === next.checked && prev.item === next.item;
  }
);
