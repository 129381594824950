import React, { useEffect, useState } from 'react';
import {
  ActionIcon,
  Button,
  Center,
  Group,
  Stack,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconEqual,
  IconEqualNot,
  IconMathEqualGreater,
  IconMathEqualLower,
  IconMathGreater,
  IconMathLower,
  TablerIconsProps,
} from '@tabler/icons-react';
import { useInputState } from '@mantine/hooks';
import { toBigOrNull } from '../../utils/toBigOrNull';

export type Comparator = '=' | '!=' | '>' | '>=' | '<' | '<=';

export const ComparatorToPrettyString = (c: Comparator) => {
  switch (c) {
    case '=':
      return '=';
    case '!=':
      return '≠';
    case '>':
      return '>';
    case '>=':
      return '≥';
    case '<':
      return '<';
    case '<=':
      return '≤';
  }
};

export type NumericComparator = { number: number; comparator: Comparator };

export const NumericComparatorToString = (
  nc?: NumericComparator | null
): string | null => {
  if (!nc) {
    return null;
  }
  return `${nc.comparator} ${nc.number}`;
};

export const NumericComparatorSelectorModal = ({
  number: startingNumber,
  comparator,
  onSelected,
}: {
  number?: number | null;
  comparator?: Comparator | null;
  onSelected: (nc: NumericComparator) => void;
}) => {
  const [selectedComparator, setSelectedComparator] =
    useState<Comparator | null>(comparator ?? null);
  const [numberStr, setNumberStr] = useInputState(`${startingNumber ?? ''}`);
  const [number, setNumber] = useState<number | null>(startingNumber ?? null);

  useEffect(() => {
    const n = toBigOrNull(numberStr);
    setNumber(n?.toNumber() ?? null);
  }, [numberStr]);

  const onComparatorSelected = () => {
    if (number === null || !selectedComparator) {
      return;
    }

    onSelected({
      comparator: selectedComparator,
      number: number as number,
    });
  };

  const comparatorIconData: {
    icon: (props: TablerIconsProps) => JSX.Element;
    comparator: Comparator;
    tooltip: string;
  }[] = [
    { icon: IconEqual, comparator: '=', tooltip: 'Equal' },
    { icon: IconEqualNot, comparator: '!=', tooltip: 'Not Equal' },
    { icon: IconMathGreater, comparator: '>', tooltip: 'Greater Than' },
    {
      icon: IconMathEqualGreater,
      comparator: '>=',
      tooltip: 'Greater Than or Equal',
    },
    { icon: IconMathLower, comparator: '<', tooltip: 'Less Than' },
    {
      icon: IconMathEqualLower,
      comparator: '<=',
      tooltip: 'Less Than or Equal',
    },
  ];

  return (
    <Stack>
      <Center>
        <Group>
          {comparatorIconData.map((data) => (
            <Tooltip key={data.comparator} label={data.tooltip} withinPortal>
              <ActionIcon
                color="orange"
                onClick={() => setSelectedComparator(data.comparator)}
                variant={
                  selectedComparator === data.comparator ? 'filled' : 'outline'
                }
              >
                <data.icon size="1.125rem" />
              </ActionIcon>
            </Tooltip>
          ))}
        </Group>
      </Center>
      <TextInput
        label="Number"
        value={numberStr}
        onChange={setNumberStr}
        placeholder="Enter a Number"
        required
      />
      <Button
        onClick={onComparatorSelected}
        disabled={selectedComparator === null || number === null}
      >
        Submit
      </Button>
    </Stack>
  );
};
