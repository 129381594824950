export const PX4 = { PX: '4px', Number: 4 };
export const PX8 = { PX: '8px', Number: 8 };
export const PX12 = { PX: '12px', Number: 12 };
export const PX16 = { PX: '16px', Number: 16 };
export const PX24 = { PX: '24px', Number: 24 };
export const PX32 = { PX: '32px', Number: 32 };
export const PX48 = { PX: '48px', Number: 48 };
export const PX64 = { PX: '64px', Number: 64 };
export const PX96 = { PX: '96px', Number: 96 };
export const PX128 = { PX: '128px', Number: 128 };
export const PX192 = { PX: '192px', Number: 192 };
export const PX256 = { PX: '256px', Number: 256 };
export const PX384 = { PX: '384px', Number: 384 };
export const PX512 = { PX: '512px', Number: 512 };
export const PX640 = { PX: '640px', Number: 640 };
export const PX768 = { PX: '768px', Number: 768 };
export const PX1024 = { PX: '1024px', Number: 1024 };
export const PX1536 = { PX: '1536px', Number: 1536 };
