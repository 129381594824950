import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  CorePaymentState,
  DefaultOperations,
  PaymentMethod,
  Vendor,
} from '../../../oas/codegen3';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Container,
  Group,
  NumberInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { v4 as uuidv4 } from 'uuid';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { VendorSelectorModalBody } from '@components/V2/modal_selector/VendorSelectorModalBody';
import React, { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { IconCheck, IconX } from '@tabler/icons-react';
import { PaymentMethodModalSelector } from '@components/V2/modal_selector/PaymentMethodModalSelector';
import { PaymentMethodMetadataManager } from '@components/V2/PaymentMethodMetadataManager';
import { showNotification } from '@mantine/notifications';
import { StatusBadge } from '@components/V2/StatusBadge';
import { RingProgressCard } from '@components/V2/RingProgressCard';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { useNavigate } from '@reach/router';
import { toDateOrNull } from '../../../utils/toDateOrNull';
import { DatePickerInput } from '@mantine/dates';

export const PurchaseOrderPaymentForm = ({
  purchaseOrderPaymentID,
}: {
  path: string;
  purchaseOrderPaymentID?: string;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const createPurchaseOrderPayment = useStatefulAPIRequestMaker(
    DefaultOperations.createPurchaseOrderPayment
  );

  const updatePurchaseOrderPayment = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderPayment
  );

  const listPaymentMethods = useStatefulAPIRequestMaker(
    DefaultOperations.getPaymentMethods
  );

  const getPurchaseOrderPayment = useStatefulAPIRequestMaker(
    DefaultOperations.getPurchaseOrderPayment
  );
  const paymentState = getPurchaseOrderPayment.response?.corePayment.state;

  const corePayment = getPurchaseOrderPayment.response?.corePayment;
  const currency = getPurchaseOrderPayment.response?.currency;

  useEffect(() => {
    listPaymentMethods.execute({
      targetPaymentType: 'purchase_order_payment',
      enabled: true,
    });
  }, []);

  const form = useForm<{
    vendor: Vendor | null;
    amount: number | '';
    paymentMethod: PaymentMethod | null;
    paymentMethodMetadata: { [key: string]: string };
    paymentMethodMetadataValid: boolean;
    currency: string;
    voucherReference?: string;
    paymentDate: Date | null;
  }>({
    initialValues: {
      vendor: null,
      amount: '',
      paymentMethod: null,
      paymentMethodMetadata: {},
      paymentMethodMetadataValid: true,
      currency: 'BHD',
      paymentDate: null,
    },
  });

  useEffect(() => {
    if (purchaseOrderPaymentID) {
      getPurchaseOrderPayment.execute({
        purchaseOrderPaymentId: purchaseOrderPaymentID,
      });
    }
  }, [purchaseOrderPaymentID]);

  useEffect(() => {
    if (getPurchaseOrderPayment.response) {
      const response = getPurchaseOrderPayment.response;
      form.setValues({
        vendor: response.vendor,
        amount: Number(response.corePayment.amount),
        paymentMethod: response.paymentMethod,
        paymentMethodMetadata: response.corePayment.metadata,
        paymentMethodMetadataValid: true,
        currency: response.corePayment.currency,
        voucherReference: response.purchaseOrderPayment.voucherReference,
        paymentDate: toDateOrNull(response.purchaseOrderPayment.paymentDate),
      });
    }
  }, [getPurchaseOrderPayment.response, getPurchaseOrderPayment.responseID]);

  const showVendorSelectorModal = () => {
    const modalID = uuidv4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Select Vendor</Title>,
      children: (
        <VendorSelectorModalBody
          onVendorSelected={(v) => {
            form.setValues({ vendor: v });
            closeModal(modalID);
          }}
        />
      ),
    });
  };

  const showPaymentMethodSelectorModal = () => {
    openModal({
      modalId: 'payment-method-selector-modal',
      title: <Title order={3}>Select Payment Method</Title>,
      children: (
        <PaymentMethodModalSelector
          targetPaymentType="purchase_order_payment"
          onPaymentMethodSelected={(pm) => {
            form.setValues({ paymentMethod: pm });
            closeModal('payment-method-selector-modal');
          }}
        />
      ),
    });
  };

  const onSubmit = form.onSubmit(async (values) => {
    if (
      !values.amount ||
      !values.vendor ||
      !values.paymentMethodMetadataValid
    ) {
      return;
    }

    if (!purchaseOrderPaymentID) {
      const response = await createPurchaseOrderPayment.execute({
        requestBody: {
          vendorID: values.vendor.id,
          amount: String(values.amount),
          paymentMethodID: values.paymentMethod?.id,
          metadata: values.paymentMethodMetadata,
          currency: values.currency,
          voucherReference: values.voucherReference,
          paymentDate: values.paymentDate?.toISOString() ?? undefined,
        },
      });

      showNotification({
        color: 'green',
        title: 'Payment Created',
        message: 'The payment has been created successfully.',
      });

      navigate(`/purchaseOrders/payments/${response.purchaseOrderPayment.id}`);
    } else {
      await updatePurchaseOrderPayment.execute({
        purchaseOrderPaymentId: purchaseOrderPaymentID,
        requestBody: {
          vendorID: values.vendor.id,
          amount: String(values.amount),
          paymentMethodID: values.paymentMethod?.id,
          metadata: values.paymentMethodMetadata,
          voucherReference: values.voucherReference,
          paymentDate: values.paymentDate?.toISOString() ?? undefined,
        },
      });

      showNotification({
        color: 'green',
        title: 'Payment Updated',
        message: 'The payment has been updated successfully.',
      });
    }
  });

  const refreshPayment = () => {
    if (purchaseOrderPaymentID) {
      getPurchaseOrderPayment.execute({
        purchaseOrderPaymentId: purchaseOrderPaymentID,
      });
    }
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer
        loading={
          createPurchaseOrderPayment.loading ||
          getPurchaseOrderPayment.loading ||
          updatePurchaseOrderPayment.loading ||
          loading
        }
        minHeight={PX384.Number}
      >
        <Stack>
          <Title>
            {purchaseOrderPaymentID
              ? getPurchaseOrderPayment.response?.purchaseOrderPayment.reference
              : 'Purchase Order Payment'}
          </Title>
          {getPurchaseOrderPayment.response && (
            <Group>
              <StatusBadge
                state={getPurchaseOrderPayment.response.corePayment.state}
              />
            </Group>
          )}

          <ActionButtons
            purchaseOrderPaymentID={purchaseOrderPaymentID}
            refreshPayment={refreshPayment}
            setLoading={setLoading}
            corePaymentState={
              getPurchaseOrderPayment.response?.corePayment.state
            }
          />

          {currency && paymentState === 'posted' && (
            <Group grow>
              <RingProgressCard
                label="Payment Amount"
                amount={formatToCurrency(corePayment?.amount ?? 0, currency)}
              />
              <RingProgressCard
                label="Amount Remaining"
                amount={formatToCurrency(
                  corePayment?.amountBalance ?? 0,
                  currency
                )}
              />
            </Group>
          )}
          <form onSubmit={onSubmit}>
            <Stack>
              <TextInput
                required
                label="Vendor"
                placeholder="Vendor"
                onClick={showVendorSelectorModal}
                disabled={
                  paymentState === 'posted' || paymentState === 'cancelled'
                }
                rightSection={
                  <IconX
                    size={14}
                    stroke={1.5}
                    onClick={() => {
                      form.setValues({ vendor: null });
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                }
                value={form.values.vendor?.name || ''}
                readOnly={true}
              />

              <NumberInput
                min={0}
                precision={3}
                label="Amount"
                placeholder="Amount"
                required
                disabled={
                  paymentState === 'posted' || paymentState === 'cancelled'
                }
                {...form.getInputProps('amount')}
              />

              <DatePickerInput
                label="Payment Date"
                placeholder="Payment Date"
                disabled={paymentState === 'cancelled'}
                {...form.getInputProps('paymentDate')}
              />

              <TextInput
                label="Voucher Reference"
                placeholder="Voucher Reference"
                disabled={paymentState === 'cancelled'}
                {...form.getInputProps('voucherReference')}
              />

              <TextInput
                onClick={showPaymentMethodSelectorModal}
                readOnly
                required
                disabled={paymentState === 'cancelled'}
                label="Payment Method"
                value={form.values.paymentMethod?.name ?? 'Cash'}
              />

              <PaymentMethodMetadataManager
                metadata={form.values.paymentMethodMetadata}
                paymentMethod={form.values.paymentMethod}
                disabled={paymentState === 'cancelled'}
                onUpdated={(updated) => {
                  form.setValues({
                    paymentMethodMetadata: updated.metadata,
                    paymentMethodMetadataValid: updated.valid,
                  });
                }}
              />

              <Group>
                <Button
                  type="submit"
                  color="green"
                  disabled={
                    !form.values.paymentMethodMetadataValid ||
                    paymentState === 'cancelled' ||
                    !form.values.vendor
                  }
                >
                  {purchaseOrderPaymentID ? 'Update Payment' : 'Create Payment'}
                </Button>
              </Group>
            </Stack>
          </form>
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const ActionButtons = ({
  purchaseOrderPaymentID,
  corePaymentState,
  refreshPayment,
  setLoading,
}: {
  setLoading: (b: boolean) => void;
  refreshPayment: () => void;
  purchaseOrderPaymentID?: string;
  corePaymentState?: CorePaymentState;
}) => {
  const updatePaymentState = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderPaymentState
  );

  useEffect(() => {
    setLoading(updatePaymentState.loading);
  }, [updatePaymentState.loading]);

  if (!corePaymentState || !purchaseOrderPaymentID) {
    return null;
  }

  const cancelPayment = async () => {
    openConfirmModal({
      title: <Title order={3}>Cancel Payment</Title>,
      children: (
        <Text size="sm">Are you sure you want to cancel this payment?</Text>
      ),
      labels: { confirm: 'Yes - Cancel', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        await updatePaymentState.execute({
          purchaseOrderPaymentId: purchaseOrderPaymentID,
          requestBody: {
            state: 'cancelled',
          },
        });

        showNotification({
          color: 'green',
          title: 'Payment Cancelled',
          message: 'The payment has been cancelled.',
        });

        refreshPayment();
      },
    });
  };

  const postPayment = async () => {
    openConfirmModal({
      title: <Title order={3}>Post Payment</Title>,
      children: (
        <Text size="sm">Are you sure you want to post this payment?</Text>
      ),
      labels: { confirm: 'Yes - Post', cancel: 'Cancel' },
      confirmProps: {
        color: 'green',
      },
      onConfirm: async () => {
        await updatePaymentState.execute({
          purchaseOrderPaymentId: purchaseOrderPaymentID,
          requestBody: {
            state: 'posted',
          },
        });

        showNotification({
          color: 'green',
          title: 'Payment Posted',
          message: 'The payment has been posted.',
        });

        refreshPayment();
      },
    });
  };

  return (
    <Group>
      {corePaymentState === 'draft' && (
        <Button
          color="red"
          variant="light"
          leftIcon={<IconX />}
          onClick={cancelPayment}
        >
          Cancel Payment
        </Button>
      )}
      {corePaymentState === 'draft' && (
        <Button
          color="green"
          variant="light"
          leftIcon={<IconCheck />}
          onClick={postPayment}
        >
          Post Payment
        </Button>
      )}
    </Group>
  );
};
