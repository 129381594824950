import { DefaultOperations, Vendor } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect, useRef } from 'react';
import { Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const VendorSelectorModalBody = ({
  onVendorSelected,
}: {
  onVendorSelected: (v: Vendor) => void;
}) => {
  const searchVendors = useStatefulAPIRequestMaker(
    DefaultOperations.searchVendors
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!searchVendors.loading) {
      inputRef.current?.focus();
    }
  }, [searchVendors.loading]);

  const {
    debounceValue,
    onChangeDeps,
    pagination,
    target,
    setTargetFromInput,
  } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
  });

  useEffect(() => {
    searchVendors.execute({ target: debounceValue, limit: pagination.limit });
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        ref={inputRef}
        autoFocus
        label="Search"
        placeholder="Search for Vendor"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={searchVendors.loading}>
        <Table highlightOnHover withBorder>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {(searchVendors.response?.vendors || []).map((v) => {
              const onClick = async () => {
                onVendorSelected(v);
              };

              return (
                <ClickableTableRow onClick={onClick} key={v.id}>
                  <td>{v.name}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
