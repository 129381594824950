import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { AlertMessage } from '../../../components/alert/Alert';
import { BHDCurrency, formatBig } from '../../../models/Currency';
import {
  ActionIcon,
  Button,
  Container,
  Flex,
  Menu,
  Modal,
  Stack,
  Table,
  Tabs,
  TextInput,
  Title,
  Text,
  Pagination,
  Group,
  Select,
  Alert,
  ThemeIcon,
  Tooltip as MantineTooltip,
} from '@mantine/core';
import {
  PX1024,
  PX32,
  PX384,
  PX4,
  PX64,
  PX768,
  PX96,
} from '../../../components/Px';
import { useForm } from '@mantine/form';
import {
  IconAdjustments,
  IconChartAreaLine,
  IconCircleOff,
  IconDots,
  IconPrinter,
  IconScale,
  IconSearch,
  IconTrash,
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

import { LoadingContainer } from '@components/V2/LoadingContainer';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  DefaultOperations,
  Product,
  StockLocationRole,
  User,
} from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import Big from 'big.js';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { UserModalSelectorBody } from '@components/V2/modal_selector/UserModalSelectorBody';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { capitalCase } from 'change-case';

import { b64toBlob } from '../../../utils/b64toBlob';
import { StockAdjustmentGroupsList } from '../stockAdjustmentGroupPages/StockAdjustmentGroupsList';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { StockBalanceHistory } from './StockBalanceHistory';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

export const StockLocationForm = ({
  stockLocationID,
}: {
  path: string;
  stockLocationID?: string;
  storeID?: string;
}) => {
  const alert = useAlert();
  const getStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.getStockLocation
  );
  useDocumentTitle(
    stockLocationID
      ? 'Stock Location: ' + getStockLocation.response?.stockLocation.name
      : 'New Stock Location'
  );

  const updateStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.updateStockLocation
  );
  const createStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.createStockLocation
  );

  const getStockBalancesPDF = useStatefulAPIRequestMaker(
    DefaultOperations.generateStockLocationStockBalancePdf
  );

  const getStockBalances = useStatefulAPIRequestMaker(
    DefaultOperations.getStockBalances
  );

  const [h1Text, setH1Text] = useState(
    stockLocationID ? '...' : 'New Stock Location'
  );
  const navigate = useNavigate();

  const form = useForm<{
    name?: string;
    currency?: string;
  }>({
    initialValues: {
      currency: 'BHD',
    },
  });

  const busyWithAPICalls =
    createStockLocation.loading ||
    updateStockLocation.loading ||
    getStockLocation.loading ||
    h1Text === '...';

  const fetchStockLocation = async () => {
    await getStockLocation.execute({ stockLocationId: stockLocationID! });
  };

  useEffect(() => {
    const stockLocation = getStockLocation.response?.stockLocation;

    if (stockLocation) {
      form.setValues({
        name: stockLocation.name,
      });
      setH1Text(stockLocation.name);
    }
  }, [getStockLocation.response?.stockLocation]);

  useEffect(() => {
    if (stockLocationID) {
      fetchStockLocation();
    }
  }, [stockLocationID]);

  const onFormSubmit = form.onSubmit(async (values) => {
    if (stockLocationID) {
      const updatedLocation = await updateStockLocation.execute({
        stockLocationId: stockLocationID,
        requestBody: {
          name: values.name!,
          currency: values.currency!,
        },
      });
      setH1Text(updatedLocation.stockLocation.name);
      alert.show(
        <AlertMessage title="Stock Location Updated" type="positive" />
      );
    } else {
      const response = await createStockLocation.execute({
        requestBody: {
          name: values.name!,
          currency: values.currency!,
        },
      });
      alert.show(
        <AlertMessage title="Stock Location Created" type="positive" />
      );
      navigate(`/stockLocations/${response.stockLocation.id}`);
    }
  });

  const cantSubmit = !form.values.name || !form.values.currency;

  const canViewUserPermissions = useFindPrivileges({
    action: 'read',
    resource: 'stock_location_user_privilege',
  });

  const getPdf = async () => {
    if (!stockLocationID) {
      return;
    }

    const response = await getStockBalancesPDF.execute({
      stockLocationId: stockLocationID,
    });

    const file = b64toBlob(response.pdfBase64, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const prepareAndShowPrintStockModal = async () => {
    if (!stockLocationID) {
      return;
    }

    const balancesPerPdf = 500;

    const response = await getStockBalances.execute({
      stockLocationId: stockLocationID!,
      limit: 0,
    });

    if (response.count <= balancesPerPdf) {
      await getPdf();
    } else {
      openModal({
        modalId: 'print-stock-balance-pdf',
        title: <Title order={3}>Pages</Title>,
        children: (
          <PrintStockPaginationModal
            balancesPerPdf={balancesPerPdf}
            stockLocationID={stockLocationID}
            stockBalancesCount={response.count}
          />
        ),
      });
    }
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Stack spacing={0}>
          {stockLocationID ? (
            <Stack spacing={0}>
              <Text size="sm" fw={700}>
                Stock Location
              </Text>
              <Title>
                {getStockLocation.response?.stockLocation.name ?? 'Loading...'}
              </Title>
            </Stack>
          ) : (
            <Title>New Stock Location</Title>
          )}
        </Stack>

        {stockLocationID && (
          <Group>
            <Button
              compact
              variant="light"
              leftIcon={<IconPrinter size={14} />}
              onClick={prepareAndShowPrintStockModal}
              loading={
                getStockBalancesPDF.loading || getStockBalancesPDF.loading
              }
            >
              Print Stock
            </Button>
          </Group>
        )}

        <form onSubmit={onFormSubmit}>
          <Stack>
            <TextInput
              label="Location Name"
              required={true}
              {...form.getInputProps('name')}
            />

            <TextInput
              label="Currency"
              required={true}
              disabled={true}
              {...form.getInputProps('currency')}
            />

            <Flex>
              <Button
                color="green"
                type="submit"
                disabled={cantSubmit}
                loading={busyWithAPICalls}
              >
                {stockLocationID ? 'Update' : 'Create'}
              </Button>
            </Flex>
          </Stack>
        </form>

        {stockLocationID && (
          <Tabs defaultValue="stockBalance">
            <Tabs.List>
              <Tabs.Tab value="stockBalance" icon={<IconScale size={14} />}>
                Stock Balances
              </Tabs.Tab>

              {/*{canViewUserPermissions.hasPrivilege && (*/}
              {/*  <Tabs.Tab value="userPermission" icon={<IconUsers size={14} />}>*/}
              {/*    User Permissions*/}
              {/*  </Tabs.Tab>*/}
              {/*)}*/}

              <Tabs.Tab
                value="stockAdjustments"
                icon={<IconAdjustments size={14} />}
              >
                Stock Adjustments
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="stockBalance" pt="lg">
              <StockBalancesSection stockLocationID={stockLocationID} />
            </Tabs.Panel>

            {canViewUserPermissions.hasPrivilege && (
              <Tabs.Panel value="userPermission" pt="lg">
                <StockLocationUserPermissions
                  stockLocationID={stockLocationID}
                />
              </Tabs.Panel>
            )}

            <Tabs.Panel value="stockAdjustments" pt="lg">
              <Stack>
                <Group>
                  <Button
                    color="green"
                    variant="light"
                    onClick={() =>
                      navigate('/stockAdjustmentGroups/new', {
                        state: {
                          stockLocation:
                            getStockLocation.response?.stockLocation,
                        },
                      })
                    }
                  >
                    New Stock Adjustment
                  </Button>
                </Group>
                <StockAdjustmentGroupsList stockLocationID={stockLocationID} />
              </Stack>
            </Tabs.Panel>
          </Tabs>
        )}
      </Stack>
    </Container>
  );
};

const StockLocationUserPermissions = ({
  stockLocationID,
}: {
  stockLocationID: string;
}) => {
  const listStockLocationUserPermissions = useStatefulAPIRequestMaker(
    DefaultOperations.listStockLocationUserPrivileges
  );

  const deleteStockLocationUserPermission = useStatefulAPIRequestMaker(
    DefaultOperations.deleteStockLocationUserPrivilege
  );

  const loadStockLocations = () => {
    listStockLocationUserPermissions.execute({
      stockLocationId: stockLocationID,
    });
  };

  useEffect(() => {
    loadStockLocations();
  }, [stockLocationID]);

  const openCreateUserPermissionModal = () => {
    openModal({
      modalId: 'create-stock-location-user-permission',
      title: <Title order={3}>Add User Permission</Title>,
      closeOnClickOutside: false,
      children: (
        <CreateStockLocationUserPermissionModal
          stockLocationID={stockLocationID}
          closeModal={() => {
            closeModal('create-stock-location-user-permission');
          }}
        />
      ),
      onClose: loadStockLocations,
    });
  };

  const openRemovePermissionModal = ({
    id,
    user,
  }: {
    id: string;
    user: User;
  }) => {
    openConfirmModal({
      title: <Title order={3}>Remove User Permission</Title>,
      children: (
        <Text size="sm">
          Are you sure you want to remove {user.firstName} {user.lastName}'s
          stock location permission?
        </Text>
      ),
      confirmProps: {
        color: 'red',
      },
      labels: {
        confirm: 'Remove Permission',
        cancel: 'Cancel',
      },
      onConfirm: async () => {
        await deleteStockLocationUserPermission.execute({
          stockLocationUserPrivilegeId: id,
        });
        loadStockLocations();
        showNotification({
          color: 'green',
          title: 'User Permission Removed',
          message: 'User permission removed successfully.',
        });
      },
    });
  };

  return (
    <LoadingContainer
      loading={
        listStockLocationUserPermissions.loading ||
        deleteStockLocationUserPermission.loading
      }
    >
      <Stack>
        <Group>
          <Button variant="light" onClick={openCreateUserPermissionModal}>
            Add User
          </Button>
        </Group>
        <Table>
          <thead>
            <tr>
              <th>User</th>
              <th>Role</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {(
              listStockLocationUserPermissions.response
                ?.stockLocationUserPrivileges ?? []
            ).map((slup) => {
              const RowMenu = () => (
                <Menu shadow="md">
                  <Menu.Target>
                    <ActionIcon>
                      <IconDots />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      color="red"
                      icon={<IconTrash />}
                      onClick={() =>
                        openRemovePermissionModal({
                          id: slup.stockLocationUserPrivilege.id,
                          user: slup.user,
                        })
                      }
                    >
                      Remove Permission
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              );

              return (
                <tr key={slup.stockLocationUserPrivilege.id}>
                  <td>
                    {slup.user.firstName} {slup.user.lastName}
                  </td>
                  <td>{capitalCase(slup.stockLocationUserPrivilege.role)}</td>
                  <td>
                    <RowMenu />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Stack>
    </LoadingContainer>
  );
};

const CreateStockLocationUserPermissionModal = ({
  stockLocationID,
  closeModal,
}: {
  stockLocationID: string;
  closeModal: () => void;
}) => {
  const [userSelectorModalOpened, userSelectorModalHandlers] =
    useDisclosure(false);

  const createStockLocationUserPermission = useStatefulAPIRequestMaker(
    DefaultOperations.createStockLocationUserPrivilege
  );

  type formFields = {
    role: StockLocationRole | null;
    user: User | null;
  };

  const form = useForm<formFields>({
    validateInputOnChange: true,
    validate: {
      user: (value) => (value === null ? 'User required.' : null),
      role: (value) => (value === null ? 'Role required.' : null),
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    try {
      await createStockLocationUserPermission.execute({
        requestBody: {
          userID: values.user!.id,
          stockLocationID: stockLocationID,
          role: values.role!,
        },
      });

      showNotification({
        color: 'green',
        title: 'User Permission Added',
        message: `User ${values.user!.firstName} ${
          values.user!.lastName
        } has been granted permission to this stock location.`,
      });

      closeModal();
    } catch (e) {
      handleApiErrorMessage(e);
    }
  });

  const disableSubmitButton = !form.values.user || !form.values.role;

  return (
    <LoadingContainer loading={createStockLocationUserPermission.loading}>
      <Stack>
        <Modal
          zIndex={10000}
          opened={userSelectorModalOpened}
          onClose={userSelectorModalHandlers.close}
          title={<Title order={3}>Select User</Title>}
        >
          <UserModalSelectorBody
            onUserSelected={(u) => {
              form.setFieldValue('user', u);
              userSelectorModalHandlers.close();
            }}
          />
        </Modal>

        <form onSubmit={onSubmit}>
          <Stack>
            <TextInput
              readOnly
              onClick={userSelectorModalHandlers.open}
              label="User"
              placeholder="Select User"
              value={
                form.values.user
                  ? form.values.user.firstName + ' ' + form.values.user.lastName
                  : ''
              }
              error={form.errors.user}
            />

            <Select
              label="Select Role"
              placeholder="Role"
              value={form.values.role}
              onChange={(value) =>
                form.setFieldValue('role', value as StockLocationRole)
              }
              error={form.errors.role}
              data={[{ value: 'can_post', label: 'Can Post' }]}
            />

            <Button disabled={disableSubmitButton} type="submit">
              Add User Permission
            </Button>
          </Stack>
        </form>
      </Stack>
    </LoadingContainer>
  );
};

// const StockChecksTable = ({ stockLocationID }: { stockLocationID: string }) => {
//   const listStockChecks = useStatefulAPIRequestMaker(
//     DefaultOperations.listStockChecks
//   );
//   const postStockCheck = useStatefulAPIRequestMaker(
//     DefaultOperations.postStockCheck
//   );
//   const cancelStockCheck = useStatefulAPIRequestMaker(
//     DefaultOperations.cancelStockCheck
//   );
//
//   const countPerPage = 10;
//   const [paginationPage, setPaginationPage] = useState(1);
//
//   const loadStockChecks = () => {
//     listStockChecks.execute({
//       stockLocationId: stockLocationID,
//       limit: countPerPage,
//       offset: (paginationPage - 1) * countPerPage,
//     });
//   };
//
//   useEffect(() => {
//     loadStockChecks();
//   }, [stockLocationID, paginationPage]);
//
//   return (
//     <LoadingContainer
//       loading={listStockChecks.loading || postStockCheck.loading}
//     >
//       <Stack>
//         <Title order={3}>Stock Checks</Title>
//
//         <Table>
//           <thead>
//             <tr>
//               <th>Date</th>
//               <th>User</th>
//               <th>Product</th>
//               <th>Change in Balance</th>
//               <th>State</th>
//               <th />
//             </tr>
//           </thead>
//           <tbody>
//             {(listStockChecks.response?.stockChecks || []).map((sc) => {
//               return (
//                 <tr key={sc.stockCheck.id}>
//                   <td>{RFC3339ToEasyDateTime(sc.stockCheck.createdAt)}</td>
//                   <td>
//                     {sc.creatingUser.firstName} {sc.creatingUser.lastName}
//                   </td>
//                   <td>{sc.product.name}</td>
//                   <td align="right">{sc.stockCheck.stockBalanceChange}</td>
//                   <td>
//                     <StockCheckStatusBadge
//                       state={sc.stockCheck.status}
//                       fullWidth
//                     />
//                   </td>
//                   <td>
//                     <Menu shadow="md">
//                       <Menu.Target>
//                         <ActionIcon>
//                           <IconDots />
//                         </ActionIcon>
//                       </Menu.Target>
//
//                       <Menu.Dropdown>
//                         <Menu.Item
//                           icon={<IconCheck />}
//                           disabled={sc.stockCheck.status !== 'draft'}
//                           onClick={() => {
//                             openConfirmModal({
//                               title: <Title order={3}>Post Stock Check</Title>,
//                               children: (
//                                 <Text size="sm">
//                                   Are you sure you want to post this stock
//                                   check?
//                                 </Text>
//                               ),
//                               labels: {
//                                 confirm: 'Post Stock Check',
//                                 cancel: 'Cancel',
//                               },
//                               onConfirm: async () => {
//                                 await postStockCheck.execute({
//                                   requestBody: {
//                                     stockCheckID: sc.stockCheck.id,
//                                   },
//                                 });
//                                 loadStockChecks();
//                                 showNotification({
//                                   color: 'green',
//                                   title: 'Stock Check Posted',
//                                   message: 'Stock check posted successfully.',
//                                 });
//                               },
//                             });
//                           }}
//                         >
//                           Post
//                         </Menu.Item>
//
//                         <Menu.Item
//                           color="red"
//                           icon={<IconTrash />}
//                           disabled={sc.stockCheck.status !== 'draft'}
//                           onClick={() => {
//                             openConfirmModal({
//                               title: (
//                                 <Title order={3}>Cancel Stock Check</Title>
//                               ),
//                               children: (
//                                 <Text size="sm">
//                                   Are you sure you want to cancel this stock
//                                   check?
//                                 </Text>
//                               ),
//                               confirmProps: {
//                                 color: 'red',
//                               },
//                               labels: {
//                                 confirm: 'Cancel Stock Check',
//                                 cancel: 'Cancel',
//                               },
//                               onConfirm: async () => {
//                                 await cancelStockCheck.execute({
//                                   requestBody: {
//                                     stockCheckID: sc.stockCheck.id,
//                                   },
//                                 });
//                                 loadStockChecks();
//                                 showNotification({
//                                   color: 'orange',
//                                   title: 'Stock Check Cancelled',
//                                   message:
//                                     'Stock check cancelled successfully.',
//                                 });
//                               },
//                             });
//                           }}
//                         >
//                           Cancel
//                         </Menu.Item>
//                       </Menu.Dropdown>
//                     </Menu>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </Table>
//         <Center>
//           <Pagination
//             total={Math.ceil(
//               (listStockChecks.response?.totalCount || 0) / countPerPage
//             )}
//             value={paginationPage}
//             onChange={(p) => {
//               setPaginationPage(p);
//             }}
//           />
//         </Center>
//       </Stack>
//     </LoadingContainer>
//   );
// };

const StockBalancesSection = ({
  stockLocationID,
}: {
  stockLocationID: string;
}) => {
  const getStockBalances = useStatefulAPIRequestMaker(
    DefaultOperations.getStockBalances
  );

  const { target, setTargetFromInput, onChangeDeps, pagination } =
    useListSearcherHelper({
      limitPerPage: 30,
      debounceDelay: 300,
      additionalDeps: [],
    });

  useEffect(() => {
    getStockBalances.execute({
      stockLocationId: stockLocationID,
      productName: target,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [...onChangeDeps]);

  const [
    balanceModalOpened,
    { open: openBalanceModal, close: closeBalanceModal },
  ] = useDisclosure(false);
  const [stockBalanceHistoryParams, setStockBalanceHistoryParams] =
    useState<{
      stockLocationID: string;
      currency: string;
      product: Product;
    } | null>(null);
  return (
    <Stack>
      {stockBalanceHistoryParams && (
        <Modal
          size={PX768.Number}
          opened={balanceModalOpened}
          onClose={() => {
            setStockBalanceHistoryParams(null);
            closeBalanceModal();
          }}
          title={<Title order={3}>Stock Balance History</Title>}
        >
          <StockBalanceHistory
            stockLocationID={stockBalanceHistoryParams!.stockLocationID}
            currency={stockBalanceHistoryParams!.currency}
            productID={stockBalanceHistoryParams!.product.id}
          />
        </Modal>
      )}
      <TextInput
        icon={<IconSearch />}
        label="Search Product"
        placeholder="Type to Search"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer
        loading={getStockBalances.loading}
        minHeight={PX384.Number}
      >
        <Stack>
          {getStockBalances.response && (
            <Flex justify="flex-end">
              <Pagination
                size="sm"
                total={pagination.calculateTotalPages(
                  getStockBalances.response?.count
                )}
                value={pagination.currentPaginationPage}
                onChange={pagination.onPaginationPageChange}
              />
            </Flex>
          )}

          <Table withBorder>
            <thead>
              <tr>
                <th>Product Name</th>
                <th style={{ textAlign: 'right' }}>Balance</th>
                <th style={{ textAlign: 'right' }}>Value</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {getStockBalances.response?.stockBalances.map((v) => {
                return (
                  <tr
                    key={
                      v.stockBalance.productID +
                      v.stockBalance.currency +
                      v.stockBalance.stockLocationID
                    }
                  >
                    <td>
                      <Group spacing={PX4.Number}>
                        {v.product.name}
                        {!v.product.enabled && (
                          <MantineTooltip label="Product disabled.">
                            <ThemeIcon color="red" variant="light" size="sm">
                              <IconCircleOff />
                            </ThemeIcon>
                          </MantineTooltip>
                        )}
                      </Group>
                    </td>
                    <td align="right">{v.stockBalance.balance}</td>
                    <td align="right">
                      {formatBig(
                        Big(v.stockBalance.balanceValueCalculated),
                        BHDCurrency
                      )}
                    </td>
                    <td>
                      <Menu shadow="md">
                        <Menu.Target>
                          <ActionIcon>
                            <IconDots />
                          </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                          <Menu.Item
                            icon={<IconChartAreaLine size={18} />}
                            onClick={() => {
                              setStockBalanceHistoryParams({
                                currency: v.stockBalance.currency,
                                stockLocationID,
                                product: v.product,
                              });
                              openBalanceModal();
                            }}
                          >
                            View History
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {getStockBalances.response && (
            <Flex justify="flex-end">
              <Pagination
                size="sm"
                total={pagination.calculateTotalPages(
                  getStockBalances.response?.count
                )}
                value={pagination.currentPaginationPage}
                onChange={pagination.onPaginationPageChange}
              />
            </Flex>
          )}
        </Stack>
      </LoadingContainer>
    </Stack>
  );
};

const PrintStockPaginationModal = ({
  balancesPerPdf,
  stockLocationID,
  stockBalancesCount,
}: {
  balancesPerPdf: number;
  stockBalancesCount: number;
  stockLocationID: string;
}) => {
  const getStockBalancesPDF = useStatefulAPIRequestMaker(
    DefaultOperations.generateStockLocationStockBalancePdf
  );

  const printPdf = async ({
    stockLocationID,
    offset,
  }: {
    stockLocationID: string;
    offset: number;
  }) => {
    try {
      const response = await getStockBalancesPDF.execute({
        stockLocationId: stockLocationID,
        limit: balancesPerPdf,
        offset,
      });

      const file = b64toBlob(response.pdfBase64, 'application/pdf');
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  const rows = [];
  for (let i = 0; i < Math.ceil(stockBalancesCount / balancesPerPdf); i++) {
    const min = i * balancesPerPdf + 1;
    const max = Math.min((i + 1) * balancesPerPdf, stockBalancesCount);

    rows.push(
      <ClickableTableRow
        onClick={() =>
          printPdf({ stockLocationID, offset: i * balancesPerPdf })
        }
      >
        <td>
          {min} - {max} Stock Balances
        </td>
      </ClickableTableRow>
    );
  }

  return (
    <Stack>
      <Alert title="PDF Pages">
        Each page contains {balancesPerPdf} stock balances each.
      </Alert>
      <Table withBorder highlightOnHover>
        <thead>
          <tr>
            <th>Stock Balances in PDF</th>
          </tr>
        </thead>
        <tbody>{rows.map((row) => row)}</tbody>
      </Table>
    </Stack>
  );
};
