import { Box, Stack, Text } from '@mantine/core';
import React from 'react';

export const LabeledText = ({
  label,
  value,
  textColor,
}: {
  label: string;
  value: string | React.ReactNode | React.ReactNode[];
  textColor?: string;
}) => {
  return (
    <Stack spacing={0}>
      <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
        {label}
      </Text>
      {typeof value === 'string' ? (
        <Text fw={700} size="md" color={textColor}>
          {value}
        </Text>
      ) : (
        <Box mt={3}>{value}</Box>
      )}
    </Stack>
  );
};
