import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import {
  Button,
  Container,
  Flex,
  Stack,
  Modal,
  Table,
  Tabs,
  TextInput,
  Title,
  Group,
  Text,
  useMantineTheme,
  Card,
  Center,
  Loader,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { IconAddressBook, IconGraph, IconPencil } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  OldPatternOperations,
  VendorContact,
} from '../../../oas/codegen3';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { showNotification } from '@mantine/notifications';
import { formatToCurrency } from '@pcc/api/models/Currency';
import {
  RFC3339ToEasyDate,
  RFC3339ToPrettyMonthDontModifyTimezone,
} from '../../../utils/dateFormatting';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Rectangle,
  Tooltip as RechartTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const VendorForm = ({
  vendorID,
}: {
  path?: string;
  vendorID?: string;
}) => {
  const getVendor = useStatefulAPIRequestMaker(OldPatternOperations.getVendor);

  useDocumentTitle(
    vendorID
      ? `Vendor: ${getVendor.response?.vendor.name ?? 'Loading...'}`
      : 'New Vendor'
  );

  const insertVendor = useStatefulAPIRequestMaker(
    DefaultOperations.createVendor
  );
  const updateVendor = useStatefulAPIRequestMaker(
    DefaultOperations.updateVendor
  );

  const navigate = useNavigate();

  const busyWithAPICalls =
    insertVendor.loading || updateVendor.loading || getVendor.loading;

  const form = useForm<{ name: string }>();

  const fetchVendor = async () => {
    try {
      const v = await getVendor.execute({ vendorId: vendorID! });
      form.setValues({
        name: v.vendor.name,
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (vendorID) {
      fetchVendor();
    }
  }, [vendorID]);

  const onFormSubmit = form.onSubmit(async ({ name }: { name: string }) => {
    if (vendorID) {
      const v = await updateVendor.execute({
        requestBody: {
          id: vendorID,
          name,
        },
      });

      showNotification({
        color: 'green',
        title: `Vendor Updated`,
        message: `Vendor ${v.vendor.name} has been updated.`,
      });

      fetchVendor();
    } else {
      const v = await insertVendor.execute({ requestBody: { name } });

      showNotification({
        color: 'green',
        title: `Vendor Created`,
        message: `Vendor ${v.vendor.name} has been updated.`,
      });

      navigate(`/vendors/${v.vendor.id}`);
    }
  });

  const canReadVendorAnalytics = useFindPrivileges({
    action: 'read',
    resource: 'vendor_analytics',
  });

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer loading={busyWithAPICalls} minHeight={PX384.Number}>
        <Stack>
          <Stack spacing={0}>
            {vendorID ? (
              <Stack spacing={0}>
                <Text size="sm" fw={700}>
                  Vendor
                </Text>
                <Title>{getVendor.response?.vendor.name ?? 'Loading...'}</Title>
              </Stack>
            ) : (
              <Title>New Vendor</Title>
            )}
          </Stack>

          <Tabs defaultValue="editVendor">
            <Tabs.List>
              <Tabs.Tab value="editVendor" icon={<IconPencil size={16} />}>
                Edit Vendor
              </Tabs.Tab>
              {vendorID && canReadVendorAnalytics.hasPrivilege && (
                <Tabs.Tab value="analytics" icon={<IconGraph size={16} />}>
                  Analytics
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="editVendor" pt="lg">
              <Stack>
                <form onSubmit={onFormSubmit}>
                  <Stack>
                    <TextInput
                      autoComplete="off"
                      label="Vendor Name"
                      required={true}
                      {...form.getInputProps('name')}
                    />

                    <Group>
                      <Button
                        color="green"
                        type="submit"
                        disabled={busyWithAPICalls}
                      >
                        {vendorID ? 'Update' : 'Create'}
                      </Button>
                    </Group>
                  </Stack>
                </form>

                {vendorID && (
                  <Tabs defaultValue="vendorContacts">
                    <Tabs.List>
                      <Tabs.Tab
                        value="vendorContacts"
                        icon={<IconAddressBook size={16} />}
                      >
                        Contacts
                      </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="vendorContacts" pt="lg">
                      <VendorContacts
                        vendorID={vendorID}
                        vendorContacts={getVendor.response?.vendorContacts}
                        refreshVendorContacts={fetchVendor}
                      />
                    </Tabs.Panel>
                  </Tabs>
                )}
              </Stack>
            </Tabs.Panel>
            <Tabs.Panel value="analytics" pt="lg">
              {vendorID && <VendorAnalyticsTabPage vendorID={vendorID} />}
            </Tabs.Panel>
          </Tabs>
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const VendorContacts = ({
  vendorID,
  vendorContacts,
  refreshVendorContacts,
}: {
  vendorID: string;
  vendorContacts?: {
    vendorContact: VendorContact;
    mostRecentCollectedPurchaseOrder?: {
      id: string;
      collectedAt: string;
    };
  }[];
  refreshVendorContacts: () => void;
}) => {
  const [modalVendorContactID, setModalVendorContactID] =
    useState<string | undefined>(undefined);
  const [
    vendorContactModalOpened,
    { open: openVendorContactModal, close: closeVendorContactModal },
  ] = useDisclosure(false);

  return (
    <Stack>
      <VendorContactModalForm
        vendorID={vendorID}
        vendorContactID={modalVendorContactID}
        opened={vendorContactModalOpened}
        hideModal={() => {
          refreshVendorContacts();
          setModalVendorContactID(undefined);
          closeVendorContactModal();
        }}
      />
      <Flex>
        <Button onClick={openVendorContactModal}>New Contact</Button>
      </Flex>
      <Table highlightOnHover withBorder striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Most Recent Purchase Order</th>
          </tr>
        </thead>
        <tbody>
          {(vendorContacts ?? []).map((vc) => {
            return (
              <ClickableTableRow
                key={vc.vendorContact.id}
                onClick={() => {
                  setModalVendorContactID(vc.vendorContact.id);
                  openVendorContactModal();
                }}
              >
                <td>{vc.vendorContact.name}</td>
                <td>{vc.vendorContact.phoneNumberWithCountryCode}</td>
                <td>
                  {vc.mostRecentCollectedPurchaseOrder
                    ? RFC3339ToEasyDate(
                        vc.mostRecentCollectedPurchaseOrder.collectedAt
                      )
                    : ''}
                </td>
              </ClickableTableRow>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

const VendorContactModalForm = ({
  vendorContactID,
  hideModal,
  vendorID,
  opened,
}: {
  vendorContactID?: string;
  vendorID: string;
  hideModal: () => void;
  opened: boolean;
}) => {
  const insertVendorContact = useStatefulAPIRequestMaker(
    DefaultOperations.createVendorContact
  );
  const updateVendorContact = useStatefulAPIRequestMaker(
    OldPatternOperations.updateVendorContact
  );
  const getVendorContact = useStatefulAPIRequestMaker(
    DefaultOperations.getVendorContact
  );

  const apiBusy =
    insertVendorContact.loading ||
    updateVendorContact.loading ||
    getVendorContact.loading;

  const form =
    useForm<{
      name: string;
      phoneNumberWithCountryCode: string;
    }>();

  useEffect(() => {
    if (vendorContactID) {
      getVendorContact.execute({ vendorContactId: vendorContactID });
    }
  }, [vendorContactID]);

  useEffect(() => {
    if (!vendorContactID) {
      form.setValues({
        name: undefined,
        phoneNumberWithCountryCode: undefined,
      });
    } else if (vendorID && getVendorContact.response?.vendorContact) {
      form.setValues({
        name: getVendorContact.response.vendorContact.name,
        phoneNumberWithCountryCode:
          getVendorContact.response.vendorContact.phoneNumberWithCountryCode,
      });
    }
  }, [getVendorContact.response?.vendorContact, vendorContactID]);

  const onFormSubmit = form.onSubmit(
    async ({ name, phoneNumberWithCountryCode }) => {
      if (vendorContactID) {
        await updateVendorContact.execute({
          requestBody: {
            vendorID,
            name,
            phoneNumberWithCountryCode,
            id: vendorContactID,
          },
        });
        hideModal();
      } else {
        await insertVendorContact.execute({
          requestBody: {
            vendorID: vendorID,
            name,
            phoneNumberWithCountryCode,
          },
        });
        hideModal();
      }
    }
  );

  return (
    <Modal
      onClose={hideModal}
      opened={opened}
      title={
        <Title order={3}>
          {vendorContactID ? 'Update Contact' : 'New Vendor Contact'}
        </Title>
      }
    >
      <Stack>
        <form onSubmit={onFormSubmit}>
          <Stack>
            <TextInput
              autoComplete="off"
              label="Name"
              required={true}
              {...form.getInputProps('name')}
            />

            <TextInput
              autoComplete="off"
              label="Phone Number"
              {...form.getInputProps('phoneNumberWithCountryCode')}
            />

            <Flex>
              <Button type="submit" color="green" disabled={apiBusy}>
                {vendorContactID ? 'Update Contact' : 'Create Contact'}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

const VendorAnalyticsTabPage = ({ vendorID }: { vendorID: string }) => {
  const theme = useMantineTheme();
  const getBalance = useStatefulAPIRequestMaker(
    DefaultOperations.getVendorBalance
  );
  const getMonthly = useStatefulAPIRequestMaker(
    DefaultOperations.getVendorMonthlyPurchasesAndPayments
  );

  useEffect(() => {
    const p = getBalance.execute({
      vendorId: vendorID,
      currency: 'BHD',
    });

    return () => p.cancel();
  }, [vendorID]);

  useEffect(() => {
    const p = getMonthly.execute({
      vendorId: vendorID,
      currency: 'BHD',
    });

    return () => p.cancel();
  }, [vendorID]);

  const gradient = theme.fn.linearGradient(45, 'orange', 'red');

  const data = React.useMemo(() => {
    if (!getMonthly.response) {
      return [];
    }

    return getMonthly.response.monthlyPurchasesAndPayments.map((m) => {
      return {
        date: RFC3339ToPrettyMonthDontModifyTimezone(m.month),
        'Payments Amount': Number(m.paymentsAmount),
        'Purchases Amount': Number(m.purchaseOrdersAmount),
      };
    });
  }, [getMonthly.response]);

  return (
    <Stack>
      <Title order={3}>Monthly Purchases & Payments</Title>
      {getMonthly.loading && (
        <Center h={PX384.Number}>
          <Loader />
        </Center>
      )}
      {!getMonthly.loading && getMonthly.response && (
        <div style={{ position: 'relative', height: PX384.Number }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis tickSize={5} />
              <RechartTooltip
                formatter={(v) => {
                  return formatToCurrency(
                    v as string,
                    getMonthly.response!.currency
                  );
                }}
              />
              <Legend />

              {/*<Line*/}
              {/*  type="monotone"*/}
              {/*  dataKey="Sales vs. Purchases Difference"*/}
              {/*  stroke="#1C7ED6"*/}
              {/*  strokeWidth={2}*/}
              {/*/>*/}
              <Bar
                dataKey="Payments Amount"
                fill="#F03E3E"
                activeBar={<Rectangle fill="#FFC9C9" stroke="#F03E3E" />}
              >
                <LabelList
                  dataKey="Payments Amount"
                  position="top"
                  fill="#000"
                  formatter={(v: any) => {
                    if (v === 0) {
                      return '';
                    }

                    return Number(v).toFixed(0);
                  }}
                />
              </Bar>
              <Bar
                dataKey="Purchases Amount"
                fill="#0CA678"
                activeBar={<Rectangle fill="#96F2D7" stroke="#0CA678" />}
              >
                <LabelList
                  dataKey="Purchases Amount"
                  position="top"
                  fill="#000"
                  formatter={(v: any) => {
                    if (v === 0) {
                      return '';
                    }

                    return Number(v).toFixed(0);
                  }}
                />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      <Title order={3}>Balance</Title>
      {getBalance.loading && (
        <Center h={PX32.PX}>
          <Loader />
        </Center>
      )}
      {getBalance.response && (
        <Stack>
          <Group grow>
            <Card bg={gradient} radius="md">
              <Stack spacing={0}>
                <Text fw={700} color="white" size="xs">
                  Total Balance
                </Text>
                <Text fw={700} color="white" size="xl">
                  {formatToCurrency(
                    getBalance.response.totalBalance,
                    getBalance.response.currency
                  )}
                </Text>
              </Stack>
            </Card>
            <Card bg={gradient} radius="md">
              <Stack spacing={0}>
                <Text fw={700} color="white" size="xs">
                  Purchase Orders With Balance Count
                </Text>
                <Text fw={700} color="white" size="xl">
                  {getBalance.response.numberOfPurchaseOrdersWithBalance}
                </Text>
              </Stack>
            </Card>
          </Group>
          <Table striped withBorder>
            <thead>
              <tr>
                <th>Month</th>
                <th style={{ textAlign: 'right' }}>Number of Orders</th>
                <th style={{ textAlign: 'right' }}>Balance</th>
              </tr>
            </thead>

            <tbody>
              {getBalance.response.balancesWithMonth.map((bwm) => {
                return (
                  <tr key={bwm.month}>
                    <td>{RFC3339ToPrettyMonthDontModifyTimezone(bwm.month)}</td>
                    <td style={{ textAlign: 'right' }}>
                      {bwm.numberOfPurchaseOrdersWithBalance}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(
                        bwm.balance,
                        getBalance.response!.currency
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Stack>
      )}
    </Stack>
  );
};
