import { PX1024, PX16, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Code,
  Container,
  Group,
  Stack,
  Table,
  Tabs,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  CorePayment,
  Currency,
  DefaultOperations,
  PurchaseOrder,
  PurchaseOrderPayment,
  PurchaseOrderToPurchaseOrderPaymentRel,
  Vendor,
} from '../../../oas/codegen3';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { IconCash, IconFileInvoice, IconPrinter } from '@tabler/icons-react';
import { Link } from '@components/V2/Link';
import { b64toBlob } from '../../../utils/b64toBlob';

export const BulkPaymentViewer = ({ id }: { path?: string; id?: string }) => {
  const getBulkPayment = useStatefulAPIRequestMaker(
    DefaultOperations.getBulkPurchaseOrderPayment
  );
  const getBulkPaymentPDF = useStatefulAPIRequestMaker(
    DefaultOperations.getBulkPurchaseOrderPaymentPdf
  );

  useEffect(() => {
    if (id) {
      getBulkPayment.execute({ bulkPurchaseOrderPaymentId: id });
    }
  }, [id]);

  const totalAmount =
    getBulkPayment.response?.bulkPurchaseOrderPayment.totalAmount;
  const createdAt = getBulkPayment.response?.bulkPurchaseOrderPayment.createdAt;
  const currency = getBulkPayment.response?.currency;

  const totalAmountStr = getBulkPayment.response
    ? formatToCurrency(totalAmount, currency!)
    : '';

  const createdAtStr = getBulkPayment.response
    ? RFC3339ToEasyDateTime(createdAt!)
    : '';

  const purchaseOrders: {
    purchaseOrder: PurchaseOrder;
    purchaseOrderToPaymentRel: PurchaseOrderToPurchaseOrderPaymentRel;
    vendor: Vendor;
  }[] = [];

  (getBulkPayment.response?.purchaseOrderPayments ?? []).forEach((pm) => {
    pm.linkedPurchaseOrders.forEach((poLink) => {
      purchaseOrders.push({
        purchaseOrder: poLink.purchaseOrder,
        purchaseOrderToPaymentRel:
          poLink.purchaseOrderToPurchaseOrderPaymentRel,
        vendor: pm.vendor,
      });
    });
  });

  const getPDF = async () => {
    const response = await getBulkPaymentPDF.execute({
      bulkPurchaseOrderPaymentId: id!,
    });

    const file = b64toBlob(response.pdfBase64, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer loading={getBulkPayment.loading}>
        <Stack>
          <Group>
            <Button
              onClick={getPDF}
              compact
              variant="light"
              leftIcon={<IconPrinter size={16} />}
            >
              Print
            </Button>
          </Group>

          {getBulkPayment.response && (
            <Title>
              {getBulkPayment.response.bulkPurchaseOrderPayment.reference}
            </Title>
          )}

          <TextInput
            label="Amount"
            placeholder="Amount"
            disabled
            value={totalAmountStr}
          />
          <TextInput
            label="Created At"
            placeholder="Created At"
            disabled
            value={createdAtStr}
          />

          <Tabs defaultValue="purchasePayments">
            <Tabs.List>
              <Tabs.Tab value="purchasePayments" icon={<IconCash size={16} />}>
                Payments
              </Tabs.Tab>

              <Tabs.Tab
                value="purchaseOrders"
                icon={<IconFileInvoice size={16} />}
              >
                Purchase Orders
              </Tabs.Tab>
            </Tabs.List>

            {getBulkPayment.response && (
              <Tabs.Panel value="purchasePayments">
                <PaymentsTable
                  currency={getBulkPayment.response.currency}
                  payments={getBulkPayment.response.purchaseOrderPayments.map(
                    (p) => {
                      return {
                        purchaseOrderPayment: p.purchaseOrderPayment,
                        vendor: p.vendor,
                        corePayment: p.corePayment,
                      };
                    }
                  )}
                />
              </Tabs.Panel>
            )}

            {getBulkPayment.response && (
              <Tabs.Panel value="purchaseOrders">
                <PurchaseOrdersTable
                  currency={getBulkPayment.response.currency}
                  purchaseOrders={purchaseOrders}
                />
              </Tabs.Panel>
            )}
          </Tabs>
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const PaymentsTable = ({
  payments,
  currency,
}: {
  currency: Currency;
  payments: {
    purchaseOrderPayment: PurchaseOrderPayment;
    vendor: Vendor;
    corePayment: CorePayment;
  }[];
}) => {
  return (
    <Stack mt={PX16.Number}>
      <Table>
        <thead>
          <tr>
            <th>Reference</th>
            <th>Vendor</th>
            <th style={{ textAlign: 'right' }}>Amount</th>
          </tr>
        </thead>

        <tbody>
          {payments.map((payment) => {
            return (
              <tr key={payment.purchaseOrderPayment.id}>
                <td>
                  <Link
                    withIcon
                    to={`/purchaseOrders/payments/${payment.purchaseOrderPayment.id}`}
                  >
                    <Code>{payment.purchaseOrderPayment.reference}</Code>
                  </Link>
                </td>
                <td>{payment.vendor.name}</td>
                <td style={{ textAlign: 'right' }}>
                  {formatToCurrency(payment.corePayment.amount, currency)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

const PurchaseOrdersTable = ({
  currency,
  purchaseOrders,
}: {
  currency: Currency;
  purchaseOrders: {
    purchaseOrder: PurchaseOrder;
    purchaseOrderToPaymentRel: PurchaseOrderToPurchaseOrderPaymentRel;
    vendor: Vendor;
  }[];
}) => {
  return (
    <Stack mt={PX16.Number}>
      <Table>
        <thead>
          <tr>
            <th>Reference</th>
            <th>Vendor</th>
            <th style={{ textAlign: 'right' }}>Total Amount</th>
            <th style={{ textAlign: 'right' }}>Amount Paid</th>
          </tr>
        </thead>

        <tbody>
          {purchaseOrders.map((po) => {
            return (
              <tr key={po.purchaseOrder.id}>
                <td>
                  <Link withIcon to={`/purchaseOrders/${po.purchaseOrder.id}`}>
                    <Code>{po.purchaseOrder.number}</Code>
                  </Link>
                </td>
                <td>{po.vendor.name}</td>
                <td style={{ textAlign: 'right' }}>
                  {formatToCurrency(po.purchaseOrder.totalAmount, currency)}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {formatToCurrency(
                    po.purchaseOrderToPaymentRel.amount,
                    currency
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};
