import { UserWithNameOnly } from '../oas/codegen3';
import { Avatar, Tooltip } from '@mantine/core';

export const UserAvatar = ({ user }: { user?: UserWithNameOnly | null }) => {
  if (!user) {
    return null;
  }

  const firstLetter = user.firstName[0];
  const secondLetter = user.lastName[0];

  return (
    <Tooltip
      label={user.firstName + ' ' + user.lastName}
      position="bottom-end"
      openDelay={300}
    >
      <Avatar radius="xl" color="orange">
        {firstLetter}
        {secondLetter}
      </Avatar>
    </Tooltip>
  );
};
