import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { DefaultOperations, PaymentMethod, User } from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect, useState } from 'react';
import {
  Code,
  Container,
  Flex,
  Group,
  Pagination,
  Stack,
  Text,
  Table,
  TextInput,
  Title,
  ActionIcon,
  List,
} from '@mantine/core';
import { PX1024, PX32, PX384, PX64, PX8, PX96 } from '../../../components/Px';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import {
  dateToRFC3339,
  RFC3339ToEasyDateTime,
} from '../../../utils/dateFormatting';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { closeModal, openModal } from '@mantine/modals';
import { PaymentMethodModalSelector } from '@components/V2/modal_selector/PaymentMethodModalSelector';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { UserModalSelectorBody } from '@components/V2/modal_selector/UserModalSelectorBody';
import { DateRangePickerModal } from '@components/V2/modal_selector/DateRangePickerModal';
import { formatDateRange } from '../../../utils/formatStartAndEndDate';
import { IconInfoCircle } from '@tabler/icons-react';

export const ListSalePayments = ({}: { path: string }) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [user, setUser] = useState<User | null>(null);
  const [targetPaymentMethod, setPaymentMethod] =
    useState<PaymentMethod | null | undefined>(undefined);
  const { pagination, target, setTargetFromInput, onChangeDeps } =
    useListSearcherHelper({
      additionalDeps: [targetPaymentMethod, user, dateRange],
      debounceDelay: 300,
      limitPerPage: 30,
    });

  const listSalePayments = useStatefulAPIRequestMaker(
    DefaultOperations.listSalePayments
  );

  useEffect(() => {
    listSalePayments.execute({
      limit: pagination.limit,
      offset: pagination.offset,
      target,
      paymentMethodId:
        targetPaymentMethod === null ? 'null' : targetPaymentMethod?.id,
      cashierUserId: user?.id,
      startDate: dateToRFC3339(dateRange[0]) ?? undefined,
      endDate: dateToRFC3339(dateRange[1]) ?? undefined,
    });
  }, [...onChangeDeps]);

  const salePaymentCount = listSalePayments.response?.count ?? 0;

  const showPaymentMethodSelectorModal = () => {
    openModal({
      modalId: 'payment-method-selector-modal',
      title: <Title order={3}>Select Payment Method</Title>,
      children: (
        <PaymentMethodModalSelector
          targetPaymentType="sale_payment"
          onPaymentMethodSelected={(pm) => {
            setPaymentMethod(pm);
            closeModal('payment-method-selector-modal');
          }}
        />
      ),
    });
  };

  const onUserBadgeClicked = () => {
    openModal({
      modalId: 'user-selector-modal',
      title: <Title order={3}>Select Store</Title>,
      children: (
        <UserModalSelectorBody
          onUserSelected={(u) => {
            setUser(u);
            closeModal('user-selector-modal');
          }}
        />
      ),
    });
  };

  const showDatePickerModal = () => {
    openModal({
      modalId: 'date-range-modal',
      title: <Title order={3}>Select Date Range</Title>,
      size: 'sm',
      children: (
        <DateRangePickerModal
          onDateRangeSelected={(dr) => {
            setDateRange(dr);
            closeModal('date-range-modal');
          }}
        />
      ),
    });
  };

  const showSearchHelpModal = () => {
    openModal({
      title: <Title order={3}>Search</Title>,
      children: (
        <Stack p={PX8.Number}>
          <Text size={16}>
            You can use the search bar to search for the following items:
          </Text>
          <List>
            <List.Item>Payment Reference</List.Item>
            <List.Item>Sale Number</List.Item>
            <List.Item>
              Amount (Typing <Code>1.500</Code> will return all payments greater
              than or equal to <Code>1.500</Code>)
            </List.Item>
          </List>
        </Stack>
      ),
    });
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Sale Payments</Title>
        <TextInput
          placeholder="Search"
          label={
            <Group spacing={2}>
              <Text>Search</Text>
              <ActionIcon
                color="blue"
                variant="transparent"
                size="xs"
                onClick={showSearchHelpModal}
              >
                <IconInfoCircle size={16} />
              </ActionIcon>
            </Group>
          }
          value={target}
          onChange={setTargetFromInput}
        />

        <Group>
          <SelectorBadge
            placeholder="Select Payment Method"
            value={
              targetPaymentMethod === null ? 'Cash' : targetPaymentMethod?.name
            }
            onClick={showPaymentMethodSelectorModal}
            onClearClick={() => setPaymentMethod(undefined)}
          />

          <SelectorBadge
            value={user ? user?.firstName + ' ' + user?.lastName : undefined}
            placeholder="Select User"
            onClick={onUserBadgeClicked}
            onClearClick={() => setUser(null)}
          />

          <SelectorBadge
            value={
              dateRange[0]
                ? `Created: ${formatDateRange(dateRange)}`
                : undefined
            }
            placeholder="Create Date"
            onClick={showDatePickerModal}
            onClearClick={() => setDateRange([null, null])}
          />
        </Group>

        <LoadingContainer
          loading={listSalePayments.loading}
          minHeight={PX384.Number}
        >
          <Stack>
            {salePaymentCount && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(salePaymentCount)}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
            <Table highlightOnHover>
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Sale Number</th>
                  <th>Created At</th>
                  <th>Payment Method</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                {(listSalePayments.response?.salePayments ?? []).map((sp) => {
                  return (
                    <ClickableTableRow
                      key={sp.salePayment.id}
                      targetURL={`/sales/payments/${sp.salePayment.id}`}
                    >
                      <td>
                        <Code>{sp.salePayment.reference}</Code>
                      </td>
                      <td>
                        <Code>{sp.sale.number}</Code>
                      </td>
                      <td>{RFC3339ToEasyDateTime(sp.salePayment.createdAt)}</td>
                      <td>{sp.paymentMethod?.name ?? 'Cash'}</td>
                      <td>
                        {formatToCurrency(sp.corePayment.amount, sp.currency)}
                      </td>
                    </ClickableTableRow>
                  );
                })}
              </tbody>
            </Table>
            {salePaymentCount && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(salePaymentCount)}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
