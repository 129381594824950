import { closeModal, modals } from '@mantine/modals';
import { v4 } from 'uuid';

export const useLoadingModal = () => {
  const showLoadingModal = ({
    modalID,
    title,
    loadingMessage,
  }: {
    modalID?: string;
    title: string;
    loadingMessage?: string;
  }) => {
    const modalId = modalID ?? v4();

    modals.openContextModal({
      modalId,
      modal: 'loadingModal',
      title,
      innerProps: {
        loadingMessage,
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });

    return () => {
      closeModal(modalId);
    };
  };

  return {
    showLoadingModal,
  };
};
