import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import { Container, Flex, NavLink, Stack, Title } from '@mantine/core';
import { useNavigate } from '@reach/router';
import { IconChevronRight } from '@tabler/icons-react';

export const SystemSettingsHomePage = ({}: { path?: string }) => {
  const navigate = useNavigate();

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>System Settings</Title>
        <Flex>
          <NavLink
            onClick={() => navigate('/settings/system/odooProductSync')}
            label="Odoo Product Sync"
            description="Import products from current Odoo."
            rightSection={<IconChevronRight size={14} stroke={1.5} />}
          />
        </Flex>
      </Stack>
    </Container>
  );
};
