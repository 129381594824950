import {
  DefaultOperations,
  Product,
  Vendor,
  VendorContact,
} from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Group,
  Stack,
  Table,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { PX256 } from '../../components/Px';

export const ProductSelector = ({
  onSelected,
  vendor,
  vendorContact,
}: {
  onSelected: (v: { product: Product }) => void;
  vendor?: Vendor;
  vendorContact?: VendorContact;
}) => {
  const [filterByVendor, setFilterByVendor] = useState(false);
  const [filterByVC, setFilterByVC] = useState(false);
  const listProducts = useStatefulAPIRequestMaker(
    DefaultOperations.listProducts
  );

  const {
    debounceValue,
    onChangeDeps,
    target,
    setTargetFromInput,
    pagination,
    paginationElement,
  } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
    additionalDeps: [filterByVendor, filterByVC],
  });

  useEffect(() => {
    const p = listProducts.execute({
      target: debounceValue,
      limit: pagination.limit,
      primaryVendorId: filterByVendor ? vendor?.id : undefined,
      vendorContactId: filterByVC ? vendorContact?.id : undefined,
      offset: pagination.offset,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  const filterChips = [];
  vendor &&
    filterChips.push(
      <Tooltip label="Filter products by primary vendor." withArrow offset={10}>
        <Box>
          <Chip
            variant="light"
            checked={filterByVendor}
            onChange={(b) => setFilterByVendor(b)}
          >
            {vendor.name}
          </Chip>
        </Box>
      </Tooltip>
    );
  vendorContact &&
    filterChips.push(
      <Tooltip
        withArrow
        label="Filter products purchased by this contact in the last year."
        multiline
        w={PX256.Number}
        offset={10}
      >
        <Box>
          <Chip
            variant="light"
            checked={filterByVC}
            onChange={(b) => setFilterByVC(b)}
          >
            {vendorContact.name}
          </Chip>
        </Box>
      </Tooltip>
    );

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for Product"
        description="You can search by name, referene, or barcode."
        value={target}
        onChange={setTargetFromInput}
      />

      {filterChips.length > 0 && (
        <Stack>
          <Group>{filterChips}</Group>
        </Stack>
      )}

      <LoadingContainer loading={listProducts.loading}>
        <Stack>
          {paginationElement(listProducts.response?.count)}
          <Table highlightOnHover withBorder>
            <thead>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Name</th>
                <th>Reference</th>
                <th>Barcode</th>
              </tr>
            </thead>
            <tbody>
              {(listProducts.response?.products || []).map((s) => {
                const onClick = async () => {
                  onSelected(s);
                };

                return (
                  <ClickableTableRow onClick={onClick} key={s.product.id}>
                    <td>{s.product.name}</td>
                    <td>{s.product.reference}</td>
                    <td>{s.product.barcode}</td>
                  </ClickableTableRow>
                );
              })}
            </tbody>
          </Table>
          {paginationElement(listProducts.response?.count)}
        </Stack>
      </LoadingContainer>
    </Stack>
  );
};
