import React from 'react';
import { GenericSelector } from '@components/V2/selectors/GenericSelector';
import { DefaultOperations, Product } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';

// @deprecated
//
// Use modal selectors, not inline selectors.
export const ProductSelector = ({
  onProductSelected,
  product,
  disabled,
  label,
  readOnly,
  productID,
}: {
  onProductSelected: (sl: Product | null) => void;
  product?: Product | null;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  productID?: string;
}) => {
  const searchProducts = useStatefulAPIRequestMaker(
    DefaultOperations.listProducts
  );
  const getProduct = useStatefulAPIRequestMaker(DefaultOperations.getProduct);

  const searchItems = (target: string) => {
    searchProducts.execute({ target });
  };

  const searchItemsResponse = searchProducts.response?.products.map((p) => {
    return {
      value: p.product.id,
      label: p.product.name,
      item: p.product,
    };
  });

  const getItemByID = async (productID: string) => {
    const response = await getProduct.execute({ productId: productID });
    return response.product;
  };

  const item = product
    ? { value: product.id, label: product.name, item: product }
    : null;

  return (
    <GenericSelector
      onItemSelected={onProductSelected}
      searchItems={searchItems}
      searchItemsResponse={searchItemsResponse}
      getItemByID={getItemByID}
      itemID={productID}
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      item={item}
      loading={searchProducts.loading || getProduct.loading}
    />
  );
};
