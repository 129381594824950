import { closeModal, ContextModalProps } from '@mantine/modals';
import { Center, Loader, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useLoadingModal } from '../hooks/useLoadingModal';
import { v4 } from 'uuid';

export const ModalManagerLoadingModal = ({
  innerProps,
}: ContextModalProps<{ loadingMessage?: string }>) => (
  <Center>
    <Stack align="center">
      <Loader />
      <Text size="sm">{innerProps.loadingMessage ?? 'Please wait...'}</Text>
    </Stack>
  </Center>
);

export const LoadingModalContainer = ({
  visible,
  modalTitle,
  children,
}: {
  visible: boolean;
  modalTitle?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const { showLoadingModal } = useLoadingModal();
  const [id] = useState(v4());

  useEffect(() => {
    if (visible) {
      showLoadingModal({
        modalID: id,
        title: modalTitle ?? 'Loading',
      });
    } else {
      closeModal(id);
    }
  }, [visible]);

  return <>{children}</>;
};
