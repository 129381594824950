import { Location, Router } from '@reach/router';
import React from 'react';
import { ListStockAdjustmentGroupsPage } from './ListStockAdjustmentGroupsPage';
import { StockAdjustmentGroupViewer } from './StockAdjustmentGroupViewer';

export const StockAdjustmentGroupRouter = ({}: { path?: string }) => {
  // const navigate = useNavigate();

  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListStockAdjustmentGroupsPage path="/" />
          <StockAdjustmentGroupViewer path="/new" />
          <StockAdjustmentGroupViewer path="/:stockAdjustmentGroupID" />
        </Router>
      )}
    </Location>
  );
};
