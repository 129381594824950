import { Location, Router } from '@reach/router';
import React from 'react';
import { SaleReturnCodeViewer } from './SaleReturnCodeViewer';

export const SaleReturnCodePagesRouter = ({
  storeID,
}: {
  path: string;
  storeID?: string;
}) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <SaleReturnCodeViewer path="/" storeID={storeID!} />
        </Router>
      )}
    </Location>
  );
};
