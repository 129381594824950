import { DefaultOperations, PurchaseOrder } from '../../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import { useFindPrivileges } from '../../../../pccstores/UserUtils';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import {
  Alert,
  Button,
  Group,
  Select,
  Stack,
  Title,
  Text,
  Textarea,
} from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { PX12 } from '../../../../components/Px';
import React, { useState } from 'react';
import { IconCheck, IconShieldCheckFilled, IconX } from '@tabler/icons-react';
import { useInputState } from '@mantine/hooks';
import { handleApiErrorMessage } from '../../../../utils/handleApiErrorMessage';
import { showNotification } from '@mantine/notifications';

export const ApprovePurchaseOrderAlert = ({
  purchaseOrder,
  reloadPurchaseOrder,
}: {
  purchaseOrder?: PurchaseOrder;
  reloadPurchaseOrder: () => void;
}) => {
  const approvePurchaseOrder = useStatefulAPIRequestMaker(
    DefaultOperations.approvePurchaseOrder
  );
  const canApprovePurchaseOrder = useFindPrivileges({
    resource: 'purchase_order.approve',
    action: 'trigger',
  });

  if (!purchaseOrder || purchaseOrder.state !== 'waiting_approval') {
    return null;
  }

  const showApproveModal = () => {
    openConfirmModal({
      title: <Title order={3}>Approve Purchase Order</Title>,
      children: 'Are you sure you want to approve this purchase order?',
      labels: {
        confirm: 'Yes - Approve Purchase Order',
        cancel: 'Cancel',
      },
      confirmProps: { color: 'green' },
      onConfirm: async () => {
        await approvePurchaseOrder.execute({
          purchaseOrderId: purchaseOrder.id,
          requestBody: {
            approved: true,
          },
        });
        reloadPurchaseOrder();
      },
    });
  };

  const showRejectModal = () => {
    if (!purchaseOrder) return;

    openModal({
      modalId: 'reject-po-modal',
      title: <Title order={3}>Reject Purchase Order</Title>,
      children: (
        <RejectPurchaseOrderModal
          reloadPurchaseOrder={reloadPurchaseOrder}
          purchaseOrderID={purchaseOrder.id}
          closeModal={() => closeModal('reject-po-modal')}
        />
      ),
    });
  };

  return (
    <LoadingContainer loading={approvePurchaseOrder.loading}>
      <Alert
        title="Approval Required"
        color="grape"
        icon={<IconShieldCheckFilled />}
      >
        This purchase order needs to be approved before it can be collected.
        {canApprovePurchaseOrder.hasPrivilege && (
          <Group mt={PX12.Number}>
            <Button
              color="green"
              variant="light"
              compact
              leftIcon={<IconCheck size={16} />}
              onClick={showApproveModal}
            >
              Approve
            </Button>
            <Button
              color="red"
              variant="light"
              compact
              leftIcon={<IconX size={16} />}
              onClick={showRejectModal}
            >
              Reject
            </Button>
          </Group>
        )}
      </Alert>
    </LoadingContainer>
  );
};

const RejectPurchaseOrderModal = ({
  closeModal,
  purchaseOrderID,
  reloadPurchaseOrder,
}: {
  closeModal: () => void;
  purchaseOrderID: string;
  reloadPurchaseOrder: () => void;
}) => {
  const approvePurchaseOrder = useStatefulAPIRequestMaker(
    DefaultOperations.approvePurchaseOrder
  );
  const [reason, setReason] = useState<string | null>(null);
  const [description, setDescription] = useInputState('');

  const rejectionReasons = [
    'Reduce Quantity',
    'Negative Margin',
    'Reduce Order',
    'Change Payment Terms',
    'Other',
  ];

  const rejectPurchaseOrder = async () => {
    if (reason === null || (reason === 'Other' && description === '')) {
      return;
    }

    try {
      await approvePurchaseOrder.execute({
        purchaseOrderId: purchaseOrderID,
        requestBody: {
          approved: false,
          rejectionReason: reason,
          rejectionDescription: description,
        },
      });

      showNotification({
        color: 'green',
        title: 'Purchase Order Rejected',
        message: 'Purchase order has been rejected.',
      });

      reloadPurchaseOrder();
      closeModal();
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  return (
    <Stack>
      <Text>Are you sure you want to reject this purchase order?</Text>
      <Select
        label="Rejection Reason"
        required
        placeholder="Rejection Reason"
        data={rejectionReasons.map((r) => {
          return { value: r, label: r };
        })}
        onChange={setReason}
        withinPortal
      />
      <Textarea
        placeholder="More details"
        description="(Optional) Provide more details why this order was rejected."
        label="More details"
        onChange={setDescription}
        value={description}
      />
      <Group>
        <Button
          color="red"
          onClick={rejectPurchaseOrder}
          loading={approvePurchaseOrder.loading}
          disabled={
            reason === null || (reason === 'Other' && description === '')
          }
        >
          Reject
        </Button>
      </Group>
    </Stack>
  );
};
