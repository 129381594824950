import { NavigateFn } from '@reach/router';
import { useEffect, useState } from 'react';
import { StockLocation } from '../../../models/StockLocation';
import {
  Button,
  Container,
  Flex,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { Store } from '../../../models/Store';
import { useDebouncedValue } from '@mantine/hooks';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const ListStockLocations = ({
  navigate,
}: {
  path: string;
  navigate: NavigateFn;
}) => {
  useDocumentTitle('Stock Locations');

  const [target, setTarget] = useState('');
  const [debounceTarget] = useDebouncedValue(target, 200);
  const searchStockLocations = useStatefulAPIRequestMaker(
    DefaultOperations.searchStockLocations
  );

  useEffect(() => {
    const p = searchStockLocations.execute({ target: debounceTarget });

    return () => p.cancel();
  }, [debounceTarget]);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Stock Locations</Title>
        <Flex>
          <Button color="green" onClick={() => navigate('/stockLocations/new')}>
            New Location
          </Button>
        </Flex>
        <TextInput
          label="Search"
          onChange={(e: any) => setTarget(e.target.value)}
          value={target}
        />
        <ProductsTable
          stockLocations={searchStockLocations.response?.stockLocations}
          loading={searchStockLocations.loading}
        />
      </Stack>
    </Container>
  );
};

const ProductsTable = ({
  stockLocations,
  loading,
}: {
  stockLocations?: {
    stockLocation: StockLocation;
    store?: Store | null;
  }[];
  loading: boolean;
}) => {
  return (
    <LoadingContainer loading={loading} minHeight={PX384.Number}>
      <Table highlightOnHover withBorder>
        <thead>
          <tr>
            <th>Name</th>
            <th>Store</th>
          </tr>
        </thead>

        <tbody>
          {(stockLocations || []).map((sl) => {
            return (
              <ClickableTableRow
                targetURL={`/stockLocations/${sl.stockLocation.id}`}
                key={sl.stockLocation.id}
              >
                <td>{sl.stockLocation.name}</td>
                <td>{sl.store?.name || ''}</td>
              </ClickableTableRow>
            );
          })}
        </tbody>
      </Table>
    </LoadingContainer>
  );
};
