import { DefaultOperations, VendorContact } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect, useRef } from 'react';
import { Stack, Table } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const VendorContactSelectorModalBody = ({
  onSelected,
  vendorID,
}: {
  onSelected: (v: VendorContact) => void;
  vendorID: string;
}) => {
  const searchVendorContacts = useStatefulAPIRequestMaker(
    DefaultOperations.getVendorContactsForVendor
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!searchVendorContacts.loading) {
      inputRef.current?.focus();
    }
  }, [searchVendorContacts.loading]);

  const {
    // debounceValue,
    onChangeDeps,
    // pagination,
    // target,
    // setTargetFromInput,
  } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
  });

  useEffect(() => {
    searchVendorContacts.execute({ vendorId: vendorID });
  }, [...onChangeDeps]);

  return (
    <Stack>
      {/*<TextInput*/}
      {/*  ref={inputRef}*/}
      {/*  autoFocus*/}
      {/*  label="Search"*/}
      {/*  placeholder="Search for Vendor"*/}
      {/*  value={target}*/}
      {/*  onChange={setTargetFromInput}*/}
      {/*/>*/}

      <LoadingContainer loading={searchVendorContacts.loading}>
        <Table highlightOnHover withBorder>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {(searchVendorContacts.response?.vendorContacts || []).map((v) => {
              const onClick = async () => {
                onSelected(v);
              };

              return (
                <ClickableTableRow onClick={onClick} key={v.id}>
                  <td>{v.name}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
