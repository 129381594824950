import { LoadingOverlay } from '@mantine/core';
import { ReactElement } from 'react';

type LoadingContainerChildrenTypes =
  | ReactElement
  | undefined
  | 0
  | false
  | null;

export const LoadingContainer = ({
  children,
  loading,
  minHeight,
}: {
  children?: LoadingContainerChildrenTypes | LoadingContainerChildrenTypes[];
  loading: boolean;
  minHeight?: number;
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} style={{ minHeight: minHeight }} />
      {children}
    </div>
  );
};
