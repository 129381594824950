import { Privilege, Store, UserType } from '../oas/codegen3';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserContext = {
  id: string;
  firstName: string;
  lastName: string;
  type: UserType;
  userStores: Store[];
  privileges: Privilege[];
  systemPrivilegesMap: {
    [actionResourceString: string]: boolean;
  };
  storePrivilegesMap: {
    [storeID: string]: {
      [actionResourceString: string]: boolean;
    };
  };
} | null;

export type UserSliceState = {
  user: UserContext;
  authentication: {
    authenticated: boolean | null;
    authContext: {
      jwtExpiresAtUnix: number;
    } | null;
  };
};

const initialState: UserSliceState = {
  user: null,
  authentication: {
    authenticated: null,
    authContext: null,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserContext>) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.authentication.authenticated = false;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authentication.authenticated = action.payload;
    },
  },
});

export const { setUser, logoutUser, setAuthenticated } = userSlice.actions;
export default userSlice.reducer;
