import { useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  usePccStoreDispatch,
  usePccStoreSelector,
} from '../pccstores/PccStore';
import { logoutUser } from '../pccstores/UserReducer';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const useAxiosAuthRefresher = () => {
  const dispatch = usePccStoreDispatch();
  const authContext = usePccStoreSelector(
    (state) => state.userStates.authentication
  );

  useEffect(() => {
    const onSuccessIntercetor = (response: AxiosResponse<any>) => {
      return response;
    };

    const onFailInterceptor = async (err: any) => {
      const responseStatusCode = err.response?.status;
      if (responseStatusCode === 401) {
        dispatch(logoutUser());
      }

      throw err;
    };

    const interceptor = axios.interceptors.response.use(
      onSuccessIntercetor,
      onFailInterceptor
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [authContext]);
};
