import { ListPurchaseOrders } from '../purchaseorderpages/ListPurchaseOrders';
import { Location, Router } from '@reach/router';
import { CheckStockPage } from '../stockLocationPages/CheckStockPage';
import React from 'react';
import { SaleReturnCodePagesRouter } from '../sale_return_code_pages/SaleReturnCodePagesRouter';

export const UserStoreRouter = ({
  storeID,
}: {
  path?: string;
  storeID?: string;
}) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListPurchaseOrders path="/purchaseOrders" storeID={storeID} />
          <CheckStockPage path="/checkStock" storeID={storeID} />
          <SaleReturnCodePagesRouter
            path="/saleReturnCodes"
            storeID={storeID}
          />
        </Router>
      )}
    </Location>
  );
};
