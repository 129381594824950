import { Location, Router, useNavigate } from '@reach/router';
import React from 'react';
import { ListStockLocations } from './ListStockLocations';
import { StockLocationForm } from './StockLocationForm';
import { CheckStockPage } from './CheckStockPage';

// import { useNavigate} from '@reach/router';

export const StockLocationsRouter = ({}: { path: string }) => {
  const navigate = useNavigate();

  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <CheckStockPage path="/checkStock/*" />
          <ListStockLocations path="/" navigate={navigate} />
          <StockLocationForm path="/:stockLocationID" />
          <StockLocationForm path="/new" />
        </Router>
      )}
    </Location>
  );
};
