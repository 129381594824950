import { DefaultOperations, User } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect } from 'react';
import { Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const UserModalSelectorBody = ({
  onUserSelected,
}: {
  onUserSelected: (u: User) => void;
}) => {
  const searchUsers = useStatefulAPIRequestMaker(DefaultOperations.listUsers);

  const {
    debounceValue,
    onChangeDeps,
    pagination,
    target,
    setTargetFromInput,
  } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
  });

  useEffect(() => {
    searchUsers.execute({
      name: debounceValue,
      userEnabled: true,
      limit: pagination.limit,
    });
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for User"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={searchUsers.loading}>
        <Table highlightOnHover striped withBorder>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {(searchUsers.response?.users || []).map((u) => {
              const onClick = async () => {
                onUserSelected(u);
              };

              return (
                <ClickableTableRow onClick={onClick} key={u.id}>
                  <td>{u.firstName + ' ' + u.lastName}</td>
                </ClickableTableRow>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};
