import { useNavigate } from '@reach/router';
import { useEffect } from 'react';
import {
  Button,
  Container,
  Title,
  Input,
  Stack,
  Flex,
  Table,
} from '@mantine/core';

import { useURLSearchQueryParamSetter } from '../../../hooks/useURLSearchQueryParamSetter';
import { PX1536, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { RFC3339ToEasyDate } from '../../../utils/dateFormatting';

export const ListVendors = ({}: { path?: string }) => {
  const navigate = useNavigate();

  const {
    onChangeDeps,
    target,
    setTargetFromInput,
    pagination,
    paginationElement,
  } = useListSearcherHelper({
    debounceDelay: 300,
    limitPerPage: 30,
  });
  const searchVendors = useStatefulAPIRequestMaker(
    DefaultOperations.searchVendors
  );

  useURLSearchQueryParamSetter({ search: target }, [target]);

  useEffect(() => {
    searchVendors.execute({
      target,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [...onChangeDeps]);

  return (
    <Container maw={PX1536.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Vendors</Title>
        <Flex>
          <Button color="green" onClick={() => navigate('/vendors/new')}>
            New Vendor
          </Button>
        </Flex>
        <Input
          placeholder="Search"
          value={target}
          onChange={setTargetFromInput}
        />
        <LoadingContainer
          loading={searchVendors.loading}
          minHeight={PX384.Number}
        >
          <Stack>
            {paginationElement(searchVendors.response?.count)}

            <Table highlightOnHover withBorder striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Last Purchase</th>
                </tr>
              </thead>

              <tbody>
                {(searchVendors.response?.vendors2 ?? []).map((v) => {
                  return (
                    <ClickableTableRow
                      targetURL={`/vendors/${v.vendor.id}`}
                      key={v.vendor.id}
                    >
                      <td>{v.vendor.name}</td>
                      <td>
                        {v.lastPurchaseOrderCollectedAt
                          ? RFC3339ToEasyDate(v.lastPurchaseOrderCollectedAt)
                          : null}
                      </td>
                    </ClickableTableRow>
                  );
                })}
              </tbody>
            </Table>
            {paginationElement(searchVendors.response?.count)}
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
