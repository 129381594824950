import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import {
  dateToRFC3339,
  RFC3339ToEasyDate,
} from '../../../utils/dateFormatting';
import {
  Box,
  Button,
  Code,
  Container,
  Flex,
  Group,
  Input,
  Pagination,
  Stack,
  Table,
  Title,
  Tooltip,
} from '@mantine/core';
import { PX1536, PX192, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { LoadingContainer } from '@components/V2/LoadingContainer';

import { StatusBadge } from '@components/V2/StatusBadge';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  PurchaseOrderPaymentTerm,
  Store,
  Vendor,
} from '../../../oas/codegen3';
import { closeModal, openModal } from '@mantine/modals';
import { VendorSelectorModalBody } from '@components/V2/modal_selector/VendorSelectorModalBody';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { StoreSelectorModalBody } from '@components/V2/modal_selector/StoreSelectorModalBody';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { formatToCurrency } from '@pcc/api/models/Currency';
import {
  ComparatorToPrettyString,
  NumericComparator,
  NumericComparatorSelectorModal,
  NumericComparatorToString,
} from '@components/V2/modal_selector/NumericComparatorSelectorModal';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { ModalSelector } from '@components/V2/modal_selector/ModalSelectorBody';
import { ProfitabilityRangeBadge } from '@components/V2/ProfitabilityRangeBadge';
import { formatDateRange } from '../../../utils/formatStartAndEndDate';
import { DateRangePickerModal } from '@components/V2/modal_selector/DateRangePickerModal';
import { DaysToSellBadge } from '@components/V2/EstimatedDaysToSellRangeBadge';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useCanUser, useFindPrivileges } from '../../../pccstores/UserUtils';

export const ListPurchaseOrders = ({
  storeID,
}: {
  path?: string;
  storeID?: string;
}) => {
  useDocumentTitle('Purchase Orders');
  const location = useLocation();
  const currentURL = location.pathname;
  const navigate = useNavigate();
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const [store, setStore] = useState<Store | null>(null);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [totalAmountComparator, setTotalAmountComparator] =
    useState<NumericComparator | null>(null);
  const [balanceAmountComparator, setBalanceAmountComparator] =
    useState<NumericComparator | null>(null);
  const [stateFilter, setStateFilter] = useState<string | null>(null);
  const [paymentTerm, setPaymentTerm] =
    useState<PurchaseOrderPaymentTerm | null>(null);

  const { target, setTargetFromInput, pagination, onChangeDeps } =
    useListSearcherHelper({
      limitPerPage: 30,
      debounceDelay: 200,
      additionalDeps: [
        vendor,
        store,
        totalAmountComparator,
        balanceAmountComparator,
        stateFilter,
        paymentTerm,
        dateRange,
      ],
    });

  const listPurchaseOrders = useStatefulAPIRequestMaker(
    DefaultOperations.listPurchaseOrders
  );

  const canViewProfitability = useFindPrivileges({
    // userType: ['admin', 'employee'],
    resource: 'profitability_analytics',
    action: 'read',
  });

  useEffect(() => {
    const p = listPurchaseOrders.execute({
      vendorId: vendor?.id ?? undefined,
      limit: pagination.limit,
      number: target,
      offset: pagination.offset,
      storeId: store?.id ?? undefined,
      totalAmount:
        NumericComparatorToString(totalAmountComparator) ?? undefined,
      balanceAmount:
        NumericComparatorToString(balanceAmountComparator) ?? undefined,
      state: stateFilter ?? undefined,
      includeProfitability: canViewProfitability.hasPrivilege,
      includeEstimatedDaysToSell: canViewProfitability.hasPrivilege,
      paymentTerms: paymentTerm ?? undefined,
      startDate: dateToRFC3339(dateRange[0]) ?? undefined,
      endDate: dateToRFC3339(dateRange[1]) ?? undefined,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  const canDraftPurchaseOrder = useCanUser({
    checkType: 'all',
    requiredPrivileges: [
      {
        action: 'write',
        resource: 'purchase_order',
      },
    ],
    storeID,
  });

  const onVendorBadgeClicked = () => {
    openModal({
      modalId: 'vendor-selector-modal',
      title: 'Select Vendor',
      children: (
        <VendorSelectorModalBody
          onVendorSelected={(v) => {
            setVendor(v);
            closeModal('vendor-selector-modal');
          }}
        />
      ),
    });
  };

  const onStoreBadgeClicked = () => {
    openModal({
      modalId: 'store-selector-modal',
      title: 'Select Vendor',
      children: (
        <StoreSelectorModalBody
          onStoreSelected={(s) => {
            setStore(s);
            closeModal('store-selector-modal');
          }}
        />
      ),
    });
  };

  const showTotalAmountNumericComparatorModal = () => {
    openModal({
      modalId: 'total-amount-comparator-modal',
      title: <Title order={3}>Filter Total Amount</Title>,
      children: (
        <NumericComparatorSelectorModal
          number={totalAmountComparator?.number}
          comparator={totalAmountComparator?.comparator}
          onSelected={(nc) => {
            setTotalAmountComparator({
              number: nc.number,
              comparator: nc.comparator,
            });
            closeModal('total-amount-comparator-modal');
          }}
        />
      ),
    });
  };

  const showBalanceAmountNumericComparatorModal = () => {
    openModal({
      modalId: 'balance-amount-comparator-modal',
      title: <Title order={3}>Filter Balance Amount</Title>,
      children: (
        <NumericComparatorSelectorModal
          number={balanceAmountComparator?.number}
          comparator={balanceAmountComparator?.comparator}
          onSelected={(nc) => {
            setBalanceAmountComparator({
              number: nc.number,
              comparator: nc.comparator,
            });
            closeModal('balance-amount-comparator-modal');
          }}
        />
      ),
    });
  };

  const showStateSelectorModal = () => {
    openModal({
      modalId: 'state-selector-modal',
      title: 'Select State',
      children: (
        <ModalSelector
          items={[
            { label: 'Draft', value: 'draft' },
            { label: 'Rejected', value: 'rejected' },
            { label: 'Ready', value: 'ready' },
            { label: 'Completed', value: 'completed' },
            { label: 'Waiting Approval', value: 'waiting_approval' },
            { label: 'Posted', value: 'posted' },
            { label: 'Cancelled', value: 'cancelled' },
          ]}
          onItemSelected={(s) => {
            setStateFilter(s.value);
            closeModal('state-selector-modal');
          }}
        />
      ),
    });
  };

  const showPaymentTermSelectorModal = () => {
    openModal({
      modalId: 'payment-term-selector-modal',
      title: 'Select State',
      children: (
        <ModalSelector
          items={[
            { label: 'Credit', value: 'credit' },
            { label: 'Cash', value: 'cash' },
          ]}
          onItemSelected={(s) => {
            setPaymentTerm(s.value as PurchaseOrderPaymentTerm);
            closeModal('payment-term-selector-modal');
          }}
        />
      ),
    });
  };

  const showDatePickerModal = () => {
    openModal({
      modalId: 'date-range-modal',
      title: <Title order={3}>Select Date Range</Title>,
      size: 'sm',
      children: (
        <DateRangePickerModal
          onDateRangeSelected={(dr) => {
            setDateRange(dr);
            closeModal('date-range-modal');
          }}
        />
      ),
    });
  };

  return (
    <Container maw={PX1536.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Purchase Orders</Title>
        <Tooltip
          withArrow
          width={PX192.Number}
          multiline
          position="left"
          label="You do not have permission to draft purchase orders."
          disabled={canDraftPurchaseOrder}
        >
          <Box sx={{ width: 'fit-content' }}>
            <Button
              color="green"
              onClick={() => navigate(`${currentURL}/new`)}
              disabled={!canDraftPurchaseOrder}
              sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
            >
              New Purchase Order
            </Button>
          </Box>
        </Tooltip>
        <Input
          placeholder="Search"
          onChange={setTargetFromInput}
          value={target}
        />

        <Group>
          <SelectorBadge
            onClick={onVendorBadgeClicked}
            onClearClick={() => setVendor(null)}
            placeholder="Select Vendor"
            value={vendor?.name}
          />
          <SelectorBadge
            onClick={onStoreBadgeClicked}
            onClearClick={() => setStore(null)}
            placeholder="Select Store"
            value={store?.name}
          />
          <SelectorBadge
            onClick={showStateSelectorModal}
            onClearClick={() => setStateFilter(null)}
            placeholder="Select State"
            value={stateFilter}
          />
          <SelectorBadge
            onClick={showTotalAmountNumericComparatorModal}
            onClearClick={() => setTotalAmountComparator(null)}
            placeholder="Filter Total Amount"
            value={
              totalAmountComparator
                ? `Total Amount ${ComparatorToPrettyString(
                    totalAmountComparator.comparator
                  )} ${totalAmountComparator.number}`
                : null
            }
          />

          <SelectorBadge
            onClick={showBalanceAmountNumericComparatorModal}
            onClearClick={() => setBalanceAmountComparator(null)}
            placeholder="Filter Balance Amount"
            value={
              balanceAmountComparator
                ? `Balance ${ComparatorToPrettyString(
                    balanceAmountComparator.comparator
                  )} ${balanceAmountComparator.number}`
                : null
            }
          />
          <SelectorBadge
            onClick={showPaymentTermSelectorModal}
            onClearClick={() => setPaymentTerm(null)}
            placeholder="Select Payment Term"
            value={paymentTerm ? `Payment Term: ${paymentTerm}` : null}
          />

          <SelectorBadge
            value={
              dateRange[0]
                ? `Created: ${formatDateRange(dateRange)}`
                : undefined
            }
            placeholder="Create Date"
            onClick={showDatePickerModal}
            onClearClick={() => setDateRange([null, null])}
          />
        </Group>

        <LoadingContainer
          loading={listPurchaseOrders.loading}
          minHeight={PX384.Number}
        >
          <Stack>
            {listPurchaseOrders.response?.purchaseOrdersTotalCount && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(
                    listPurchaseOrders.response?.purchaseOrdersTotalCount
                  )}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
            <Table highlightOnHover withBorder>
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Vendor Ref</th>
                  <th>Created At</th>
                  <th>Vendor</th>
                  <th>Store</th>
                  <th>State</th>
                  {stateFilter === 'rejected' && <th>Rejected Reason</th>}
                  <th style={{ textAlign: 'right' }}>Amount</th>
                  {stateFilter !== 'rejected' && (
                    <th style={{ textAlign: 'right' }}>Balance</th>
                  )}
                  {canViewProfitability.hasPrivilege && <th>Profit Margin</th>}
                  {canViewProfitability.hasPrivilege && <th>Days to Sell</th>}
                </tr>
              </thead>

              <tbody>
                {(listPurchaseOrders.response?.purchaseOrders || []).map(
                  (i) => {
                    const totalAmount = i.purchaseOrder.totalAmount
                      ? formatToCurrency(
                          i.purchaseOrder.totalAmount,
                          i.currency
                        )
                      : '';
                    const balance = i.purchaseOrder.balanceAmount
                      ? formatToCurrency(
                          i.purchaseOrder.balanceAmount,
                          i.currency
                        )
                      : '';

                    return (
                      <ClickableTableRow
                        targetURL={`/purchaseOrders/${i.purchaseOrder.id}`}
                        key={i.purchaseOrder.id}
                      >
                        <td>
                          <Code style={{ whiteSpace: 'nowrap' }}>
                            {i.purchaseOrder.number}
                          </Code>
                        </td>
                        <td>
                          {i.purchaseOrder.vendorInvoiceReference && (
                            <Code style={{ whiteSpace: 'nowrap' }}>
                              {i.purchaseOrder.vendorInvoiceReference}
                            </Code>
                          )}
                        </td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                          {RFC3339ToEasyDate(i.purchaseOrder.createdAt)}
                        </td>
                        <td>{i.vendor.name}</td>
                        <td>{i.store?.name || 'No Store'}</td>
                        <td>
                          <StatusBadge state={i.purchaseOrder.state} />
                        </td>
                        {stateFilter === 'rejected' && (
                          <td>{i.purchaseOrder.lastRejectedReason}</td>
                        )}
                        <td
                          style={{ whiteSpace: 'nowrap', textAlign: 'right' }}
                        >
                          {totalAmount}
                        </td>
                        {stateFilter !== 'rejected' && (
                          <td
                            style={{ whiteSpace: 'nowrap', textAlign: 'right' }}
                          >
                            {balance}
                          </td>
                        )}
                        {canViewProfitability.hasPrivilege && (
                          <td>
                            <ProfitabilityRangeBadge
                              profitabilityRange={i.currentProfitability}
                            />
                          </td>
                        )}
                        {canViewProfitability.hasPrivilege && (
                          <td>
                            <DaysToSellBadge
                              estimatedDaysToSellRange={
                                i.estimatedDaysToSellRange
                              }
                            />
                          </td>
                        )}
                      </ClickableTableRow>
                    );
                  }
                )}
              </tbody>
            </Table>
            {listPurchaseOrders.response?.purchaseOrdersTotalCount && (
              <Flex justify="flex-end">
                <Pagination
                  size="sm"
                  total={pagination.calculateTotalPages(
                    listPurchaseOrders.response?.purchaseOrdersTotalCount
                  )}
                  value={pagination.currentPaginationPage}
                  onChange={pagination.onPaginationPageChange}
                />
              </Flex>
            )}
          </Stack>
        </LoadingContainer>
      </Stack>
    </Container>
  );
};
