import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Container,
  Group,
  MultiSelect,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { openConfirmModal } from '@mantine/modals';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  PaymentMethodTargetPaymentType,
} from '../../../oas/codegen3';

export const PaymentMethodViewer = ({
  paymentMethodID,
}: {
  path?: string;
  paymentMethodID?: string;
}) => {
  const [enabledCheck, setEnabledCheck] = useState(true);
  const getPaymentMethod = useStatefulAPIRequestMaker(
    DefaultOperations.getPaymentMethod
  );
  const createPaymentMethod = useStatefulAPIRequestMaker(
    DefaultOperations.createPaymentMethod
  );
  const updatePaymentMethod = useStatefulAPIRequestMaker(
    DefaultOperations.updatePaymentMethod
  );
  const updatePaymentMethodEnabled = useStatefulAPIRequestMaker(
    DefaultOperations.setPaymentMethodEnabled
  );

  useEffect(() => {
    if (paymentMethodID) {
      getPaymentMethod.execute({ paymentMethodId: paymentMethodID });
    }
  }, [paymentMethodID]);

  const form = useForm<{
    name?: string;
    requiredFields: string[];
    targetPaymentType?: PaymentMethodTargetPaymentType;
  }>({
    validate: {
      name: (v?: string) => (v ? null : 'Name required'),
      targetPaymentType: (v?: string) =>
        v ? null : 'Target Payment Type required',
    },
    initialValues: {
      requiredFields: [],
    },
  });

  useEffect(() => {
    if (getPaymentMethod.response) {
      form.setValues({
        name: getPaymentMethod.response.paymentMethod.name,
        requiredFields:
          getPaymentMethod.response.paymentMethod.requiredMetadataFields,
        targetPaymentType:
          getPaymentMethod.response.paymentMethod.targetPaymentType,
      });

      setEnabledCheck(getPaymentMethod.response.paymentMethod.enabled);
    }
  }, [getPaymentMethod.response]);

  const onSubmit = form.onSubmit(async (values) => {
    if (paymentMethodID) {
      await updatePaymentMethod.execute({
        paymentMethodId: paymentMethodID,
        requestBody: {
          name: values.name!,
          requiredFields: values.requiredFields,
          targetPaymentType: values.targetPaymentType!,
        },
      });

      showNotification({
        color: 'green',
        title: 'Payment Method Updated',
        message: `Payment method ${values.name} has been updated.`,
      });
    } else {
      const response = await createPaymentMethod.execute({
        requestBody: {
          name: values.name!,
          requiredFields: values.requiredFields,
          targetPaymentType: values.targetPaymentType!,
        },
      });

      showNotification({
        color: 'green',
        title: 'Payment Method Created',
        message: `Payment method ${values.name} has been created successfully.`,
      });

      navigate(`/paymentMethods/${response.paymentMethod.id}`);
    }
  });

  return (
    <LoadingContainer
      loading={
        getPaymentMethod.loading ||
        createPaymentMethod.loading ||
        updatePaymentMethod.loading ||
        updatePaymentMethodEnabled.loading
      }
    >
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <Title>
            {paymentMethodID
              ? form.values.name ||
                getPaymentMethod.response?.paymentMethod.name
              : 'New Payment Method'}
          </Title>

          <Switch
            checked={enabledCheck}
            onLabel="Enabled"
            offLabel="Disabled"
            color="green"
            onChange={(e) => {
              const checked = e.currentTarget.checked;

              openConfirmModal({
                title: checked
                  ? 'Enable Payment Method'
                  : 'Disable Payment Method',
                children: (
                  <Text size="sm">
                    Are you sure you want to {checked ? 'enable' : 'disable'}{' '}
                    this payment method?
                  </Text>
                ),
                confirmProps: {
                  color: checked ? 'green' : 'red',
                },
                labels: {
                  confirm: checked
                    ? 'Enable Payment Method'
                    : 'Disable Payment Method',
                  cancel: 'Cancel',
                },
                // onCancel: () => console.log('Cancel'),
                onConfirm: async () => {
                  await updatePaymentMethodEnabled.execute({
                    paymentMethodId: paymentMethodID!,
                    requestBody: {
                      enabled: checked,
                    },
                  });
                  await getPaymentMethod.execute({
                    paymentMethodId: paymentMethodID!,
                  });
                },
              });
            }}
          />

          <form onSubmit={onSubmit}>
            <Stack>
              <TextInput label="Name" {...form.getInputProps('name')} />
              <MultiSelect
                label="Required Fields"
                data={form.values.requiredFields || []}
                value={form.values.requiredFields || []}
                searchable
                creatable
                onChange={(values: string[]) => {
                  form.setValues({
                    requiredFields: values,
                  });
                }}
                getCreateLabel={(query) => `+ Add ${query}`}
                onCreate={(query) => {
                  return query;
                }}
              />
              <Select
                label="Target Payment Type"
                data={[
                  { value: 'sale_payment', label: 'Sale Payments' },
                  {
                    value: 'purchase_order_payment',
                    label: 'Purchase Order Payments',
                  },
                ]}
                {...form.getInputProps('targetPaymentType')}
              />
              <Group>
                <Button type="submit" color="green">
                  {paymentMethodID ? 'Update' : 'Create'}
                </Button>
              </Group>
            </Stack>
          </form>
        </Stack>
      </Container>
    </LoadingContainer>
  );
};
