import { useDisclosure, useInputState, useListState } from '@mantine/hooks';
import {
  DefaultOperations,
  ExtendedProductGenericCode,
} from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  PX1024,
  PX16,
  PX256,
  PX32,
  PX384,
  PX4,
  PX64,
  PX96,
} from '../../../components/Px';
import {
  Alert,
  Button,
  Code,
  Container,
  Divider,
  Flex,
  Group,
  Modal,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { IconInfoCircle, IconSearch } from '@tabler/icons-react';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { showNotification } from '@mantine/notifications';
import { useLoadingModal } from '../../../hooks/useLoadingModal';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';

export const MergeProductGenericCodePage = ({}: { path?: string }) => {
  const listGenericCodes = useStatefulAPIRequestMaker(
    DefaultOperations.listProductGenericCodes
  );
  const mergeGenericCodes = useStatefulAPIRequestMaker(
    DefaultOperations.mergeProductGenericCodes
  );
  const { showLoadingModal } = useLoadingModal();

  const [genericCodeToMergeTo, setGenericCodeToMergeTo] = useInputState('');
  const [opened, { open, close }] = useDisclosure(false);
  const { target, setTargetFromInput, onChangeDeps, pagination } =
    useListSearcherHelper({
      debounceDelay: 200,
      limitPerPage: 40,
    });

  const [selectedGenericCodes, handler] =
    useListState<ExtendedProductGenericCode>();

  useEffect(() => {
    listGenericCodes.execute({
      genericCode: target,
      limit: pagination.limit,
    });
  }, [...onChangeDeps]);

  const tryToMergeGenericCodes = async () => {
    const closeLoadingModal = showLoadingModal({
      title: 'Merging Generic Codes',
    });

    try {
      await mergeGenericCodes.execute({
        requestBody: {
          genericCodes: selectedGenericCodes.map((gc) => gc.genericCode),
          genericCodeToMergeTo: genericCodeToMergeTo,
        },
      });

      showNotification({
        color: 'green',
        title: 'Generic Codes Merged',
        message: 'The generic codes have been merged successfully.',
      });

      close();
      setGenericCodeToMergeTo('');
      handler.setState([]);
      listGenericCodes.execute({
        genericCode: target,
        limit: pagination.limit,
      });
    } catch (e) {
      handleApiErrorMessage(e);
    } finally {
      closeLoadingModal();
    }
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Modal
        opened={opened}
        onClose={close}
        title={<Title order={3}>Merge Generic Codes</Title>}
      >
        {mergeGenericCodes.loading ? null : (
          <Stack>
            You are about to merge {selectedGenericCodes.length} generic codes
            together. Type the name of the generic code to merge into, and click
            "Merge".
            <TextInput
              label="Merged Generic Code"
              placeholder="Merged Generic Code"
              value={genericCodeToMergeTo}
              onChange={setGenericCodeToMergeTo}
            />
            <Alert
              hidden={genericCodeToMergeTo.trim() === ''}
              color="red"
              title="New Generic Code"
              icon={<IconInfoCircle />}
            >
              All products with the selected generic codes will be merged into{' '}
              <Code>{genericCodeToMergeTo}</Code>. The selected generic codes
              will also be deleted.
            </Alert>
            <Button
              color="green"
              disabled={genericCodeToMergeTo.trim() === ''}
              onClick={tryToMergeGenericCodes}
            >
              Merge
            </Button>
          </Stack>
        )}
      </Modal>
      <Stack>
        <Title>Merge Product Generic Codes</Title>

        <Alert
          color="blue"
          title="Merge Product Generic Codes"
          icon={<IconInfoCircle />}
        >
          Select the product generic codes you want to merge by clicking on
          them. When you've selected all the generic codes, click on "Next
          Step".
        </Alert>
        <Group>
          <Button
            color="green"
            variant="light"
            disabled={selectedGenericCodes.length === 0}
            onClick={open}
          >
            Next Step
          </Button>
        </Group>
        <TextInput
          icon={<IconSearch />}
          label="Search"
          placeholder="Search"
          onChange={setTargetFromInput}
          value={target}
        />

        <Flex gap={PX16.Number}>
          <Container style={{ flex: 1 }} miw={PX256.Number}>
            <Stack spacing={PX4.Number}>
              <LoadingContainer
                loading={listGenericCodes.loading}
                minHeight={PX384.Number}
              >
                <Table highlightOnHover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th style={{ textAlign: 'right' }}>Product Count</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(listGenericCodes.response?.productGenericCodes ?? []).map(
                      (gc) => {
                        const onClick = () => {
                          const res = selectedGenericCodes.find(
                            (ps) => ps.id === gc.productGenericCode.id
                          );

                          if (!res) {
                            handler.append(gc.productGenericCode);
                          } else {
                            showNotification({
                              color: 'blue',
                              title: 'Product Generic Code Already In List',
                              message:
                                'The product generic code is already in the selected list.',
                            });
                          }
                        };

                        return (
                          <tr
                            onClick={onClick}
                            key={gc.productGenericCode.id}
                            style={{ cursor: 'pointer' }}
                          >
                            <td>{gc.productGenericCode.genericCode}</td>
                            <td style={{ textAlign: 'right' }}>
                              {gc.productGenericCode.productCount}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </LoadingContainer>
            </Stack>
          </Container>
          <Divider orientation="vertical" />
          <Container style={{ flex: 1 }} miw={PX256.Number}>
            <Stack spacing={PX4.Number}>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th style={{ textAlign: 'right' }}>Product Count</th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {selectedGenericCodes.map((gc, i) => {
                    const removeSelectGC = () => {
                      handler.remove(i);
                    };

                    return (
                      <tr key={gc.id}>
                        <td>{gc.genericCode}</td>
                        <td style={{ textAlign: 'right' }}>
                          {gc.productCount}
                        </td>
                        <td align="center">
                          <Button
                            compact
                            color="red"
                            variant="light"
                            onClick={removeSelectGC}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Stack>
          </Container>
        </Flex>
      </Stack>
    </Container>
  );
};
