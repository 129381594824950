import {
  Container,
  Title,
  Stack,
  Select,
  Group,
  TextInput,
  Button,
  Box,
  Text,
  Flex,
  Code,
  Tooltip,
  ActionIcon,
  CopyButton,
  Alert,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { PhoneNumberCountryCodeData } from 'src/utils/phoneNumberCountryCodeData';
import { PX1024, PX128, PX32, PX64, PX8, PX96 } from '../../../components/Px';
import React, { forwardRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { openModal } from '@mantine/modals';
import { useNavigate } from '@reach/router';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const NewUserPage = ({}: { path?: string }) => {
  const navigate = useNavigate();
  const createUser = useStatefulAPIRequestMaker(DefaultOperations.createUser);

  const form = useForm<{
    email: string;
    phoneNumber: string;
    phoneNumberCountryCode: string;
    firstName: string;
    lastName: string;
    userType: 'admin' | 'employee' | null;
  }>({
    initialValues: {
      email: '',
      phoneNumber: '',
      phoneNumberCountryCode: '+973',
      firstName: '',
      lastName: '',
      userType: null,
    },
    validateInputOnBlur: true,
    validate: {
      email: (value) => (value ? null : true),
      phoneNumber: (value) => (value ? null : true),
      phoneNumberCountryCode: (value) => (value ? null : true),
      firstName: (value) => (value ? null : true),
      lastName: (value) => (value ? null : true),
      userType: (value: any) => (value ? null : true),
    },
  });

  const onSubmit = form.onSubmit(async (v) => {
    const response = await createUser.execute({
      requestBody: {
        email: v.email,
        phoneCountryCode: v.phoneNumberCountryCode,
        phoneNumber: v.phoneNumber,
        firstName: v.firstName,
        lastName: v.lastName,
        userType: v.userType!,
      },
    });

    openModal({
      title: <Title order={3}>User Created</Title>,
      closeOnClickOutside: false,
      children: (
        <Stack>
          <Alert variant="outline" color="blue">
            Here is the user's temporary password. They will need it to sign in.
            When signing in, they will be asked to set their own password.{' '}
            <Text span color="red" weight={700}>
              You will not be able to see this password again, so please copy
              it.
            </Text>
          </Alert>
          <Group>
            <CopyButton value={response.temporaryPassword || ''} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Click to Copy'}>
                  <ActionIcon
                    onClick={copy}
                    color={copied ? 'green' : 'orange'}
                  >
                    {copied ? <IconCheck /> : <IconCopy />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
            <Code block>{response.temporaryPassword}</Code>
          </Group>
        </Stack>
      ),
      onClose: () => {
        navigate(`/users/${response.userID}`);
      },
    });
  });

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>New User</Title>
        <form onSubmit={onSubmit}>
          <Stack>
            <TextInput
              disabled={createUser.loading}
              label="Email"
              type="email"
              required
              {...form.getInputProps('email')}
            />
            <Flex align="flex-end" gap={PX8.Number}>
              <Box style={{ width: '100%', flexGrow: 1 }}>
                <TextInput
                  disabled={createUser.loading}
                  styles={{
                    icon: {
                      pointerEvents: 'auto',
                      width: PX128.Number,
                    },
                  }}
                  iconWidth={PX128.Number + 12}
                  icon={
                    <Select
                      styles={{
                        input: {
                          borderBottomRightRadius: 0,
                          borderTopRightRadius: 0,
                        },
                      }}
                      disabled={createUser.loading}
                      itemComponent={forwardRef<
                        HTMLDivElement,
                        { value: string; label: string; flag: string }
                      >(({ label, flag, ...others }, ref) => (
                        <div ref={ref} {...others}>
                          <Group spacing={PX8.Number}>
                            <ReactCountryFlag
                              countryCode={flag}
                              style={{
                                fontSize: 18,
                              }}
                            />
                            <Text>{label}</Text>
                          </Group>
                        </div>
                      ))}
                      data={PhoneNumberCountryCodeData}
                      {...form.getInputProps('phoneNumberCountryCode')}
                    />
                  }
                  label="Phone Number"
                  required
                  {...form.getInputProps('phoneNumber')}
                />
              </Box>
            </Flex>
            <Group grow>
              <TextInput
                disabled={createUser.loading}
                label="First Name"
                required
                {...form.getInputProps('firstName')}
              />
              <TextInput
                disabled={createUser.loading}
                label="Last Name"
                required
                {...form.getInputProps('lastName')}
              />
            </Group>
            <Select
              disabled={createUser.loading}
              data={[
                { value: 'admin', label: 'Admin' },
                { value: 'employee', label: 'Employee' },
              ]}
              label="User Type"
              required
              {...form.getInputProps('userType')}
            />

            <Group>
              <Button
                type="submit"
                disabled={!form.isValid()}
                loading={createUser.loading}
              >
                Create User
              </Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
};
