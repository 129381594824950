import styled from 'styled-components';

export const Header1 = styled.h1`
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  color: #000000;
  margin: 0;
`;
