import { DefaultOperations, ProductGenericCode } from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect, useRef } from 'react';
import { Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useListSearcherHelper } from '../../hooks/useListSearcherHelper';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const GenericCodeSelectorModalBody = ({
  onGenericCodeSelected,
}: {
  onGenericCodeSelected: (g: ProductGenericCode) => void;
}) => {
  const listProductGenericCode = useStatefulAPIRequestMaker(
    DefaultOperations.listProductGenericCodes
  );

  const {
    debounceValue,
    onChangeDeps,
    pagination,
    paginationElement,
    target,
    setTargetFromInput,
  } = useListSearcherHelper({
    limitPerPage: 30,
    debounceDelay: 200,
  });

  useEffect(() => {
    const p = listProductGenericCode.execute({
      genericCode: debounceValue,
      limit: pagination.limit,
      offset: pagination.offset,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!listProductGenericCode.loading) {
      inputRef.current?.focus();
    }
  }, [listProductGenericCode.loading]);

  return (
    <Stack>
      <TextInput
        ref={inputRef}
        label="Search"
        placeholder="Search for Generic Code"
        value={target}
        onChange={setTargetFromInput}
      />

      {paginationElement(listProductGenericCode.response?.totalCount)}
      <LoadingContainer loading={listProductGenericCode.loading}>
        <Table highlightOnHover striped withBorder>
          <thead>
            <tr>
              <th>Generic Code</th>
              <th style={{ textAlign: 'right' }}>Product Count</th>
            </tr>
          </thead>
          <tbody>
            {(listProductGenericCode.response?.productGenericCodes || []).map(
              (gc) => {
                const onClick = async () => {
                  onGenericCodeSelected(gc.productGenericCode);
                };

                return (
                  <ClickableTableRow
                    onClick={onClick}
                    key={gc.productGenericCode.id}
                  >
                    <td>{gc.productGenericCode.genericCode}</td>
                    <td style={{ textAlign: 'right' }}>
                      {gc.productGenericCode.productCount}
                    </td>
                  </ClickableTableRow>
                );
              }
            )}
          </tbody>
        </Table>
      </LoadingContainer>
      {paginationElement(listProductGenericCode.response?.totalCount)}
    </Stack>
  );
};
