import { Location, Router } from '@reach/router';
import React from 'react';
import { TeamListPage } from './TeamsList';
import { TeamViewer } from './TeamViewer';

export const TeamPages = ({}: { path?: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <TeamListPage path="/" />
          <TeamViewer path="/new" />
          <TeamViewer path="/:teamID" />
        </Router>
      )}
    </Location>
  );
};
