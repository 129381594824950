import { Tax } from '@pcc/api/models/Tax';
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Group,
  Indicator,
  Loader,
  Space,
  Stack,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import {
  Currency,
  DefaultOperations,
  ProductPrice,
} from '../../../../oas/codegen3';
import React, { useEffect } from 'react';
import { useFindPrivileges } from '../../../../pccstores/UserUtils';
import Big from 'big.js';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { PX16, PX24, PX4, PX512 } from '../../../../components/Px';
import { showNotification } from '@mantine/notifications';
import { Link } from '@reach/router';
import { BHDCurrency, formatToCurrency } from '@pcc/api/models/Currency';
import { calculateAmounts } from '../../../../utils/calculateAmounts';
import { ProfitabilityRangeBadge } from '@components/V2/ProfitabilityRangeBadge';
import { FlexBox } from '../../../../components/flex/FlexBox';
import { ProductSellingCaseOfStatsOverTimeChart } from './ProductSellingCaseOfStatsOverTimeChart';
import {
  IconCash,
  IconChartBar,
  IconCheck,
  IconCircleOff,
  IconHandClick,
  IconInfoCircle,
  IconPencil,
} from '@tabler/icons-react';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { useForm } from '@mantine/form';
import { ApiError } from '../../../../oas/codegen3/core/ApiError';
import { toBigOrNull } from '../../../../utils/toBigOrNull';
import { VStack } from '../../../../components/stack/VStack';
import { HStack } from '../../../../components/stack/HStack';

export const ProductPricesManager = ({
  productID,
  saleTaxes,
}: {
  productID: string;
  saleTaxes: Tax[];
}) => {
  const theme = useMantineTheme();

  const getProductPrices = useStatefulAPIRequestMaker(
    DefaultOperations.getProductPrices
  );

  const getProductRecentPurchases = useStatefulAPIRequestMaker(
    DefaultOperations.getProductRecentPurchases
  );

  const setProductPriceEnabled = useStatefulAPIRequestMaker(
    DefaultOperations.setProductPriceEnabled
  );

  useEffect(() => {
    getProductPrices.execute({ productId: productID });
  }, [productID]);

  const canViewProfitability = useFindPrivileges({
    resource: 'profitability_analytics',
    action: 'read',
  });

  useEffect(() => {
    if (canViewProfitability.hasPrivilege) {
      const p = getProductRecentPurchases.execute({
        productId: productID,
        currency: 'BHD',
      });

      return () => p.cancel();
    }
  }, [canViewProfitability.hasPrivilege, productID]);

  const purchaseGradient = theme.fn.linearGradient(45, 'orange', 'red');
  // const sellingGradient = theme.fn.linearGradient(45, '#7048E8', '#4263EB');

  const recentPurchasesCurrency = getProductRecentPurchases.response?.currency;

  const hasRecentPurchases =
    getProductRecentPurchases.response &&
    getProductRecentPurchases.response.recentPurchases.length > 0;

  const lastPurchase =
    getProductRecentPurchases.response &&
    getProductRecentPurchases.response.recentPurchases.length >= 1
      ? getProductRecentPurchases.response?.recentPurchases[0]
      : null;

  const lastPurchasePCsUnitCost = lastPurchase
    ? Big(lastPurchase.preTaxTotal).div(lastPurchase.pcsQuantity)
    : Big(0);

  let preTaxTotalBig = Big(0);
  let pcsBig = Big(0);

  let preTaxTotalBigForMargin = Big(0);
  let pcsBigForMargin = Big(0);
  (getProductRecentPurchases.response?.recentPurchases ?? []).forEach(
    (rp, i) => {
      if (i < 3) {
        preTaxTotalBigForMargin = preTaxTotalBigForMargin.add(rp.preTaxTotal);
        pcsBigForMargin = pcsBigForMargin.add(rp.pcsQuantity);
      }

      preTaxTotalBig = preTaxTotalBig.add(rp.preTaxTotal);
      pcsBig = pcsBig.add(rp.pcsQuantity);
    }
  );

  const avgRecentPurchases = pcsBig.eq(0) ? null : preTaxTotalBig.div(pcsBig);
  const avgRecentPurchasesForMargin = pcsBigForMargin.eq(0)
    ? null
    : preTaxTotalBigForMargin.div(pcsBigForMargin);

  const showNewPriceModal = () => {
    openModal({
      modalId: 'new-price-modal',
      title: <Title order={3}>New Price</Title>,
      children: (
        <ProductPriceModal
          hideModal={() => {
            getProductPrices.execute({ productId: productID });
            closeModal('new-price-modal');
          }}
          productID={productID}
          saleTaxes={saleTaxes}
          recentPurchasesPCsCost={avgRecentPurchasesForMargin}
        />
      ),
    });
  };

  const showEditPriceModal = (price: ProductPrice, priceCurrency: Currency) => {
    openModal({
      modalId: 'edit-price-modal',
      title: <Title order={3}>Edit Price</Title>,
      children: (
        <ProductPriceModal
          productPrice={price}
          hideModal={() => {
            getProductPrices.execute({ productId: productID });
            closeModal('edit-price-modal');
          }}
          productID={productID}
          saleTaxes={saleTaxes}
          currency={priceCurrency}
          recentPurchasesPCsCost={avgRecentPurchasesForMargin}
        />
      ),
    });
  };

  const showRecentPurchasesModal = () => {
    if (!getProductRecentPurchases.response) {
      return;
    }

    openModal({
      modalId: 'recent-purchases-modal',
      title: <Title order={3}>Recent Purchases</Title>,
      size: PX512.Number,
      children: (
        <RecentPurchasesTable
          currency={getProductRecentPurchases.response?.currency}
          recentPurchases={
            getProductRecentPurchases.response?.recentPurchases || []
          }
        />
      ),
    });
  };

  const setPriceEnabled = (priceID: string, enabled: boolean) => {
    openConfirmModal({
      title: (
        <Title order={3}>{enabled ? 'Enable' : 'Disable'} Product Price</Title>
      ),
      children: (
        <Text>
          Are you sure you want to{' '}
          {enabled ? (
            <Text span color="green" fw={700}>
              ENABLE
            </Text>
          ) : (
            <Text span color="red" fw={700}>
              DISABLE
            </Text>
          )}{' '}
          this product price?
        </Text>
      ),
      labels: {
        confirm: `Yes - ${enabled ? 'Enable' : 'Disable'} Product Price`,
        cancel: 'Go Back',
      },
      confirmProps: {
        color: enabled ? 'green' : 'red',
      },
      onConfirm: async () => {
        await setProductPriceEnabled.execute({
          productPriceId: priceID,
          requestBody: {
            enabled,
          },
        });

        showNotification({
          color: 'green',
          title: `Product Price ${enabled ? 'Enabled' : 'Disabled'}`,
          message: `Product price has been successfully ${
            enabled ? 'enabled' : 'disabled'
          }.`,
        });

        getProductPrices.execute({ productId: productID });
      },
    });
  };

  return (
    <Stack>
      {canViewProfitability.hasPrivilege && (
        <Stack>
          {getProductRecentPurchases.loading && (
            <Center pt={PX16.Number}>
              <Loader />
            </Center>
          )}
          {getProductRecentPurchases.response && !hasRecentPurchases && (
            <Alert title="No Recent Purchases" icon={<IconInfoCircle />}>
              There have been no recent purchases for this item. When this item
              is purchased, the product's cost will appear here.
            </Alert>
          )}

          {getProductRecentPurchases.response && (
            <Group grow align="stretch">
              {lastPurchase && recentPurchasesCurrency && (
                <Card
                  bg={purchaseGradient}
                  radius="md"
                  component={Link}
                  to={`/purchaseOrders/${lastPurchase.purchaseOrderID}`}
                >
                  <Group position="apart" align="flex-start">
                    <Stack spacing={0}>
                      <Text fw={700} color="white" size="xs">
                        Last Purchase PCs Cost
                      </Text>
                      <Text fw={700} color="white" size="xl">
                        {formatToCurrency(
                          lastPurchasePCsUnitCost,
                          recentPurchasesCurrency
                        )}
                      </Text>
                    </Stack>
                    <Group spacing={PX4.Number}>
                      <IconHandClick style={{ color: 'white' }} size={16} />
                      <Text fw={500} color="white" size="xs">
                        Click to go to Purchase
                      </Text>
                    </Group>
                  </Group>
                </Card>
              )}

              {avgRecentPurchases && recentPurchasesCurrency && (
                <Card
                  style={{ cursor: 'pointer' }}
                  onClick={showRecentPurchasesModal}
                  bg={purchaseGradient}
                  radius="md"
                >
                  <Group position="apart" align="flex-start">
                    <Stack spacing={0}>
                      <Text fw={700} color="white" size="xs">
                        Avgerage PCs Cost (
                        {
                          getProductRecentPurchases.response.recentPurchases
                            .length
                        }{' '}
                        Purchase
                        {getProductRecentPurchases.response.recentPurchases
                          .length > 1
                          ? 's'
                          : ''}
                        )
                      </Text>
                      <Text fw={700} color="white" size="xl">
                        {formatToCurrency(
                          avgRecentPurchases,
                          recentPurchasesCurrency
                        )}
                      </Text>
                    </Stack>
                    <Group spacing={PX4.Number}>
                      <IconHandClick style={{ color: 'white' }} size={16} />
                      <Text fw={500} color="white" size="xs">
                        Click to view Purchases
                      </Text>
                    </Group>
                  </Group>
                </Card>
              )}
            </Group>
          )}
        </Stack>
      )}

      <Space h={PX24.Number} />
      <Title order={3}>Selling Price</Title>

      <Tabs defaultValue="prices">
        <Tabs.List>
          <Tabs.Tab value="prices" icon={<IconCash size={14} />}>
            Prices
          </Tabs.Tab>
          <Indicator inline label="New" size={16} color="blue">
            <Tabs.Tab value="analytics" icon={<IconChartBar size={14} />}>
              Analytics
            </Tabs.Tab>
          </Indicator>
        </Tabs.List>

        <Tabs.Panel value="prices" pt="md">
          <Stack>
            <Group>
              <Button onClick={showNewPriceModal}>New Price</Button>
            </Group>
            <Table withBorder striped>
              <thead>
                <tr>
                  <th />
                  <th style={{ textAlign: 'right' }}>Case of</th>
                  <th style={{ textAlign: 'right' }}>Final Price</th>
                  <th style={{ textAlign: 'right' }}>
                    Price per PC (Without Tax)
                  </th>
                  {canViewProfitability.hasPrivilege &&
                    getProductRecentPurchases.response &&
                    hasRecentPurchases && <th>Margin</th>}
                  <th style={{ textAlign: 'center' }}>Tax Included</th>
                  <th>Barcode</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {(getProductPrices.response?.prices || []).map((price) => {
                  const onClick = () => {
                    showEditPriceModal(price.price, price.currency);
                  };

                  const unitPriceAmounts = calculateAmounts({
                    currency: price.currency,
                    quantity: 1,
                    taxIncludedInPrice: price.price.taxIncluded,
                    taxes: saleTaxes,
                    unitPrice: price.price.unitPrice,
                  });

                  const unitPriceTooltipLabel = (
                    <Stack spacing={PX4.Number}>
                      <Group position="apart">
                        <Text>Before Tax: </Text>
                        <Text>
                          {formatToCurrency(
                            unitPriceAmounts.preTaxTotal,
                            price.currency
                          )}
                        </Text>
                      </Group>
                      <Group position="apart">
                        <Text>Tax: </Text>
                        <Text>
                          {formatToCurrency(
                            unitPriceAmounts.taxTotal,
                            price.currency
                          )}
                        </Text>
                      </Group>
                      <Group position="apart">
                        <Text>Final Price: </Text>
                        <Text>
                          {formatToCurrency(
                            unitPriceAmounts.total,
                            price.currency
                          )}
                        </Text>
                      </Group>
                    </Stack>
                  );

                  const pricePerPcAmounts = calculateAmounts({
                    currency: price.currency,
                    quantity: 1,
                    taxIncludedInPrice: price.price.taxIncluded,
                    taxes: saleTaxes,
                    unitPrice: price.price.pricePerPC,
                  });

                  let profitPercentMargin = '';

                  if (avgRecentPurchasesForMargin) {
                    profitPercentMargin = pricePerPcAmounts.unitPrice
                      .sub(avgRecentPurchasesForMargin)
                      .div(pricePerPcAmounts.unitPrice)
                      .toFixed(3);
                  }

                  return (
                    <tr key={price.price.id}>
                      <td>
                        {price.price.enabled && (
                          <Badge color="green" fullWidth>
                            Enabled
                          </Badge>
                        )}

                        {!price.price.enabled && (
                          <Badge color="red" fullWidth>
                            Disabled
                          </Badge>
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <Text
                          td={price.price.enabled ? undefined : 'line-through'}
                        >
                          {price.price.countPerUnit}
                        </Text>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <Tooltip
                          label={unitPriceTooltipLabel}
                          disabled={unitPriceTooltipLabel === null}
                        >
                          <Text
                            td={`underline${
                              price.price.enabled ? '' : ' line-through'
                            }`}
                            style={{
                              cursor: 'help',
                            }}
                          >
                            {formatToCurrency(
                              unitPriceAmounts.total,
                              price.currency
                            )}
                          </Text>
                        </Tooltip>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <Text
                          td={price.price.enabled ? undefined : 'line-through'}
                        >
                          {formatToCurrency(
                            pricePerPcAmounts.unitPrice,
                            price.currency
                          )}
                        </Text>
                      </td>
                      {canViewProfitability.hasPrivilege &&
                        getProductRecentPurchases.response &&
                        hasRecentPurchases && (
                          <td>
                            <ProfitabilityRangeBadge
                              profitabilityRange={{
                                lowestProfitability: {
                                  profit: '',
                                  profitMarginPercent: profitPercentMargin,
                                },
                                highestProfitability: {
                                  profit: '',
                                  profitMarginPercent: profitPercentMargin,
                                },
                              }}
                            />
                          </td>
                        )}
                      <td align="center">
                        <FlexBox justifyContent="center">
                          <Checkbox
                            disabled={true}
                            checked={price.price.taxIncluded}
                          />
                        </FlexBox>
                      </td>

                      <td>{price.price.barcode}</td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        <Group spacing={PX4.Number}>
                          <ActionIcon
                            onClick={onClick}
                            color="orange"
                            variant="outline"
                          >
                            <IconPencil size={18} />
                          </ActionIcon>
                          {price.price.enabled && (
                            <Tooltip label="Disable Price" withArrow>
                              <ActionIcon
                                onClick={() => {
                                  setPriceEnabled(price.price.id, false);
                                }}
                                color="red"
                                variant="outline"
                              >
                                <IconCircleOff size={18} />
                              </ActionIcon>
                            </Tooltip>
                          )}

                          {!price.price.enabled && (
                            <Tooltip label="Enable Price" withArrow>
                              <ActionIcon
                                onClick={() => {
                                  setPriceEnabled(price.price.id, true);
                                }}
                                color="green"
                                variant="outline"
                              >
                                <IconCheck size={18} />
                              </ActionIcon>
                            </Tooltip>
                          )}
                        </Group>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="analytics" pt="md">
          <ProductSellingCaseOfStatsOverTimeChart productID={productID} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

const RecentPurchasesTable = ({
  recentPurchases,
  currency,
}: {
  currency: Currency;
  recentPurchases: {
    purchaseOrderID: string;
    purchaseOrderNumber: string;
    preTaxTotal: string;
    pcsQuantity: string;
  }[];
}) => {
  return (
    <Table withBorder highlightOnHover>
      <thead>
        <tr>
          <th>Purchase Order Number</th>
          <th>PCs Cost</th>
        </tr>
      </thead>
      <tbody>
        {recentPurchases.map((rp) => {
          const unitCost = Big(rp.preTaxTotal).div(rp.pcsQuantity);

          return (
            <ClickableTableRow
              targetURL={`/purchaseOrders/${rp.purchaseOrderID}`}
              key={rp.purchaseOrderID}
            >
              <td>{rp.purchaseOrderNumber}</td>
              <td>{formatToCurrency(unitCost, currency)}</td>
            </ClickableTableRow>
          );
        })}
      </tbody>
    </Table>
  );
};

const ProductPriceModal = ({
  hideModal,
  productID,
  productPrice,
  saleTaxes,
  currency,
  recentPurchasesPCsCost,
}: {
  productPrice?: ProductPrice;
  productID: string;
  hideModal: () => void;
  saleTaxes: Tax[];
  currency?: Currency;
  recentPurchasesPCsCost?: Big | null;
}) => {
  const createProductPrice = useStatefulAPIRequestMaker(
    DefaultOperations.createProductPrice
  );
  const updateProductPrice = useStatefulAPIRequestMaker(
    DefaultOperations.updateProductPrice
  );
  const deleteProductPrice = useStatefulAPIRequestMaker(
    DefaultOperations.deleteProductPrice
  );
  const busyWithAPICall =
    createProductPrice.loading ||
    updateProductPrice.loading ||
    deleteProductPrice.loading;

  type FormFields = {
    countPerUnit: number;
    unitPrice: number;
    barcode: string;
    taxIncluded: boolean;
  };
  const form = useForm<FormFields>({});

  useEffect(() => {
    if (productPrice) {
      form.setValues({
        countPerUnit: Number(productPrice.countPerUnit),
        unitPrice: Number(productPrice.unitPrice),
        barcode: productPrice.barcode,
        taxIncluded: productPrice.taxIncluded,
      });
    }
  }, [productPrice]);

  const onSubmit = form.onSubmit(
    async ({ barcode, countPerUnit, unitPrice, taxIncluded }: FormFields) => {
      try {
        if (!productPrice) {
          await createProductPrice.execute({
            requestBody: {
              productID,
              currency: 'BHD',
              unitPrice: String(unitPrice),
              countPerUnit,
              barcode,
              taxIncluded,
            },
          });
          showNotification({
            color: 'green',
            title: 'Product Price Added',
            message: 'The product price has been added.',
          });
        } else {
          await updateProductPrice.execute({
            productPriceId: productPrice.id,
            requestBody: {
              countPerUnit: String(countPerUnit),
              unitPrice: String(unitPrice),
              barcode,
              taxIncluded,
            },
          });
          showNotification({
            color: 'green',
            title: 'Product Price Updated',
            message: 'The price has been updated successfully.',
          });
        }

        hideModal();
      } catch (e) {
        if (e instanceof ApiError) {
          if (e.body === 'barcode for currency already taken') {
            showNotification({
              color: 'red',
              title: 'Barcode Taken',
              message: 'The given price barcode is already taken.',
            });
          }
        } else {
          throw e;
        }
      }
    }
  );

  const doDeleteProductPrice = async () => {
    if (productPrice) {
      await deleteProductPrice.execute({ productPriceId: productPrice.id });
      showNotification({
        color: 'green',
        title: 'Product Price Deleted',
        message: 'The product price has been deleted.',
      });
      hideModal();
    }
  };

  let amounts = null;

  const unitPriceBig = toBigOrNull(form.values.unitPrice);
  if (unitPriceBig) {
    amounts = calculateAmounts({
      currency: currency ?? BHDCurrency,
      quantity: 1,
      taxIncludedInPrice: form.values.taxIncluded,
      taxes: saleTaxes,
      unitPrice: unitPriceBig,
    });
  }

  let margin = null;
  let marginColor = 'orange';
  if (
    recentPurchasesPCsCost &&
    amounts &&
    form.values.countPerUnit > 0 &&
    form.values.unitPrice != 0
  ) {
    const pcsSellingUnitPrice = amounts.unitPrice.div(form.values.countPerUnit);
    const markup = pcsSellingUnitPrice.sub(recentPurchasesPCsCost);

    margin = markup.div(pcsSellingUnitPrice);
    marginColor = margin.lte(0.1)
      ? 'red'
      : margin.lte(0.25)
      ? 'yellow'
      : 'green';
  }

  return (
    <VStack spacing="20px">
      <form onSubmit={onSubmit}>
        <VStack spacing="10px">
          <TextInput
            autoComplete="off"
            label="Count per Unit"
            required={true}
            {...form.getInputProps('countPerUnit')}
          />

          <TextInput
            autoComplete="off"
            label="Unit Price"
            required={true}
            {...form.getInputProps('unitPrice')}
          />

          <TextInput
            autoComplete="off"
            label="Barcode"
            {...form.getInputProps('barcode')}
          />

          <Checkbox
            label="Tax Included"
            checked={form.values.taxIncluded}
            {...form.getInputProps('taxIncluded')}
          />

          <TextInput
            label="Pre Tax"
            readOnly
            placeholder="Pre Tax"
            value={
              amounts
                ? formatToCurrency(amounts.preTaxTotal, currency ?? BHDCurrency)
                : ''
            }
          />
          <TextInput
            label="Tax"
            readOnly
            placeholder="Tax"
            value={
              amounts
                ? formatToCurrency(amounts.taxTotal, currency ?? BHDCurrency)
                : ''
            }
          />
          <TextInput
            label="Final Price"
            readOnly
            placeholder="Final Price"
            value={
              amounts
                ? formatToCurrency(amounts.total, currency ?? BHDCurrency)
                : ''
            }
          />

          {margin && (
            <TextInput
              styles={(theme) => ({
                input: {
                  color: theme.colors[marginColor][theme.fn.primaryShade()],
                  borderColor:
                    theme.colors[marginColor][theme.fn.primaryShade()],
                },
                label: {
                  color: theme.colors[marginColor][theme.fn.primaryShade()],
                },
              })}
              label="Margin"
              value={`${margin.mul(100).toFixed(0)}%`}
              readOnly
              color={marginColor}
            />
          )}

          <Box mt={PX24.Number}>
            <HStack spacing="5px">
              <Button type="submit" color="green" disabled={busyWithAPICall}>
                {productPrice ? 'Update Price' : 'Add New Price'}
              </Button>
              {productPrice && (
                <Button
                  color="red"
                  disabled={busyWithAPICall}
                  onClick={doDeleteProductPrice}
                >
                  Delete Price
                </Button>
              )}
            </HStack>
          </Box>
        </VStack>
      </form>
    </VStack>
  );
};
