import {
  useVariablePagination,
  VariablePagination,
} from './useVariablePagination';
import { useDebouncedValue, useListState, usePrevious } from '@mantine/hooks';
import React, { ReactNode, useEffect, useState } from 'react';
import { Flex, Pagination } from '@mantine/core';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const useListSearcherHelper = ({
  debounceDelay,
  limitPerPage,
  additionalDeps,
  targetInitialState,
}: {
  debounceDelay: number;
  limitPerPage: number;
  additionalDeps?: any[];
  targetInitialState?: string;
}): {
  target: string;
  setTarget: (target: string) => void;
  setTargetFromInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pagination: VariablePagination;
  debounceValue: string;
  onChangeDeps: any[];
  paginationElement: (totalCount?: number | null | undefined) => ReactNode;
  forceListRefresh: () => void;
} => {
  const [target, setTarget] = useState<string>(targetInitialState ?? '');
  const [debounceTarget] = useDebouncedValue(target, debounceDelay);
  // Only set and send debounceValue after we've updated pagination target.
  const [debounceValue, setDebounceValue] = useState(targetInitialState ?? '');
  const previousDebounceTarget = usePrevious(debounceTarget);
  const [onChangeDeps, onChangeDepsListHandler] = useListState(additionalDeps);
  const [forceRefreshID, setForceRefreshID] = useState(0);

  const pagination = useVariablePagination({
    limit: limitPerPage,
    startingPaginationPage: 1,
  });

  useEffect(() => {
    if (
      debounceTarget !== previousDebounceTarget &&
      previousDebounceTarget !== undefined &&
      pagination.currentPaginationPage !== 1
    ) {
      pagination.onPaginationPageChange(1);
    }

    if (debounceValue !== debounceTarget) {
      setDebounceValue(debounceTarget);
    }

    if (!areBothAArraysEqual(onChangeDeps, additionalDeps || [])) {
      onChangeDepsListHandler.setState(additionalDeps || []);
    }
  }, [
    debounceTarget,
    pagination.currentPaginationPage,
    ...(additionalDeps || []),
  ]);

  return {
    target,
    setTarget,
    setTargetFromInput: (e: React.ChangeEvent<HTMLInputElement>) =>
      setTarget(e.target.value),
    pagination,
    debounceValue,
    onChangeDeps: [
      ...onChangeDeps,
      debounceValue,
      pagination.limit,
      pagination.offset,
      forceRefreshID,
    ],
    forceListRefresh: () => {
      setForceRefreshID((prev) => prev + 1);
    },
    paginationElement: (totalCount?: number | null | undefined) => {
      if (totalCount === null || totalCount === undefined) {
        return null;
      }

      return (
        <Flex justify="flex-end">
          <Pagination
            size="sm"
            total={pagination.calculateTotalPages(totalCount)}
            value={pagination.currentPaginationPage}
            onChange={pagination.onPaginationPageChange}
            siblings={2}
          />
        </Flex>
      );
    },
  };
};

const areBothAArraysEqual = (a: any[], b: any[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  return a.every((item, index) => item === b[index]);
};
