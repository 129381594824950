import React from 'react';
import { Stack, Table } from '@mantine/core';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';

export const ModalSelector = <T,>({
  items,
  onItemSelected,
}: {
  items: { label: string; value: T }[];
  onItemSelected: (i: { label: string; value: T }) => void;
}) => {
  return (
    <Stack>
      <Table highlightOnHover withBorder>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {(items || []).map((i) => {
            const onClick = async () => {
              onItemSelected(i);
            };

            return (
              <ClickableTableRow onClick={onClick} key={i.label}>
                <td>{i.label}</td>
              </ClickableTableRow>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};
