import {
  createStyles,
  Navbar,
  Group,
  Title,
  Stack,
  ScrollArea,
  getStylesRef,
  Loader,
  Center,
} from '@mantine/core';
import {
  IconApps,
  IconBucket,
  IconBuildingStore,
  IconBuildingWarehouse,
  IconCarrot,
  IconChartBar,
  IconCreditCard,
  IconLogout,
  IconSettings,
  IconUsers,
  IconWalk,
  TablerIconsProps,
} from '@tabler/icons-react';
import { NavigateFn, useLocation } from '@reach/router';
import { PX16, PX4, PX8 } from '../Px';
import { ReactNode } from 'react';
import {
  usePccStoreDispatch,
  usePccStoreSelector,
} from '../../pccstores/PccStore';
import {
  DefaultOperations,
  PermissionAction,
  PermissionResource,
} from '../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../hooks/useStatefulAPIRequestMaker';
import { canUser } from '../../pccstores/UserUtils';
import { logoutUser } from '../../pccstores/UserReducer';
import { showNotification } from '@mantine/notifications';

const data: {
  link: string;
  label: string;
  icon: (props: TablerIconsProps) => JSX.Element;
  requiredPrivileges?: {
    action: PermissionAction;
    resource: PermissionResource;
  }[];
  subLinks?: {
    label: string;
    link: string;
    requiredPrivileges?: {
      action: PermissionAction;
      resource: PermissionResource;
    }[];
  }[];
}[] = [
  {
    link: '/products',
    label: 'Products',
    icon: IconCarrot,
    requiredPrivileges: [
      {
        action: 'read',
        resource: 'product',
      },
    ],
    subLinks: [
      {
        label: 'Price Margins',
        link: '/products/priceMargins',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'profitability_analytics',
          },
        ],
      },
      {
        label: 'Taxes',
        link: '/taxes',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'tax',
          },
        ],
      },
      {
        label: 'Generic Codes',
        link: '/products/genericCodes',
        requiredPrivileges: [
          {
            action: 'write',
            resource: 'product.metadata',
          },
        ],
      },
      {
        label: 'Brands',
        link: '/products/brands',
        requiredPrivileges: [
          {
            action: 'write',
            resource: 'product.metadata',
          },
        ],
      },
      {
        label: 'Class Codes',
        link: '/products/classCodes',
        requiredPrivileges: [
          {
            action: 'write',
            resource: 'product.metadata',
          },
        ],
      },
    ],
  },
  {
    link: '/purchaseOrders',
    requiredPrivileges: [
      {
        action: 'read',
        resource: 'purchase_order',
      },
    ],
    label: 'Purchase Orders',
    icon: IconBucket,
    subLinks: [
      {
        label: 'Vendors',
        link: '/vendors',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'purchase_order',
          },
        ],
      },
      {
        label: 'Payments',
        link: '/purchaseOrders/payments',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'purchase_order_payment',
          },
        ],
      },
      {
        label: 'Bulk Payments',
        link: '/purchaseOrders/bulkPayments',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'purchase_order',
          },
        ],
      },
    ],
  },
  {
    link: '/stores',
    label: 'Stores',
    icon: IconBuildingStore,
    subLinks: [
      {
        label: 'Cashier Sessions',
        link: '/stores/cashierSessions',
      },
    ],
  },
  {
    link: '/stockLocations',
    label: 'Stock Locations',
    icon: IconBuildingWarehouse,
    subLinks: [
      {
        label: 'Stock Adjustments',
        link: '/stockAdjustmentGroups',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'stock_adjustment_group',
          },
          {
            action: 'write',
            resource: 'stock_adjustment_group',
          },
        ],
      },
      {
        label: 'Stock Moves',
        link: '/stockMoves/',
      },
    ],
  },
  {
    link: '/sales',
    label: 'Sales',
    icon: IconChartBar,
    subLinks: [
      {
        label: 'Sale Payments',
        link: '/sales/payments',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'sale_payment',
          },
        ],
      },
      {
        label: 'Coupons',
        link: '/couponGroups',
        requiredPrivileges: [
          {
            action: 'read',
            resource: 'coupon_group',
          },
        ],
      },
    ],
  },
  { link: '/members', label: 'Members', icon: IconWalk },
  {
    link: '/paymentMethods',
    label: 'Payment Methods',
    icon: IconCreditCard,
  },
  {
    link: '/users',
    label: 'Users',
    icon: IconUsers,
    subLinks: [
      {
        label: 'Teams',
        link: '/teams',
        requiredPrivileges: [
          {
            action: 'write',
            resource: 'team',
          },
        ],
      },
    ],
  },
  { link: '/posAppVersions', label: 'POS Apps', icon: IconApps },
  { link: '/settings/system', label: 'System Settings', icon: IconSettings },
];

const useStyles = createStyles((theme) => {
  const icon = getStylesRef('icon');
  return {
    header: {
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    subLink: {
      fontSize: theme.fontSizes.xs,
      padding: `${PX8.PX} ${theme.spacing.sm}`,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.variant({
          variant: 'light',
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: 'light',
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  };
});

export const Navbar2 = ({
  navigate,
  hideSidebarIcon,
}: {
  navigate: NavigateFn;
  hideSidebarIcon: ReactNode;
}) => {
  const { classes } = useStyles();
  const location = useLocation();
  const currentURL = location.pathname;
  const userStates = usePccStoreSelector((state) => state.userStates);
  const dispatch = usePccStoreDispatch();

  const links = data.map((item) => {
    if (
      item.requiredPrivileges &&
      !canUser({
        user: userStates,
        requiredPrivileges: item.requiredPrivileges,
        checkType: 'any',
      })
    ) {
      return null;
    }

    return (
      <Stack spacing={PX4.Number} key={item.link + item.label}>
        <NavbarLink
          active={
            currentURL.startsWith(item.link) &&
            !(
              item.subLinks &&
              item.subLinks.find((i) => currentURL.startsWith(i.link))
            )
          }
          navigate={navigate}
          link={item.link}
          label={item.label}
          icon={item.icon}
        />
        {item.subLinks &&
          item.subLinks.map((sl) => {
            if (
              sl.requiredPrivileges &&
              !canUser({
                user: userStates,
                requiredPrivileges: sl.requiredPrivileges,
                checkType: 'any',
              })
            ) {
              return null;
            }

            return (
              <NavbarLink
                key={sl.link + sl.label}
                active={currentURL.startsWith(sl.link)}
                navigate={navigate}
                link={sl.link}
                label={sl.label}
                variant="sublink"
              />
            );
          })}
      </Stack>
    );
  });

  const logout = useStatefulAPIRequestMaker(DefaultOperations.logout);

  const onLogoutClicked = async () => {
    await logout.execute();
    dispatch(logoutUser());
    showNotification({
      color: 'green',
      title: `Logged Out`,
      message: `You have been logged out successfully.`,
    });
  };

  return (
    <Navbar height="100vh" width={{ sm: 300 }} p="md">
      <Navbar.Section>
        <Stack spacing={0} className={classes.header}>
          <Group position="apart">
            <Title order={3}>Promoco Market</Title>
            {hideSidebarIcon}
          </Group>
        </Stack>
      </Navbar.Section>
      {userStates.user === null && (
        <Center mt="xl">
          <Loader />
        </Center>
      )}

      {userStates.authentication.authenticated && userStates.user !== null && (
        <Navbar.Section
          grow
          component={ScrollArea}
          style={{ marginTop: PX16.Number }}
          type="never"
        >
          <Stack spacing={PX8.Number}>{links}</Stack>
        </Navbar.Section>
      )}

      {userStates.authentication.authenticated && userStates.user !== null && (
        <Navbar.Section className={classes.footer}>
          {userStates.user && (
            <a
              href="#"
              className={classes.link}
              onClick={(event) => {
                onLogoutClicked();
                event.preventDefault();
              }}
            >
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>
                Logout ({userStates.user.firstName} {userStates.user.lastName})
              </span>
            </a>
          )}
        </Navbar.Section>
      )}
    </Navbar>
  );
};

const NavbarLink = ({
  active,
  link,
  label,
  navigate,
  icon: Icon,
  variant,
}: {
  navigate: NavigateFn;
  active: boolean;
  link: string;
  label: string;
  icon?: (props: TablerIconsProps) => JSX.Element;
  variant?: 'sublink' | 'topLevelLink';
}) => {
  const { classes, cx } = useStyles();
  return (
    <a
      style={{
        marginLeft: variant === 'sublink' ? 36 : undefined,
      }}
      className={cx(classes.link, {
        [classes.linkActive]: active,
        [classes.subLink]: variant === 'sublink',
      })}
      href={link}
      key={label}
      onClick={(e) => {
        if (!e.ctrlKey && !e.metaKey && !e.shiftKey) {
          e.preventDefault();
          navigate(link);
        }
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore*/}
      {Icon && <Icon className={classes.linkIcon} stroke={1.5} />}
      <span>{label}</span>
    </a>
  );
};
