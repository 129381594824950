import {
  Currency,
  DefaultOperations,
  PurchaseOrder,
  PurchaseOrderCredit,
} from '../../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import { useLoadingModal } from '../../../../hooks/useLoadingModal';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { PX16, PX640, PX8 } from '../../../../components/Px';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Menu,
  NumberInput,
  Stack,
  Table,
  Title,
} from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { handleApiErrorMessage } from '../../../../utils/handleApiErrorMessage';
import { RFC3339ToEasyDate } from '../../../../utils/dateFormatting';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { Link } from '@components/V2/Link';
import Big from 'big.js';
import { showNotification } from '@mantine/notifications';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { LabeledText } from '@components/V2/LabeledText';
import { IconDots, IconPlus, IconTrash } from '@tabler/icons-react';

export const PurchaseOrderAppliedCreditNotes = ({
  currency,
  purchaseOrder,
  purchaseOrderCredits,
  refreshPurchaseOrder,
}: {
  currency: Currency;
  purchaseOrder: PurchaseOrder;
  purchaseOrderCredits: {
    purchaseOrderCredit: PurchaseOrderCredit;
    creditingPurchaseOrder: PurchaseOrder;
  }[];
  refreshPurchaseOrder: () => void;
}) => {
  const updateOrderCreditAmount = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderAmountCredited
  );

  const { showLoadingModal } = useLoadingModal();

  const showApplyCreditNoteModal = () => {
    openModal({
      modalId: 'apply-credit-note-modal',
      size: PX640.Number,
      title: <Title order={3}>Apply Credit Note</Title>,
      children: (
        <ApplyCreditNoteModal
          currency={currency}
          purchaseOrder={purchaseOrder}
          onCreditNoteApplied={() => {
            closeModal('apply-credit-note-modal');
            refreshPurchaseOrder();
          }}
        />
      ),
    });
  };

  const TableMenu = useCallback(
    ({
      purchaseOrderID,
      creditingPurchaseOrderID,
    }: {
      purchaseOrderID: string;
      creditingPurchaseOrderID: string;
    }) => {
      const removeCreditNoteDiscount = async () => {
        const closeLoadingModal = showLoadingModal({
          title: 'Removing Credit Note',
        });

        try {
          await updateOrderCreditAmount.execute({
            purchaseOrderId: purchaseOrderID,
            requestBody: {
              amount: '0',
              creditingPurchaseOrderID: creditingPurchaseOrderID,
            },
          });

          refreshPurchaseOrder();
        } catch (e) {
          handleApiErrorMessage(e);
        }

        closeLoadingModal();
      };

      const removeCreditNoteConfirmationModal = () => {
        openConfirmModal({
          title: <Title order={3}>Remove Credit Note Discount</Title>,
          children:
            'Are you sure you want to remove this credit note discount?',
          labels: {
            confirm: 'Yes - Remove Credit Note Discount',
            cancel: 'Cancel',
          },
          confirmProps: { color: 'red' },
          onConfirm: removeCreditNoteDiscount,
        });
      };

      return (
        <Menu shadow="md">
          <Menu.Target>
            <ActionIcon>
              <IconDots />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              color="red"
              icon={<IconTrash />}
              onClick={removeCreditNoteConfirmationModal}
            >
              Remove Credit Discount
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      );
    },
    []
  );

  return (
    <Stack spacing={PX8.Number}>
      <Box>
        <Button
          onClick={showApplyCreditNoteModal}
          variant="light"
          leftIcon={<IconPlus />}
        >
          Add Credit Note
        </Button>
      </Box>
      <Table>
        <thead>
          <tr>
            <th>Credit Note</th>
            <th>Credited At</th>
            <th>Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {purchaseOrderCredits.map((c) => {
            const createdAt = RFC3339ToEasyDate(
              c.purchaseOrderCredit.createdAt
            );
            const amountStr = formatToCurrency(
              c.purchaseOrderCredit.amount,
              currency
            );

            return (
              <tr key={c.purchaseOrderCredit.id}>
                <td>
                  <Link
                    to={`/purchaseOrders/${c.creditingPurchaseOrder.id}`}
                    hidden
                    withIcon
                  >
                    {c.creditingPurchaseOrder.number}
                  </Link>
                </td>
                <td>{createdAt}</td>
                <td>{amountStr}</td>
                <td>
                  <TableMenu
                    purchaseOrderID={purchaseOrder.id}
                    creditingPurchaseOrderID={
                      c.purchaseOrderCredit.creditingPurchaseOrderID
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

const ApplyCreditNoteModal = ({
  currency,
  purchaseOrder,
  onCreditNoteApplied,
}: {
  currency: Currency;
  purchaseOrder: PurchaseOrder;
  onCreditNoteApplied: () => void;
}) => {
  const [creditingPurchaseOrder, setCreditingPurchaseOrder] =
    useState<PurchaseOrder | null>(null);
  const listPurchaseOrders = useStatefulAPIRequestMaker(
    DefaultOperations.listPurchaseOrders
  );
  const updatePurchaseOrderAmountCredited = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderAmountCredited
  );
  const [amountToCredit, setAmountToCredit] = useState<number | ''>(0);

  useEffect(() => {
    listPurchaseOrders.execute({
      vendorId: purchaseOrder.vendorID,
      isCreditNote: true,
      hasUnusedCreditAmount: true,
    });
  }, [purchaseOrder]);

  const creditAvailable = new Big(creditingPurchaseOrder?.balanceAmount || 0);

  const creditingPOAvailableCreditStr = formatToCurrency(
    creditAvailable,
    currency
  );

  const poBalance = new Big(purchaseOrder.balanceAmount ?? 0);
  const poBalanceStr = formatToCurrency(
    purchaseOrder.balanceAmount ?? 0,
    currency
  );

  const balanceAfterCreditBig = amountToCredit
    ? new Big(purchaseOrder.balanceAmount ?? 0).add(new Big(amountToCredit))
    : null;
  const balanceAfterCreditStr = balanceAfterCreditBig
    ? formatToCurrency(balanceAfterCreditBig, currency)
    : '...';

  const minCreditAmount = creditAvailable.abs().lt(poBalance)
    ? creditAvailable
    : poBalance.mul(-1);

  const minCreditAmountNum = minCreditAmount.toNumber();

  const onApplyCreditClicked = async () => {
    if (!creditingPurchaseOrder) {
      return;
    }

    await updatePurchaseOrderAmountCredited.execute({
      purchaseOrderId: purchaseOrder.id,
      requestBody: {
        creditingPurchaseOrderID: creditingPurchaseOrder?.id,
        amount: `${amountToCredit}`,
      },
    });

    showNotification({
      color: 'green',
      title: 'Credit Note Applied',
      message:
        'The credit note has been applied to the purchase order successfully.',
    });

    onCreditNoteApplied();
  };

  return (
    <Stack>
      <LoadingContainer
        loading={
          listPurchaseOrders.loading ||
          updatePurchaseOrderAmountCredited.loading
        }
      >
        <Stack spacing={PX8.Number}>
          {creditingPurchaseOrder === null && (
            <Table>
              <thead>
                <tr>
                  <th>Credit Note</th>
                  <th>Available Credit</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {listPurchaseOrders.response?.purchaseOrders.map((po) => {
                  const totalAmount = new Big(
                    po.purchaseOrder.totalAmount || 0
                  );
                  const creditUtilized = new Big(
                    po.purchaseOrder.creditAmountUtilized || 0
                  );
                  const availableCredit = totalAmount
                    .abs()
                    .sub(creditUtilized.abs())
                    .mul(new Big(-1));

                  const availableCreditStr = formatToCurrency(
                    availableCredit,
                    currency
                  );

                  const onApplyPressed = () => {
                    setCreditingPurchaseOrder(po.purchaseOrder);
                  };

                  return (
                    <tr key={po.purchaseOrder.id}>
                      <td>
                        <Link
                          to={`/purchaseOrders/${po.purchaseOrder.id}`}
                          hidden
                          withIcon
                        >
                          {po.purchaseOrder.number}
                        </Link>
                      </td>
                      <td>{availableCreditStr}</td>
                      <td>
                        <Button
                          compact={true}
                          variant="subtle"
                          onClick={onApplyPressed}
                        >
                          Apply
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {creditingPurchaseOrder && (
            <Stack spacing={PX16.Number}>
              <LabeledText
                label="Available Credit"
                value={creditingPOAvailableCreditStr}
              />
              <LabeledText
                label="Purchase Order Balance"
                value={poBalanceStr}
              />
              <Group align="flex-end">
                <NumberInput
                  style={{ flex: 1 }}
                  label="Amount to Credit"
                  max={0}
                  precision={currency.decimalPlaces}
                  min={minCreditAmountNum}
                  value={amountToCredit}
                  onChange={(v) => setAmountToCredit(v)}
                />
                <Button
                  variant="light"
                  onClick={() => setAmountToCredit(minCreditAmountNum)}
                >
                  Set to {formatToCurrency(minCreditAmount, currency)}
                </Button>
              </Group>

              <LabeledText
                label="Balance After Credit"
                value={balanceAfterCreditStr}
              />
              <Button
                disabled={
                  !balanceAfterCreditBig ||
                  balanceAfterCreditBig.lt(new Big(0)) ||
                  amountToCredit < creditAvailable.toNumber()
                }
                onClick={onApplyCreditClicked}
              >
                Apply Credit Note
              </Button>
            </Stack>
          )}
        </Stack>
      </LoadingContainer>
    </Stack>
  );
};
