import styled, { css } from 'styled-components';

export const ParagraphCSS = css`
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
`;

export const Paragraph = styled.p`
  ${ParagraphCSS};
  color: ${(props) => (props.color ? props.color : '#000')};
`;
