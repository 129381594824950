import { useAlert } from 'react-alert';
import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { AlertMessage } from '../../../components/alert/Alert';
import { BHDCurrency } from '../../../models/Currency';

import { Alert, Modal as MantineModal, Tooltip } from '@mantine/core';
import {
  ActionIcon,
  Badge,
  Button,
  Container,
  Flex,
  Loader,
  LoadingOverlay,
  Menu,
  Select,
  Stack,
  Table,
  Tabs,
  TextInput,
  Title,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { PX1024, PX256, PX32, PX64, PX96 } from '../../../components/Px';
import {
  IconAlertTriangle,
  IconBuildingWarehouse,
  IconDots,
  IconInfoCircle,
  IconMinus,
  IconStar,
} from '@tabler/icons-react';
import { StockLocation } from '../../../models/StockLocation';
import { Store } from '../../../models/Store';
import { useDebouncedState } from '@mantine/hooks';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { showNotification } from '@mantine/notifications';
import { Link } from '@components/V2/Link';
import { LoadingContainer } from '@components/V2/LoadingContainer';

export const StoresForm = ({ storeID }: { path: string; storeID?: string }) => {
  const form = useForm<{
    name: string;
    phoneNumber: string;
    companyRegistrationNumber: string;
  }>({
    initialValues: {
      name: '',
      phoneNumber: '',
      companyRegistrationNumber: '',
    },
  });

  const alert = useAlert();

  const createStore = useStatefulAPIRequestMaker(DefaultOperations.createStore);
  const updateStore = useStatefulAPIRequestMaker(DefaultOperations.updateStore);
  const getStore = useStatefulAPIRequestMaker(DefaultOperations.getStoreById);

  useEffect(() => {
    const fn = async () => {
      if (storeID) {
        const response = await getStore.execute({ storeId: storeID });
        form.setValues({
          name: response.store.name,
          phoneNumber: response.store.phoneNumber,
          companyRegistrationNumber:
            response.store.companyRegistrationNumber ?? '',
        });
        setH1Text(response.store.name);
      }
    };

    fn();
  }, [storeID]);

  const [h1Text, setH1Text] = useState(
    getStore.response?.store ? getStore.response.store.name : 'New Store'
  );
  const navigate = useNavigate();

  const busyWithAPICalls =
    createStore.loading ||
    updateStore.loading ||
    getStore.loading ||
    h1Text === '...';

  const formSubmit = form.onSubmit(async (values) => {
    if (storeID) {
      const updatedStore = await updateStore.execute({
        storeId: storeID,
        requestBody: {
          name: values.name,
          phoneNumber: values.phoneNumber,
          companyRegistrationNumber:
            values.companyRegistrationNumber || undefined,
        },
      });

      setH1Text(updatedStore.store.name);
      showNotification({
        color: 'green',
        title: 'Store Updated',
        message: `Store ${values.name} has been updated.`,
      });
    } else {
      const newStore = await createStore.execute({
        requestBody: {
          name: values.name,
          phoneNumber: values.phoneNumber,
          currency: BHDCurrency.iso4217Code,
          companyRegistrationNumber:
            values.companyRegistrationNumber || undefined,
        },
      });
      alert.show(<AlertMessage title="Store Created" type="positive" />);
      navigate(`/stores/${newStore.store.id}`);
    }
  });

  return (
    <LoadingContainer
      loading={createStore.loading || updateStore.loading || getStore.loading}
    >
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <Title>{h1Text}</Title>
          <form id="storeForm" onSubmit={formSubmit}>
            <Stack>
              <TextInput
                label="Store Name"
                required={true}
                {...form.getInputProps('name')}
              />

              <TextInput
                label="Phone Number"
                {...form.getInputProps('phoneNumber')}
              />

              <TextInput
                label="CR Number"
                {...form.getInputProps('companyRegistrationNumber')}
              />
              <Flex>
                <Button
                  color="green"
                  form="storeForm"
                  type="submit"
                  disabled={busyWithAPICalls}
                >
                  {storeID ? 'Update' : 'Create'}
                </Button>
              </Flex>
            </Stack>
          </form>
          {storeID && (
            <Tabs defaultValue="stockLocations">
              <Tabs.List>
                <Tabs.Tab
                  value="stockLocations"
                  icon={<IconBuildingWarehouse size={14} />}
                >
                  Stock Locations
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="stockLocations" pt="lg">
                <StoreStockLocationsPage
                  storeID={storeID}
                  store={getStore.response?.store}
                  loadingStore={getStore.loading}
                  refreshStore={() => getStore.execute({ storeId: storeID })}
                  stockLocations={getStore.response?.stockLocations}
                  sellingStockLocation={getStore.response?.sellingStockLocation}
                />
              </Tabs.Panel>
            </Tabs>
          )}
        </Stack>
      </Container>
    </LoadingContainer>
  );
};

const StoreStockLocationsPage = ({
  storeID,
  store,
  loadingStore,
  sellingStockLocation,
  stockLocations,
  refreshStore,
}: {
  storeID: string;
  store?: Store;
  loadingStore: boolean;
  refreshStore: () => void;
  stockLocations?: StockLocation[];
  sellingStockLocation?: StockLocation | null;
}) => {
  const [showSetStockModal, setShowSetStockModal] = useState(false);
  const setStoreStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.setStoreStockLocation
  );
  const setStoreSellingStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.setStoreSellingStockLocation
  );

  const SellingLocationBadge = () => {
    return (
      <Tooltip
        multiline
        width={PX256.Number}
        label="When a sale is made from this store, the stock deduction will happen
            from this location."
      >
        <Badge variant="filled" color="blue">
          Selling Location
        </Badge>
      </Tooltip>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      {store && (
        <AddStockLocationModal
          onStockLocationSet={() => {
            refreshStore();
          }}
          storeID={storeID}
          opened={showSetStockModal}
          onClose={() => {
            setShowSetStockModal(false);
          }}
        />
      )}
      <LoadingOverlay
        visible={loadingStore || setStoreSellingStockLocation.loading}
      />

      <Stack>
        <Flex>
          <Button onClick={() => setShowSetStockModal(true)}>
            Add Stock Location
          </Button>
        </Flex>

        <Table>
          <thead>
            <tr>
              <th>Stock Location</th>
              <th />
              <th />
            </tr>
          </thead>

          <tbody>
            {(stockLocations || []).map((sl) => {
              const { name, id } = sl;

              return (
                <tr key={id}>
                  <td>
                    <Link to={`/stockLocations/${id}`} withIcon hidden>
                      {name}
                    </Link>
                  </td>
                  <td>
                    {sellingStockLocation?.id === id && (
                      <SellingLocationBadge />
                    )}
                  </td>
                  <td>
                    <Menu shadow="md" width={PX256.Number}>
                      <Menu.Target>
                        <ActionIcon>
                          <IconDots />
                        </ActionIcon>
                      </Menu.Target>

                      <Menu.Dropdown>
                        {sellingStockLocation?.id === id && (
                          <Menu.Item disabled>
                            <Alert
                              color="red"
                              variant="outline"
                              icon={<IconAlertTriangle />}
                            >
                              <Text size="xs">
                                Set another location as the selling location
                                before making any changes here.
                              </Text>
                            </Alert>
                          </Menu.Item>
                        )}
                        <Menu.Item
                          disabled={sellingStockLocation?.id === id}
                          icon={<IconStar size={18} />}
                          onClick={async () => {
                            await setStoreSellingStockLocation.execute({
                              requestBody: {
                                storeID,
                                stockLocationID: id,
                                remove: false,
                              },
                            });

                            refreshStore();
                          }}
                        >
                          Set As Sell Location
                        </Menu.Item>

                        <Menu.Item
                          disabled={sellingStockLocation?.id === id}
                          color="red"
                          icon={<IconMinus size={18} />}
                          onClick={async () => {
                            await setStoreStockLocation.execute({
                              requestBody: {
                                storeID,
                                stockLocationID: id,
                                remove: true,
                              },
                            });

                            refreshStore();
                          }}
                        >
                          Remove Stock Location
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Stack>
    </div>
  );
};

const AddStockLocationModal = ({
  storeID,
  opened,
  onClose,
  onStockLocationSet,
}: {
  storeID: string;
  opened: boolean;
  onClose: () => void;
  onStockLocationSet: () => void;
}) => {
  const [slSearchTarget, setSlSearchTarget] = useDebouncedState('', 200);
  const searchStockLocations = useStatefulAPIRequestMaker(
    DefaultOperations.searchStockLocations
  );
  const setStoreStockLocation = useStatefulAPIRequestMaker(
    DefaultOperations.setStoreStockLocation
  );

  useEffect(() => {
    searchStockLocations.execute({ target: slSearchTarget, hasNoStore: true });
  }, [slSearchTarget]);

  const [selectedLocationID, setSelectedLocationID] =
    useState<string | null>(null);

  const stockLocationDataSet = !searchStockLocations.response?.stockLocations
    ? []
    : searchStockLocations.response.stockLocations.map((sl) => {
        return { value: sl.stockLocation.id, label: sl.stockLocation.name };
      });

  return (
    <MantineModal
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Add Stock Location</Title>}
    >
      <Stack>
        <Alert color="blue" variant="outline" icon={<IconInfoCircle />}>
          Search and select for a stock location, then click "Add" to add it to
          the store.
        </Alert>
        <Select
          rightSection={searchStockLocations.loading && <Loader size="sm" />}
          label="Add Stock Location"
          data={stockLocationDataSet}
          searchable
          clearable
          onSearchChange={(s) => {
            setSlSearchTarget(s);
          }}
          onChange={(stockLocationID) => {
            console.log('sl id:', stockLocationID);
            setSelectedLocationID(stockLocationID);
          }}
        />

        <Button
          disabled={selectedLocationID === null}
          onClick={async () => {
            await setStoreStockLocation.execute({
              requestBody: {
                storeID: storeID,
                stockLocationID: selectedLocationID!,
                remove: false,
              },
            });

            onStockLocationSet();
          }}
        >
          Add
        </Button>
      </Stack>
    </MantineModal>
  );
};
