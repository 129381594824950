import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, ProductGenericCode } from '../../../oas/codegen3';
import React, { useEffect, useState } from 'react';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { PX1536, PX32, PX4, PX64, PX96 } from '../../../components/Px';
import {
  Badge,
  Container,
  Stack,
  Table,
  Title,
  Text,
  Group,
} from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { calculateAmounts } from '../../../utils/calculateAmounts';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { closeModal, openModal } from '@mantine/modals';
import { GenericCodeSelectorModalBody } from '@components/V2/modal_selector/GenericCodeSelectorModalBody';
import { DatePickerModal } from '@components/V2/modal_selector/DatePickerModal';
import { dateToRFC3339, formatDate } from '../../../utils/dateFormatting';

export const ListProductPriceMargins = ({}: { path?: string }) => {
  useDocumentTitle('Price Margins');

  const listMargins = useStatefulAPIRequestMaker(
    DefaultOperations.listProductPriceMargins
  );
  const [genericCode, setGenericCode] =
    useState<ProductGenericCode | null>(null);
  const [date, setDate] = useState<Date | null>(null);

  const { onChangeDeps, paginationElement, pagination } = useListSearcherHelper(
    {
      limitPerPage: 30,
      debounceDelay: 200,
      additionalDeps: [genericCode, date],
    }
  );

  useEffect(() => {
    const p = listMargins.execute({
      currency: 'BHD',
      limit: pagination.limit,
      offset: pagination.offset,
      genericCode: genericCode?.genericCode ?? undefined,
      soldSince: dateToRFC3339(date) ?? undefined,
    });
    return () => p.cancel();
  }, [...onChangeDeps]);

  const onGenericCodeBadgeClicked = () => {
    openModal({
      modalId: 'generic-code-selector-modal',
      title: 'Select Generic Code',
      children: (
        <GenericCodeSelectorModalBody
          onGenericCodeSelected={(gc) => {
            setGenericCode(gc);
            closeModal('generic-code-selector-modal');
          }}
        />
      ),
    });
  };

  const currency = listMargins.response?.currency;

  const showDatePickerModal = () => {
    openModal({
      modalId: 'date-range-modal',
      title: <Title order={3}>Select Date</Title>,
      size: 'sm',
      children: (
        <DatePickerModal
          onDateRangeSelected={(d) => {
            setDate(d);
            closeModal('date-range-modal');
          }}
        />
      ),
    });
  };

  return (
    <Container maw={PX1536.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Stack spacing={PX4.PX}>
          <Title>Product Price Margins</Title>
          <Text c="dimmed">View margins of product prices.</Text>
        </Stack>
        <Group>
          <SelectorBadge
            placeholder="Select Generic Code"
            onClick={onGenericCodeBadgeClicked}
            onClearClick={() => setGenericCode(null)}
            value={genericCode?.genericCode}
          />
          <SelectorBadge
            value={date ? 'Sold Since: ' + formatDate(date) : null}
            placeholder="Sold Since"
            onClick={showDatePickerModal}
            onClearClick={() => setDate(null)}
          />
        </Group>
        {paginationElement(listMargins.response?.count)}
        <LoadingContainer loading={listMargins.loading}>
          <Table withBorder striped highlightOnHover>
            <thead>
              <tr>
                <th>Product</th>
                <th style={{ textAlign: 'right' }}>Selling Case Of</th>
                <th style={{ textAlign: 'right' }}>Selling Price</th>
                <th style={{ textAlign: 'right' }}>Price per PC (No Tax)</th>
                <th style={{ textAlign: 'right' }}>Cost per PC</th>
                <th>Margin</th>
              </tr>
            </thead>

            <tbody>
              {listMargins.response?.productPriceMargins.map((ppm) => {
                const margin =
                  Number(ppm.productPriceMargin.marginFromRecentPurchases) *
                  100;
                const color =
                  margin < 15 ? 'red' : margin < 25 ? 'yellow' : 'green';

                const sellingAmount = calculateAmounts({
                  currency: currency!,
                  quantity: 1,
                  taxIncludedInPrice: ppm.productPrice.taxIncluded,
                  taxes: ppm.saleTaxes,
                  unitPrice: ppm.productPrice.unitPrice,
                });

                return (
                  <ClickableTableRow
                    key={ppm.productPrice.id}
                    targetURL={`/products/${ppm.product.id}?tab=prices`}
                  >
                    <td>{ppm.product.name}</td>
                    <td style={{ textAlign: 'right' }}>
                      {ppm.productPrice.countPerUnit}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(sellingAmount.total, currency)}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(
                        sellingAmount.unitPrice.div(
                          ppm.productPrice.countPerUnit
                        ),
                        currency
                      )}{' '}
                      / PC
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(
                        ppm.productPriceMargin
                          .recentPurchasesAvgPCSCostPreTaxes,
                        currency
                      )}
                    </td>
                    <td>
                      <Badge color={color} fullWidth>
                        {margin.toFixed(0)}%
                      </Badge>
                    </td>
                  </ClickableTableRow>
                );
              })}
            </tbody>
          </Table>
        </LoadingContainer>
        {paginationElement(listMargins.response?.count)}
      </Stack>
    </Container>
  );
};
