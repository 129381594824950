import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer from './UserReducer';

export const pccStore = configureStore({
  reducer: {
    userStates: userReducer,
  },
});

export type RootState = ReturnType<typeof pccStore.getState>;
export type AppDispatch = typeof pccStore.dispatch;

export const usePccStoreDispatch: () => AppDispatch = useDispatch;
export const usePccStoreSelector: TypedUseSelectorHook<RootState> = useSelector;
