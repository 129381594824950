import { Box } from '../components/box/Box';
import { FlexBox } from '../components/flex/FlexBox';
import { Button, Collapse, Stack, TextInput } from '@mantine/core';
import { useState } from 'react';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useStatefulAPIRequestMaker } from '../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../oas/codegen3';
import { ApiError } from '../oas/codegen3/core/ApiError';
import { setAuthenticated } from 'src/pccstores/UserReducer';
import { usePccStoreDispatch } from '../pccstores/PccStore';

export const LoginPage = ({}: { path?: string }) => {
  const [mustSetTemporaryPassword, setMustSetTemporaryPassword] =
    useState(false);
  const dispatch = usePccStoreDispatch();
  const authenticate = useStatefulAPIRequestMaker(
    DefaultOperations.postAuthAuthenticate
  );

  const form = useForm<{
    email: string;
    password: string;
    newPassword1: string;
    newPassword2: string;
  }>({
    validateInputOnChange: ['newPassword2'],
    validate: {
      email: (v) => (v ? null : true),
      password: (v) => (v ? null : true),
      newPassword1: (v) => (v || !mustSetTemporaryPassword ? null : true),
      newPassword2: (v, { newPassword1 }) =>
        !v && mustSetTemporaryPassword
          ? true
          : v && v !== newPassword1
          ? 'Passwords do not match'
          : null,
    },
  });

  const onSubmit = form.onSubmit(async ({ email, password, newPassword1 }) => {
    try {
      await authenticate.execute({
        requestBody: {
          email,
          password,
          temporaryPasswordReplacement: mustSetTemporaryPassword
            ? newPassword1
            : undefined,
          authReturnLocation: 'cookie',
        },
      });
      dispatch(setAuthenticated(true));
    } catch (e) {
      console.log('(e as any).response?.data?', (e as ApiError).body);
      if (
        (e as ApiError).body?.message ===
        'temporary password replacement not given'
      ) {
        setMustSetTemporaryPassword(true);
      } else {
        showNotification({
          color: 'red',
          title: 'Login Failed',
          message:
            'Please make sure you are using the right password and try again.',
        });
      }
    }
    return;
  });

  return (
    <FlexBox height="100vh" alignItems="center" flexDirection="column">
      <Box marginTop="340px" width="406px">
        <form onSubmit={onSubmit}>
          <Stack>
            <TextInput
              type="email"
              label="Email"
              disabled={mustSetTemporaryPassword || authenticate.loading}
              required
              {...form.getInputProps('email')}
            />
            <TextInput
              type="password"
              label="Password"
              disabled={mustSetTemporaryPassword || authenticate.loading}
              required
              {...form.getInputProps('password')}
            />
            <Collapse in={mustSetTemporaryPassword}>
              <Stack>
                <TextInput
                  type="password"
                  label="New Password"
                  disabled={authenticate.loading}
                  {...form.getInputProps('newPassword1')}
                />

                <TextInput
                  type="password"
                  label="Repeat Password"
                  disabled={authenticate.loading}
                  error={
                    form.values.newPassword1 !== form.values.newPassword2
                      ? 'Passwords do not match'
                      : null
                  }
                  {...form.getInputProps('newPassword2')}
                />
              </Stack>
            </Collapse>

            <Button type="submit" loading={authenticate.loading}>
              Login
            </Button>
          </Stack>
        </form>
      </Box>
    </FlexBox>
  );
};
