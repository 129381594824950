import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  Currency,
  DefaultOperations,
  PaymentMethod,
  UserWithNameOnly,
} from '../../../oas/codegen3';
import React, { useEffect } from 'react';
import { PX1024, PX32, PX64, PX8, PX96 } from '../../../components/Px';
import {
  Button,
  Card,
  Container,
  Divider,
  Group,
  NumberInput,
  Stack,
  Table,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import {
  RFC3339ToEasyDate,
  RFC3339ToEasyDateTime,
} from '../../../utils/dateFormatting';
import {
  IconChecks,
  IconCircleCheck,
  IconClock,
  IconPlus,
} from '@tabler/icons-react';
import { StatusBadge } from '@components/V2/StatusBadge';
import Big from 'big.js';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useForm } from '@mantine/form';
import { closeModal, openModal } from '@mantine/modals';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';

export const ViewCashierSessionPage = ({
  cashierSessionID,
}: {
  path?: string;
  cashierSessionID?: string;
}) => {
  const getCashierSession = useStatefulAPIRequestMaker(
    DefaultOperations.getCashierWorkSession
  );
  useDocumentTitle(
    'Cashier Session: ' +
      (getCashierSession.response
        ? getCashierSession.response?.cashierUser.firstName +
          ' ' +
          getCashierSession.response?.cashierUser.lastName +
          ' (' +
          RFC3339ToEasyDate(
            getCashierSession.response?.cashierWorkSession.startedAt
          ) +
          ')'
        : 'Loading...')
  );

  const loadCashierSession = () => {
    if (cashierSessionID) {
      getCashierSession.execute({
        cashierWorkSessionId: cashierSessionID,
      });
    }
  };

  useEffect(() => {
    loadCashierSession();
  }, [cashierSessionID]);

  // let prettyStartDate = '';
  // let prettyEndDate = '';
  //
  // if (getCashierSession.response) {
  //   prettyStartDate = RFC3339ToEasyDateTime(
  //     getCashierSession.response.cashierWorkSession.startedAt
  //   );
  // }
  //
  // if (getCashierSession.response?.cashierWorkSession.endedAt) {
  //   prettyEndDate = RFC3339ToEasyDateTime(
  //     getCashierSession.response.cashierWorkSession.endedAt
  //   );
  // }

  const state = getCashierSession.response?.cashierWorkSession.state;
  const totalSaleReturn = getCashierSession.response?.totalSaleReturns;
  const totalCashSales = getCashierSession.response?.totalCashSales;

  const startingBalance =
    getCashierSession.response?.cashierWorkSession.startingBalance;
  const endingBalance =
    getCashierSession.response?.cashierWorkSession.endingBalance;
  const reviewedEndingBalance =
    getCashierSession.response?.cashierWorkSession.reviewedEndingBalance;
  const currency = getCashierSession.response?.currency;

  const showReviewSessionModal = () => {
    if (!getCashierSession.response) {
      return;
    }

    const { startingBalance, endingBalance } =
      getCashierSession.response.cashierWorkSession;
    const { totalCashSales, totalSaleReturns, currency } =
      getCashierSession.response;

    if (!endingBalance || !cashierSessionID) {
      return;
    }

    openModal({
      modalId: 'review-session-modal',
      title: <Title order={3}>Review Session</Title>,
      children: (
        <ReviewSessionModal
          cashierWorkSessionID={cashierSessionID}
          startingBalance={startingBalance}
          endingBalance={endingBalance}
          totalCashSales={totalCashSales}
          currency={currency}
          totalSaleReturns={totalSaleReturns ?? '0'}
          onReviewed={() => {
            closeModal('review-session-modal');
            loadCashierSession();
          }}
        />
      ),
    });
  };

  const actionButtons = [];
  if (state === 'waiting_review') {
    actionButtons.push(
      <Button
        compact
        variant="light"
        color="blue"
        leftIcon={<IconChecks size={14} />}
        onClick={showReviewSessionModal}
      >
        Review
      </Button>
    );
  }

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer loading={getCashierSession.loading}>
        <Stack>
          <Stack spacing={0}>
            {getCashierSession.response ? (
              <Stack spacing={0}>
                <Text size="sm" fw={700}>
                  Cashier Session
                </Text>
                <Title>
                  {getCashierSession.response.cashierUser.firstName}{' '}
                  {getCashierSession.response.cashierUser.lastName}
                </Title>
              </Stack>
            ) : (
              <Title>New Cashier Session</Title>
            )}
          </Stack>

          <Group>
            <StatusBadge state={state} />
            {actionButtons.length > 0 && <Divider orientation="vertical" />}
            {actionButtons}
          </Group>

          <StartedAtGroup
            cashierUser={getCashierSession.response?.cashierUser}
            startedAt={getCashierSession.response?.cashierWorkSession.startedAt}
          />

          <EndedAtGroup
            cashierUser={getCashierSession.response?.cashierUser}
            endedAt={getCashierSession.response?.cashierWorkSession.endedAt}
          />

          <ReviewedGroup
            reviewedAt={
              getCashierSession.response?.cashierWorkSession.reviewedAt
            }
            reviewingUser={getCashierSession.response?.reviewingUser}
          />

          <TextInput
            readOnly
            value={formatToCurrency(totalSaleReturn ?? 0, currency)}
            label="Sale Returns"
          />
          <Group grow>
            {startingBalance && (
              <Card withBorder radius="md" p="sm">
                <Text fw={700} color="dark" size="xs">
                  Starting Balance
                </Text>
                <Text fw={700} color="dark" size="xl">
                  {formatToCurrency(startingBalance, currency)}
                </Text>
              </Card>
            )}

            <EndingBalanceCard
              endingBalance={endingBalance}
              currency={currency}
              reviewedEndingBalance={reviewedEndingBalance}
            />

            {startingBalance && (
              <CashDifferenceCard
                endingBalance={endingBalance}
                currency={currency}
                startingBalance={startingBalance}
                totalSaleReturns={totalSaleReturn ?? '0'}
                totalCashSales={totalCashSales ?? '0'}
                reviewedEndingBalance={reviewedEndingBalance}
              />
            )}
          </Group>

          {getCashierSession.response && (
            <CashierSessionPaymentsTable
              paymentMethodTotals={
                getCashierSession.response.paymentMethodTotals
              }
              totalCashSales={getCashierSession.response.totalCashSales}
              currency={getCashierSession.response.currency}
            />
          )}
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const EndingBalanceCard = ({
  endingBalance,
  reviewedEndingBalance,
  currency,
}: {
  currency?: Currency | null;
  endingBalance?: string | null;
  reviewedEndingBalance?: string | null;
}) => {
  if (!endingBalance) {
    return null;
  }

  let endingBalanceIcon;
  let endingBalanceText = (
    <Text fw={700} color="dark" size="xl">
      {formatToCurrency(endingBalance, currency)}
    </Text>
  );
  if (endingBalance && !reviewedEndingBalance) {
    endingBalanceIcon = (
      <ThemeIcon color="orange" variant="light">
        <IconClock />
      </ThemeIcon>
    );
  } else if (endingBalance === reviewedEndingBalance) {
    endingBalanceIcon = (
      <ThemeIcon color="blue" variant="light">
        <IconChecks />
      </ThemeIcon>
    );
  } else {
    endingBalanceIcon = (
      <ThemeIcon color="blue" variant="light">
        <IconChecks />
      </ThemeIcon>
    );
    endingBalanceText = (
      <Group align="baseline">
        <Text fw={700} color="dark" size="xl">
          {formatToCurrency(reviewedEndingBalance, currency)}
        </Text>
        <Text fw={500} color="dark" size="sm" td="line-through" c="dimmed">
          {formatToCurrency(endingBalance, currency)}
        </Text>
      </Group>
    );
  }

  return (
    <Card withBorder radius="md" p="sm">
      <Group>
        {endingBalanceIcon}
        <Stack spacing={0}>
          <Text fw={700} color="dark" size="xs">
            Ending Balance
          </Text>
          {endingBalanceText}
        </Stack>
      </Group>
    </Card>
  );
};

const StartedAtGroup = ({
  cashierUser,
  startedAt,
}: {
  cashierUser?: UserWithNameOnly;
  startedAt?: string;
}) => {
  if (!cashierUser) {
    return null;
  }
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="orange" radius="md" size="lg" variant="light">
        <IconPlus />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {cashierUser.firstName} {cashierUser.lastName} started this cashier
          session.
        </Text>
        {startedAt && (
          <Text size="xs" c="dimmed">
            {RFC3339ToEasyDateTime(startedAt)}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const EndedAtGroup = ({
  cashierUser,
  endedAt,
}: {
  cashierUser?: UserWithNameOnly;
  endedAt?: string | null;
}) => {
  if (!cashierUser) {
    return null;
  }
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="teal" radius="md" size="lg" variant="light">
        <IconCircleCheck />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {cashierUser.firstName} {cashierUser.lastName} ended this cashier
          session.
        </Text>
        {endedAt && (
          <Text size="xs" c="dimmed">
            {RFC3339ToEasyDateTime(endedAt)}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const ReviewedGroup = ({
  reviewingUser,
  reviewedAt,
}: {
  reviewingUser?: UserWithNameOnly | null;
  reviewedAt?: string | null;
}) => {
  if (!reviewingUser) {
    return null;
  }
  return (
    <Group spacing={PX8.Number}>
      <ThemeIcon color="blue" radius="md" size="lg" variant="light">
        <IconChecks />
      </ThemeIcon>
      <Stack spacing={0}>
        <Text>
          {reviewingUser.firstName} {reviewingUser.lastName} reviewed this
          cashier session.
        </Text>
        {reviewedAt && (
          <Text size="xs" c="dimmed">
            {RFC3339ToEasyDateTime(reviewedAt)}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const CashDifferenceCard = ({
  currency,
  endingBalance,
  reviewedEndingBalance,
  startingBalance,
  totalSaleReturns,
  totalCashSales,
}: {
  endingBalance?: string | null;
  reviewedEndingBalance?: string | null;
  startingBalance: string;
  currency?: Currency;
  totalSaleReturns: string;
  totalCashSales: string;
}) => {
  if (!endingBalance) {
    return null;
  }

  const cashSales = new Big(totalCashSales ?? 0);

  const endingBalanceDiff = new Big(endingBalance)
    .sub(startingBalance)
    .sub(cashSales);

  const cashBalanceWithReturnsBig = endingBalanceDiff.add(
    totalSaleReturns ?? 0
  );
  const cashBalanceWithReturnsIsZero = cashBalanceWithReturnsBig.eq(new Big(0));

  let endingBalanceText = (
    <Text
      fw={700}
      size="xl"
      color={cashBalanceWithReturnsIsZero ? 'green' : 'red'}
    >
      {formatToCurrency(cashBalanceWithReturnsBig, currency)}
    </Text>
  );
  if (reviewedEndingBalance && reviewedEndingBalance !== endingBalance) {
    const reviewedEndingBalanceDiff = new Big(reviewedEndingBalance)
      .sub(startingBalance)
      .sub(cashSales);

    const reviewedEndingBalanceDiffWithReturns = reviewedEndingBalanceDiff.add(
      totalSaleReturns ?? 0
    );
    const reviewedEndingBalanceDiffWithReturnsIsZero =
      reviewedEndingBalanceDiffWithReturns.eq(new Big(0));

    endingBalanceText = (
      <Group align="baseline">
        <Text
          fw={700}
          color={reviewedEndingBalanceDiffWithReturnsIsZero ? 'green' : 'red'}
          size="xl"
        >
          {formatToCurrency(reviewedEndingBalanceDiffWithReturns, currency)}
        </Text>
        <Text fw={500} color="dimmed" size="sm" td="line-through" c="dimmed">
          {formatToCurrency(cashBalanceWithReturnsBig, currency)}
        </Text>
      </Group>
    );
  }

  return (
    <Card withBorder radius="md" p="sm">
      <Text fw={700} color="dark" size="xs">
        Cash Difference
      </Text>
      {endingBalanceText}
    </Card>
  );
};

const CashierSessionPaymentsTable = ({
  paymentMethodTotals,
  currency,
  totalCashSales,
}: {
  currency: Currency;
  paymentMethodTotals: {
    paymentMethod: PaymentMethod;
    totalSales: string;
  }[];
  totalCashSales: string;
}) => {
  return (
    <Table withBorder striped>
      <thead>
        <tr>
          <th>Payment Method</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr key="cash">
          <td>Cash</td>
          <td>{formatToCurrency(totalCashSales, currency)}</td>
        </tr>
        {paymentMethodTotals.map((p) => {
          const paymentAmount = p.totalSales;
          return (
            <tr key={p.paymentMethod.id}>
              <td>{p.paymentMethod.name}</td>
              <td>{formatToCurrency(paymentAmount, currency)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const ReviewSessionModal = ({
  cashierWorkSessionID,
  startingBalance,
  endingBalance,
  currency,
  totalSaleReturns,
  totalCashSales,
  onReviewed,
}: {
  cashierWorkSessionID: string;
  startingBalance: string;
  endingBalance: string;
  totalCashSales: string;
  currency: Currency;
  totalSaleReturns: string;
  onReviewed: () => void;
}) => {
  const reviewSession = useStatefulAPIRequestMaker(
    DefaultOperations.reviewCashierWorkSession
  );

  const form =
    useForm<{
      startingBalance: number;
      endingBalance: '' | number;
      cashSales: number;
    }>();

  useEffect(() => {
    form.setValues({
      startingBalance: Number(startingBalance),
      endingBalance: Number(endingBalance),
      cashSales: Number(totalCashSales),
    });
  }, [startingBalance, endingBalance, totalCashSales]);

  let salesDiff = null;
  if (form.values.endingBalance && form.values.endingBalance > 0) {
    salesDiff = new Big(form.values.endingBalance)
      .sub(form.values.startingBalance)
      .sub(form.values.cashSales)
      .add(totalSaleReturns);
  }

  const onSubmit = form.onSubmit(async (v) => {
    try {
      await reviewSession.execute({
        cashierWorkSessionId: cashierWorkSessionID,
        requestBody: {
          reviewedEndingBalance: v.endingBalance + '',
        },
      });

      onReviewed();
    } catch (e) {
      handleApiErrorMessage(e);
    }
  });

  return (
    <Stack>
      <form onSubmit={onSubmit}>
        <Stack>
          <NumberInput
            precision={currency.decimalPlaces}
            label="Starting Balance"
            readOnly
            {...form.getInputProps('startingBalance')}
          />

          <NumberInput
            precision={currency.decimalPlaces}
            label="Ending Balance"
            {...form.getInputProps('endingBalance')}
          />
          <TextInput
            readOnly
            label="Sale Returns"
            value={formatToCurrency(totalSaleReturns, currency)}
          />
          {salesDiff && (
            <TextInput
              readOnly
              value={formatToCurrency(salesDiff, currency)}
              label="Cash Difference"
            />
          )}
          <Button color="green" loading={reviewSession.loading} type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
