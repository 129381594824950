import { useEffect } from 'react';
import { useCounter } from '@mantine/hooks';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const useURLSearchQueryParamSetter = (
  {
    search,
  }: {
    search?: string | null;
  },
  deps: any[]
) => {
  useEffect(() => {
    if (!search) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.delete('search');
      window.history.replaceState(
        null,
        '',
        window.location.pathname + '?' + currentUrlParams.toString()
      );
    } else {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('search', search);
      window.history.replaceState(
        null,
        '',
        window.location.pathname + '?' + currentUrlParams.toString()
      );
    }
  }, deps);
};

export const useURLQueryParamSetter = (
  queryParams?: { [key: string]: string | null },
  deps?: any[]
): [(qp: { [key: string]: string }) => void] => {
  const set = (qp?: { [key: string]: string | null }) => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    for (const k in qp) {
      const v = qp[k];

      if (!v) {
        currentUrlParams.delete(k);
      } else {
        currentUrlParams.set(k, v);
      }
    }

    if (currentUrlParams.toString() === '') {
      window.history.replaceState(null, '', window.location.pathname);
    } else {
      window.history.replaceState(
        null,
        '',
        window.location.pathname + '?' + currentUrlParams.toString()
      );
    }
  };

  useEffect(() => {
    set(queryParams);
  }, deps);

  return [set];
};

export const useSerialEffect = (deps: any[], ...funcs: (() => void)[]) => {
  const [counter, handler] = useCounter(0);

  useEffect(() => {
    if (funcs.length === 0) {
      return;
    }

    let f;
    if (funcs.length > counter) {
      f = funcs[counter];
      handler.increment();
    } else {
      f = funcs[funcs.length - 1];
    }

    f();
  }, deps);
};
