import { showNotification } from '@mantine/notifications';
import { ApiError } from '../oas/codegen3/core/ApiError';

export const handleApiErrorMessage = (e: unknown) => {
  const apiError = e as ApiError;

  const message = apiError.body?.message;
  if (message) {
    showNotification({
      color: 'red',
      title: `Error: ${message.substring(0, 50)}`,
      message: `${message}`,
    });
  }
};
