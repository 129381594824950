import { TextInput } from '@mantine/core';
import React, { CSSProperties } from 'react';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type ModalSelectorInputProps = {
  onClick?: () => void;
  disableOnClick?: any;
  value?: string | null | undefined;
  label: string;
  placeholder?: string;
  required?: any;
  description?: string;
  rightSection?: React.ReactNode;
  style?: CSSProperties;
};

export const ModalSelectorInput = (props: ModalSelectorInputProps) => {
  return (
    <TextInput
      style={props.style}
      label={props.label}
      placeholder={props.placeholder}
      description={props.description}
      onClick={props.disableOnClick ? undefined : props.onClick}
      styles={{
        input: {
          cursor: props.disableOnClick ? 'not-allowed' : 'pointer',
        },
      }}
      rightSection={props.rightSection}
      value={props.value ?? ''}
      readOnly={true}
      required={props.required}
    />
  );
};
