import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, PosAppVersion } from '../../../oas/codegen3';
import { PX1024, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import { Button, Container, Flex, Stack, Table, Title } from '@mantine/core';
import { useNavigate } from '@reach/router';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useEffect } from 'react';

export const PosAppVersionsList = ({}: { path: string }) => {
  const navigate = useNavigate();
  const listPosAppVersions = useStatefulAPIRequestMaker(
    DefaultOperations.listPosAppVersions
  );

  useEffect(() => {
    listPosAppVersions.execute({});
  }, []);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Products</Title>
        <Flex>
          <Button color="green" onClick={() => navigate('/posAppVersions/new')}>
            New App Version
          </Button>
        </Flex>

        <AppVersionsTable
          loading={listPosAppVersions.loading}
          appVersions={listPosAppVersions.response?.posAppVersions || []}
        />
      </Stack>
    </Container>
  );
};

const AppVersionsTable = ({
  appVersions,
  loading,
}: {
  loading: boolean;
  appVersions: PosAppVersion[];
}) => {
  const navigate = useNavigate();

  const rows = appVersions.map((v) => {
    const onClick = () => {
      navigate(`/posAppVersions/${v.id}`);
    };

    return (
      <tr onClick={onClick} key={v.id}>
        <td>{v.version}</td>
        <td>{v.status}</td>
      </tr>
    );
  });

  return (
    <LoadingContainer loading={loading} minHeight={PX384.Number}>
      <Table highlightOnHover>
        <thead>
          <tr>
            <th>Version</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </Table>
    </LoadingContainer>
  );
};
