import {
  ActionIcon,
  Button,
  Group,
  Menu,
  NumberInput,
  Stack,
  Table,
  Tabs,
  Title,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { Container } from '@mantine/core';
import { PX1024, PX192, PX32, PX64, PX96 } from '../../../components/Px';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from '@reach/router';
import { StatusBadge } from '@components/V2/StatusBadge';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { StockLocationSelector } from '@components/V2/selectors/StockLocationSelector';
import {
  IconCaretDown,
  IconCheck,
  IconDots,
  IconList,
  IconPencil,
  IconPrinter,
  IconSignature,
  IconTrash,
} from '@tabler/icons-react';

import { ProductSelector } from '@components/V2/selectors/ProductSelector';
import { v4 as uuidv4 } from 'uuid';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  Product,
  StockLocation,
  StockMoveGroup,
  StockMoveGroupLine,
} from '../../../oas/codegen3';
import { b64toBlob } from '../../../utils/b64toBlob';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const StockMoveViewer = ({
  id,
  storeID,
}: {
  path?: string;
  id?: string;
  storeID?: string;
}) => {
  const navigate = useNavigate();

  const getStockMoveGroup = useStatefulAPIRequestMaker(
    DefaultOperations.getStockMoveGroupById
  );
  const updateStockMoveGroupState = useStatefulAPIRequestMaker(
    DefaultOperations.updateStockMoveGroupState
  );
  const createStockMoveGroup = useStatefulAPIRequestMaker(
    DefaultOperations.createStockMoveGroup
  );
  const updateStockMoveGroup = useStatefulAPIRequestMaker(
    DefaultOperations.updateStockMoveGroup
  );
  const getStockMoveGroupPDF = useStatefulAPIRequestMaker(
    DefaultOperations.getStockMoveGroupPdf
  );

  const form =
    useForm<{
      sourceLocation: StockLocation | null;
      destinationLocation: StockLocation | null;
    }>();

  const canSubmit =
    form.values.sourceLocation !== null &&
    form.values.destinationLocation !== null;
  const sourceAndDestinationTheSame =
    !!form.values.destinationLocation &&
    !!form.values.sourceLocation &&
    form.values.destinationLocation.id === form.values.sourceLocation.id;

  useEffect(() => {
    if (id) {
      const fn = async () => {
        const response = await getStockMoveGroup.execute({
          stockMoveGroupId: id,
        });
        form.setValues({
          sourceLocation: response.sourceLocation,
          destinationLocation: response.destinationLocation,
        });
      };
      fn();
    }
  }, [id]);

  const onSubmit = form.onSubmit(
    async ({ destinationLocation, sourceLocation }) => {
      if (id) {
        updateStockMoveGroup.execute({
          stockMoveGroupId: id,
          requestBody: {
            destinationLocationID: destinationLocation!.id,
            sourceLocationID: sourceLocation!.id,
          },
        });

        showNotification({
          color: 'green',
          title: `Stock Move Updated`,
          message: `Stock move has been updated`,
        });
      } else {
        const response = await createStockMoveGroup.execute({
          requestBody: {
            destinationLocationID: destinationLocation!.id,
            sourceLocationID: sourceLocation!.id,
          },
        });

        showNotification({
          color: 'green',
          title: `Stock Move Created`,
          message: `Stock move has been successfully created`,
        });

        navigate(`/stockMoves/${response.stockMoveGroup.id}`);
      }
    }
  );

  const getPDF = async () => {
    const response = await getStockMoveGroupPDF.execute({
      stockMoveGroupId: id!,
    });

    const file = b64toBlob(response.pdfBase64, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const stockMove = getStockMoveGroup.response?.stockMoveGroup;
  const stockMoveState = stockMove?.state;
  const canEdit = !id || stockMoveState === 'draft';

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer
        loading={
          createStockMoveGroup.loading ||
          updateStockMoveGroup.loading ||
          getStockMoveGroup.loading ||
          updateStockMoveGroupState.loading
        }
      >
        <Stack>
          <Group position="apart">
            <Title>
              {getStockMoveGroup.response
                ? getStockMoveGroup.response.stockMoveGroup.reference
                : 'New Stock Move'}
            </Title>

            <Menu shadow="md" width={PX192.Number}>
              <Menu.Target>
                <Button
                  variant="subtle"
                  rightIcon={<IconCaretDown size={14} />}
                >
                  Actions
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  disabled={!id}
                  onClick={getPDF}
                  icon={<IconPrinter />}
                >
                  Print
                </Menu.Item>
                <Menu.Divider />
                <Menu.Label>Actions</Menu.Label>
                <Menu.Item
                  icon={<IconSignature />}
                  disabled={
                    getStockMoveGroup.response?.stockMoveGroup.state !==
                      'draft' ||
                    getStockMoveGroup.response.stockMoveGroupLines.length === 0
                  }
                  onClick={() =>
                    openConfirmModal({
                      title: <Title order={3}>Ready Stock Move</Title>,
                      children:
                        'Are you sure you want to ready this stock move?',
                      labels: {
                        confirm: 'Yes - Ready Stock Moves',
                        cancel: 'Go Back',
                      },
                      onConfirm: async () => {
                        await updateStockMoveGroupState.execute({
                          stockMoveGroupId: id!,
                          requestBody: {
                            state: 'ready',
                          },
                        });
                        await getStockMoveGroup.execute({
                          stockMoveGroupId: id!,
                        });

                        showNotification({
                          color: 'green',
                          title: `Stock Move Ready`,
                          message: `The stock move is ready to be collected.`,
                        });
                      },
                    })
                  }
                >
                  Ready
                </Menu.Item>
                <Menu.Item
                  icon={<IconCheck />}
                  disabled={
                    getStockMoveGroup.response?.stockMoveGroup.state !==
                      'ready' ||
                    getStockMoveGroup.response.stockMoveGroupLines.length === 0
                  }
                  onClick={() =>
                    openConfirmModal({
                      title: <Title order={3}>Post Stock Move</Title>,
                      children:
                        'Are you sure you want to post this stock move? All stock move lines will be initiated.',
                      labels: {
                        confirm: 'Yes - Post All Stock Moves',
                        cancel: 'Go Back',
                      },
                      onConfirm: async () => {
                        await updateStockMoveGroupState.execute({
                          stockMoveGroupId: id!,
                          requestBody: {
                            state: 'posted',
                          },
                        });
                        await getStockMoveGroup.execute({
                          stockMoveGroupId: id!,
                        });

                        showNotification({
                          color: 'green',
                          title: `Stock Moves Posted`,
                          message: `All stock move lines have been posted.`,
                        });
                      },
                    })
                  }
                >
                  Post
                </Menu.Item>
                <Menu.Item
                  icon={<IconTrash />}
                  color="red"
                  disabled={
                    getStockMoveGroup.response?.stockMoveGroup.state !==
                      'draft' &&
                    getStockMoveGroup.response?.stockMoveGroup.state !== 'ready'
                  }
                  onClick={() =>
                    openConfirmModal({
                      title: <Title order={3}>Post Stock Move</Title>,
                      children:
                        'Are you sure you want to cancel these stock move?',
                      labels: {
                        confirm: 'Yes - Cancel Stock Moves',
                        cancel: 'Go Back',
                      },
                      onConfirm: async () => {
                        await updateStockMoveGroupState.execute({
                          stockMoveGroupId: id!,
                          requestBody: {
                            state: 'cancelled',
                          },
                        });
                        await getStockMoveGroup.execute({
                          stockMoveGroupId: id!,
                        });
                        showNotification({
                          color: 'green',
                          title: `Stock Moves Cancelled`,
                          message: `All stock move lines have been cancelled.`,
                        });
                      },
                    })
                  }
                >
                  Cancel
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
          <form onSubmit={onSubmit}>
            <Stack>
              <Group>
                {getStockMoveGroup.response && (
                  <StatusBadge
                    state={getStockMoveGroup.response.stockMoveGroup.state}
                  />
                )}
              </Group>

              <StockLocationSelector
                label="Source Location"
                storeID={storeID}
                readOnly={!canEdit}
                stockLocation={form.values.sourceLocation}
                onStockLocationSelected={(sl) => {
                  form.setValues({ sourceLocation: sl });
                }}
              />

              <StockLocationSelector
                label="Destination Location"
                stockLocation={form.values.destinationLocation}
                readOnly={!canEdit}
                error={
                  sourceAndDestinationTheSame
                    ? 'Source and destination location must be different'
                    : null
                }
                onStockLocationSelected={(sl) => {
                  form.setValues({ destinationLocation: sl });
                }}
              />

              {canEdit && (
                <Group>
                  <Button
                    type="submit"
                    color="green"
                    disabled={
                      !canSubmit ||
                      sourceAndDestinationTheSame ||
                      (getStockMoveGroup.response?.stockMoveGroup &&
                        getStockMoveGroup.response?.stockMoveGroup.state !==
                          'draft')
                    }
                    loading={createStockMoveGroup.loading}
                  >
                    {!id ? 'Create Stock Move' : 'Update Stock Move'}
                  </Button>
                </Group>
              )}
            </Stack>
          </form>

          {id && (
            <Tabs defaultValue="stockMoveLines">
              <Tabs.List>
                <Tabs.Tab value="stockMoveLines" icon={<IconList size={14} />}>
                  Stock Move Lines
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="stockMoveLines" pt="lg">
                <StockMoveGroupLines
                  lines={getStockMoveGroup.response?.stockMoveGroupLines}
                  stockMoveGroup={getStockMoveGroup.response?.stockMoveGroup}
                  onChange={() => {
                    getStockMoveGroup.execute({ stockMoveGroupId: id });
                  }}
                />
              </Tabs.Panel>
            </Tabs>
          )}
        </Stack>
      </LoadingContainer>
    </Container>
  );
};

const StockMoveGroupLines = ({
  lines,
  stockMoveGroup,
  onChange,
}: {
  stockMoveGroup?: StockMoveGroup;
  lines?: { product: Product; stockMoveGroupLine: StockMoveGroupLine }[];
  onChange: () => void;
}) => {
  const deleteStockMoveGroupLine = useStatefulAPIRequestMaker(
    DefaultOperations.deleteStockMoveGroupLine
  );

  return (
    <Stack>
      {stockMoveGroup && stockMoveGroup.state === 'draft' && (
        <Group>
          <Button
            onClick={() => {
              const modalID = uuidv4();

              openModal({
                modalId: modalID,
                title: <Title order={3}>Add Stock Move Line</Title>,
                children: (
                  <StockMoveGroupLineModal
                    closeModal={() => {
                      onChange();
                      closeModal(modalID);
                    }}
                    stockMoveGroup={stockMoveGroup!}
                  />
                ),
              });
            }}
          >
            Add Line
          </Button>
        </Group>
      )}
      <Table>
        <thead>
          <tr>
            <th>Product</th>
            <th>Count per Quantity</th>
            <th>Quantity</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {lines &&
            lines.map((l) => {
              return (
                <tr>
                  <td>{l.product.name}</td>
                  <td>{l.stockMoveGroupLine.countPerQuantity}</td>
                  <td>{l.stockMoveGroupLine.quantity}</td>
                  <td>
                    <Menu shadow="md" width={PX192.Number}>
                      <Menu.Target>
                        <ActionIcon>
                          <IconDots />
                        </ActionIcon>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Label>Edit Line</Menu.Label>
                        <Menu.Item
                          icon={<IconPencil />}
                          disabled={stockMoveGroup?.state !== 'draft'}
                          onClick={() => {
                            const modalID = uuidv4();

                            openModal({
                              modalId: modalID,
                              title: (
                                <Title order={3}>Update Stock Move Line</Title>
                              ),
                              children: (
                                <StockMoveGroupLineModal
                                  closeModal={() => {
                                    onChange();
                                    closeModal(modalID);
                                  }}
                                  stockMoveGroup={stockMoveGroup!}
                                  stockMoveGroupLine={l.stockMoveGroupLine}
                                />
                              ),
                            });
                          }}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          icon={<IconTrash />}
                          color="red"
                          disabled={stockMoveGroup?.state !== 'draft'}
                          onClick={() =>
                            openConfirmModal({
                              title: (
                                <Title order={3}>Delete Stock Move Line</Title>
                              ),
                              children:
                                'Are you sure you want to delete this stock move line?',
                              labels: {
                                confirm: 'Yes - Delete Move Line',
                                cancel: 'Go Back',
                              },
                              onConfirm: async () => {
                                await deleteStockMoveGroupLine.execute({
                                  stockMoveGroupLineId: l.stockMoveGroupLine.id,
                                });
                                onChange();
                              },
                            })
                          }
                        >
                          Delete
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Stack>
  );
};

const StockMoveGroupLineModal = ({
  stockMoveGroup,
  closeModal,
  stockMoveGroupLine,
}: {
  stockMoveGroup: StockMoveGroup;
  stockMoveGroupLine?: StockMoveGroupLine;
  closeModal: () => void;
}) => {
  const createStockMoveGroupLine = useStatefulAPIRequestMaker(
    DefaultOperations.createStockMoveGroupLine
  );
  const updateStockMoveGroupLine = useStatefulAPIRequestMaker(
    DefaultOperations.updateStockMoveGroupLine
  );

  const form = useForm<{
    product?: Product | null;
    countPerQuantity?: number;
    quantity?: number;
  }>({
    validate: {
      product: (v: any) => (v ? null : 'Product required'),
      countPerQuantity: (v: any) =>
        v && v > 0 ? null : 'Count per quantity required',
      quantity: (v: any) => (v && v > 0 ? null : 'Quantity required'),
    },
  });

  useEffect(() => {
    if (stockMoveGroupLine) {
      form.setValues({
        countPerQuantity: Number(stockMoveGroupLine.countPerQuantity),
        quantity: Number(stockMoveGroupLine.quantity),
      });
    }
  }, [stockMoveGroupLine]);

  const onSubmit = form.onSubmit(async (v) => {
    if (!stockMoveGroupLine) {
      await createStockMoveGroupLine.execute({
        requestBody: {
          countPerQuantity: String(v.countPerQuantity!),
          productID: v.product!.id,
          quantity: String(v.quantity!),
          stockMoveGroupID: stockMoveGroup.id!,
        },
      });
    } else {
      await updateStockMoveGroupLine.execute({
        stockMoveGroupLineId: stockMoveGroupLine.id,
        requestBody: {
          countPerQuantity: String(v.countPerQuantity!),
          productID: v.product!.id,
          quantity: String(v.quantity!),
        },
      });
    }

    closeModal();
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <ProductSelector
          label="Product"
          onProductSelected={(p) => form.setValues({ product: p })}
          product={form.values.product}
          productID={stockMoveGroupLine?.productID}
        />

        <NumberInput
          label="Count per Quantity"
          {...form.getInputProps('countPerQuantity')}
        />

        <NumberInput label="Quantity" {...form.getInputProps('quantity')} />

        <Button
          type="submit"
          disabled={!form.isValid()}
          loading={createStockMoveGroupLine.loading}
        >
          {stockMoveGroupLine ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </form>
  );
};
