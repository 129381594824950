import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Container,
  Group,
  Modal,
  Stack,
  Table,
  TextInput,
  Title,
  Text,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { showNotification } from '@mantine/notifications';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, Product } from '../../../oas/codegen3';
import { useForm } from '@mantine/form';
import { PX1024, PX32, PX4, PX64, PX96 } from '../../../components/Px';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { Link } from '@components/V2/Link';
import { useNavigate } from '@reach/router';
import {
  IconAlertTriangle,
  IconPencil,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { ModalSelectorInput } from '@components/V2/modal_selector/ModalSelectorInput';
import { useDisclosure } from '@mantine/hooks';
import { GenericCodeSelectorModalBody } from '@components/V2/modal_selector/GenericCodeSelectorModalBody';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';
import { useFindPrivileges } from '../../../pccstores/UserUtils';

export const ProductGenericCodeForm = ({
  genericCodeID,
}: {
  path?: string;
  genericCodeID?: string;
}) => {
  const navigate = useNavigate();
  const createGenericCode = useStatefulAPIRequestMaker(
    DefaultOperations.createProductGenericCode
  );
  const updateGenericCode = useStatefulAPIRequestMaker(
    DefaultOperations.updateProductGenericCode
  );
  const getGenericCode = useStatefulAPIRequestMaker(
    DefaultOperations.getProductGenericCode
  );
  const deleteGenericCode = useStatefulAPIRequestMaker(
    DefaultOperations.deleteProductGenericCode
  );

  type FormFields = {
    genericCode: string;
  };

  const form = useForm<FormFields>({
    validate: {
      genericCode: (value) =>
        value.trim().length === 0 ? 'Generic Code is required' : null,
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (genericCodeID) {
      await updateGenericCode.execute({
        genericCodeId: genericCodeID,
        requestBody: {
          genericCode: values.genericCode,
        },
      });

      showNotification({
        color: 'green',
        title: 'GenericCode Updated',
        message: `GenericCode ${values.genericCode} has been updated.`,
      });
    } else {
      const response = await createGenericCode.execute({
        requestBody: {
          genericCode: values.genericCode,
        },
      });

      showNotification({
        color: 'green',
        title: 'GenericCode Created',
        message: `GenericCode ${values.genericCode} has been created.`,
      });

      navigate(`/products/genericCodes/${response.productGenericCode.id}`);
    }
  });

  useEffect(() => {
    const fn = async () => {
      if (!genericCodeID) {
        return;
      }

      const response = await getGenericCode.execute({
        genericCodeId: genericCodeID,
      });
      form.setValues({
        genericCode: response.productGenericCode.genericCode,
      });
    };

    fn();
  }, [genericCodeID]);

  const onDeleteClicked = async () => {
    if (!genericCodeID) {
      return;
    }

    openConfirmModal({
      title: <Title order={3}>Delete Generic Code</Title>,
      children:
        'Are you sure you want to delete the product generic code? If a product is using this generic code, the generic code will be emptied. No products will be deleted.',
      labels: {
        confirm: 'Yes - Delete Generic Code',
        cancel: 'Go Back',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        await deleteGenericCode.execute({
          genericCodeId: genericCodeID,
        });

        showNotification({
          color: 'green',
          title: 'Product Generic Code Deleted',
          message: `Product generic code has been deleted.`,
        });

        navigate(`/products/genericCodes`);
      },
    });
  };

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <LoadingContainer
          loading={
            getGenericCode.loading ||
            createGenericCode.loading ||
            updateGenericCode.loading
          }
        >
          <Stack>
            <Stack spacing={0}>
              {genericCodeID ? (
                <Stack spacing={0}>
                  <Text size="sm" fw={700}>
                    Generic Code
                  </Text>
                  <Title>
                    {getGenericCode.response?.productGenericCode.genericCode ??
                      'Loading...'}
                  </Title>
                </Stack>
              ) : (
                <Title>New Generic Code</Title>
              )}
            </Stack>
            <Group>
              {genericCodeID && (
                <Button
                  color="red"
                  onClick={onDeleteClicked}
                  leftIcon={<IconTrash size={14} />}
                  variant="light"
                  compact
                >
                  Delete
                </Button>
              )}
            </Group>
            <form onSubmit={onSubmit}>
              <Stack>
                <TextInput
                  label="Generic Code"
                  placeholder="Product Generic Code"
                  {...form.getInputProps('genericCode')}
                />
                <Box>
                  <Button type="submit" color="green">
                    {genericCodeID ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </Stack>
            </form>
          </Stack>
        </LoadingContainer>

        {getGenericCode.response && (
          <Stack mt={PX32.PX}>
            <Stack spacing={PX4.PX}>
              <Title order={3}>Generic Code Products</Title>
              <Text c="dimmed">
                Below is a list of products with the generic code{' '}
                <Text fw={700} span>
                  {getGenericCode.response.productGenericCode.genericCode}
                </Text>
                .
              </Text>
            </Stack>
            <GenericCodeProducts
              genericCode={
                getGenericCode.response.productGenericCode.genericCode
              }
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

const GenericCodeProducts = ({ genericCode }: { genericCode: string }) => {
  const listProducts = useStatefulAPIRequestMaker(
    DefaultOperations.listProducts
  );

  const { pagination, paginationElement, onChangeDeps, forceListRefresh } =
    useListSearcherHelper({
      debounceDelay: 200,
      limitPerPage: 30,
      additionalDeps: [genericCode],
    });

  useEffect(() => {
    const p = listProducts.execute({
      genericCode: genericCode,
      limit: pagination.limit,
      offset: pagination.offset,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  const productsArr = listProducts.response?.products ?? [];

  const canUpdateProduct = useFindPrivileges({
    action: 'write',
    resource: 'product',
  }).hasPrivilege;

  const showProductGenericCodeEditorForm = ({
    product,
  }: {
    product: Product;
  }) => {
    openModal({
      modalId: 'product-generic-code-editor-form',
      title: <Title order={3}>Set Product Generic Code</Title>,
      closeOnClickOutside: false,
      children: (
        <ProductGenericCodeEditorForm
          product={product}
          onGenericCodeEdited={() => {
            closeModal('product-generic-code-editor-form');
            forceListRefresh();
          }}
        />
      ),
    });
  };

  return (
    <Stack>
      {paginationElement(listProducts.response?.count)}
      <LoadingContainer loading={listProducts.loading}>
        <Table withBorder>
          <thead>
            <tr>
              <th>Name</th>
              <th>Reference</th>
              <th>Barcode</th>
              {canUpdateProduct && <th />}
            </tr>
          </thead>

          <tbody>
            {productsArr.map((p) => {
              const emptyName = p.product.name.trim() === '';

              return (
                <tr key={p.product.id}>
                  <td>
                    <Link
                      to={`/products/${p.product.id}`}
                      withIcon
                      hidden
                      inline
                    >
                      {emptyName ? '[No Name]' : p.product.name}
                    </Link>
                  </td>
                  <td>{p.product.reference}</td>
                  <td>{p.product.barcode}</td>
                  {canUpdateProduct && (
                    <td>
                      <ActionIcon
                        variant="subtle"
                        color="orange"
                        onClick={() =>
                          showProductGenericCodeEditorForm({
                            product: p.product,
                          })
                        }
                      >
                        <IconPencil />
                      </ActionIcon>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </LoadingContainer>
      {paginationElement(listProducts.response?.count)}
    </Stack>
  );
};

const ProductGenericCodeEditorForm = ({
  product,
  onGenericCodeEdited,
}: {
  product: Product;
  onGenericCodeEdited: () => void;
}) => {
  const patchProductGenericCode = useStatefulAPIRequestMaker(
    DefaultOperations.patchProductGenericCode
  );
  const [genericCodeModalOpen, genericCodeModalHandler] = useDisclosure(false);

  const form =
    useForm<{
      genericCode: string;
    }>();

  useEffect(() => {
    form.setValues({ genericCode: product.genericCode ?? '' });
  }, [product]);

  const updateGenericCode = form.onSubmit(async (v) => {
    try {
      await patchProductGenericCode.execute({
        productId: product.id,
        requestBody: {
          genericCode: v.genericCode === '' ? undefined : v.genericCode,
        },
      });

      showNotification({
        color: 'green',
        title:
          'Product Generic Code ' + v.genericCode ? ' Changed' : ' Removed',
        message: v.genericCode
          ? `Product generic code changed to ${v.genericCode}`
          : 'Product generic code removed.',
      });

      onGenericCodeEdited();
    } catch (e) {
      handleApiErrorMessage(e);
    }
  });

  return (
    <Stack>
      <Modal
        zIndex={1000}
        opened={genericCodeModalOpen}
        onClose={genericCodeModalHandler.close}
        title={<Title order={3}>Select Generic Code</Title>}
      >
        <GenericCodeSelectorModalBody
          onGenericCodeSelected={(gc) => {
            form.setValues({ genericCode: gc.genericCode });
            genericCodeModalHandler.close();
          }}
        />
      </Modal>
      <Text>
        Set the generic code for{' '}
        <Text fw={700} span>
          {product.name}
        </Text>
        .
      </Text>
      <form onSubmit={updateGenericCode}>
        <Stack>
          <ModalSelectorInput
            label="Generic Code"
            placeholder="Generic Code"
            {...form.getInputProps('genericCode')}
            onClick={genericCodeModalHandler.open}
            rightSection={
              <IconX
                size={14}
                stroke={1.5}
                onClick={() => {
                  form.setValues({ genericCode: '' });
                }}
                style={{ cursor: 'pointer' }}
              />
            }
          />
          {form.values.genericCode === '' && (
            <Alert
              color="red"
              title="No Generic Code Set"
              icon={<IconAlertTriangle />}
            >
              If you save,{' '}
              <Text fw={700} span>
                {product.name}
              </Text>{' '}
              will not have a generic code attached to it.
            </Alert>
          )}
          <Button
            type="submit"
            color="green"
            loading={patchProductGenericCode.loading}
          >
            Save
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
