import { Location, Router } from '@reach/router';
import React from 'react';
import { UsersListPage } from './UsersListPage';
import { UserViewer } from './UserViewer';
import { NewUserPage } from './NewUserPage';

export const UserPages = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <NewUserPage path="/new" />
          <UserViewer path="/:userID" />
          <UsersListPage path="/" />
        </Router>
      )}
    </Location>
  );
};
