import { PurchaseOrder, UserWithNameOnly } from '../../../../oas/codegen3';
import { Alert, Text } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { RFC3339ToEasyDateTime } from '../../../../utils/dateFormatting';

export const PurchaseOrderRejectionReasonAlert = ({
  purchaseOrder,
  rejectingUser,
}: {
  purchaseOrder?: PurchaseOrder;
  rejectingUser?: UserWithNameOnly;
}) => {
  if (
    !purchaseOrder ||
    !(purchaseOrder.state === 'draft' && purchaseOrder.lastRejectedAt)
  ) {
    return null;
  }

  const { lastRejectedReason, lastRejectedDescription, lastRejectedAt } =
    purchaseOrder;

  return (
    <Alert
      icon={<IconAlertTriangle />}
      title={`Purchase Order Rejected - ${lastRejectedReason}`}
      color="red"
    >
      {lastRejectedAt && (
        <Text>
          <Text fw={700} span>
            Rejected at:
          </Text>{' '}
          {RFC3339ToEasyDateTime(lastRejectedAt)}
        </Text>
      )}
      {rejectingUser && (
        <Text>
          <Text fw={700} span>
            Rejected by:
          </Text>{' '}
          {rejectingUser.firstName} {rejectingUser.lastName}
        </Text>
      )}
      {lastRejectedDescription && (
        <Text>
          <Text fw={700} span>
            More details:
          </Text>{' '}
          {lastRejectedDescription}
        </Text>
      )}
    </Alert>
  );
};
