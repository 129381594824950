import { Location, Router } from '@reach/router';
import React from 'react';
import { ListPurchaseOrders } from './ListPurchaseOrders';
import { PurchaseOrderForm } from './PurchaseOrderForm';
import { CreatePurchaseOrderBulkPaymentsPage } from './CreatePurchaseOrderBulkPaymentsPage';
import { ListBulkPurchaseOrderPaymentsPage } from './ListBulkPurchaseOrderPaymentsPage';
import { ListPurchaseOrderPayments } from './ListPurchaseOrderPayments';
import { PurchaseOrderPaymentForm } from './PurchaseOrderPaymentForm';
import { BulkPaymentViewer } from './BulkPaymentViewer';

export const PurchaseOrderRouter = ({}: { path: string }) => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location}>
          <ListPurchaseOrders path="/" />
          <PurchaseOrderForm path="/:purchaseOrderID" />
          <PurchaseOrderForm path="/new" />
          {/*Purchase Order Payments*/}
          <ListPurchaseOrderPayments path="/payments" />
          <PurchaseOrderPaymentForm path="/payments/new" />
          <PurchaseOrderPaymentForm path="/payments/:purchaseOrderPaymentID" />
          {/*Bulk Payments*/}
          <ListBulkPurchaseOrderPaymentsPage path="/bulkPayments" />
          <BulkPaymentViewer path="/bulkPayments/:id" />
          <CreatePurchaseOrderBulkPaymentsPage path="/bulkPayments/new" />
        </Router>
      )}
    </Location>
  );
};
