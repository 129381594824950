/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Vendor } from '../models/Vendor';
import type { VendorContact } from '../models/VendorContact';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OldPatternOperations {
    /**
     * Get Vendor
     * @returns any OK
     * @throws ApiError
     */
    public static getVendor({
        vendorId,
    }: {
        vendorId: string,
    }): CancelablePromise<{
        vendor: Vendor;
        vendorContacts: Array<{
            vendorContact: VendorContact;
            mostRecentCollectedPurchaseOrder?: {
                collectedAt: string;
                id: string;
            };
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendor',
            query: {
                'vendorID': vendorId,
            },
        });
    }
    /**
     * Update Vendor Contact
     * @returns any OK
     * @throws ApiError
     */
    public static updateVendorContact({
        requestBody,
    }: {
        requestBody?: {
            id: string;
            name: string;
            vendorID: string;
            phoneNumberWithCountryCode: string;
        },
    }): CancelablePromise<{
        vendorContact: VendorContact;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/vendor/contact',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
