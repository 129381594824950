import {
  Button,
  Card,
  Center,
  Container,
  Loader,
  Select,
  Stack,
  Title,
  Text,
  NumberInput,
  Collapse,
  Checkbox,
  Grid,
  Table,
  Tooltip,
  Box,
  Alert,
} from '@mantine/core';
import { PX256, PX32, PX64, PX96 } from '../../../components/Px';
import React, { useEffect, useState } from 'react';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import Big from 'big.js';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { showNotification } from '@mantine/notifications';

import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { StockCheckStatusBadge } from '@components/V2/StockCheckStatusBadge';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  StockBalance,
  StockCheck,
  StockLocation,
  Store,
  User,
} from '../../../oas/codegen3';
import { IconQuestionMark } from '@tabler/icons-react';

export const CheckStockPage = ({
  storeID,
}: {
  path?: string;
  storeID?: string;
}) => {
  const searchStockLocations = useStatefulAPIRequestMaker(
    DefaultOperations.searchStockLocations
  );
  const searchProducts = useStatefulAPIRequestMaker(
    DefaultOperations.listProducts
  );
  const getProductStockBalance = useStatefulAPIRequestMaker(
    DefaultOperations.getProductStockBalance
  );
  const listStockChecks = useStatefulAPIRequestMaker(
    DefaultOperations.listStockChecks
  );

  const [selectedStockLocationID, setSelectedStockLocationID] =
    useInputState<string | null>(null);
  const [selectedProductID, setSelectedProductID] =
    useInputState<string | null>(null);
  const [productSearchTarget, setProductSearchTarget] = useInputState('');
  const [productTargetDebounce] = useDebouncedValue(productSearchTarget, 300);

  useEffect(() => {
    searchStockLocations.execute({ target: '', storeId: storeID });
  }, []);

  useEffect(() => {
    searchProducts.execute({ target: productTargetDebounce, limit: 10 });
  }, [productTargetDebounce]);

  useEffect(() => {
    if (selectedStockLocationID && selectedProductID) {
      getProductStockBalance.execute({
        productId: selectedProductID,
        stockLocationId: selectedStockLocationID,
      });
      listStockChecks.execute({
        stockLocationId: selectedStockLocationID,
        productId: selectedProductID,
        limit: 20,
      });
    }
  }, [selectedStockLocationID, selectedProductID]);

  // const canViewStockChecks = useCanUser({
  //   requiredSystemActions: ['view_stock_checks'],
  //   storeID,
  // });

  const canViewStockChecks = true;

  return (
    <Container pt={PX64.PX} pb={PX96.PX} px={PX32.PX} size="sm">
      <Stack>
        <Title>Stock Checker</Title>
        <Collapse
          in={selectedStockLocationID === null || selectedProductID === null}
        >
          <Alert title="Check Stock" icon={<IconQuestionMark />}>
            Select a stock location and product to begin checking its stock.
          </Alert>
        </Collapse>
        <Select
          searchable
          label="Select Stock Location"
          onChange={setSelectedStockLocationID}
          nothingFound="Stock Location Not Found"
          value={selectedStockLocationID}
          data={(searchStockLocations.response?.stockLocations || []).map(
            (sl) => {
              return {
                value: sl.stockLocation.id,
                label: sl.stockLocation.name,
              };
            }
          )}
        />
        <Select
          searchable
          label="Select Product"
          clearable={true}
          icon={searchProducts.loading ? <Loader size={16} /> : undefined}
          searchValue={productSearchTarget}
          onSearchChange={setProductSearchTarget}
          onChange={setSelectedProductID}
          value={selectedProductID}
          nothingFound="Product Not Found"
          data={
            searchProducts.response?.products.map((p) => {
              return {
                value: p.product.id,
                label: p.product.name,
              };
            }) || []
          }
          filter={() => true}
        />

        <Collapse
          in={selectedStockLocationID !== null && selectedProductID !== null}
        >
          {/*<Center>*/}
          <Grid grow justify="center">
            <Grid.Col span={1}>
              <SubmitStockCheckCard
                stockBalance={getProductStockBalance.response?.stockBalance}
                stockLocation={getProductStockBalance.response?.stockLocation}
                onStockChecked={() => {
                  setSelectedProductID(null);
                }}
              />
            </Grid.Col>
            {canViewStockChecks && (
              <Grid.Col span={4}>
                <PastStockChecksCard
                  storeID={storeID}
                  stockChecks={listStockChecks.response?.stockChecks}
                />
              </Grid.Col>
            )}
          </Grid>
          {/*</Center>*/}
        </Collapse>
      </Stack>
    </Container>
  );
};

const PastStockChecksCard = ({
  stockChecks,
}: {
  storeID?: string;
  stockChecks?: {
    stockCheck: StockCheck;
    stockLocation: StockLocation;
    store: Store;
    creatingUser: User;
  }[];
}) => {
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section inheritPadding py="xs" withBorder>
        <Stack>
          <Text fw={500}>Past Stock Checks</Text>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Change</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {stockChecks?.length === 0 && (
                <tr>
                  <td colSpan={3} align="center">
                    <Text c="dimmed">No Previous Stock Checks Found</Text>
                  </td>
                </tr>
              )}
              {(stockChecks || []).map((sc) => {
                const { id, createdAt, stockBalanceChange, status } =
                  sc.stockCheck;

                return (
                  <tr key={id}>
                    <td>{RFC3339ToEasyDateTime(createdAt)}</td>
                    <td align="right">{stockBalanceChange}</td>
                    <td>
                      <StockCheckStatusBadge state={status} fullWidth />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Stack>
      </Card.Section>
    </Card>
  );
};

const SubmitStockCheckCard = ({
  stockBalance,
  onStockChecked,
}: {
  stockBalance?: StockBalance | null;
  stockLocation?: StockLocation | null;
  onStockChecked: () => void;
}) => {
  const insertStockCheck = useStatefulAPIRequestMaker(
    DefaultOperations.createStockCheck
  );
  const [newBalance, setNewBalance] = useInputState<number | ''>(0);
  const [adjustImmediately, setAdjustImmediately] =
    useInputState<boolean>(false);
  const [showBalanceChangeSection, setShowBalanceChangeSection] =
    useState(false);
  // const canAdjustImmediately = useCanUser({
  //   requiredSystemActions: ['post_stock_check'],
  //   storeID: stockLocation?.storeID,
  // });

  const canAdjustImmediately = true;

  useEffect(() => {
    if (stockBalance?.balance) {
      setNewBalance(Number(stockBalance.balance));
    } else {
      setNewBalance(0);
    }

    setAdjustImmediately(false);
    setShowBalanceChangeSection(false);
  }, [stockBalance?.balance]);

  return (
    <LoadingContainer loading={insertStockCheck.loading}>
      <Card
        shadow="sm"
        p="lg"
        radius="md"
        withBorder
        // style={{ maxWidth: PX256.PX }}
      >
        <Card.Section inheritPadding py="xs" withBorder>
          <Stack>
            <Text fw={500}>Current Stock Balance</Text>

            <Center>
              <Title order={2}>{stockBalance?.balance || 'No Balance'}</Title>
            </Center>
          </Stack>
        </Card.Section>

        {!showBalanceChangeSection && (
          <Card.Section py="sm" inheritPadding>
            <Stack spacing="xs">
              <Button
                color="green"
                fullWidth
                variant="light"
                onClick={() => {
                  openConfirmModal({
                    title: <Title order={3}>Confirm Stock Balance</Title>,
                    children: (
                      <Text size="sm">
                        Are you sure you want to confirm that the stock balance
                        is correct?
                      </Text>
                    ),
                    labels: { confirm: 'Confirm', cancel: 'Cancel' },
                    onConfirm: async () => {
                      await insertStockCheck.execute({
                        requestBody: {
                          stockLocationID: stockBalance!.stockLocationID,
                          productID: stockBalance!.productID,
                          stockBalanceChange: '0',
                          comments: '',
                          postImmediately: false,
                        },
                      });
                      showNotification({
                        color: 'green',
                        title: 'Stock Checked!',
                        message: 'Stock balance has been checked successfully.',
                      });
                      onStockChecked();
                    },
                  });
                }}
              >
                Stock is Correct
              </Button>
              <Button
                color="red"
                fullWidth
                variant="light"
                onClick={() => setShowBalanceChangeSection(true)}
              >
                Edit Stock Balance
              </Button>
            </Stack>
          </Card.Section>
        )}

        {showBalanceChangeSection && (
          <Card.Section py="sm" inheritPadding>
            <Stack>
              <NumberInput
                onChange={setNewBalance}
                value={newBalance}
                label="Actual Balance"
              />
              <Tooltip
                label="You do not have permission to adjust immediately."
                width={PX256.Number}
                multiline
                position="left"
                withArrow
                withinPortal
                disabled={canAdjustImmediately}
              >
                <Box w="fit-content">
                  <Checkbox
                    disabled={!canAdjustImmediately}
                    label="Adjust Stock Immediately"
                    checked={adjustImmediately}
                    onChange={setAdjustImmediately}
                  />
                </Box>
              </Tooltip>
              <Button
                disabled={newBalance === undefined || !stockBalance}
                variant="light"
                fullWidth
                onClick={() => {
                  openConfirmModal({
                    title: <Title order={3}>Confirm Stock Check</Title>,
                    children: (
                      <Text size="sm">
                        Are you sure you want to submit this stock check?
                      </Text>
                    ),
                    labels: { confirm: 'Confirm', cancel: 'Cancel' },
                    onConfirm: async () => {
                      await insertStockCheck.execute({
                        requestBody: {
                          stockLocationID: stockBalance!.stockLocationID,
                          productID: stockBalance!.productID,
                          stockBalanceChange: new Big(newBalance)
                            .sub(stockBalance!.balance)
                            .toString(),
                          comments: '',
                          postImmediately: adjustImmediately,
                        },
                      });
                      showNotification({
                        color: 'green',
                        title: 'Stock Checked!',
                        message: 'Stock balance has been checked successfully.',
                      });
                      onStockChecked();
                    },
                  });
                }}
              >
                Save
              </Button>
              <Button
                color="red"
                fullWidth
                variant="light"
                onClick={() => setShowBalanceChangeSection(false)}
              >
                Cancel
              </Button>
            </Stack>
          </Card.Section>
        )}
      </Card>
    </LoadingContainer>
  );
};
