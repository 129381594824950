import {
  CorePayment,
  Currency,
  DefaultOperations,
  PurchaseOrder,
  PurchaseOrderPayment,
  PurchaseOrderToPurchaseOrderPaymentRel,
  Vendor,
} from '../../../../oas/codegen3';
import {
  findCurrency,
  formatBig,
  formatToCurrency,
} from '@pcc/api/models/Currency';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import React, { useEffect, useState } from 'react';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import {
  ActionIcon,
  Button,
  Code,
  Group,
  LoadingOverlay,
  Menu,
  NumberInput,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { PX192, PX24, PX384, PX512 } from '../../../../components/Px';
import { showNotification } from '@mantine/notifications';
import { Link } from '@components/V2/Link';
import { RFC3339ToEasyDate } from '../../../../utils/dateFormatting';
import { StatusBadge } from '@components/V2/StatusBadge';
import Big from 'big.js';
import { useListSearcherHelper } from '../../../../hooks/useListSearcherHelper';
import { toBig } from '../../../../utils/toBigOrNull';
import { handleApiErrorMessage } from '../../../../utils/handleApiErrorMessage';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { IconArrowLeft, IconPlus, IconTrash } from '@tabler/icons-react';
import { IconDots } from '@tabler/icons-react';

export const PurchaseOrderPaymentsTable = ({
  purchaseOrder,
  vendor,
  payments,
  reloadPurchaseOrder,
}: {
  purchaseOrder: PurchaseOrder;
  vendor: Vendor;
  payments: {
    corePayment: CorePayment;
    purchaseOrderPayment: PurchaseOrderPayment;
    purchaseOrderToPurchaseOrderPaymentRel: PurchaseOrderToPurchaseOrderPaymentRel;
  }[];
  reloadPurchaseOrder: () => void;
}) => {
  const currency = findCurrency(purchaseOrder.currency);

  const updatePurchaseOrderPaymentState = useStatefulAPIRequestMaker(
    DefaultOperations.updatePurchaseOrderPaymentState
  );
  const listPaymentMethods = useStatefulAPIRequestMaker(
    DefaultOperations.getPaymentMethods
  );

  const deletePoPaymentLink = useStatefulAPIRequestMaker(
    DefaultOperations.deletePurchaseOrderToPurchaseOrderPaymentLink
  );

  useEffect(() => {
    listPaymentMethods.execute({
      targetPaymentType: 'purchase_order_payment',
      enabled: true,
    });
  }, []);

  const showLinkPaymentModal = () => {
    openModal({
      modalId: 'link-payment-modal',
      title: <Title order={3}>Link Payment</Title>,
      size: PX512.Number,
      closeOnClickOutside: false,
      children: (
        <LinkPaymentModal
          purchaseOrder={purchaseOrder}
          vendor={vendor}
          onPaymentLinked={() => {
            closeModal('link-payment-modal');
            reloadPurchaseOrder();
          }}
        />
      ),
    });
  };

  const unlinkPayment = ({
    purchaseOrderID,
    paymentID,
  }: {
    purchaseOrderID: string;
    paymentID: string;
  }) => {
    openConfirmModal({
      title: <Title order={3}>Unlink Payment</Title>,
      children: (
        <Text size="sm">
          Are you sure you want to delete this payment link? You can always add
          it back later.
        </Text>
      ),
      labels: { confirm: 'Yes - Unlink', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        await deletePoPaymentLink.execute({
          purchaseOrderId: purchaseOrderID,
          purchaseOrderPaymentId: paymentID,
        });

        showNotification({
          color: 'green',
          title: 'Payment Unlinked',
          message: 'The payment has been unlinked.',
        });
        reloadPurchaseOrder();
      },
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay
        visible={updatePurchaseOrderPaymentState.loading}
        overlayBlur={2}
      />
      <Stack spacing={PX24.PX}>
        <Group>
          <Button
            variant="light"
            leftIcon={<IconPlus />}
            onClick={showLinkPaymentModal}
          >
            Link Payment
          </Button>
        </Group>
        <Table>
          <thead>
            <tr>
              <th>Payment Reference</th>
              <th>Create Date</th>
              <th>Status</th>
              <th>Posted At</th>
              <th>Amount</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {payments.map((p) => {
              const paymentState = p.corePayment.state;
              const paymentID = p.purchaseOrderPayment.id;
              const paymentReference = p.purchaseOrderPayment.reference;
              const createdAt = p.purchaseOrderPayment.createdAt;
              const postedAt = p.corePayment.postedAt;
              const amount = p.purchaseOrderToPurchaseOrderPaymentRel.amount;

              const dateToShow = postedAt;

              return (
                <tr key={paymentID}>
                  <td>
                    <Link to={`/purchaseOrders/payments/${paymentID}`} withIcon>
                      <Code>{paymentReference}</Code>
                    </Link>
                  </td>
                  <td>{RFC3339ToEasyDate(createdAt)}</td>
                  <td>
                    <StatusBadge state={paymentState} />
                  </td>
                  <td>{dateToShow ? RFC3339ToEasyDate(dateToShow) : ''}</td>
                  <td>{formatBig(Big(amount), currency)}</td>
                  <td>
                    <Menu shadow="md">
                      <Menu.Target>
                        <ActionIcon>
                          <IconDots />
                        </ActionIcon>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item
                          color="red"
                          icon={<IconTrash />}
                          onClick={() =>
                            unlinkPayment({
                              purchaseOrderID: purchaseOrder.id,
                              paymentID,
                            })
                          }
                        >
                          Unlink Payment
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Stack>
    </div>
  );
};

const LinkPaymentModal = ({
  purchaseOrder,
  vendor,
  onPaymentLinked,
}: {
  purchaseOrder: PurchaseOrder;
  vendor: Vendor;
  onPaymentLinked: () => void;
}) => {
  const [amountToLink, setAmountToLink] = useState<number | ''>('');
  const [paymentToLink, setPaymentToLink] =
    useState<{
      corePayment: CorePayment;
      purchaseOrderPayment: PurchaseOrderPayment;
      currency: Currency;
    } | null>(null);
  const listPurchaseOrderPayments = useStatefulAPIRequestMaker(
    DefaultOperations.listPurchaseOrderPayments
  );

  const linkPurchaseOrderToPayment = useStatefulAPIRequestMaker(
    DefaultOperations.linkPurchaseOrderToPurchaseOrderPayment
  );

  const { target, onChangeDeps, setTargetFromInput, pagination } =
    useListSearcherHelper({
      debounceDelay: 300,
      limitPerPage: 100,
    });

  useEffect(() => {
    listPurchaseOrderPayments.execute({
      vendorId: vendor.id,
      target,
      limit: pagination.limit,
      offset: pagination.offset,
      hasBalance: true,
      state: 'posted',
    });
  }, [...onChangeDeps]);

  const clearPaymentToLink = () => {
    setPaymentToLink(null);
    setAmountToLink('');
  };

  const paymentToLinkBalance = toBig(
    paymentToLink?.corePayment.amountBalance,
    0
  );
  const poBalance = toBig(purchaseOrder.balanceAmount, 0);

  const amountToLinkCorrect =
    toBig(amountToLink, 0).lte(poBalance) &&
    toBig(amountToLink, 0).lte(paymentToLinkBalance) &&
    toBig(amountToLink, 0).gt(0);

  const linkPayment = async () => {
    if (!amountToLink || !paymentToLink) {
      return;
    }

    try {
      await linkPurchaseOrderToPayment.execute({
        requestBody: {
          purchaseOrderID: purchaseOrder.id,
          purchaseOrderPaymentID: paymentToLink?.purchaseOrderPayment.id,
          amount: String(amountToLink),
        },
      });

      showNotification({
        color: 'green',
        title: `Payment Linked`,
        message: `The payment has been linked.`,
      });

      onPaymentLinked();
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  return (
    <LoadingContainer
      loading={linkPurchaseOrderToPayment.loading}
      minHeight={PX384.Number}
    >
      {!paymentToLink && (
        <Stack>
          <TextInput
            label="Search"
            placeholder="Search"
            value={target}
            onChange={setTargetFromInput}
          />

          <LoadingContainer
            loading={listPurchaseOrderPayments.loading}
            minHeight={PX192.Number}
          >
            <Table>
              <thead>
                <tr>
                  <th>Payment Reference</th>
                  <th>Amount</th>
                  <th>Balance</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {(
                  listPurchaseOrderPayments.response?.purchaseOrderPayments ||
                  []
                ).map((p) => {
                  const setPaymentToFocus = () => {
                    setPaymentToLink({
                      purchaseOrderPayment: p.purchaseOrderPayment,
                      corePayment: p.corePayment,
                      currency: p.currency,
                    });

                    const paymentBalance = toBig(
                      p.corePayment.amountBalance,
                      0
                    );
                    const poBalance = toBig(purchaseOrder.balanceAmount, 0);

                    setAmountToLink(
                      paymentBalance.gt(poBalance)
                        ? poBalance.toNumber()
                        : paymentBalance.toNumber()
                    );
                  };

                  return (
                    <tr key={p.purchaseOrderPayment.id}>
                      <td>
                        <Code>{p.purchaseOrderPayment.reference}</Code>
                      </td>
                      <td>
                        {formatToCurrency(p.corePayment.amount, p.currency)}
                      </td>
                      <td>
                        {formatToCurrency(
                          p.corePayment.amountBalance,
                          p.currency
                        )}
                      </td>
                      <td>
                        <Button
                          compact
                          color="link"
                          variant="subtle"
                          onClick={setPaymentToFocus}
                        >
                          Link
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </LoadingContainer>
        </Stack>
      )}

      {paymentToLink && (
        <Stack>
          <Group>
            <Button
              variant="light"
              onClick={clearPaymentToLink}
              color="red"
              leftIcon={<IconArrowLeft />}
            >
              Back
            </Button>
          </Group>
          <Text>
            Payment Reference:{' '}
            <Code>{paymentToLink.purchaseOrderPayment.reference}</Code>
          </Text>
          <TextInput
            readOnly
            value={formatToCurrency(
              paymentToLink.corePayment.amount,
              paymentToLink.currency
            )}
            label="Payment Amount"
          />
          <TextInput
            readOnly
            value={formatToCurrency(
              paymentToLink.corePayment.amountBalance,
              paymentToLink.currency
            )}
            label="Payment Balance"
          />
          <TextInput
            readOnly
            value={formatToCurrency(
              purchaseOrder.balanceAmount ?? 0,
              paymentToLink.currency
            )}
            label="Purchase Order Balance"
          />
          <NumberInput
            min={0}
            precision={paymentToLink.currency.decimalPlaces}
            value={amountToLink}
            onChange={setAmountToLink}
            label="Amount to Apply"
            placeholder="Amount to Apply"
          />
          <Button
            color="green"
            disabled={!amountToLinkCorrect}
            onClick={linkPayment}
          >
            Link Payment
          </Button>
        </Stack>
      )}
    </LoadingContainer>
  );
};
