import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Checkbox,
  Flex,
  Group,
  Input,
  LoadingOverlay,
  Stack,
  Switch,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
  Alert,
  Loader,
  Center,
  Space,
  Anchor,
  NumberInput,
  Menu,
  ActionIcon,
  Badge,
  Box,
} from '@mantine/core';
import {
  IconAlertTriangle,
  IconCash,
  IconCheck,
  IconCopy,
  IconDots,
  IconExternalLink,
  IconFocus2,
  IconGraph,
  IconHistory,
  IconPencil,
  IconPercentage,
  IconX,
} from '@tabler/icons-react';
import { formatToCurrency } from '../../../models/Currency';
import {
  PX1024,
  PX192,
  PX256,
  PX32,
  PX4,
  PX48,
  PX64,
  PX640,
  PX96,
} from '../../../components/Px';
import { showNotification } from '@mantine/notifications';
import { Tax } from '@pcc/api/models/Tax';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from '@mantine/form';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import {
  DefaultOperations,
  Product,
  ProductClassCode,
  ProductGenericCode,
  ProductPurchasingCost,
  Store,
  Vendor,
} from '../../../oas/codegen3';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { useInputState } from '@mantine/hooks';
import { VendorSelectorModalBody } from '@components/V2/modal_selector/VendorSelectorModalBody';
import { calculateAmounts } from '../../../utils/calculateAmounts';
import { toBigOrNull } from '../../../utils/toBigOrNull';
import { emptyStringToNull } from '../../../utils/emptyStringToNull';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { ChangelogsTimelines } from '@components/V2/ChangelogsTimelines';
import { handleApiErrorMessage } from '../../../utils/handleApiErrorMessage';

import { RFC3339ToEasyDate } from '../../../utils/dateFormatting';
import { StoreSelectorModalBody } from '@components/V2/modal_selector/StoreSelectorModalBody';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { ProductPurchasingAndSalesChart } from './components/ProductPurchasingAndSalesChart';
import { useFindPrivileges } from '../../../pccstores/UserUtils';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip as RechartTooltip,
} from 'recharts';
import { ProductPricesManager } from './components/ProductPricesManager';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const ProductForm = ({
  productID,
}: {
  path: string;
  productID?: string;
}) => {
  const defaultTab = useQueryParams('tab') ?? 'editProduct';
  const getProduct = useStatefulAPIRequestMaker(DefaultOperations.getProduct);
  useDocumentTitle(
    productID ? 'Product: ' + getProduct.response?.product.name : 'New Products'
  );
  const createProduct = useStatefulAPIRequestMaker(
    DefaultOperations.createProduct
  );

  const updateProduct = useStatefulAPIRequestMaker(
    DefaultOperations.updateProduct
  );
  const setProductEnabled = useStatefulAPIRequestMaker(
    DefaultOperations.setProductEnabled
  );

  const [isProductEnabled, setIsProductEnabled] = useState(true);

  const navigate = useNavigate();

  const busyWithAPICalls =
    setProductEnabled.loading ||
    createProduct.loading ||
    getProduct.loading ||
    updateProduct.loading;

  const form = useForm<{
    name: string;
    barcode?: string | null;
    reference: string;
    isFreshProduct: boolean;
    earnsPoints: boolean;
    genericCode: string | null;
    classCode: string | null;
    primaryVendor?: Vendor | null;
  }>({
    initialValues: {
      name: '',
      reference: '',
      isFreshProduct: false,
      earnsPoints: true,
      genericCode: null,
      classCode: null,
    },
  });

  const vanViewProductMargin = useFindPrivileges({
    action: 'read',
    resource: 'product.margin',
  });

  const loadProduct = async () => {
    try {
      const response = await getProduct.execute({
        productId: productID!,
        includeTargetMargin: vanViewProductMargin.hasPrivilege,
      });

      form.setValues({
        name: response.product.name,
        barcode: response.product.barcode,
        reference: response.product.reference || '',
        isFreshProduct: response.product.isFreshProduct,
        genericCode: response.product.genericCode,
        classCode: response.product.classCode,
        earnsPoints: response.product.earnsPoints,
        primaryVendor: response.primaryVendor,
      });

      setIsProductEnabled(response.product.enabled);
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  useEffect(() => {
    if (productID) {
      loadProduct();
    }
  }, [productID]);

  const switchProductEnabled = async () => {
    const newEnabled = !isProductEnabled;
    await setProductEnabled.execute({
      productId: productID!,
      requestBody: {
        enabled: newEnabled,
      },
    });
    setIsProductEnabled(newEnabled);
  };

  const onSaveOrUpdate = form.onSubmit(async (values) => {
    if (productID) {
      await updateProduct.execute({
        productId: productID,
        requestBody: {
          name: values.name,
          barcode: emptyStringToNull(values.barcode),
          reference: emptyStringToNull(values.reference),
          isFreshProduct: values.isFreshProduct!,
          genericCode: values.genericCode,
          classCode: values.classCode,
          earnsPoints: values.earnsPoints,
          primaryVendorID: values.primaryVendor?.id,
        },
      });

      showNotification({
        color: 'green',
        title: 'Product Updated',
        message: 'The product has been updated.',
      });
    } else {
      const response = await createProduct.execute({
        requestBody: {
          name: values.name,
          barcode: emptyStringToNull(values.barcode),
          reference: emptyStringToNull(values.reference),
          isFreshProduct: values.isFreshProduct!,
          genericCode: values.genericCode,
          classCode: values.classCode,
          earnsPoints: values.earnsPoints,
          primaryVendorID: values.primaryVendor?.id,
        },
      });
      showNotification({
        color: 'green',
        title: 'Product Created',
        message: 'The product has been created.',
      });
      navigate(`/products/${response.product.id}`);
    }
  });

  const onGenericCodeInputClicked = () => {
    const modalID = uuidv4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Select Generic Code</Title>,
      children: (
        <ProductGenericCodeSelectorModalBody
          onProductGenericCodeSelected={(gc) => {
            form.setValues({ genericCode: gc.genericCode });
            closeModal(modalID);
          }}
        />
      ),
    });
  };

  const onClassCodeInputClicked = () => {
    const modalID = uuidv4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Select Class Code</Title>,
      children: (
        <ProductClassCodeSelector
          onProductClassCodeSelected={(cc) => {
            form.setValues({ classCode: cc.classCode });
            closeModal(modalID);
          }}
        />
      ),
    });
  };

  const showPrimaryVendorModal = () => {
    const modalID = uuidv4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Primary Vendor</Title>,
      children: (
        <VendorSelectorModalBody
          onVendorSelected={(v) => {
            form.setValues({ primaryVendor: v });
            closeModal(modalID);
          }}
        />
      ),
    });
  };

  const showProductChangelogModal = () => {
    if (!productID) {
      return;
    }

    openModal({
      modalId: 'changelogs-modal',
      title: <Title order={3}>Changelogs</Title>,
      size: PX640.Number,
      children: <ChangelogModal productID={productID} />,
    });
  };

  const canViewProfitability = useFindPrivileges({
    resource: 'profitability_analytics',
    action: 'read',
  });

  const canViewPurchasingCosts = useFindPrivileges({
    resource: 'product.purchasing_cost',
    action: 'read',
  }).hasPrivilege;

  const canWritePurchaseOrder = useFindPrivileges({
    resource: 'purchase_order',
    action: 'write',
  }).hasPrivilege;

  const canWriteFreshPurchaseOrder = useFindPrivileges({
    resource: 'fresh_purchase_order',
    action: 'write',
  }).hasPrivilege;

  const showProductPurchaseCosts =
    canViewPurchasingCosts ||
    canWritePurchaseOrder ||
    canWriteFreshPurchaseOrder;

  const showSimilarProducts = useFindPrivileges({
    resource: 'foreign_products',
    action: 'read',
  });

  const updateProductName = async (newName: string) => {
    if (!getProduct.response || !productID || !newName) {
      return;
    }

    try {
      await updateProduct.execute({
        productId: productID,
        requestBody: {
          name: newName,
          barcode: getProduct.response.product.barcode ?? null,
          reference: getProduct.response.product.reference ?? null,
          isFreshProduct: getProduct.response.product.isFreshProduct,
          genericCode: getProduct.response.product.genericCode ?? null,
          classCode: getProduct.response.product.classCode ?? null,
          earnsPoints: getProduct.response.product.earnsPoints,
          primaryVendorID: getProduct.response.primaryVendor?.id,
        },
      });

      form.setValues({ name: newName });
      showNotification({
        color: 'green',
        title: 'Product Name Updated',
        message: "The product's name has been updated.",
      });
    } catch (e) {
      handleApiErrorMessage(e);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={busyWithAPICalls} />
      <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
        <Stack>
          <Group>
            <Button
              compact
              variant="light"
              color="blue"
              leftIcon={<IconHistory size={14} />}
              onClick={showProductChangelogModal}
            >
              History
            </Button>
          </Group>
          <Stack spacing={0}>
            {productID ? (
              <Stack spacing={0}>
                <Text size="sm" fw={700}>
                  Product
                </Text>
                <Title>
                  {getProduct.response?.product.name ?? 'Loading...'}
                </Title>
              </Stack>
            ) : (
              <Title>New Product</Title>
            )}
          </Stack>
          <Tabs defaultValue={defaultTab}>
            <Tabs.List>
              <Tabs.Tab value="editProduct" icon={<IconPencil size={14} />}>
                {productID ? 'Edit Product' : 'Create Product'}
              </Tabs.Tab>

              {productID && getProduct.response && (
                <Tabs.Tab value="prices" icon={<IconCash size={14} />}>
                  Price{showProductPurchaseCosts ? ' & Cost' : ''}
                </Tabs.Tab>
              )}

              {productID && getProduct.response && (
                <Tabs.Tab value="taxes" icon={<IconPercentage size={14} />}>
                  Taxes
                </Tabs.Tab>
              )}

              {productID && canViewProfitability.hasPrivilege && (
                <Tabs.Tab
                  value="productAnalytics"
                  icon={<IconGraph size={14} />}
                >
                  Analytics
                </Tabs.Tab>
              )}

              {getProduct.response?.productTargetMargin !== undefined && (
                <Tabs.Tab value="targetMargin" icon={<IconFocus2 size={14} />}>
                  Target Margin
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="editProduct" pt="lg">
              <Stack>
                <Flex align="center" gap="xl">
                  {productID && (
                    <Switch
                      onChange={switchProductEnabled}
                      checked={isProductEnabled}
                      color={'green'}
                      label="Enabled"
                      description="When disabled, the product cannot be sold."
                      thumbIcon={
                        isProductEnabled ? (
                          <IconCheck size={12} color="green " />
                        ) : (
                          <IconX size={12} color="red" />
                        )
                      }
                    />
                  )}
                </Flex>
                <form onSubmit={onSaveOrUpdate}>
                  <Stack>
                    <TextInput
                      label="Product Name"
                      required={true}
                      {...form.getInputProps('name')}
                    />
                    <TextInput
                      label="Reference"
                      {...form.getInputProps('reference')}
                    />

                    <TextInput
                      label="Barcode"
                      placeholder="Product Barcode"
                      {...form.getInputProps('barcode')}
                    />
                    <TextInput
                      label="Generic Code"
                      placeholder="Product Generic Code"
                      onClick={onGenericCodeInputClicked}
                      rightSection={
                        <IconX
                          size={14}
                          stroke={1.5}
                          onClick={() => {
                            form.setValues({ genericCode: null });
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      }
                      value={form.values.genericCode || ''}
                      readOnly={true}
                    />
                    <TextInput
                      label="Class Code"
                      placeholder="Product Class Code"
                      onClick={onClassCodeInputClicked}
                      rightSection={
                        <IconX
                          size={14}
                          stroke={1.5}
                          onClick={() => {
                            form.setValues({ classCode: null });
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      }
                      value={form.values.classCode || ''}
                      readOnly={true}
                    />

                    <TextInput
                      label="Primary Vendor"
                      placeholder="Primary Vendor"
                      onClick={showPrimaryVendorModal}
                      rightSection={
                        <IconX
                          size={14}
                          stroke={1.5}
                          onClick={() => {
                            form.setValues({ primaryVendor: null });
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      }
                      value={form.values.primaryVendor?.name || ''}
                      readOnly={true}
                    />

                    <Alert
                      title="Fresh Product"
                      variant="outline"
                      icon={<IconAlertTriangle />}
                    >
                      <Stack>
                        <Text>
                          Fresh products do not require an LPO for them to be
                          collected. These are items that are restocked daily.
                          If an LPO is required to collect this product, disable
                          the checkbox below.
                        </Text>
                        <Checkbox
                          label="Is Fresh Product"
                          checked={form.values.isFreshProduct}
                          {...form.getInputProps('isFreshProduct')}
                        />
                      </Stack>
                    </Alert>

                    <Alert
                      title="Earns Points"
                      variant="outline"
                      icon={<IconAlertTriangle />}
                    >
                      <Stack>
                        <Text>
                          Set whether members can earn points from buying this
                          product.
                        </Text>
                        <Checkbox
                          label="Earns Points"
                          checked={form.values.earnsPoints}
                          {...form.getInputProps('earnsPoints')}
                        />
                      </Stack>
                    </Alert>

                    <Group>
                      <Button color="green" type="submit">
                        {productID ? 'Update' : 'Create'}
                      </Button>
                    </Group>
                  </Stack>
                </form>
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="prices" pt="lg">
              {productID && getProduct.response && (
                <Stack spacing={PX48.Number}>
                  <ProductPricesManager
                    productID={productID}
                    saleTaxes={getProduct.response.saleTaxes}
                  />

                  {showSimilarProducts.hasPrivilege && (
                    <SimilarCompetitorProducts
                      product={getProduct.response.product}
                      updateProductName={updateProductName}
                    />
                  )}

                  {showProductPurchaseCosts && (
                    <PurchasingCosts
                      reloadProduct={loadProduct}
                      productID={productID}
                      purchasingTaxes={getProduct.response.purchasingTaxes}
                      strictPurchasingUnitPrice={
                        getProduct.response.product.strictPurchasingUnitPrice
                      }
                    />
                  )}
                </Stack>
              )}
            </Tabs.Panel>

            {productID && (
              <Tabs.Panel value="productAnalytics" pt="lg">
                <ProductAnalytics productID={productID} />
              </Tabs.Panel>
            )}

            {productID && getProduct.response && (
              <Tabs.Panel value="taxes" pt="lg">
                <Stack>
                  <Title order={3}>Sale Taxes</Title>
                  <ProductTaxes
                    productID={productID}
                    taxType="sales"
                    taxes={getProduct.response.saleTaxes}
                    reloadProduct={loadProduct}
                  />

                  <Space h={PX32.Number} />

                  <Title order={3}>Purchasing Taxes</Title>

                  <ProductTaxes
                    productID={productID}
                    taxType="purchasing"
                    taxes={getProduct.response.purchasingTaxes}
                    reloadProduct={loadProduct}
                  />
                </Stack>{' '}
              </Tabs.Panel>
            )}

            {getProduct.response?.productTargetMargin !== undefined &&
              getProduct.response.product && (
                <Tabs.Panel value="targetMargin" pt="lg">
                  <TargetMarginTab
                    targetMargin={getProduct.response.productTargetMargin}
                    product={getProduct.response.product}
                  />
                </Tabs.Panel>
              )}
          </Tabs>
        </Stack>
      </Container>
    </div>
  );
};

const ProductAnalytics = ({ productID }: { productID: string }) => {
  const [store, setStore] = useState<Store | null>(null);

  const showStoreSelectorModal = () => {
    openModal({
      modalId: 'store-selector-modal',
      title: 'Select Store',
      children: (
        <StoreSelectorModalBody
          onStoreSelected={(v) => {
            setStore(v);
            closeModal('store-selector-modal');
          }}
        />
      ),
    });
  };

  return (
    <Stack spacing={PX48.Number}>
      <Stack>
        <TextInput
          label="Store"
          placeholder="Store"
          onClick={showStoreSelectorModal}
          value={store?.name ?? ''}
          readOnly={true}
          rightSection={
            <IconX
              size={14}
              stroke={1.5}
              onClick={() => {
                setStore(null);
              }}
              style={{ cursor: 'pointer' }}
            />
          }
        />

        <Title order={3}>Monthly PCs Purchased & Sold</Title>

        <ProductPurchasingAndSalesChart
          productID={productID}
          storeID={store?.id}
        />
      </Stack>

      <ProductSellingPriceDistributionChart
        productID={productID}
        currency="BHD"
        storeID={store?.id}
      />
    </Stack>
  );
};

const PurchasingCosts = ({
  productID,
  strictPurchasingUnitPrice,
  purchasingTaxes,
  reloadProduct,
}: {
  productID: string;
  strictPurchasingUnitPrice: boolean;
  purchasingTaxes: Tax[];
  reloadProduct: () => void;
}) => {
  const setStrictPurchasingUnitPrice = useStatefulAPIRequestMaker(
    DefaultOperations.setProductStrictPurchasingCost
  );
  const listPurchasingCosts = useStatefulAPIRequestMaker(
    DefaultOperations.listProductPurchasingCost
  );

  useEffect(() => {
    const p = listPurchasingCosts.execute({
      productId: productID,
      currency: 'BHD',
    });

    return () => p.cancel();
  }, [productID]);

  const purchasingCostsArr =
    listPurchasingCosts.response?.productPurchasingCosts ?? [];
  const currency = listPurchasingCosts.response?.currency;

  const showPurchasingCostModal = ({
    purchasingCost,
  }: {
    purchasingCost?: ProductPurchasingCost;
  }) => {
    openModal({
      modalId: 'purchasing-cost-modal',
      title: (
        <Title order={3}>
          {purchasingCost ? 'Edit Purchasing Cost' : 'New Purchasing Cost'}
        </Title>
      ),
      children: (
        <PurchasingCostForm
          existingPurchasingCosts={purchasingCostsArr.map(
            (pc) => pc.productPurchasingCost
          )}
          productID={productID}
          purchasingCost={purchasingCost}
          onCreatedOrUpdated={() => {
            listPurchasingCosts.execute({
              productId: productID,
              currency: 'BHD',
            });
            closeModal('purchasing-cost-modal');
          }}
        />
      ),
    });
  };

  const canWriteCost = useFindPrivileges({
    action: 'write',
    resource: 'product.purchasing_cost',
  });

  const showConfirmSetStrictPurchasingUnitPrice = ({
    enabled,
  }: {
    enabled: boolean;
  }) => {
    openConfirmModal({
      title: (
        <Title order={3}>
          {enabled ? 'Enable' : 'Disable'} Strict Purchasing Unit Price
        </Title>
      ),
      children: (
        <Text>
          Are you sure you want to{' '}
          {enabled ? (
            <Text color="green" span tt="uppercase" fw={700}>
              Enable
            </Text>
          ) : (
            <Text color="red" span tt="uppercase" fw={700}>
              Disable
            </Text>
          )}{' '}
          strict purchasing unit price?
        </Text>
      ),
      confirmProps: {
        color: enabled ? 'green' : 'red',
      },
      labels: {
        confirm: enabled ? 'Yes - Enable' : 'Yes - Disable',
        cancel: 'Cancel',
      },
      onConfirm: async () => {
        await setStrictPurchasingUnitPrice.execute({
          productId: productID,
          requestBody: {
            strictPurchasingUnitPrice: enabled,
          },
        });

        reloadProduct();
      },
    });
  };

  const canModifyStrictPurchasing = useFindPrivileges({
    action: 'write',
    resource: 'product.strict_purchasing_unit_cost',
  }).hasPrivilege;

  return (
    <Stack>
      <Title order={3}>Purchasing</Title>
      <Alert
        hidden={true}
        title={`Strict Purchasing Unit Price ${
          strictPurchasingUnitPrice ? 'ENABLED' : 'DISABLED'
        }`}
        icon={strictPurchasingUnitPrice ? <IconCheck /> : <IconAlertTriangle />}
        color={strictPurchasingUnitPrice ? 'green' : 'red'}
        // variant="outline"
      >
        <Stack>
          {setStrictPurchasingUnitPrice.loading && (
            <Center>
              <Loader />
            </Center>
          )}
          {strictPurchasingUnitPrice && (
            <Text>
              Strict purchasing is enabled. All purchases of this product must
              be equal to or less than than one of the unit prices below.
            </Text>
          )}
          {!strictPurchasingUnitPrice && (
            <Text>
              Strict purchasing is disabled. This product can be purchased at
              any price and case of size.
            </Text>
          )}
          {canModifyStrictPurchasing && (
            <Group>
              <Button
                color={strictPurchasingUnitPrice ? 'red' : 'green'}
                onClick={() =>
                  showConfirmSetStrictPurchasingUnitPrice({
                    enabled: !strictPurchasingUnitPrice,
                  })
                }
              >
                {strictPurchasingUnitPrice ? 'Disable' : 'Enable'} Strict
                Purchasing
              </Button>
            </Group>
          )}
        </Stack>
      </Alert>

      {canWriteCost.hasPrivilege && (
        <Group>
          <Button onClick={() => showPurchasingCostModal({})}>
            New Purchasing Cost
          </Button>
        </Group>
      )}

      <LoadingContainer loading={listPurchasingCosts.loading}>
        <Table withBorder striped>
          <thead>
            <tr>
              <th style={{ textAlign: 'right' }}>Case of</th>
              <th style={{ textAlign: 'right' }}>Unit Cost</th>
              <th style={{ textAlign: 'right' }}>Unit Cost (After Taxes)</th>
              {canWriteCost.hasPrivilege && <th />}
            </tr>
          </thead>

          <tbody>
            {currency &&
              purchasingCostsArr.map((pc) => {
                const amounts = calculateAmounts({
                  currency: currency,
                  quantity: 1,
                  taxIncludedInPrice: false,
                  taxes: purchasingTaxes,
                  unitPrice: pc.productPurchasingCost.unitCost,
                });

                return (
                  <tr key={pc.productPurchasingCost.id}>
                    <td style={{ textAlign: 'right' }}>
                      {pc.productPurchasingCost.caseOf}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(
                        pc.productPurchasingCost.unitCost,
                        currency
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {formatToCurrency(amounts.total, currency)}
                    </td>
                    {canWriteCost.hasPrivilege && (
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          variant="subtle"
                          compact
                          onClick={() => {
                            showPurchasingCostModal({
                              purchasingCost: pc.productPurchasingCost,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};

const PurchasingCostForm = ({
  productID,
  purchasingCost,
  onCreatedOrUpdated,
  existingPurchasingCosts,
}: {
  productID: string;
  purchasingCost?: ProductPurchasingCost;
  onCreatedOrUpdated: () => void;
  existingPurchasingCosts: ProductPurchasingCost[];
}) => {
  const updatePurchasingCost = useStatefulAPIRequestMaker(
    DefaultOperations.updateProductPurchasingCost
  );

  const createPurchasingCost = useStatefulAPIRequestMaker(
    DefaultOperations.createProductPurchasingCost
  );

  const form = useForm<{ caseOf: number | ''; unitCost: number | '' }>();

  useEffect(() => {
    if (purchasingCost) {
      form.setValues({
        caseOf: Number(purchasingCost.caseOf),
        unitCost: Number(purchasingCost.unitCost),
      });
    }
  }, [purchasingCost]);

  const onSubmit = form.onSubmit(async (values) => {
    try {
      if (purchasingCost) {
        await updatePurchasingCost.execute({
          productPurchasingCostId: purchasingCost.id,
          requestBody: {
            caseOf: values.caseOf.toString(),
            unitCost: values.unitCost.toString(),
          },
        });

        showNotification({
          color: 'green',
          title: 'Purchasing Cost Updated',
          message: 'Product purchasing cost has been added.',
        });
      } else {
        await createPurchasingCost.execute({
          requestBody: {
            productID: productID,
            currency: 'BHD',
            caseOf: values.caseOf.toString(),
            unitCost: values.unitCost.toString(),
          },
        });

        showNotification({
          color: 'green',
          title: 'Purchasing Cost Added',
          message: 'Product purchasing cost has been added.',
        });
      }
      onCreatedOrUpdated();
    } catch (e) {
      handleApiErrorMessage(e);
    }
  });

  const caseOfConflict = existingPurchasingCosts.find(
    (pc) =>
      form.values.caseOf &&
      pc.caseOf.toString() === form.values.caseOf.toString() &&
      pc.id !== purchasingCost?.id
  );

  return (
    <Stack>
      <form onSubmit={onSubmit}>
        <Stack>
          <NumberInput
            label="Case Of"
            precision={0}
            placeholder="Case Of"
            {...form.getInputProps('caseOf')}
            error={
              caseOfConflict
                ? `Cost for case of ${form.values.caseOf} already exists`
                : undefined
            }
          />
          <NumberInput
            label="Unit Cost"
            precision={3}
            placeholder="Unit Cost"
            {...form.getInputProps('unitCost')}
          />
          <Button type="submit" color="green" disabled={!!caseOfConflict}>
            {purchasingCost ? 'Update Cost' : 'Add Cost'}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

const ProductSellingPriceDistributionChart = ({
  currency,
  productID,
  storeID,
}: {
  productID: string;
  storeID?: string;
  currency: string;
}) => {
  const getProductSellingPriceStats = useStatefulAPIRequestMaker(
    DefaultOperations.getProductSellingPriceStats
  );

  const canViewProfitability = useFindPrivileges({
    resource: 'profitability_analytics',
    action: 'read',
  });

  useEffect(() => {
    if (canViewProfitability.hasPrivilege) {
      const p = getProductSellingPriceStats.execute({
        productId: productID,
        currency,
        storeId: storeID,
      });

      return () => p.cancel();
    }
  }, [productID, currency, storeID]);

  if (!canViewProfitability.hasPrivilege) {
    return null;
  }

  const data = (
    getProductSellingPriceStats.response?.sellingStatistics || []
  ).map((stat) => {
    return {
      name: `Case of ${stat.caseOf}`,
      distinctSales: Number(stat.distinctSales),
      preTaxTotal: Number(stat.preTaxTotalSold),
    };
  });

  const currencyLabelFormatter = (v: any) => {
    if (!getProductSellingPriceStats.response?.currency) {
      return '';
    }
    return formatToCurrency(v, getProductSellingPriceStats.response.currency);
  };

  const COLORS = ['#F76707', '#37B24D', '#7048E8', '#4263EB', '#1C7ED6'];

  return (
    <Stack>
      <Stack spacing={0}>
        <Title order={3}>Sales by Case Of</Title>
        <Text c="dimmed">
          Shows the number of sales and total sale values by case of size from,
          at most, the last 50 sales.
        </Text>
      </Stack>
      {!getProductSellingPriceStats.loading && data.length == 0 && (
        <Alert title="No Sales">
          <Text>
            There have been no sales for this item. When this item is sold,
            sales by case of will be shown here.
          </Text>
        </Alert>
      )}
      {getProductSellingPriceStats.loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!getProductSellingPriceStats.loading && data.length > 0 && (
        <div style={{ position: 'relative', height: PX256.Number }}>
          <Group h={PX256.Number} position="center" spacing={PX192.Number}>
            <PieChart width={PX256.Number} height={PX256.Number}>
              <RechartTooltip formatter={(v) => `${v} Sales`} />
              <Legend />
              <Pie
                dataKey="distinctSales"
                data={data}
                outerRadius={60}
                fill="#8884d8"
              >
                {(data || []).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>

            <PieChart width={PX256.Number} height={PX256.Number}>
              <RechartTooltip formatter={currencyLabelFormatter} />
              <Legend />
              <Pie
                dataKey="preTaxTotal"
                data={data}
                outerRadius={60}
                fill="#82ca9d"
              >
                {(data || []).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </Group>
        </div>
      )}
    </Stack>
  );
};

const SimilarCompetitorProducts = ({
  product,
  updateProductName,
}: {
  product: Product;
  updateProductName?: (s: string) => void;
}) => {
  const listProductSimilarForeignProducts = useStatefulAPIRequestMaker(
    DefaultOperations.listProductSimilarForeignProducts
  );

  useEffect(() => {
    const p = listProductSimilarForeignProducts.execute({
      productId: product.id,
      currency: 'BHD',
    });
    return () => p.cancel();
  }, [product.id]);

  const foreignProductsArr =
    listProductSimilarForeignProducts.response?.similarForeignProducts ?? [];
  const currency = listProductSimilarForeignProducts.response?.currency;

  const showConfirmUpdateProductName = (newName: string) => {
    if (!updateProductName) {
      return;
    }

    openConfirmModal({
      title: <Title order={3}>Change Product Name</Title>,
      children: (
        <Stack>
          <Text>Are you sure you want change this product's name?</Text>

          <Table withBorder striped>
            <tbody>
              <tr>
                <td>Current Name</td>
                <td>
                  <Text span fw={700}>
                    {product.name}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>New Name</td>
                <td>
                  <Text span fw={700}>
                    {newName}
                  </Text>
                </td>
              </tr>
            </tbody>
          </Table>
        </Stack>
      ),
      labels: {
        confirm: 'Yes - Change Name',
        cancel: 'Cancel',
      },
      onConfirm: () => {
        updateProductName(newName);
      },
    });
  };

  return (
    <Stack>
      <Stack spacing={0}>
        <Title order={3}>Competitor Prices</Title>
        <Text c="dimmed">
          Below are prices of products with{' '}
          <Text span italic>
            similar
          </Text>{' '}
          names to{' '}
          <Text span fw={700}>
            {product.name}
          </Text>
          .
        </Text>
      </Stack>
      <LoadingContainer loading={listProductSimilarForeignProducts.loading}>
        <Box mih={PX32.Number}>
          <Table withBorder striped>
            <thead>
              <tr>
                <th>Name</th>
                <th />
                <th>Store</th>
                <th>Price</th>
                <th>Synced At</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {foreignProductsArr.map((fp) => {
                const similarity = Number(fp.similarity) * 100;
                const similarityColor =
                  similarity < 50
                    ? 'red'
                    : similarity < 80
                    ? 'yellow'
                    : 'green';

                return (
                  <tr key={fp.foreignProduct.id}>
                    <td>{fp.foreignProduct.name}</td>
                    <td>
                      <Badge color={similarityColor} variant="light">
                        {similarity.toFixed(0)}% Match
                      </Badge>
                    </td>
                    <td>{fp.foreignProductSource.name}</td>
                    <td>
                      {formatToCurrency(fp.foreignProduct.price, currency)}
                    </td>
                    <td>{RFC3339ToEasyDate(fp.foreignProduct.syncedAt)}</td>
                    <td>
                      <Menu shadow="md" width={200}>
                        <Menu.Target>
                          <ActionIcon>
                            <IconDots />
                          </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                          <Menu.Item
                            icon={<IconExternalLink size={14} />}
                            component="a"
                            href={fp.foreignProduct.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Go to Website
                          </Menu.Item>

                          {updateProductName && (
                            <Menu.Item
                              icon={<IconCopy size={14} />}
                              onClick={() =>
                                showConfirmUpdateProductName(
                                  fp.foreignProduct.name
                                )
                              }
                            >
                              Adopt Name
                            </Menu.Item>
                          )}
                        </Menu.Dropdown>
                      </Menu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>
      </LoadingContainer>
    </Stack>
  );
};

const ProductTaxes = ({
  productID,
  taxType,
  taxes,
  reloadProduct,
}: {
  productID: string;
  taxType: 'sales' | 'purchasing';
  taxes?: Tax[];
  reloadProduct: () => void;
}) => {
  const removeSaleTaxFromProduct = useStatefulAPIRequestMaker(
    DefaultOperations.deleteProductSalesTax
  );
  const removePurchasingTaxFromProduct = useStatefulAPIRequestMaker(
    DefaultOperations.deleteProductPurchasingTax
  );
  const addSaleTaxToProduct = useStatefulAPIRequestMaker(
    DefaultOperations.addProductSalesTax
  );
  const addPurchasingTaxToProduct = useStatefulAPIRequestMaker(
    DefaultOperations.addProductPurchasingTax
  );

  const showAddTaxModal = () => {
    const modalID = uuidv4();

    openModal({
      modalId: modalID,
      title: <Title order={3}>Add {taxType ? 'Sale' : 'Purchasing'} Tax</Title>,
      children: (
        <AddTaxToProductModal
          closeModal={() => {
            closeModal(modalID);
          }}
          addTax={async (taxID: string) => {
            if (taxType === 'sales') {
              await addSaleTaxToProduct.execute({
                productId: productID,
                requestBody: { taxID },
              });
            } else {
              await addPurchasingTaxToProduct.execute({
                productId: productID,
                requestBody: {
                  taxID,
                },
              });
            }
          }}
        />
      ),
      onClose: () => {
        reloadProduct();
      },
    });
  };

  return (
    <Stack>
      <Group>
        <Button onClick={showAddTaxModal}>Add Tax</Button>
      </Group>

      <Table width="100%" withBorder captionSide="bottom">
        <thead>
          <tr>
            <th>Name</th>
            <th>Percent</th>
            <th />
          </tr>
        </thead>

        {taxes && taxes.length === 0 && (
          <caption>
            There are no taxes.{' '}
            <Anchor onClick={showAddTaxModal}>Click here to add a tax.</Anchor>
          </caption>
        )}

        <tbody>
          {(taxes || []).map((t) => {
            const onClick = async () => {
              if (taxType === 'sales') {
                await removeSaleTaxFromProduct.execute({
                  taxId: t.id,
                  productId: productID,
                });
              } else {
                await removePurchasingTaxFromProduct.execute({
                  taxId: t.id,
                  productId: productID,
                });
              }

              showNotification({
                color: 'green',
                title: 'Tax Removed',
                message: 'The tax has been removed.',
              });
              reloadProduct();
            };

            return (
              <tr key={t.id}>
                <td>{t.name}</td>
                <td>{t.percent}</td>
                <td>
                  <Button variant="subtle" color="red" onClick={onClick}>
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

const AddTaxToProductModal = ({
  closeModal,
  addTax,
}: {
  closeModal: () => void;
  addTax: (taxID: string) => Promise<void>;
}) => {
  const searchTaxes = useStatefulAPIRequestMaker(DefaultOperations.searchTaxes);
  const [target, setTarget] = useState('');

  useEffect(() => {
    if (target === '') {
      searchTaxes.execute({ target });
    } else {
      const t = setTimeout(() => {
        searchTaxes.execute({ target });
      }, 1000);

      return () => {
        clearTimeout(t);
      };
    }
  }, [target]);

  return (
    <Stack>
      <Input
        placeholder="Search"
        value={target}
        onChange={(e: any) => setTarget(e.target.value)}
      />

      <Table width="100%" withBorder>
        <thead>
          <tr>
            <th>Name</th>
            <th>Percent</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {(searchTaxes.response || []).map((t) => {
            const onClick = async () => {
              await addTax(t.id);
              showNotification({
                color: 'green',
                title: 'Tax Added',
                message: 'The tax has been added.',
              });
              closeModal();
            };

            return (
              <tr key={t.id}>
                <td>{t.name}</td>
                <td>{t.percent}</td>
                <td onClick={onClick}>
                  <Button variant="subtle" color="green">
                    Add
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};

const ProductGenericCodeSelectorModalBody = ({
  onProductGenericCodeSelected,
}: {
  onProductGenericCodeSelected: (gc: ProductGenericCode) => void;
}) => {
  const listGenericCodes = useStatefulAPIRequestMaker(
    DefaultOperations.listProductGenericCodes
  );

  const [target, setTarget] = useInputState('');

  useEffect(() => {
    const t = setTimeout(() => {
      listGenericCodes.execute({ genericCode: target });
    }, 200);

    return () => {
      clearTimeout(t);
    };
  }, [target]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for Generic Code"
        value={target}
        onChange={setTarget}
      />

      <LoadingContainer loading={listGenericCodes.loading}>
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>Generic Code</th>
              <th>Products</th>
            </tr>
          </thead>
          <tbody>
            {(listGenericCodes.response?.productGenericCodes || []).map(
              (genericCode) => {
                const onClick = async () => {
                  onProductGenericCodeSelected(genericCode.productGenericCode);
                };

                return (
                  <tr onClick={onClick} key={genericCode.productGenericCode.id}>
                    <td>{genericCode.productGenericCode.genericCode}</td>
                    <td>{genericCode.productGenericCode.productCount}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};

const ProductClassCodeSelector = ({
  onProductClassCodeSelected,
}: {
  onProductClassCodeSelected: (productClassCode: ProductClassCode) => void;
}) => {
  const listProductClassCodes = useStatefulAPIRequestMaker(
    DefaultOperations.listProductClassCodes
  );

  const [target, setTarget] = useInputState('');

  useEffect(() => {
    const t = setTimeout(() => {
      listProductClassCodes.execute({ classCode: target });
    }, 200);

    return () => {
      clearTimeout(t);
    };
  }, [target]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search for Class Code"
        value={target}
        onChange={setTarget}
      />

      <LoadingContainer loading={listProductClassCodes.loading}>
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>Class Code Code</th>
            </tr>
          </thead>
          <tbody>
            {(listProductClassCodes.response?.productClassCodes || []).map(
              (productClassCode) => {
                const onClick = async () => {
                  onProductClassCodeSelected(productClassCode);
                };
                return (
                  <tr onClick={onClick} key={productClassCode.id}>
                    <td>{productClassCode.classCode}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </LoadingContainer>
    </Stack>
  );
};

const ChangelogModal = ({ productID }: { productID: string }) => {
  const listChangelogs = useStatefulAPIRequestMaker(
    DefaultOperations.listProductChangelogs
  );

  useEffect(() => {
    listChangelogs.clearResponse();
    listChangelogs.execute({
      productId: productID,
    });
  }, [productID]);

  return (
    <ChangelogsTimelines
      loading={listChangelogs.loading}
      changelogs={listChangelogs.response?.changelogs ?? []}
    />
  );
};

const TargetMarginTab = ({
  product,
  targetMargin,
}: {
  product: Product;
  targetMargin: string;
}) => {
  const updateMargin = useStatefulAPIRequestMaker(
    DefaultOperations.updateProductTargetMargin
  );

  const form = useForm<{ margin: string }>({
    initialValues: { margin: '' },
  });

  useEffect(() => {
    form.setValues({ margin: targetMargin });
  }, [product, targetMargin]);

  const updateTargetMargin = form.onSubmit(async (v) => {
    const targetMarginBig = toBigOrNull(v.margin);

    if (!targetMarginBig) {
      showNotification({
        color: 'red',
        title: 'Invalid Margin',
        message: 'The margin value is invalid.',
      });
      return;
    }

    try {
      await updateMargin.execute({
        productId: product.id,
        requestBody: {
          targetMargin: v.margin.toString(),
        },
      });

      showNotification({
        color: 'green',
        title: 'Margin Updated',
        message: "The product's target margin has been updated.",
      });
    } catch (e) {
      handleApiErrorMessage(e);
    }
  });

  return (
    <Stack spacing={PX4.Number}>
      <Title order={3}>Target Margin</Title>
      <form onSubmit={updateTargetMargin}>
        <Stack>
          <TextInput
            label="Target Margin"
            placeholder="Target Margin"
            description="A value of '25' equals to 25%."
            {...form.getInputProps('margin')}
          />
          <Group>
            <Button type="submit" loading={updateMargin.loading}>
              Update Target Margin
            </Button>
          </Group>
        </Stack>
      </form>
    </Stack>
  );
};
