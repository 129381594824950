import { Badge, Text } from '@mantine/core';
import React from 'react';

export const EnabledBadge = ({
  enabled,
  fullWidth,
}: {
  enabled?: string | boolean;
  fullWidth?: boolean;
}) => {
  let color: string;
  let text: string;

  if (enabled || enabled === 'enabled') {
    color = 'green';
    text = 'Enabled';
  } else {
    color = 'red';
    text = 'Disabled';
  }

  return (
    <Badge color={color} variant="dot" fullWidth={fullWidth} size="md">
      <Text fw={700}>{text}</Text>
    </Badge>
  );
};
