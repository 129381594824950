import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations, Vendor } from '../../../oas/codegen3';
import React, { useEffect, useState } from 'react';
import { PX1536, PX32, PX384, PX64, PX96 } from '../../../components/Px';
import {
  Button,
  Code,
  Container,
  Group,
  Stack,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import {
  dateToRFC3339,
  RFC3339ToEasyDate,
} from '../../../utils/dateFormatting';
import { StatusBadge } from '@components/V2/StatusBadge';
import { formatToCurrency } from '@pcc/api/models/Currency';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { Link } from '@reach/router';
import { SelectorBadge } from '@components/V2/modal_selector/SelectorBadge';
import { closeModal, openModal } from '@mantine/modals';
import { ModalSelector } from '@components/V2/modal_selector/ModalSelectorBody';
import { VendorSelectorModalBody } from '@components/V2/modal_selector/VendorSelectorModalBody';
import {
  Comparator,
  ComparatorToPrettyString,
  NumericComparatorSelectorModal,
} from '@components/V2/modal_selector/NumericComparatorSelectorModal';
import { formatDateRange } from '../../../utils/formatStartAndEndDate';
import { DateRangePickerModal } from '@components/V2/modal_selector/DateRangePickerModal';

export const ListPurchaseOrderPayments = ({}: { path: string }) => {
  const listPurchaseOrderPayments = useStatefulAPIRequestMaker(
    DefaultOperations.listPurchaseOrderPayments
  );
  const [paymentState, setPaymentState] = useState<string | null>(null);
  const [balanceComparator, setBalanceComparator] =
    useState<{
      number: number;
      comparator: Comparator;
    } | null>(null);
  const [vendor, setVendor] = useState<Vendor | null>(null);

  const [createDateRange, setCreateDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const {
    pagination,
    onChangeDeps,
    target,
    setTargetFromInput,
    paginationElement,
  } = useListSearcherHelper({
    additionalDeps: [paymentState, vendor, balanceComparator, createDateRange],
    debounceDelay: 300,
    limitPerPage: 30,
  });

  useEffect(() => {
    const p = listPurchaseOrderPayments.execute({
      limit: pagination.limit,
      offset: pagination.offset,
      target,
      state: paymentState ?? undefined,
      vendorId: vendor?.id ?? undefined,
      balance: balanceComparator
        ? `${balanceComparator.comparator} ${balanceComparator.number}`
        : undefined,
      createDateStartDate: dateToRFC3339(createDateRange[0]) ?? undefined,
      createDateEndDate: dateToRFC3339(createDateRange[1]) ?? undefined,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  const showStateSelector = () => {
    openModal({
      modalId: 'state-selector-modal',
      title: 'Select State',
      children: (
        <ModalSelector
          items={[
            { label: 'Draft', value: 'draft' },
            { label: 'Posted', value: 'posted' },
            { label: 'Cancelled', value: 'cancelled' },
          ]}
          onItemSelected={(s) => {
            setPaymentState(s.value);
            closeModal('state-selector-modal');
          }}
        />
      ),
    });
  };

  const onVendorBadgeClicked = () => {
    openModal({
      modalId: 'vendor-selector-modal',
      title: 'Select Vendor',
      children: (
        <VendorSelectorModalBody
          onVendorSelected={(v) => {
            setVendor(v);
            closeModal('vendor-selector-modal');
          }}
        />
      ),
    });
  };

  const onBalanceComparatorClicked = () => {
    openModal({
      modalId: 'balance-comparator-modal',
      title: <Title order={3}>Select Balance</Title>,
      children: (
        <NumericComparatorSelectorModal
          number={balanceComparator?.number}
          comparator={balanceComparator?.comparator}
          onSelected={(nc) => {
            setBalanceComparator({
              number: nc.number,
              comparator: nc.comparator,
            });
            closeModal('balance-comparator-modal');
          }}
        />
      ),
    });
  };

  const showCreateDatePickerModal = () => {
    openModal({
      modalId: 'create-date-range-modal',
      title: <Title order={3}>Select Create Date Range</Title>,
      size: 'sm',
      children: (
        <DateRangePickerModal
          onDateRangeSelected={(dr) => {
            setCreateDateRange(dr);
            closeModal('create-date-range-modal');
          }}
        />
      ),
    });
  };

  return (
    <Container maw={PX1536.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <LoadingContainer
        loading={listPurchaseOrderPayments.loading}
        minHeight={PX384.Number}
      >
        <Stack>
          <Title>Purchase Order Payments</Title>

          <Group>
            <Button
              component={Link}
              to="/purchaseOrders/payments/new"
              color="green"
            >
              New Payment
            </Button>
          </Group>

          <TextInput
            label="Search"
            placeholder="Search"
            value={target}
            onChange={setTargetFromInput}
          />

          <Group>
            <SelectorBadge
              onClick={onVendorBadgeClicked}
              onClearClick={() => setVendor(null)}
              placeholder="Select Vendor"
              value={vendor?.name}
            />
            <SelectorBadge
              onClick={showStateSelector}
              onClearClick={() => setPaymentState(null)}
              placeholder="Select State"
              value={paymentState}
            />
            <SelectorBadge
              onClick={onBalanceComparatorClicked}
              onClearClick={() => setBalanceComparator(null)}
              placeholder="Select Balance"
              value={
                balanceComparator
                  ? `Balance ${ComparatorToPrettyString(
                      balanceComparator.comparator
                    )} ${balanceComparator.number}`
                  : null
              }
            />
            <SelectorBadge
              value={
                createDateRange[0]
                  ? `Created: ${formatDateRange(createDateRange)}`
                  : undefined
              }
              placeholder="Create Date"
              onClick={showCreateDatePickerModal}
              onClearClick={() => setCreateDateRange([null, null])}
            />
          </Group>

          {paginationElement(listPurchaseOrderPayments.response?.count)}
          <Table highlightOnHover withBorder>
            <thead>
              <tr>
                <th>Reference</th>
                <th>Vendor</th>
                <th>Voucher Reference</th>
                <th>Created At</th>
                <th>Payment Date</th>
                <th>State</th>
                <th style={{ textAlign: 'right' }}>Amount</th>
                <th style={{ textAlign: 'right' }}>Balance</th>
              </tr>
            </thead>

            <tbody>
              {listPurchaseOrderPayments.response?.purchaseOrderPayments.map(
                (p) => {
                  return (
                    <ClickableTableRow
                      targetURL={`/purchaseOrders/payments/${p.purchaseOrderPayment.id}`}
                      key={p.purchaseOrderPayment.id}
                    >
                      <td>
                        <Code style={{ whiteSpace: 'nowrap' }}>
                          {p.purchaseOrderPayment.reference}
                        </Code>
                      </td>
                      <td>{p.vendor?.name}</td>
                      <td>
                        <Code style={{ whiteSpace: 'nowrap' }}>
                          {p.purchaseOrderPayment.voucherReference}
                        </Code>
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {RFC3339ToEasyDate(p.purchaseOrderPayment.createdAt)}
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {RFC3339ToEasyDate(p.purchaseOrderPayment.paymentDate)}
                      </td>
                      <td>
                        <StatusBadge state={p.corePayment.state} />
                      </td>
                      <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                        {formatToCurrency(p.corePayment.amount, p.currency)}
                      </td>
                      <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                        {p.corePayment.amountBalance
                          ? formatToCurrency(
                              p.corePayment.amountBalance,
                              p.currency
                            )
                          : ''}
                      </td>
                    </ClickableTableRow>
                  );
                }
              )}
            </tbody>
          </Table>
          {paginationElement(listPurchaseOrderPayments.response?.count)}
        </Stack>
      </LoadingContainer>
    </Container>
  );
};
