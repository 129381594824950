import Big from 'big.js';

export const toBigOrNull = (s?: string | Big | number | null): Big | null => {
  if (s === null || s === undefined) {
    return null;
  }

  try {
    const b = new Big(s);
    return b;
  } catch (e) {
    return null;
  }
};

export const toBig = (
  s: string | number | null | undefined | Big,
  ifInvalid: Big | string | number
): Big => {
  if (s === null || s === undefined || s === '') {
    return new Big(ifInvalid);
  }

  try {
    const b = new Big(s);
    return b;
  } catch (e) {
    return new Big(ifInvalid);
  }
};
