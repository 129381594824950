import styled, { css } from 'styled-components';

export const Header4CSS = css`
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
`;

export const Header4 = styled.h4`
  ${Header4CSS};
  color: ${(props) => (props.color ? props.color : '#000')};
`;
