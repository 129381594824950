import {
  Button,
  Container,
  Flex,
  Input,
  Stack,
  Table,
  Title,
} from '@mantine/core';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import { useNavigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { useURLSearchQueryParamSetter } from '../../../hooks/useURLSearchQueryParamSetter';
import { DefaultOperations, Team } from '../../../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const TeamListPage = ({}: { path: string }) => {
  const navigate = useNavigate();
  const currentUrlParams = new URLSearchParams(window.location.search);
  const search = currentUrlParams.get('search');
  const [target, setTarget] = useState(search || '');

  useURLSearchQueryParamSetter({ search: target }, [target]);

  const listTeams = useStatefulAPIRequestMaker(DefaultOperations.listTeams);

  useEffect(() => {
    const t = setTimeout(() => {
      listTeams.execute({ name: target });
    }, 200);

    return () => {
      clearTimeout(t);
    };
  }, [target]);

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Teams</Title>
        <Flex>
          <Button color="green" onClick={() => navigate('/teams/new')}>
            New Team
          </Button>
        </Flex>
        <Input
          placeholder="Search"
          onChange={(e: any) => setTarget(e.target.value)}
          value={target}
        />
        <TeamsTable teams={listTeams.response?.teams || []} />
      </Stack>
    </Container>
  );
};

const TeamsTable = ({ teams }: { teams: Team[] }) => {
  const navigate = useNavigate();

  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {teams.map((t) => {
          const onClick = () => {
            navigate(`/teams/${t.id}`);
          };

          return (
            <tr onClick={onClick} key={t.id}>
              <td>{t.name}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
