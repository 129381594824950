import { useStatefulAPIRequestMaker } from '../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../oas/codegen3';
import { useListSearcherHelper } from '../../../hooks/useListSearcherHelper';
import { useEffect } from 'react';
import { Code, Stack, Table, TextInput } from '@mantine/core';
import { LoadingContainer } from '@components/V2/LoadingContainer';
import { ClickableTableRow } from '@components/V2/ClickableTableRow';
import { RFC3339ToEasyDateTime } from '../../../utils/dateFormatting';
import { StatusBadge } from '@components/V2/StatusBadge';

export const StockAdjustmentGroupsList = ({
  stockLocationID,
}: {
  stockLocationID?: string;
}) => {
  const listStockAdjustments = useStatefulAPIRequestMaker(
    DefaultOperations.listStockAdjustments
  );

  const {
    target,
    setTargetFromInput,
    pagination,
    onChangeDeps,
    paginationElement,
  } = useListSearcherHelper({
    additionalDeps: [],
    debounceDelay: 200,
    limitPerPage: 50,
  });

  useEffect(() => {
    const p = listStockAdjustments.execute({
      limit: pagination.limit,
      offset: pagination.offset,
      stockLocationId: stockLocationID,
      target,
    });

    return () => p.cancel();
  }, [...onChangeDeps]);

  return (
    <Stack>
      <TextInput
        label="Search"
        placeholder="Search"
        value={target}
        onChange={setTargetFromInput}
      />

      <LoadingContainer loading={listStockAdjustments.loading}>
        <Stack>
          {paginationElement(listStockAdjustments.response?.count)}
          <Table highlightOnHover withBorder>
            <thead>
              <tr>
                <th>Number</th>
                <th>Stock Location</th>
                <th>Created At</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              {(listStockAdjustments.response?.stockAdjustmentGroups || []).map(
                (sa) => (
                  <ClickableTableRow
                    targetURL={`/stockAdjustmentGroups/${sa.stockAdjustmentGroup.id}`}
                    key={sa.stockAdjustmentGroup.id}
                  >
                    <td>
                      <Code>{sa.stockAdjustmentGroup.number}</Code>
                    </td>
                    <td>{sa.stockLocation.name}</td>
                    <td>
                      {RFC3339ToEasyDateTime(sa.stockAdjustmentGroup.createdAt)}
                    </td>
                    <td>
                      <StatusBadge state={sa.stockAdjustmentGroup.state} />
                    </td>
                  </ClickableTableRow>
                )
              )}
            </tbody>
          </Table>
          {paginationElement(listStockAdjustments.response?.count)}
        </Stack>
      </LoadingContainer>
    </Stack>
  );
};
