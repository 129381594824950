import {
  DefaultOperations,
  PermissionAction,
  PermissionResource,
  Privilege,
} from '../oas/codegen3';
import { useStatefulAPIRequestMaker } from '../hooks/useStatefulAPIRequestMaker';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Switch,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useInputState } from '@mantine/hooks';
import { PX384 } from '../components/Px';

export const StorePermissionsSection = ({
  entityID,
  entityType,
  showStoreSelector,
}: {
  entityID: string;
  showStoreSelector: boolean;
  entityType: 'user' | 'team';
}) => {
  const searchStores = useStatefulAPIRequestMaker(
    DefaultOperations.searchStores
  );
  const [storeInFocusID, setStoreInFocusID] =
    useInputState<undefined | string>(undefined);

  useEffect(() => {
    if (showStoreSelector && !searchStores.response?.stores) {
      searchStores.execute({ target: '' });
    }
  }, [searchStores.response?.stores]);

  return (
    <div style={{ position: 'relative', minHeight: PX384.Number }}>
      <LoadingOverlay visible={searchStores.loading} />
      <Stack>
        {showStoreSelector && (
          <Select
            label="Select Store"
            onChange={setStoreInFocusID}
            data={(searchStores.response?.stores || []).map((s) => {
              return {
                value: s.id,
                label: s.name,
              };
            })}
          />
        )}

        {(storeInFocusID || !showStoreSelector) && (
          <SystemActionPermissionsManager
            entityID={entityID}
            entityType={entityType}
            storeID={storeInFocusID}
          />
        )}
      </Stack>
    </div>
  );
};

const SystemActionPermissionsManager = ({
  entityID,
  entityType,
  storeID,
}: {
  entityID: string;
  entityType: 'user' | 'team';
  storeID?: string;
}) => {
  const [privileges, setPrivileges] = useState<Privilege[]>([]);
  const getUserPrivileges = useStatefulAPIRequestMaker(
    DefaultOperations.getUserPrivileges
  );

  const getTeamPrivileges = useStatefulAPIRequestMaker(
    DefaultOperations.getTeamPrivileges
  );

  useEffect(() => {
    const fn = async () => {
      if (entityType === 'user') {
        const response = await getUserPrivileges.execute({
          userId: entityID,
          storeId: storeID,
        });
        setPrivileges(response.privileges);
      } else {
        const response = await getTeamPrivileges.execute({
          teamId: entityID,
          storeId: storeID,
        });
        setPrivileges(response.privileges);
      }
    };

    fn();
  }, [storeID, entityID]);

  return (
    <Stack>
      <LoadingOverlay visible={getUserPrivileges.loading} />
      {allPermissions
        .filter((c) =>
          storeID
            ? c.permissions.findIndex((p) => p.isStoreResource) !== -1
            : c.permissions.findIndex((p) => p.isSystemResource) !== -1
        )
        .map((aPermissionCategory) => (
          <Stack>
            <Title order={3} key={aPermissionCategory.categoryName}>
              {aPermissionCategory.categoryName}
            </Title>
            <Stack>
              {aPermissionCategory.permissions
                .filter((p) =>
                  storeID ? p.isStoreResource : p.isSystemResource
                )
                .map((p) => {
                  return (
                    <PrivilegeRowController
                      privileges={privileges.filter(
                        (aPrivilege) => aPrivilege.resource === p.resource
                      )}
                      entityID={entityID}
                      entityType={entityType}
                      storeID={storeID}
                      resource={p.resource}
                      actions={p.actions
                        .filter((a) =>
                          storeID ? a.isStorePermission : a.isSystemPermission
                        )
                        .map((a) => a.action)}
                      key={p.resource}
                    />
                  );
                })}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

const PrivilegeRowController = ({
  entityID,
  entityType,
  storeID,
  resource,
  actions,
  privileges,
}: {
  entityID: string;
  entityType: 'user' | 'team';
  storeID?: string;
  resource: PermissionResource;
  actions: PermissionAction[];
  privileges: Privilege[];
}) => {
  const hasReadAction = actions.find((a) => a === 'read');
  const hasCreateAction = actions.find((a) => a === 'create');
  const hasUpdateAction = actions.find((a) => a === 'update');
  const hasDeleteAction = actions.find((a) => a === 'delete');
  const hasTriggerAction = actions.find((a) => a === 'trigger');
  const hasWriteAction = actions.find((a) => a === 'write');

  return (
    <Card shadow="xs" radius="md" withBorder>
      <Stack>
        <Title order={5}>{resource}</Title>
        <Group position="apart">
          {hasReadAction && (
            <PermissionActionController
              action="read"
              resource={resource}
              storeID={storeID}
              entityID={entityID}
              entityType={entityType}
              privilege={privileges.find((p) => p.action === 'read')}
            />
          )}

          {hasCreateAction && (
            <PermissionActionController
              action="create"
              resource={resource}
              storeID={storeID}
              entityID={entityID}
              entityType={entityType}
              privilege={privileges.find((p) => p.action === 'create')}
            />
          )}

          {hasUpdateAction && (
            <PermissionActionController
              action="update"
              resource={resource}
              storeID={storeID}
              entityID={entityID}
              entityType={entityType}
              privilege={privileges.find((p) => p.action === 'update')}
            />
          )}

          {hasDeleteAction && (
            <PermissionActionController
              action="delete"
              resource={resource}
              storeID={storeID}
              entityID={entityID}
              entityType={entityType}
              privilege={privileges.find((p) => p.action === 'delete')}
            />
          )}

          {hasTriggerAction && (
            <PermissionActionController
              action="trigger"
              resource={resource}
              storeID={storeID}
              entityID={entityID}
              entityType={entityType}
              privilege={privileges.find((p) => p.action === 'trigger')}
            />
          )}

          {hasWriteAction && (
            <PermissionActionController
              action="write"
              resource={resource}
              storeID={storeID}
              entityID={entityID}
              entityType={entityType}
              privilege={privileges.find((p) => p.action === 'write')}
            />
          )}
        </Group>
      </Stack>
    </Card>
  );
};

const PermissionActionController = ({
  action,
  resource,
  entityID,
  entityType,
  storeID,
  privilege,
}: {
  entityID: string;
  entityType: 'user' | 'team';
  storeID?: string;
  resource: PermissionResource;
  action: PermissionAction;
  privilege?: Privilege;
}) => {
  const updateUserPrivilege = useStatefulAPIRequestMaker(
    DefaultOperations.updateUserPrivilege
  );
  const updateTeamPrivilege = useStatefulAPIRequestMaker(
    DefaultOperations.updateTeamPrivilege
  );

  const [checked, setChecked] = useState(privilege !== undefined);

  useEffect(() => {
    setChecked(privilege !== undefined);
  }, [privilege, storeID]);

  const onChange = async () => {
    if (entityType === 'user') {
      await updateUserPrivilege.execute({
        userId: entityID,
        requestBody: {
          storeID,
          action,
          resource,
          remove: checked,
        },
      });

      showNotification({
        color: 'green',
        title: `Permission Updated`,
        message: `Permission has been ${checked ? 'removed' : 'added'}.`,
      });

      setChecked(!checked);
    } else {
      await updateTeamPrivilege.execute({
        teamId: entityID,
        requestBody: {
          storeID,
          action,
          resource,
          remove: checked,
        },
      });

      showNotification({
        color: 'green',
        title: `Permission Updated`,
        message: `Permission has been ${checked ? 'removed' : 'added'}.`,
      });

      setChecked(!checked);
    }
  };

  return (
    <Switch
      label={action}
      disabled={updateUserPrivilege.loading}
      checked={checked}
      onChange={onChange}
    />
  );
};

const allPermissions: {
  categoryName: string;
  description?: string;
  permissions: {
    resource: PermissionResource;
    isStoreResource?: boolean;
    isSystemResource?: boolean;
    actions: {
      action: PermissionAction;
      description?: string;
      isStorePermission?: boolean;
      isSystemPermission?: boolean;
    }[];
  }[];
}[] = [
  {
    categoryName: 'Analytics',
    permissions: [
      {
        resource: 'profitability_analytics',
        isSystemResource: true,
        actions: [
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Cashier Sessions',
    permissions: [
      {
        resource: 'cashier_session',
        isSystemResource: true,
        actions: [
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'cashier_work_session.review',
        isSystemResource: true,
        actions: [
          {
            action: 'trigger',
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Users',
    permissions: [
      {
        resource: 'user',
        isSystemResource: true,
        actions: [
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Sales',
    permissions: [
      {
        resource: 'pos_sale',
        isStoreResource: true,
        actions: [
          {
            action: 'write',
            isStorePermission: true,
          },
        ],
      },
      {
        resource: 'sale_return_code',
        isStoreResource: true,
        actions: [
          {
            action: 'write',
            isStorePermission: true,
          },
        ],
      },
      {
        resource: 'sale',
        isSystemResource: true,
        actions: [
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'sale_payment',
        isSystemResource: true,
        actions: [
          {
            action: 'write',
            isSystemPermission: true,
          },
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Product Management',
    permissions: [
      {
        resource: 'product',
        isSystemResource: true,
        actions: [
          {
            action: 'write',
            isSystemPermission: true,
          },
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'product.margin',
        isSystemResource: true,
        actions: [
          {
            action: 'write',
            isSystemPermission: true,
          },
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'product.price',
        isSystemResource: true,
        actions: [
          {
            action: 'write',
            isSystemPermission: true,
          },
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Vendors',
    permissions: [
      {
        resource: 'vendor',
        isSystemResource: true,
        actions: [
          {
            action: 'write',
            isSystemPermission: true,
          },
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'vendor_contact',
        isSystemResource: true,
        actions: [
          {
            action: 'write',
            isSystemPermission: true,
          },
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'vendor_analytics',
        isSystemResource: true,
        actions: [
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Purchasing',
    permissions: [
      {
        resource: 'purchase_order',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'write',
            isStorePermission: true,
            isSystemPermission: true,
          },
          {
            action: 'read',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'purchase_order_payment',
        isSystemResource: true,
        actions: [
          {
            action: 'write',
            isSystemPermission: true,
          },
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'fresh_purchase_order',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'write',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'completed_purchase_order',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'write',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'purchase_order.set_ready',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'purchase_order.set_collected',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'purchase_order.set_cancelled',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'purchase_order.set_posted',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Stock Management',
    permissions: [
      {
        resource: 'stock_balance',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'read',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'stock_check',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'write',
            isStorePermission: true,
            isSystemPermission: true,
          },
          {
            action: 'read',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'stock_check.post',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'stock_check.cancel',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'stock_move',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'write',
            isStorePermission: true,
            isSystemPermission: true,
          },
          {
            action: 'read',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'stock_move.ready',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'stock_move.post',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
      {
        resource: 'stock_move.cancel',
        isSystemResource: true,
        isStoreResource: true,
        actions: [
          {
            action: 'trigger',
            isStorePermission: true,
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Foreign Products',
    permissions: [
      {
        resource: 'foreign_products',
        isSystemResource: true,
        actions: [
          {
            action: 'read',
            isSystemPermission: true,
          },
        ],
      },
    ],
  },
];
