import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { StockAdjustmentGroupsList } from './StockAdjustmentGroupsList';
import { PX1024, PX32, PX64, PX96 } from '../../../components/Px';
import { Button, Container, Group, Stack, Title } from '@mantine/core';
import React from 'react';
import { navigate } from '@reach/router';

export const ListStockAdjustmentGroupsPage = ({
  stockLocationID,
}: {
  path?: string;
  stockLocationID?: string;
}) => {
  useDocumentTitle('Stock Adjustments');

  return (
    <Container maw={PX1024.PX} px={PX32.PX} pt={PX64.PX} pb={PX96.PX}>
      <Stack>
        <Title>Stock Adjustments</Title>
        <Group>
          <Button
            color="green"
            onClick={() => navigate('/stockAdjustmentGroups/new')}
          >
            New Stock Adjustment
          </Button>
        </Group>
        <StockAdjustmentGroupsList stockLocationID={stockLocationID} />
      </Stack>
    </Container>
  );
};
