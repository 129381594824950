/* eslint-disable  @typescript-eslint/no-explicit-any */
export const ClickableTableRow = ({
  onClick,
  children,
  targetURL,
}: {
  onClick?: () => void;
  targetURL?: string;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const onRowClick = (e: any) => {
    if (onClick) {
      onClick();
    }

    if (targetURL) {
      if (!e.ctrlKey && !e.metaKey && !e.shiftKey) {
        window.open(targetURL);
      } else {
        window.open(targetURL);
      }
    }
  };

  return (
    <tr style={{ cursor: 'pointer' }} onClick={onRowClick}>
      {children}
    </tr>
  );
};
