import { formatDate } from './dateFormatting';

export const formatStartAndEndDate = (
  startDate: Date | null,
  endDate: Date | null
): string | null => {
  if (!startDate || !endDate) {
    return null;
  }

  const startDateString = formatDate(startDate);
  const endDateString = formatDate(endDate);
  return `${startDateString} → ${endDateString}`;
};

export const formatDateRange = (
  dateRange: [Date | null, Date | null]
): string | null => {
  return formatStartAndEndDate(dateRange[0], dateRange[1]);
};
