import { PX12, PX24, PX384 } from '../../../../components/Px';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Rectangle,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import React, { useEffect, useMemo } from 'react';
import { useStatefulAPIRequestMaker } from '../../../../hooks/useStatefulAPIRequestMaker';
import { DefaultOperations } from '../../../../oas/codegen3';
import { RFC3339ToPrettyMonthDontModifyTimezone } from '../../../../utils/dateFormatting';
import { Center, Loader, useMantineTheme } from '@mantine/core';
import { DateTime } from 'luxon';
import { AxisDomain } from 'recharts/types/util/types';

export const ProductPurchasingAndSalesChart = ({
  productID,
  storeID,
  height,
  dateRange,
  highlightMonths,
}: {
  productID: string;
  storeID?: string;
  height?: number;
  dateRange?: {
    startMonth: DateTime;
    endMonth: DateTime;
  };
  highlightMonths?: DateTime[];
}) => {
  const theme = useMantineTheme();

  const referenceLines = useMemo(() => {
    const rl = [];
    if (highlightMonths) {
      highlightMonths.forEach((hm) => {
        const monthStr = hm.toFormat('MMM') + " '" + hm.toFormat('yy');
        rl.push(
          <ReferenceArea
            x1={monthStr}
            x2={monthStr}
            fill={theme.colors.orange[1]}
          />
        );
      });
    } else {
      let dt = DateTime.now();
      const thisMonthStr = dt.toFormat('MMM') + " '" + dt.toFormat('yy');
      dt = dt.set({ year: dt.year - 1 });
      const thisMonthLastYearStr =
        dt.toFormat('MMM') + " '" + dt.toFormat('yy');

      rl.push(
        <ReferenceArea
          x1={thisMonthStr}
          x2={thisMonthStr}
          fill={theme.colors.orange[1]}
        />
      );

      rl.push(
        <ReferenceArea
          x1={thisMonthLastYearStr}
          x2={thisMonthLastYearStr}
          fill={theme.colors.orange[1]}
        />
      );
    }
    return rl;
  }, [highlightMonths, dateRange]);

  const getMonthlyPCsStats = useStatefulAPIRequestMaker(
    DefaultOperations.getProductMonthlyPcsStats
  );

  useEffect(() => {
    const p = getMonthlyPCsStats.execute({
      productId: productID,
      storeId: storeID,
      startMonth: dateRange?.startMonth.toISO() ?? undefined,
      endMonth: dateRange?.endMonth.toISO() ?? undefined,
    });

    return () => p.cancel();
  }, [productID, storeID]);

  const data = React.useMemo(() => {
    if (!getMonthlyPCsStats.response) {
      return [];
    }

    return getMonthlyPCsStats.response.monthlyPCsSold.map((m, i) => {
      const pcsSold = Number(m.pcsSold);
      const pcsPurchased = Number(
        getMonthlyPCsStats.response?.monthlyPCsPurchased[i].pcsPurchased ?? 0
      );
      const pcsSupplierReturn =
        Number(
          getMonthlyPCsStats.response?.monthlyPCsPurchased[i]
            .pcsReturnedPurchase
        ) ?? 0;

      return {
        date: RFC3339ToPrettyMonthDontModifyTimezone(m.monthDate),
        'PCs Sold': pcsSold,
        'PCs Purchased': pcsPurchased,
        'PCs Supplier Return': pcsSupplierReturn,
        'Sales vs. Purchases Difference': pcsSold - pcsPurchased,
      };
    });
  }, [getMonthlyPCsStats.response]);

  const yRangeCalculator: AxisDomain = ([dataMin, dataMax]: [
    number,
    number
  ]) => {
    const totalRange = Math.abs(dataMin) + dataMax;
    const padding = Math.ceil(totalRange * 0.15);

    const min = dataMin === 0 ? 0 : Math.floor(dataMin) - padding;
    const max = dataMax === 0 ? 0 : Math.floor(dataMax) + padding;

    return [min, max];
  };

  const labelFormatter = (v: number) => {
    if (v === 0) {
      return '';
    }
    return v;
  };

  return (
    <>
      {getMonthlyPCsStats.loading && (
        <Center h={height ?? PX384.Number}>
          <Loader />
        </Center>
      )}
      {!getMonthlyPCsStats.loading && getMonthlyPCsStats.response && (
        <div style={{ position: 'relative', height: height ?? PX384.Number }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={data}
              stackOffset="sign"
              height={PX384.Number}
              margin={{ top: PX24.Number, bottom: PX24.Number }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                padding={{ left: PX12.Number, right: PX12.Number }}
              />
              <YAxis type="number" domain={yRangeCalculator} />
              {referenceLines}
              <Tooltip />
              <Legend />
              <ReferenceLine y={0} stroke="#000" />
              <Bar
                dataKey="PCs Purchased"
                fill={theme.colors.grape[7]}
                stackId="purchasing"
                activeBar={
                  <Rectangle
                    fill={theme.colors.grape[2]}
                    stroke={theme.colors.grape[7]}
                  />
                }
              >
                <LabelList
                  dataKey="PCs Purchased"
                  position="top"
                  fill={theme.colors.grape[7]}
                  formatter={labelFormatter}
                />
              </Bar>

              <Bar
                dataKey="PCs Supplier Return"
                fill={theme.colors.pink[7]}
                stackId="purchasing"
                activeBar={
                  <Rectangle
                    fill={theme.colors.pink[2]}
                    stroke={theme.colors.pink[7]}
                  />
                }
              >
                <LabelList
                  dataKey="PCs Supplier Return"
                  position="top"
                  fill={theme.colors.pink[7]}
                  formatter={labelFormatter}
                />
              </Bar>

              <Bar
                dataKey="PCs Sold"
                fill={theme.colors.teal[7]}
                activeBar={
                  <Rectangle
                    fill={theme.colors.teal[2]}
                    stroke={theme.colors.teal[7]}
                  />
                }
              >
                <LabelList
                  dataKey="PCs Sold"
                  position="top"
                  fill={theme.colors.teal[7]}
                  formatter={labelFormatter}
                />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};
